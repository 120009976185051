import {
  whisk_backoffice_b2c_v1_DirectoryDomainRuleAPI_AddDomainRule as AddDomainRuleAPI,
  whisk_backoffice_b2c_v1_AddDomainRuleRequest as AddDomainRuleRequest,
  whisk_backoffice_b2c_v1_DirectoryDomainRuleAPI_DeleteDomainRule as DeleteDomainRuleAPI,
  whisk_backoffice_b2c_v1_DeleteDomainRuleRequest as DeleteDomainRuleRequest,
  whisk_backoffice_b2c_v1_ListDomainsRequest as ListDomainRuleRequest,
  whisk_backoffice_b2c_v1_DirectoryDomainRuleAPI_ListDomainRules as ListDomainRulesAPI,
  whisk_backoffice_b2c_v1_DirectoryDomainRuleAPI_UpdateDomainRule as UpdateDomainRuleAPI,
  whisk_backoffice_b2c_v1_UpdateDomainRuleRequest as UpdateDomainRuleRequest,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

const api = b2cGrpc();

export const removeDomain = (payload: DeleteDomainRuleRequest) => api(DeleteDomainRuleAPI, payload);
export const updateDomain = (payload: UpdateDomainRuleRequest) => api(UpdateDomainRuleAPI, payload);
export const createDomain = (payload: AddDomainRuleRequest) => api(AddDomainRuleAPI, payload);
export const loadDomains = (payload: ListDomainRuleRequest) => api(ListDomainRulesAPI, payload);
