import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { User, getAllUsers } from '../api';

export class UsersTableStore extends BaseStore {
  private readonly userById = observable.map<string, User>([], { deep: false });

  @observable totalItems = 0;

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.loader.reset();
    this.userById.clear();
    this.totalItems = 0;
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get users(): User[] {
    return Array.from(this.userById.values());
  }

  @action loadUsers = async () => {
    const { cancelled, data, error } = await this.loader.load(() => getAllUsers());

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.userById.replace(data.user.map((user) => [user.id, user]));
      this.totalItems = data.user.length;
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action handleUserDeleted = (user: User) => {
    this.userById.delete(user.id);
    this.totalItems = this.totalItems - 1;
  };
}
