import { action, makeObservable } from 'mobx';

import { Campaign, deleteCampaign } from 'b2b/campaigns/api';
import { DataLoadersMap } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

export class CampaignDeleteStore extends BaseStore {
  readonly deleteLoaders = new DataLoadersMap();

  @action storeReset = () => {
    this.deleteLoaders.reset();
  };

  @action deleteCampaign = async (campaign: Campaign) => {
    const deleteLoader = this.deleteLoaders.get(campaign.id);
    const { cancelled, success, error } = await deleteLoader.load(() => deleteCampaign({ campaignId: campaign.id }));

    if (cancelled) {
      return;
    }

    if (success) {
      getStore('toast').success(`Deleted campaign "${campaign.name}"`);
      deleteLoader.ok();
    } else {
      getStore('toast').error(`Failed to delete campaign "${campaign.name}"`, error);
      deleteLoader.fail(error);
    }
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
