import { Classes, Spinner } from '@blueprintjs/core';
import { isFunction, isObject, isPresent } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { ReactNode, forwardRef, useCallback, useLayoutEffect, useMemo, useRef } from 'react';

import { useIntersection } from 'common/hooks/use-intersection';
import { clsFlex, clsFlexAlignItemsCenter, clsFlexJustifyCenter } from 'common/styles/layout';

const SPINNER_HEIGHT = Spinner.SIZE_SMALL;
// Spinner is a rectangle and it's diagonal is longer than it's side
// which causes height adjustments of the container and scroll appearing/disappearing
// We use diagonal's height for container to prevent it
const SPINNER_WRAPPER_HEIGHT = Math.sqrt(2) * SPINNER_HEIGHT;

const clsScrollContainer = css`
  padding-left: 0;
  overflow: auto;
  list-style: none;
  & .${Classes.MENU} {
    max-height: none !important;
    height: auto !important;
    overflow: visible !important;
  }
`;

const clsSentinelWrapper = css`
  position: relative;
  height: 0;
`;

const clsSentinel = css`
  position: absolute;
  width: 100%;
  min-height: 1px;
  bottom: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
`;

export interface Props {
  children?: ReactNode;
  loadMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
  isEmpty: boolean;
  emptyElement?: ReactNode;
  minThresholdPx?: number;
  thresholdRatio?: number;
  className?: string;
  preloader?: ReactNode | false;
  useScrollContentAsRoot?: boolean;
}

export const InfiniteScroll = forwardRef<HTMLUListElement, Props>(
  (
    {
      thresholdRatio = 0.9,
      minThresholdPx = 1,
      children,
      emptyElement,
      loadMore,
      hasMore,
      isLoading,
      isEmpty,
      className,
      preloader,
      useScrollContentAsRoot = true,
    },
    ref
  ) => {
    const scrollRef = useRef<HTMLUListElement | null>(null);
    const sentinelRef = useRef<HTMLDivElement | null>(null);

    const intersectionObserverOptions = useMemo(
      () => ({
        threshold: thresholdRatio,
        root: useScrollContentAsRoot ? scrollRef : undefined,
      }),
      [thresholdRatio, useScrollContentAsRoot]
    );

    const handleIntersection = useCallback(
      (entry?: IntersectionObserverEntry) => {
        if (!isLoading && hasMore && entry?.isIntersecting && entry.intersectionRatio > 0) {
          loadMore();
        }
      },
      [hasMore, loadMore, isLoading]
    );

    const handleScrollRef = useCallback(
      (el: HTMLUListElement) => {
        scrollRef.current = el;
        if (isFunction(ref)) {
          ref(el);
        } else if (isObject(ref)) {
          ref.current = el;
        }
      },
      [ref, scrollRef]
    );

    useIntersection(sentinelRef, intersectionObserverOptions, handleIntersection);

    useLayoutEffect(() => {
      if (isPresent(scrollRef.current) && isPresent(sentinelRef.current) && useScrollContentAsRoot) {
        const computedStyle = window.getComputedStyle(scrollRef.current);
        const verticalPadding =
          (parseFloat(computedStyle.getPropertyValue('padding-top')) || 0) +
          (parseFloat(computedStyle.getPropertyValue('padding-bottom')) || 0);

        const contentHeight = Math.max(0, document.documentElement.clientHeight - verticalPadding);
        scrollRef.current.style.height = `${Math.floor(contentHeight * thresholdRatio)}px`;
      }
    });

    return (
      <ul ref={handleScrollRef} className={cx(clsScrollContainer, className)}>
        {isEmpty ? (isLoading ? null : emptyElement) : children}

        {isLoading
          ? preloader ?? (
              <li
                key="infinite-scroll-spinner"
                className={cx(clsFlex, clsFlexAlignItemsCenter, clsFlexJustifyCenter)}
                style={{ height: SPINNER_WRAPPER_HEIGHT }}
              >
                <Spinner size={SPINNER_HEIGHT} />
              </li>
            )
          : null}
        <li key="infinite-scroll-sentinel" className={clsSentinelWrapper}>
          <div ref={sentinelRef} className={clsSentinel} style={{ minHeight: minThresholdPx }} />
        </li>
      </ul>
    );
  }
);
