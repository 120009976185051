import { memo } from 'react';

import { PageHeader } from 'common/components/page-header';
import { useStore } from 'common/hooks/use-store';

import { CampaignsTableStore } from './store';
import { CampaignsTable } from './table';

export const CampaignsTablePage = memo(() => {
  const store = useStore(() => new CampaignsTableStore());

  return (
    <div>
      <PageHeader title="Campaigns" />
      <CampaignsTable store={store} />
    </div>
  );
});
