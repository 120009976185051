import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { PageHeader } from 'common/components/page-header';
import { RouterTab, RouterTabs } from 'common/components/router-tabs';

import { TrustedUsersAddPage } from './add';
import { TrustedUsersListPage } from './list';
import { TrustedUsersRemovePage } from './remove';

export const TrustedUsersPage = observer(() => {
  const tabs = useMemo(
    (): RouterTab[] => [
      { path: '/add', label: 'Add', component: TrustedUsersAddPage },
      { path: '/remove', label: 'Remove', component: TrustedUsersRemovePage },
      { path: '/list', label: 'List', component: TrustedUsersListPage },
    ],
    []
  );

  return (
    <>
      <PageHeader title="Trusted users" />
      <RouterTabs tabs={tabs} />
    </>
  );
});
