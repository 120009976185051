import { Location } from 'history';

import { parseQuery } from 'common/helpers/query';

export const initialQuery = parseQuery(window.location.search);

/**
 * Meant to be used with return values of `useRouteMatch()`
 * Normalizes concatenation of paths, avoids:
 * - double-slash issue, e.g. when parent path is `/`.
 * - no slash between paths
 *
 * @example
 * ```
 * const { path, url } = useRouteMatch();
 * `join(path, '/users/:id')` // instead of `${path}/users/:id`
 * `join(url, '/users/1')` // instead of `${url}/users/1`
 * ```
 */
export const join = (basePath: string, path = ''): string =>
  [basePath.endsWith('/') ? basePath.slice(0, -1) : basePath, path.startsWith('/') ? path.slice(1) : path].join('/');

export const getLocationPath = (location: Location): string => `${location.pathname}${location.search}${location.hash}`;
