import { css } from 'linaria';

export const clsTextAlignLeft = css`
  text-align: left !important;
`;

export const clsTextAlignRight = css`
  text-align: right !important;
`;

export const clsLineHeight1 = css`
  line-height: 1 !important;
`;

// Don't forget to add title attribute to that html element with full text
export const textTruncate = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
