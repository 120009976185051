import { observer } from 'mobx-react-lite';

import { useStore } from 'common/hooks/use-store';

import { TrustedUsersListStore } from './store';
import { UserListTable } from './table';

export const FlaggedTextTable = observer(() => {
  const store = useStore(() => new TrustedUsersListStore());
  return <UserListTable store={store} />;
});
