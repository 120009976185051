import { observer } from 'mobx-react-lite';

import { useStore } from 'common/hooks/use-store';

import { BlockedUrlsStore } from './store';
import { Table } from './table';

export const BlockedUrlsTable = observer(() => {
  const store = useStore(() => new BlockedUrlsStore());

  return (
    <>
      <Table store={store} />
    </>
  );
});
