import { Callout, Intent } from '@blueprintjs/core';
import { memo } from 'react';

import { clsMb30 } from 'common/styles/margin-padding';

interface Props {
  delay?: string;
}

export const NoticeDelayedChanges = memo(({ delay = '30 minutes' }: Props) => (
  <Callout intent={Intent.WARNING} className={clsMb30}>
    Some changes may take up to {delay} to propagate
  </Callout>
));
