import { action, makeObservable } from 'mobx';

import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { BaseStore, getStore } from 'common/stores';

import { DeleteUserFromTrustedRequest as Request, deleteUserFromTrusted } from '../../api';

export class UserRemoveFormStore extends BaseStore {
  readonly loader = new DataLoader();

  readonly form = new FormModel({
    userId: new FieldModel('').validators(validateRequired),
  });

  @action storeReset = () => {
    this.loader.reset();
    this.form.reset();
  };

  @action sumbitForm = ({ userId }: { userId: string }) => {
    void this.send({ userId });
  };

  @action send = async (payload: Request) => {
    const { cancelled, success, error, data } = await this.loader.load(() => deleteUserFromTrusted(payload));

    if (cancelled) {
      return;
    }

    if (success) {
      if (data.result?.oneof === 'ok') {
        getStore('toast').success('User removed');
        this.form.reset();
        this.loader.ok();
      } else {
        this.loader.warn(data.result);
      }
    } else {
      this.loader.fail(error);
    }
  };

  constructor() {
    super();

    makeObservable(this);
  }
}
