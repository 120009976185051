import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable } from 'mobx';

import { updateApp } from 'b2b/apps/api';
import { AppDetailsStore } from 'b2b/apps/details/store';
import { DataLoader } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { AuthProvider } from './api';

export class AppAuthProvidersStore extends BaseStore {
  readonly saveLoader = new DataLoader();

  constructor(private readonly appDetailsStore: AppDetailsStore) {
    super();
    makeObservable(this);
  }

  @computed get appAuthProviders(): AuthProvider[] {
    return this.appDetailsStore.app.credentials?.authProviders ?? [];
  }

  @action save = async (appAuthProviders: AuthProvider[]) => {
    const { cancelled, data, error } = await this.saveLoader.load(() =>
      updateApp(this.appDetailsStore.app.appId, {
        credentials: {
          authProviders: appAuthProviders,
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.app)) {
      getStore('toast').success('Updated auth providers');
      this.appDetailsStore.setApp(data.app);
      this.saveLoader.ok();
    } else {
      getStore('toast').error('Failed to update auth providers', error);
      this.saveLoader.fail(error);
    }
  };
}
