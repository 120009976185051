import { isError, isNumber } from '@whisklabs/typeguards';

const limitWindow = 10_000;

const eventsLimit: Record<string, number | undefined> = {};

let timerLimit = -1;

export const hasRateLimit = (originalException?: unknown): boolean => {
  const id = isError(originalException)
    ? `${originalException.name}@@@${originalException.message}@@@${originalException.stack ?? ''}`
    : String(originalException);

  let status = false;
  const now = Date.now();
  const time = eventsLimit[id];

  if (isNumber(time)) {
    status = now < time + limitWindow;
    if (status) {
      eventsLimit[id] = now;
    } else {
      delete eventsLimit[id];
    }
  } else {
    eventsLimit[id] = now;
  }

  setClean();

  return status;
};

function setClean() {
  window.clearTimeout(timerLimit);
  timerLimit = window.setTimeout(cleanRateEnd, limitWindow);
}

function cleanRateEnd() {
  const now = Date.now();
  let next = false;

  for (const id of Object.keys(eventsLimit)) {
    const time = eventsLimit[id];

    if (!isNumber(time) || now > time + limitWindow) {
      delete eventsLimit[id];
    } else {
      next = true;
    }
  }

  if (next) {
    setClean();
  }
}
