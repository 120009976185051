import { isText } from '@whisklabs/typeguards';
import { action, autorun, computed, makeObservable, observable } from 'mobx';

import { SITE_TITLE } from 'common/config';

import { BaseStore } from './base';

interface TitleParams {
  id: string;
  title: string;
}

export class DocumentStore extends BaseStore {
  @observable.shallow private readonly titles: TitleParams[] = [];

  @action storeInit = () => {
    autorun(
      () => {
        document.title = this.title;
      },
      { delay: 300 }
    );
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get title() {
    // Chrome collapses multiple space chars into 1, we want 2 spaces in separator for readability
    const NON_COLLAPSIBLE_SPACE = '\u205f';
    const separator = `${NON_COLLAPSIBLE_SPACE} | ${NON_COLLAPSIBLE_SPACE}`;

    let title = SITE_TITLE;

    for (const titleParams of this.titles) {
      if (isText(titleParams.title)) {
        title = `${titleParams.title}${separator}${title}`;
      }
    }

    return title;
  }

  @action setTitle = (titleParams: TitleParams) => {
    const existingIndex = this.titles.findIndex((t) => t.id === titleParams.id);

    if (existingIndex > -1) {
      this.titles.splice(existingIndex, 1, titleParams);
    } else {
      this.titles.push(titleParams);
    }
  };

  @action removeTitle = (id: string) => {
    const existingIndex = this.titles.findIndex((t) => t.id === id);
    if (existingIndex > -1) {
      this.titles.splice(existingIndex, 1);
    }
  };
}
