import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

import { CountriesStore } from 'b2b/countries';
import { Table } from 'common/components/client-pagination-table';
import { Column, RenderRowFunction } from 'common/components/table-view';
import { createSearchFunction, searchRankings } from 'common/helpers/search';
import { useStore } from 'common/hooks/use-store';

import { AppPublisher } from './api';
import { AppPublisherRow } from './row';
import { AppPublishersStore } from './store';
import { ColumnId } from './types';

const columns: Column<AppPublisher, ColumnId>[] = [
  {
    id: 'publisher',
    title: 'Publisher',
    width: '100%',
    vAlign: 'top',
  },
  {
    id: 'countries',
    title: 'Available countries',
    vAlign: 'top',
    maxWidth: '300px',
  },
  {
    id: 'licence',
    title: 'Licence',
    vAlign: 'top',
  },
  {
    id: 'action',
    title: 'Action',
    vAlign: 'top',
    align: 'center',
  },
];

const searchFunction = createSearchFunction<AppPublisher>([
  (appPublisher) => appPublisher.name ?? '',
  (appPublisher) => appPublisher.domain ?? '',
  { key: (appPublisher) => appPublisher.id, threshold: searchRankings.EQUAL },
]);

interface Props {
  store: AppPublishersStore;
}

export const AppPublishersTable = observer(({ store }: Props) => {
  const countriesStore = useStore(() => new CountriesStore());

  const renderRow: RenderRowFunction<AppPublisher, ColumnId> = useCallback(
    (props) => <AppPublisherRow appPublishersStore={store} countriesStore={countriesStore} {...props} />,
    [store, countriesStore]
  );

  useEffect(() => {
    void countriesStore.loadCountries();
    void store.loadAppPublishers();
  }, [store, countriesStore]);

  return (
    <Table
      data={store.appPublishers}
      columns={columns}
      loader={store.loader}
      rowKey="id"
      renderRow={renderRow}
      renderNewRow={renderRow}
      cellPaddingVertical={7}
      searchFunction={searchFunction}
      defaultPagination={false}
    />
  );
});
