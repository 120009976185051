import { IIconProps, Icon } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { memo } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';

import { join } from 'common/helpers/router';
import { clsNavLink, clsNavLinkActive } from 'common/styles/link';

const clsListItem = css`
  list-style-type: none;
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
`;

const clsSidebarNavLink = css`
  display: flex;
  font-size: 18px;
  line-height: 1.5;
`;

const clsIcon = css`
  margin-right: 12px;
  margin-top: -2px;
`;

interface Props {
  to: string;
  icon: IIconProps['icon'];
  label: string;
  className?: string;
}

export const SidebarItem = memo(({ className, icon, label, to }: Props) => {
  const { url } = useRouteMatch();

  return (
    <li className={cx(clsListItem, className)}>
      <NavLink to={join(url, to)} className={cx(clsNavLink, clsSidebarNavLink)} activeClassName={clsNavLinkActive}>
        <span className={clsIcon}>
          <Icon icon={icon} iconSize={16} color="#bdbdbd" />
        </span>
        <span>{label}</span>
      </NavLink>
    </li>
  );
});
