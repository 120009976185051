import { Button } from '@blueprintjs/core';
import { css } from 'linaria';
import { noop } from 'lodash';
import { observer } from 'mobx-react-lite';

import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';

import { BlockUserFormStore } from './store';

const clsButton = css`
  & + & {
    margin-left: 8px;
  }
`;

export const BlockUserForm = observer(() => {
  const { loader, blockUser, unblockUser, form } = useStore(() => new BlockUserFormStore());

  return (
    <Card>
      <Form form={form} loader={loader} onSubmit={noop} errorTitle="Failed to block user">
        <div className={clsMb30}>
          <FieldInput
            field={form.fields.userId}
            label="User id"
            placeholder="e.g. 102ea21f92db5084836ada740d19c295dl3"
          />
        </div>
        <Button className={clsButton} onClick={blockUser} intent="danger" text="Block" large />
        <Button className={clsButton} onClick={unblockUser} intent="primary" text="Unblock" large />
      </Form>
    </Card>
  );
});
