import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable, observable } from 'mobx';

import { App, getApp, stubApp } from 'b2b/apps/api';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

export class AppDetailsStore extends BaseStore {
  @observable.ref app: App = stubApp();

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.loader.reset();
    this.app = stubApp();
  };

  @action loadApp = async (appId: string) => {
    const { cancelled, data, error } = await this.loader.load(() => getApp({ appId }));

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.app)) {
      this.app = data.app;
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @action setApp(app: App) {
    this.app = app;
  }

  @action handleAppDeleted = () => {
    getStore('router').replace('/b2b/api-keys');
  };
}
