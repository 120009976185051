export const pageSlice = <T>(data: T[], page = 1, pageSize = 20): T[] => {
  if (data.length <= pageSize) {
    return data;
  }

  const startIndex = Math.max((page - 1) * pageSize, 0);
  const endIndex = Math.min(startIndex + pageSize, data.length);

  return startIndex > 0 || endIndex < data.length ? data.slice(startIndex, endIndex) : data;
};
