import { action, makeObservable, observable } from 'mobx';

import { DataLoader, DataLoaderOptions } from './data-loader';

export class DataLoadersMap {
  private readonly options: DataLoaderOptions;
  private readonly dataLoadersMap = observable.map<PropertyKey, DataLoader>([], { deep: false });

  constructor(options: DataLoaderOptions = {}) {
    makeObservable(this);
    this.options = options;
  }

  get = (key: PropertyKey): DataLoader => {
    const dataLoader: DataLoader = this.dataLoadersMap.get(key) ?? new DataLoader(this.options);

    if (!this.dataLoadersMap.has(key)) {
      this.dataLoadersMap.set(key, dataLoader);
    }

    return dataLoader;
  };

  @action reset = () => {
    this.dataLoadersMap.clear();
  };
}
