import { isText } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { InfiniteListStoreType } from 'common/components/infinite-list';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { BaseStore, getStore } from 'common/stores';

import { AddDomainRequestBE, ClaimedDomain, DeleteDomainRequestBE, addDomain, deleteDomain, loadDomains } from '../api';

export class ClaimedDomaisStore extends BaseStore implements InfiniteListStoreType {
  constructor() {
    super();

    makeObservable(this);
  }

  @observable private readonly limit = 100;
  createLoader = new DataLoader({ initialStatus: LoadStatus.ok });
  deleteLoader = new DataLoader({ initialStatus: LoadStatus.ok });
  loader = new DataLoader();
  @observable claimedDomains: ClaimedDomain[] = [];

  readonly createForm = new FormModel({
    domain: new FieldModel('').validators(validateRequired),
    userId: new FieldModel('').validators(validateRequired),
  });

  readonly deleteForm = new FormModel({
    domain: new FieldModel('').validators(validateRequired),
  });

  @observable hasMoreData = true;

  @observable after = '';

  @observable searchText = '';

  @action storeReset = () => {
    this.claimedDomains = [];
    this.createLoader.reset();
    this.loader.reset();
    this.deleteLoader.reset();
    this.after = '';
  };

  @computed get total(): number {
    return this.claimedDomains.length;
  }

  @action search = (query: string) => {
    this.searchText = query;
  };

  loadInitialData = () => void this.loadDomains();

  loadMoreData = () => void this.loadDomains({ loadMore: true });

  @action loadDomains = async ({ loadMore }: { loadMore?: boolean } = {}) => {
    const { cancelled, data, success, error } = await this.loader.load(() =>
      loadDomains({
        limit: this.limit,
        cursors: {
          after: this.after,
          before: '',
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (success) {
      const after = data.paging?.cursors?.after;

      this.claimedDomains = loadMore ? this.claimedDomains.concat(data.domains) : data.domains;
      this.after = after ?? '';
      this.hasMoreData = isText(after);
      this.loader.ok();
    } else {
      this.loader.fail(error);
      getStore('toast').error(error.message ?? 'Smth went wrong');
    }
  };

  @action addDomain = async (req: AddDomainRequestBE) => {
    const { success, error, cancelled } = await this.createLoader.load(() => addDomain(req));

    if (cancelled) {
      return;
    }

    if (success) {
      getStore('toast').success('Domain was added');
      await this.loadDomains();
      this.createLoader.ok();
      this.createForm.reset();
    } else {
      this.createLoader.fail(error);
      getStore('toast').error(error.message ?? 'Smth went wrong');
    }
  };

  @action deleteDomain = async (req: DeleteDomainRequestBE) => {
    const { success, error } = await this.deleteLoader.load(() => deleteDomain(req));
    this.claimedDomains = this.claimedDomains.filter((item) => item.domain !== req.domain);

    if (success) {
      getStore('toast').success('Domain has been deleted');
      this.deleteForm.reset();
      this.deleteLoader.ok();
    } else {
      this.deleteLoader.fail(error?.message ?? 'Smth went wrong');
    }
  };
}
