import { observer } from 'mobx-react-lite';

import { PageHeader } from 'common/components/page-header';

import { FlaggedTextTable } from './form';

export const FlaggedTextQueuePage = observer(() => (
  <>
    <PageHeader title="Flagged Text Approval Queue" />
    <FlaggedTextTable />
  </>
));
