import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { Business } from 'b2b/businesses/api';
import { UserInvitation, getInvitations } from 'b2b/businesses/details/invitations/api';
import { BusinessDetailsStore } from 'b2b/businesses/details/store';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

export class BusinessInvitationsTableStore extends BaseStore {
  readonly invitationById = observable.map<string, UserInvitation>([], { deep: false });

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  constructor(private readonly businessDetailsStore: BusinessDetailsStore) {
    super();
    makeObservable(this);
  }

  @action storeReset = () => {
    this.loader.reset();
    this.invitationById.clear();
  };

  get business(): Business {
    return this.businessDetailsStore.business;
  }

  @computed get invitations(): UserInvitation[] {
    return Array.from(this.invitationById.values());
  }

  @action loadInvitations = async () => {
    const { cancelled, data, error } = await this.loader.load(() =>
      getInvitations({
        id: this.business.id,
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.invitationById.replace(data.invitations.map((invitation) => [invitation.id, invitation]));
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
