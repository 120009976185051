import { css } from 'linaria';

export const clsPageContainer = css`
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-self: center;
`;

export const clsFlex = css`
  display: flex !important;
`;

export const clsInlineFlex = css`
  display: inline-flex !important;
`;

export const clsFlexAlignItemsCenter = css`
  align-items: center !important;
`;

export const clsFlexJustifyBetween = css`
  justify-content: space-between !important;
`;

export const clsFlexJustifyCenter = css`
  justify-content: center !important;
`;

export const clsFlexJustifyEnd = css`
  justify-content: flex-end !important;
`;

export const clsFlexJustifySelfCenter = css`
  margin-left: auto !important;
  margin-right: auto !important;
`;

export const clsFlexJustifySelfRight = css`
  margin-left: auto !important;
`;

export const clsFlexGrow = css`
  flex-grow: 1 !important;
`;

export const clsRelativeContainer = css`
  position: relative !important;
`;

export const clsInlineBlock = css`
  display: inline-block !important;
`;

export const clsFullWidth = css`
  width: 100% !important;
`;

export const clsVAlignMiddle = css`
  vertical-align: middle !important;
`;

export const clsVAlignBaseline = css`
  vertical-align: baseline !important;
`;

export const clsFullSize = css`
  width: 100% !important;
  height: 100% !important;
`;

export const clsVisuallyHidden = css`
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
`;
