import { Callout, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { Card, CardTitle } from 'common/components/card';
import { clsMb30 } from 'common/styles/margin-padding';

import { AppRedirectUrlsTable } from './table';

export const AppRedirectUrls = observer(() => (
  <Card header={<CardTitle>Redirect URLs</CardTitle>}>
    <Callout icon="info-sign" intent={Intent.PRIMARY} className={clsMb30}>
      <em>Redirect URL</em> is the path that users are redirected to after they have authenticated with OAuth provider.
      <br />
      The path will be appended with the authorization code for access.
      <ul>
        <li>
          <strong>
            MUST have a protocol, like <em>https://</em>
          </strong>
        </li>
        <li>
          <strong>
            CANNOT contain URL fragments, like <em>#hash</em>
          </strong>
        </li>
      </ul>
    </Callout>

    <AppRedirectUrlsTable />
  </Card>
));
