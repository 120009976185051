import {
  whisk_backoffice_apps_v1_BusinessApp as App,
  whisk_backoffice_apps_v1_BusinessAppPreview as AppPreview,
  whisk_backoffice_apps_v1_AppsAPI_CreateBusinessApp as CreateApp,
  whisk_backoffice_apps_v1_CreateBusinessAppRequest as CreateAppRequest,
  whisk_internalbackoffice_apps_v1_AppsAPI_DeleteApp as DeleteApp,
  whisk_internalbackoffice_apps_v1_DeleteAppRequest as DeleteAppRequest,
  whisk_backoffice_apps_v1_AppsAPI_GetBusinessApp as GetApp,
  whisk_backoffice_apps_v1_GetBusinessAppRequest as GetAppRequest,
  whisk_backoffice_apps_v1_AppsAPI_GetBusinessApps as GetApps,
  whisk_backoffice_apps_v1_GetBusinessAppsRequest as GetAppsRequest,
  whisk_backoffice_apps_v1_AppsAPI_UpdateBusinessApp as UpdateApp,
  whisk_backoffice_apps_v1_UpdateBusinessApp as UpdateAppPayload,
  whisk_backoffice_apps_v1_UpdateBusinessAppRequest as UpdateAppRequest,
} from '@whisklabs/b2b-backoffice-protobuf';
import { Default, maskWrap } from '@whisklabs/grpc';

import { b2bGrpc } from 'b2b/grpc';
import { DeepPartial } from 'common/types/types';

export { App, GetAppRequest, CreateAppRequest, DeleteAppRequest, UpdateAppRequest, AppPreview, GetAppsRequest };

export const stubApp = (): App => Default(App);

const api = b2bGrpc();

// BE validates businessId format if it's passed as an empty string
function omitBusinessIdIfEmpty<T extends { businessId?: string }>(payload: T): T {
  if (payload.businessId === '') {
    return { ...payload, businessId: undefined };
  }
  return payload;
}

export const getApp = (payload: GetAppRequest) => api(GetApp, payload);

export const getApps = (payload?: GetAppsRequest) => api(GetApps, payload);

export const createApp = (payload: CreateAppRequest) => api(CreateApp, omitBusinessIdIfEmpty(payload));

export const deleteApp = (payload: DeleteAppRequest) => api(DeleteApp, payload);

export const updateApp = (appId: string, updatePayload: DeepPartial<UpdateAppPayload>) =>
  api(UpdateApp, {
    appId,
    update: maskWrap(omitBusinessIdIfEmpty(updatePayload), true, 'updateMask'),
  } as UpdateAppRequest);
