import { getApiStandUrl } from 'common/helpers/api-stand';
import { createGrpcClient } from 'common/helpers/grpc';

import { API_B2B } from './config';
import { initialQuery } from './helpers/router';

const API_STAND_TEMPLATE = 'https://b2b-be-backoffice-grpc-web%ENV.whisk-dev.com';

export const templateStands: Record<string, string> = {
  dev: '-dev',
  dev1: '-dev-1',
  b2b: '.b2b',
};

const queryApiUrl = getApiStandUrl(initialQuery['api-common'], API_STAND_TEMPLATE, templateStands);

export const commonGrpc = () => createGrpcClient(queryApiUrl ?? API_B2B);
