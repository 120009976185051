import { memo } from 'react';

import { RouteSwitch } from 'common/components/routes';

import { BusinessDetailsPage } from './details';
import { BusinessesTablePage } from './table';

export const BusinessesPage = memo(() => (
  <RouteSwitch
    routes={[
      { path: '/', exact: true, component: BusinessesTablePage },
      { path: '/:businessId', component: BusinessDetailsPage },
    ]}
  />
));
