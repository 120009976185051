import { observer } from 'mobx-react-lite';

import { useBusinessDetailsStoreContext } from 'b2b/businesses/details/context';
import { useStore } from 'common/hooks/use-store';

import { BusinessUsersTableStore } from './store';
import { BusinessUsersTable } from './table';

export const BusinessUsersTablePage = observer(() => {
  const businessDetailsStore = useBusinessDetailsStoreContext();
  const store = useStore(() => new BusinessUsersTableStore(businessDetailsStore));

  return <BusinessUsersTable store={store} />;
});
