import { Classes, FormGroup, ITextAreaProps, TextArea } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { observer } from 'mobx-react-lite';

import { useFormContext } from 'common/components/form/context';
import { getFieldFormGroupProps, getFieldInputProps } from 'common/components/form/helpers';
import { clsField } from 'common/components/form/styles';
import { FieldModel } from 'common/helpers/form';
import { useRandomId } from 'common/hooks/use-random-id';
import { clsNoMargin } from 'common/styles/margin-padding';
import { FormGroupProps } from 'common/types/blueprint';

interface Props extends ITextAreaProps {
  field?: FieldModel<string>;
  formId?: string;
  label?: FormGroupProps['label'];
  labelInfo?: FormGroupProps['labelInfo'];
  helperText?: FormGroupProps['helperText'];
  inline?: FormGroupProps['inline'];
  loading?: boolean;
  className?: string;
  contentClassName?: string;
  noMargin?: boolean;
}

export const FieldTextArea = observer(
  ({
    field,
    id,
    formId,
    loading: loadingProp,
    disabled,
    readOnly,
    label,
    maxLength = 1000,
    labelInfo,
    intent,
    helperText,
    autoComplete = 'off',
    autoCapitalize = 'off',
    autoCorrect = 'off',
    growVertically = true,
    large = true,
    fill = true,
    inline = false,
    noMargin = false,
    className,
    contentClassName,
    ...textAreaProps
  }: Props) => {
    const { loader } = useFormContext();
    const loading = loadingProp ?? loader?.isPending;
    const randomId = useRandomId();
    const inputId = id ?? field?.id ?? randomId;

    const formGroupProps: FormGroupProps = isDefined(field) ? getFieldFormGroupProps(field) : {};
    const fieldInputProps: ITextAreaProps = isDefined(field) ? getFieldInputProps(field) : {};

    return (
      <FormGroup
        labelFor={inputId}
        label={label}
        labelInfo={labelInfo ?? formGroupProps.labelInfo}
        className={cx(
          formGroupProps.className ?? clsField,
          large ? Classes.LARGE : undefined,
          noMargin ? clsNoMargin : undefined,
          className
        )}
        contentClassName={contentClassName}
        intent={intent ?? formGroupProps.intent}
        helperText={helperText ?? formGroupProps.helperText}
        disabled={(disabled ?? formGroupProps.disabled) || loading}
        inline={inline}
      >
        <TextArea
          {...fieldInputProps}
          id={inputId}
          form={formId}
          autoComplete={autoComplete}
          autoCapitalize={autoCapitalize}
          autoCorrect={autoCorrect}
          growVertically={growVertically}
          maxLength={maxLength}
          large={large}
          fill={fill}
          intent={intent}
          disabled={(fieldInputProps.disabled ?? disabled) || loading}
          readOnly={fieldInputProps.readOnly ?? readOnly}
          {...textAreaProps}
        />
      </FormGroup>
    );
  }
);
