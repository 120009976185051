import { FormGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { getFieldControlProps, getFieldFormGroupProps } from 'common/components/form/helpers';
import { FieldModel } from 'common/helpers/form';

import { CountriesMultiselect, CountriesMultiselectProps } from './countries-multiselect';

interface Props extends Omit<CountriesMultiselectProps, keyof ReturnType<typeof getFieldControlProps>> {
  field: FieldModel<string[]>;
  label?: ReactNode;
}

export const FieldCountries = observer(({ field, label, ...multiSelectProps }: Props) => (
  <FormGroup {...getFieldFormGroupProps(field)} label={label}>
    <CountriesMultiselect {...getFieldControlProps(field)} {...multiSelectProps} />
  </FormGroup>
));
