import { Classes } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { memo } from 'react';

export const clsPageDescription = css`
  font-size: 16px;
`;

interface Props {
  description?: string;
}

export const PageDescription = memo(({ description }: Props) => (
  <div className={cx(clsPageDescription, Classes.TEXT_MUTED)}>{description}</div>
));
