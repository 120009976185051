import { Button, Classes, Icon, IconName, Intent } from '@blueprintjs/core';
import { isDefined, isPresent } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { MouseEventHandler, memo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { clsLineHeight1 } from 'common/styles/text';

interface Props {
  onClick?: MouseEventHandler<HTMLElement>;
  to?: LinkProps['to'];
  icon?: IconName;
  intent?: Intent;
  text: string;
}

const propsByIntent: Record<Intent, { className?: string; icon?: IconName }> = {
  [Intent.NONE]: {},
  [Intent.SUCCESS]: { className: Classes.INTENT_SUCCESS, icon: 'add' },
  [Intent.PRIMARY]: { className: Classes.INTENT_PRIMARY },
  [Intent.WARNING]: { className: Classes.INTENT_WARNING },
  [Intent.DANGER]: { className: Classes.INTENT_DANGER },
};

export const LinkButton = memo(({ to, onClick, text, icon: iconProp, intent = Intent.SUCCESS }: Props) => {
  const intentProps = propsByIntent[intent];
  const icon =
    isPresent(iconProp) || isPresent(intentProps.icon) ? (
      <Icon icon={iconProp ?? intentProps.icon} iconSize={Icon.SIZE_LARGE} />
    ) : null;

  if (isDefined(to)) {
    return (
      <Link to={to} className={cx(Classes.BUTTON, Classes.LARGE, intentProps.className)} onClick={onClick}>
        {icon}
        <span className={cx(Classes.BUTTON_TEXT, clsLineHeight1)}>{text}</span>
      </Link>
    );
  }

  return <Button onClick={onClick} intent={Intent.SUCCESS} icon={icon} text={text} large />;
});
