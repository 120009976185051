import { FormGroup, IInputGroupProps, IPopoverProps } from '@blueprintjs/core';
import { DateRange, DateRangeInput, IDateRangeInputProps } from '@blueprintjs/datetime';
import dayjs from 'dayjs';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';

import { getFieldFormGroupProps } from 'common/components/form/helpers';
import { DATE_FORMAT_NUMERIC } from 'common/config';
import { formatDate, parseDate } from 'common/helpers/date';
import { FieldModel } from 'common/helpers/form';
import { sharedPopoverProps } from 'common/popover';

const dayPickerContainer = css`
  height: 100%;
  &:first-child {
    padding-right: 10px;
    border-right: 1px solid #eee;
  }
  &:last-child {
    padding-left: 10px;
  }
`;

const dayPickerProps: IDateRangeInputProps['dayPickerProps'] = {
  className: dayPickerContainer,
  showOutsideDays: true,
};

const minDate = dayjs().startOf('year').subtract(20, 'years').toDate();
const maxDate = dayjs().endOf('year').add(20, 'years').toDate();
const startDateInputProps: IInputGroupProps = { large: true, placeholder: 'e.g. 20/01/2021' };
const endDateInputProps: IInputGroupProps = { large: true, placeholder: 'e.g. 21/01/2021' };
const popoverProps: IPopoverProps = {
  ...sharedPopoverProps,
  fill: false,
};

const dateParse = (dateString: string) => new Date(parseDate(dateString, DATE_FORMAT_NUMERIC));
const dateFormat = (date: Date) => formatDate(date, DATE_FORMAT_NUMERIC);

interface Props {
  field: FieldModel<DateRange>;
  label: string;
}

export const CampaignFormFieldDateRange = observer(({ field, label }: Props) => (
  <FormGroup label={label} {...getFieldFormGroupProps(field)}>
    <DateRangeInput
      formatDate={dateFormat}
      parseDate={dateParse}
      value={field.value}
      onChange={field.handleValueChange}
      startInputProps={startDateInputProps}
      endInputProps={endDateInputProps}
      popoverProps={popoverProps}
      dayPickerProps={dayPickerProps}
      contiguousCalendarMonths={false}
      shortcuts={false}
      closeOnSelection
      selectAllOnFocus
      minDate={minDate}
      maxDate={maxDate}
    />
  </FormGroup>
));
