import { MenuItem } from '@blueprintjs/core';
import { ItemRenderer } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';

import { getFieldFormGroupProps } from 'common/components/form/helpers';
import { Suggest } from 'common/components/form/suggest';
import { FieldModel } from 'common/helpers/form';
import { createSearchFunction, searchRankings } from 'common/helpers/search';

interface Item {
  value: string;
  label: string;
}

const items: Item[] = [
  { value: 'retail', label: 'Retail' },
  { value: 'health', label: 'Health' },
  { value: 'publisher', label: 'Publisher' },
  { value: 'contentCreator', label: 'Content Creator' },
  { value: 'advertising', label: 'Advertising' },
  { value: 'iot', label: 'IoT' },
  { value: 'cpg', label: 'CPG' },
  { value: 'student', label: 'Student' },
  { value: 'hobbyist', label: 'Hobbyist' },
  { value: 'other', label: 'Other' },
];

const filterFunction = createSearchFunction<Item>([
  (item) => item.label,
  { key: (item) => item.value, threshold: searchRankings.EQUAL },
]);

const renderIndustry: ItemRenderer<Item> = (item, { handleClick, modifiers }) =>
  modifiers.matchesPredicate ? (
    <MenuItem active={modifiers.active} key={item.value} onClick={handleClick} text={item.label} />
  ) : null;

const renderInputValue = (item: Item) => item.label;

interface Props {
  field: FieldModel<string>;
  label: string;
}

export const BusinessAccountFormFieldIndustry = observer(({ field, label }: Props) => {
  const handleItemSelect = useCallback(
    (item: Item) => {
      field.handleValueChange(item.value);
    },
    [field]
  );

  const selectedItem = useMemo(() => items.find((item) => item.value === field.value), [field.value]);
  const { labelInfo, helperText, intent } = getFieldFormGroupProps(field);

  return (
    <Suggest
      id={field.id}
      label={label}
      labelInfo={labelInfo}
      helperText={helperText}
      intent={intent}
      placeholder=""
      inputValueRenderer={renderInputValue}
      items={items}
      itemRenderer={renderIndustry}
      onItemSelect={handleItemSelect}
      disabled={field.isDisabled}
      selectedItem={selectedItem}
      onBlur={field.handleBlur}
      filterFunction={filterFunction}
    />
  );
});
