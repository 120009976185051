import { Tag } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { Box } from 'common/components/box';
import { getStore } from 'common/stores';

const clsInfo = css`
  background: white;
`;

const clsInfoType = css`
  color: #555;
  font-weight: 700;
`;

const clsInfoData = css`
  word-break: break-all;
`;

const lines = 3;

const clsInfoText = css`
  display: flex;
  -webkit-line-clamp: ${lines};
  line-clamp: ${lines};
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
`;

const clsInfoTextFull = css`
  display: block;
`;

interface Props {
  type: string;
  text?: React.ReactNode;
}

export const Info: React.FC<Props> = observer(({ type, text }) => {
  const [more, setMore] = useState(false);
  const [full, setFull] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { currentWidth } = getStore('size');

  useEffect(() => {
    setFull(false);
    setMore(false);
  }, [text]);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (ref.current) {
        const sh = ref.current.scrollHeight;
        setMore(sh > 20 * lines /* optimize for resize */ || sh > ref.current.clientHeight);
      }
    });
  }, [text, currentWidth]);

  return (
    <Box className={clsInfo} display="inline-flex" alignItems="stretch" p={4} m={2} borderRadius="12px">
      <Box pr={4} flexDirection="column" alignItems="flex-start" justifyContent="space-between">
        <Box className={clsInfoType}>{type}:</Box>
        {more ? (
          <Tag
            rightIcon={full ? 'chevron-up' : 'chevron-down'}
            fill
            minimal
            interactive
            onClick={() => setFull((i) => !i)}
          >
            {full ? 'Less' : 'More'}
          </Tag>
        ) : null}
      </Box>
      <Box className={clsInfoData} flexDirection="column" alignItems="flex-start" pl={4}>
        <div ref={ref} className={`${clsInfoText} ${full ? clsInfoTextFull : ''}`}>
          {text ?? '-----'}
        </div>
      </Box>
    </Box>
  );
});
