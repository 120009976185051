import { FormGroup } from '@blueprintjs/core';
import { ItemListRenderer } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';
import { ReactNode, useCallback, useMemo } from 'react';

import { getFieldControlProps, getFieldFormGroupProps } from 'common/components/form/helpers';
import { Multiselect, MultiselectItem } from 'common/components/form/multi-select';
import { InfiniteList } from 'common/components/infinite-list';
import { FieldModel } from 'common/helpers/form';
import { getItemId } from 'common/helpers/selectors';
import { useStore } from 'common/hooks/use-store';

import { Publisher } from './api';
import { PublishersStore } from './store';

const publisherToItem = (publisher: Publisher): MultiselectItem<Publisher> => ({
  value: publisher,
  label: publisher.name,
  comment: publisher.domain,
});

interface Props {
  field: FieldModel<Publisher[]>;
  label?: ReactNode;
}

export const FieldPublishers = observer(({ field, label }: Props) => {
  const store = useStore(() => new PublishersStore());
  const { publishers } = store;

  const items: MultiselectItem<Publisher>[] = useMemo(() => publishers.map(publisherToItem), [publishers]);
  const selectedItems: MultiselectItem<Publisher>[] = useMemo(() => field.value.map(publisherToItem), [field.value]);

  const renderItemList: ItemListRenderer<MultiselectItem<Publisher>> = useCallback(
    (itemListRendererProps) => <InfiniteList itemListRendererProps={itemListRendererProps} store={store} />,
    [store]
  );

  return (
    <FormGroup {...getFieldFormGroupProps(field)} label={label}>
      <Multiselect
        {...getFieldControlProps(field)}
        items={items}
        value={selectedItems}
        getValueKey={getItemId}
        itemListRenderer={renderItemList}
        placeholder="Search publishers"
        large
        minimal
      />
    </FormGroup>
  );
});
