import { isText } from '@whisklabs/typeguards';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { CampaignStatus } from 'b2b/campaigns/common/status';
import { CampaignDelete } from 'b2b/campaigns/delete';
import { Table } from 'common/components/client-pagination-table';
import { LinkButton } from 'common/components/links/link-button';
import { NonEmptyString } from 'common/components/non-empty-string';
import { Column, TableChangeHandler } from 'common/components/table-view';
import { DATE_FORMAT_TEXT_SHORT } from 'common/config';
import { formatDate, fromUTC } from 'common/helpers/date';
import { join } from 'common/helpers/router';
import { clsLink } from 'common/styles/link';

import { Campaign } from '../api';

import { CampaignsTableStore } from './store';

type ColumnId = 'name' | 'startDate' | 'endDate' | 'status' | 'action';

const createColumns = (url: string, store: CampaignsTableStore): Column<Campaign, ColumnId>[] => [
  {
    id: 'name',
    title: 'Name',
    width: '100%',
    render: (campaign) => (
      <Link className={clsLink} to={join(url, `/${campaign.id}`)}>
        <NonEmptyString value={campaign.name} />
      </Link>
    ),
  },
  {
    id: 'startDate',
    title: 'Start date',
    width: '150px',
    render: (campaign) => <span>{formatDate(fromUTC(campaign.startAt), DATE_FORMAT_TEXT_SHORT)}</span>,
  },
  {
    id: 'endDate',
    title: 'End date',
    width: '150px',
    render: (campaign) => <span>{formatDate(fromUTC(campaign.endAt), DATE_FORMAT_TEXT_SHORT)}</span>,
  },
  {
    id: 'status',
    title: 'Status',
    width: '150px',
    render: (campaign) => <CampaignStatus campaign={campaign} />,
  },
  {
    id: 'action',
    title: 'Action',
    align: 'center',
    render: (campaign) => <CampaignDelete campaign={campaign} onDeleted={store.handleCampaignDeleted} />,
  },
];

interface Props {
  store: CampaignsTableStore;
}

export const CampaignsTable = observer(({ store }: Props) => {
  const { url } = useRouteMatch();
  const columns = useMemo(() => createColumns(url, store), [url, store]);

  const handleLoadCampaigns: TableChangeHandler<ColumnId> = useCallback(
    ({ offset, limit, query }) => {
      void store.loadCampaigns({
        offset,
        limit,
        query: { name: isText(query) ? query : undefined },
      });
    },
    [store]
  );

  useEffect(() => {
    void store.loadCampaigns({});
  }, [store]);

  return (
    <Table
      data={store.campaigns}
      totalItems={store.totalItems}
      columns={columns}
      rowKey="id"
      onChange={handleLoadCampaigns}
      loader={store.loader}
      headerAction={<LinkButton to="/b2b/campaigns/new/" text="Add new" />}
    />
  );
});
