import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

import { useBusinessDetailsStoreContext } from 'b2b/businesses/details/context';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { LoadingWrapper } from 'common/components/loading-wrapper';
import { PageHeader } from 'common/components/page-header';
import { useStore } from 'common/hooks/use-store';

import { WidgetFormFields, WidgetFormStore } from '../common/widget-form';

import { WidgetCreateStore } from './store';

export const WidgetCreatePage = observer(() => {
  const businessDetailsStore = useBusinessDetailsStoreContext();
  const createStore = useStore(() => new WidgetCreateStore(businessDetailsStore));
  const formStore = useStore(() => new WidgetFormStore());
  const { form } = formStore;

  const handleSubmit = useCallback(() => {
    void createStore.create(formStore.getData());
  }, [createStore, formStore]);

  useEffect(() => {
    void createStore.createTrackingId();
  }, [createStore]);

  useEffect(() => {
    form.fields.trackingId.reset(createStore.trackingId);
  }, [form, createStore.trackingId]);

  return (
    <>
      <PageHeader title="New Widget" />
      <LoadingWrapper loader={createStore.createTrackingIdLoader}>
        <Form
          form={form}
          loader={createStore.createLoader}
          onSubmit={handleSubmit}
          errorTitle="Failed to create widget"
        >
          <WidgetFormFields store={formStore} />
          <ButtonSubmit kind="add" text="Create" large />
        </Form>
      </LoadingWrapper>
    </>
  );
});
