import { isText } from '@whisklabs/typeguards';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { Table } from 'common/components/client-pagination-table';
import { LinkButton } from 'common/components/links/link-button';
import { NonEmptyString } from 'common/components/non-empty-string';
import { Column, TableChangeHandler } from 'common/components/table-view';
import { join } from 'common/helpers/router';
import { clsLink } from 'common/styles/link';

import { Widget } from '../api';
import { WidgetDelete } from '../delete';

import { WidgetsTableStore } from './store';

type ColumnId = 'name' | 'trackingId' | 'action';

const createColumns = (url: string, store: WidgetsTableStore): Column<Widget, ColumnId>[] => [
  {
    id: 'name',
    title: 'Name',
    width: '100%',
    render: (widget) => (
      <Link className={clsLink} to={join(url, `/${widget.id}`)}>
        <NonEmptyString value={widget.name} />
      </Link>
    ),
  },
  {
    id: 'trackingId',
    title: 'Tracking ID',
    width: '300px',
    render: (widget) => <span>{widget.trackingId}</span>,
  },
  {
    id: 'action',
    title: 'Action',
    align: 'center',
    render: (widget) => <WidgetDelete widget={widget} onDeleted={store.handleWidgetDeleted} />,
  },
];

interface Props {
  store: WidgetsTableStore;
}

export const WidgetsTable = observer(({ store }: Props) => {
  const { url } = useRouteMatch();
  const columns = useMemo(() => createColumns(url, store), [url, store]);

  const handleLoadWidgets: TableChangeHandler<ColumnId> = useCallback(
    ({ offset, limit, query }) => {
      void store.loadWidgets({
        offset,
        limit,
        query: { name: isText(query) ? query : undefined },
      });
    },
    [store]
  );

  useEffect(() => {
    void store.loadWidgets({});
  }, [store]);

  return (
    <Table
      data={store.widgets}
      totalItems={store.totalItems}
      columns={columns}
      rowKey="id"
      onChange={handleLoadWidgets}
      loader={store.loader}
      headerAction={<LinkButton to={join(url, '/new')} text="Add new" />}
    />
  );
});
