import { isArray } from '@whisklabs/typeguards';
import { KeyAttributesOptions, MatchSorterOptions, ValueGetterKey, matchSorter, rankings } from 'match-sorter';

import { TypedOmit } from 'common/types/types';

export interface TypedKeyAttributesOptions<T> extends TypedOmit<KeyAttributesOptions<T>, 'key'> {
  key?: ValueGetterKey<T>;
}

export type TypedKeyOption<T> = TypedKeyAttributesOptions<T> | ValueGetterKey<T>;

export const searchRankings = rankings;

export const createSearchFunction =
  <T>(keysOrOptions?: TypedKeyOption<T>[] | MatchSorterOptions<T>, options?: MatchSorterOptions<T>) =>
  (items: T[], query: string): T[] =>
    matchSorter(items, query, isArray(keysOrOptions) ? { keys: keysOrOptions, ...options } : keysOrOptions);
