import { observer } from 'mobx-react-lite';

import { IndexingToolContextWrapper } from './context';
import { RulesListTable } from './list/table';
import { AddRecordForm } from './record-input';
import { Settings } from './settings';

export const IndexingRules = observer(() => {
  return (
    <IndexingToolContextWrapper>
      <Settings />
      <AddRecordForm />
      <RulesListTable />
    </IndexingToolContextWrapper>
  );
});
