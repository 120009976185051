import { Intent, Tag } from '@blueprintjs/core';
import { css } from 'linaria';
import { useState } from 'react';

import { Actions, Img, Info, Link, Time, User } from 'b2c/components';
import { PostEntity, Reported } from 'b2c/helpers/reported/api';
import { postPath, profilePath, recipePath } from 'b2c/helpers/routes';
import { Box } from 'common/components/box';
import { DATE_FORMAT_NUMERIC, DATE_FORMAT_TIME } from 'common/config';
import { formatDate } from 'common/helpers/date';
import { fromTimestampToMillis } from 'common/helpers/grpc';
import { displayReportReason } from 'common/helpers/reports';

const clsRow = css`
  &:hover {
    background-color: #f5f8fa;
  }
`;

interface Props {
  report: Reported<PostEntity>;
  onApprove: (reportId: string) => void;
  onReject: (reportId: string) => void;
}

export const ReportedPost: React.FC<Props> = ({ report, onApprove, onReject }) => {
  const [full, setFull] = useState(false);

  return (
    <Box display="block" border="1px solid #DDD" borderRadius="18px" p={4} mb={4} className={clsRow}>
      <Box alignItems="stretch">
        <Box mt={4}>
          <Img imageUrl={report.item.imageUrl} />
        </Box>

        <Box flexGrow={1} flexDirection="row" alignItems="flex-start" flexWrap="wrap">
          <Box flexDirection="column" width="50%" alignItems="flex-start">
            <Info type="Email" text={report.email} />
            <Info
              type="Profile link"
              text={<Link title="Profile" small link={profilePath(report.item.author?.id ?? '')} />}
            />
            <Info type="Post link" text={<Link title="Post" small link={postPath(report.item.postId)} />} />
          </Box>

          <Box flexDirection="column" width="50%" alignItems="flex-start">
            <Info type="Text" text={report.item.text} />
            <Info type="Comment" text={report.comment} />
            <Info
              type="Reporter profile link"
              text={<Link title="Profile" small link={profilePath(report.reporter?.id ?? '')} />}
            />
          </Box>
          <Box mt={16} flexGrow={1} width="100%" flexDirection="row" alignItems="flex-start" flexWrap="wrap">
            {report.item.recipeIds.map((id) => (
              <Box key={id} mr={4}>
                <Link title={recipePath(id)} small link={recipePath(id)} />
              </Box>
            ))}
          </Box>
        </Box>

        <Box flexDirection="column" alignItems="flex-end">
          <Box mb={4}>
            <Actions reportId={report.reportId} onApprove={onApprove} onReject={onReject} />
          </Box>
          <Box>
            <Box mr={4} flexDirection="column" alignItems="flex-end">
              <Time time={formatDate(fromTimestampToMillis(report.reportedAt), DATE_FORMAT_NUMERIC)} />
              <Time time={formatDate(fromTimestampToMillis(report.reportedAt), DATE_FORMAT_TIME)} />
            </Box>
            <Box mr={4} width="64px">
              <Tag large fill intent={Intent.PRIMARY} title={displayReportReason(report.reason)}>
                {displayReportReason(report.reason)}
              </Tag>
            </Box>
            <Box width="82px">
              <Tag
                rightIcon={full ? 'double-chevron-up' : 'double-chevron-down'}
                large
                fill
                interactive
                onClick={() => setFull((i) => !i)}
              >
                {full ? 'Less' : 'More'}
              </Tag>
            </Box>
          </Box>
        </Box>
      </Box>

      {full ? (
        <Box>
          <Box flexGrow={1}>
            <Box mr={4}>
              <User type="Author" {...report.item.author} id={report.item.author?.id ?? ''} />
            </Box>
            <User type="Reporter" {...report.reporter} id={report.reporter?.id ?? ''} />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
