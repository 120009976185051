import {
  whisk_backoffice_users_v1_UsersAPI_MergeUsers as MergeUsers,
  whisk_backoffice_users_v1_MergeUsersRequest as MergeUsersRequest,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { MergeUsers, MergeUsersRequest };

const api = b2cGrpc();

export const mergeUsers = (payload: MergeUsersRequest) => api(MergeUsers, payload);
