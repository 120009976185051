import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { UserRole, userRoleSearchKey, userRolesCompareAsc } from 'b2b/users/common/user-role';
import { Table } from 'common/components/client-pagination-table';
import { LinkButton } from 'common/components/links/link-button';
import { NonEmptyString } from 'common/components/non-empty-string';
import { Column, Sort } from 'common/components/table-view';
import { join } from 'common/helpers/router';
import { createSearchFunction, searchRankings } from 'common/helpers/search';
import { stringCompareAsc } from 'common/helpers/sort';
import { clsLink } from 'common/styles/link';

import { User, UserRoleType } from '../api';
import { UserDelete } from '../delete';

import { UsersTableStore } from './store';

type ColumnId = 'email' | 'role' | 'action';

const createColumns = (url: string, store: UsersTableStore): Column<User, ColumnId>[] => [
  {
    id: 'email',
    title: 'Email',
    width: '100%',
    sorter: (a, b) => stringCompareAsc(a.email, b.email),
    render: (user) => (
      <Link className={clsLink} to={join(url, `/${user.id}`)}>
        <NonEmptyString value={user.email} />
      </Link>
    ),
  },
  {
    id: 'role',
    title: 'Role',
    width: '200px',
    sorter: (a, b) => userRolesCompareAsc(a.roles[0] as UserRoleType, b.roles[0] as UserRoleType),
    render: (user) => <UserRole role={user.roles[0] as UserRoleType} />,
  },
  {
    id: 'action',
    title: 'Action',
    align: 'center',
    render: (user) => <UserDelete user={user} onDeleted={store.handleUserDeleted} />,
  },
];

const searchFunction = createSearchFunction<User>([
  (user) => user.email,
  (user) => userRoleSearchKey(user.roles[0] as UserRoleType | undefined),
  { key: (user) => user.id, threshold: searchRankings.EQUAL },
]);

const DEFAULT_SORT: Sort<ColumnId> = { sortKey: 'email', sortOrder: 'asc' };

interface Props {
  store: UsersTableStore;
}

export const UsersTable = observer(({ store }: Props) => {
  const { url } = useRouteMatch();
  const columns = useMemo(() => createColumns(url, store), [url, store]);

  useEffect(() => {
    void store.loadUsers();
  }, [store]);

  return (
    <Table
      data={store.users}
      totalItems={store.totalItems}
      columns={columns}
      loader={store.loader}
      rowKey="id"
      searchFunction={searchFunction}
      headerAction={<LinkButton to="/b2b/users/new" text="Add new" />}
      defaultSort={DEFAULT_SORT}
    />
  );
});
