import { NonIdealState } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { ReactElement } from 'react';

import notFoundImage from './not-found-image.png';

const clsNotFound = css`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: auto;
  height: 100%;
  min-height: 100%;
`;

interface Props {
  goToLink?: ReactElement;
  className?: string;
}

export const NotFoundPage = ({ goToLink, className }: Props) => (
  <div className={cx(clsNotFound, className)}>
    <NonIdealState
      icon={<img src={notFoundImage} alt="Not Found" />}
      title="404: Page not found"
      action={isDefined(goToLink) ? <div>Go to {goToLink}</div> : undefined}
    />
  </div>
);
