import { isText } from '@whisklabs/typeguards';
import { useEffect, useRef } from 'react';

import { ColumnId, SetTableState, TableState } from 'common/components/table-view';

export const useRestoreSortAfterSearch = <U extends ColumnId>(
  { sort, search }: TableState<U>,
  setState: SetTableState<U>
) => {
  const prevSortBeforeSearchRef = useRef(sort);
  const prevSearchRef = useRef(search);

  useEffect(() => {
    const isEndingSearch = !isText(search) && isText(prevSearchRef.current);

    if (isEndingSearch) {
      const prevSortBeforeSearch = prevSortBeforeSearchRef.current;
      setState((curState) => ({
        ...curState,
        sort: prevSortBeforeSearch,
      }));
    }

    if (!isText(search)) {
      prevSortBeforeSearchRef.current = sort;
    }

    prevSearchRef.current = search;
  }, [search, sort, setState]);
};
