import { Icon } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { AppPreview } from 'b2b/apps/api';
import { Table } from 'common/components/client-pagination-table';
import { LinkButton } from 'common/components/links/link-button';
import { NonEmptyString } from 'common/components/non-empty-string';
import { Column, Sort } from 'common/components/table-view';
import { join } from 'common/helpers/router';
import { createSearchFunction, searchRankings } from 'common/helpers/search';
import { boolCompareAsc, stringCompareAsc } from 'common/helpers/sort';
import { clsLink } from 'common/styles/link';

import { AppDelete } from '../delete';

import { AppsTableStore } from './store';

const clsIconTick = css`
  color: #4db690;
`;

type ColumnId = 'name' | 'sandbox' | 'test' | 'action';

const createColumns = (store: AppsTableStore): Column<AppPreview, ColumnId>[] => [
  {
    id: 'name',
    title: 'Name',
    width: '100%',
    paddingVertical: 0,
    sorter: (a, b) => stringCompareAsc(a.name, b.name),
    render: ({ appId, name }) => (
      <Link className={clsLink} to={join('/b2b/api-keys', appId)}>
        <NonEmptyString value={name} />
      </Link>
    ),
  },
  {
    id: 'sandbox',
    title: 'Sandbox',
    align: 'center',
    sorter: (a, b) => boolCompareAsc(a.isTrial, b.isTrial),
    render: ({ isTrial }) => (isTrial ? <Icon icon="tick-circle" iconSize={20} className={clsIconTick} /> : null),
  },
  {
    id: 'test',
    title: 'Test',
    align: 'center',
    sorter: (a, b) => boolCompareAsc(a.isTest, b.isTest),
    render: ({ isTest }) => (isTest ? <Icon icon="tick-circle" iconSize={20} className={clsIconTick} /> : null),
  },
  {
    id: 'action',
    title: 'Action',
    align: 'center',
    render: (app) => <AppDelete app={app} onDeleted={store.handleAppDeleted} />,
  },
];

const searchFunction = createSearchFunction<AppPreview>([
  (app) => app.name,
  { key: (app) => app.appId, threshold: searchRankings.EQUAL },
]);

const DEFAULT_SORT: Sort<ColumnId> = { sortKey: 'name', sortOrder: 'asc' };

interface Props {
  store: AppsTableStore;
  businessId?: string;
}

export const AppsTable = observer(({ store, businessId }: Props) => {
  const columns = useMemo(() => createColumns(store), [store]);

  useEffect(() => {
    void store.loadApps({ businessId });
  }, [store, businessId]);

  return (
    <Table
      data={store.apps}
      columns={columns}
      rowKey="appId"
      searchFunction={searchFunction}
      defaultSort={DEFAULT_SORT}
      loader={store.loader}
      headerAction={<LinkButton to={`/b2b/api-keys/new/?businessId=${businessId ?? ''}`} text="Add new" />}
    />
  );
});
