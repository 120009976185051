import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable } from 'mobx';

import { App, updateApp } from 'b2b/apps/api';
import { AppDetailsStore } from 'b2b/apps/details/store';
import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FieldValidator, FormModel } from 'common/helpers/form';
import { validateRequired, validateUrlProtocol } from 'common/helpers/form/validators';
import { BaseStore, getStore } from 'common/stores';

export class AppRedirectUrlStore extends BaseStore {
  readonly addLoader = new DataLoader();
  readonly saveLoader = new DataLoader();
  readonly removeLoader = new DataLoader();

  readonly form = new FormModel({
    redirectUrl: new FieldModel<string>('').validators(
      validateRequired,
      validateUrlProtocol,
      (value) => this.validateNoHash(value),
      (value) => this.validateUnique(value)
    ),
  });

  constructor(private readonly appDetailsStore: AppDetailsStore) {
    super();
    makeObservable(this);
  }

  get app(): App {
    return this.appDetailsStore.app;
  }

  @computed get appRedirectUrls(): string[] {
    return this.appDetailsStore.app.credentials?.redirectUrls ?? [];
  }

  @action add = async (newAppRedirectUrl: string) => {
    const { cancelled, data, error } = await this.addLoader.load(() =>
      updateApp(this.app.appId, {
        credentials: {
          redirectUrls: [...this.appRedirectUrls, newAppRedirectUrl],
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.app)) {
      this.appDetailsStore.setApp(data.app);
      this.form.reset();
      getStore('toast').success('Added redirect url');
      this.addLoader.ok();
    } else {
      getStore('toast').error('Failed to add redirect url', error);
      this.addLoader.fail(error);
    }
  };

  @action save = async (newAppRedirectUrl: string, index: number) => {
    const { cancelled, data, error } = await this.saveLoader.load(() =>
      updateApp(this.app.appId, {
        credentials: {
          redirectUrls: [
            ...this.appRedirectUrls.slice(0, index),
            newAppRedirectUrl,
            ...this.appRedirectUrls.slice(index + 1),
          ],
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.app)) {
      this.appDetailsStore.setApp(data.app);
      getStore('toast').success('Updated redirect url');
      this.saveLoader.ok();
    } else {
      getStore('toast').error('Failed to update redirect url', error);
      this.saveLoader.fail(error);
    }
  };

  @action remove = async (index: number) => {
    const { cancelled, data, error } = await this.removeLoader.load(() =>
      updateApp(this.app.appId, {
        credentials: {
          redirectUrls: [...this.appRedirectUrls.slice(0, index), ...this.appRedirectUrls.slice(index + 1)],
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.app)) {
      this.appDetailsStore.setApp(data.app);
      getStore('toast').success('Removed redirect url');
      this.removeLoader.ok();
    } else {
      getStore('toast').error('Failed to remove redirect url', error);
      this.removeLoader.fail(error);
    }
  };

  // Fragments are not allowed in OAuth redirect urls, this is a naive check
  private readonly validateNoHash: FieldValidator<string> = (redirectUrl) =>
    redirectUrl.includes('#') ? 'Cannot contain hash' : true;

  @action private readonly validateUnique: FieldValidator<string> = (redirectUrl) =>
    this.appRedirectUrls.includes(redirectUrl) ? 'Duplicate URL' : true;
}
