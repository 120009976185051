import { Tab, Tabs } from '@blueprintjs/core';
import { isPresent } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { ComponentType, useMemo } from 'react';
import { Link, matchPath, useRouteMatch } from 'react-router-dom';

import { DocumentTitle } from 'common/components/document-title';
import { RouteGuardProp, RouteSwitch } from 'common/components/routes';
import { join } from 'common/helpers/router';
import { useRandomId } from 'common/hooks/use-random-id';
import { getStore } from 'common/stores';
import { clsMb40 } from 'common/styles/margin-padding';

const clsTabLink = css`
  font-size: 20px;
`;

const doesParentMatch = (pathname: string, parentPath: string): boolean =>
  isPresent(matchPath(pathname, { path: parentPath, exact: true }));

export type RouterTab = {
  path: string;
  label: string;
  component: ComponentType;
};

interface Props {
  defaultGuard?: RouteGuardProp;
  defaultPath?: string;
  tabs: (RouterTab | null | undefined)[];
}

export const RouterTabs = observer(({ tabs: tabsProp, defaultPath: defaultPathProp, defaultGuard }: Props) => {
  const { pathname } = getStore('router');
  const { path: parentPath, url: parentUrl } = useRouteMatch();
  const tabs = useMemo(() => tabsProp.filter(isPresent), [tabsProp]);
  const defaultPath = defaultPathProp ?? tabs[0]?.path;

  const selectedTabPath: string | undefined = useMemo(() => {
    const matchedTab = tabs.find((tab) => matchPath(pathname, join(parentPath, tab.path)));
    return matchedTab?.path ?? (doesParentMatch(pathname, parentPath) ? defaultPath : undefined) ?? undefined;
  }, [tabs, pathname, parentPath, defaultPath]);

  const randomId = useRandomId();

  if (tabs.length === 0) {
    return null;
  }

  // 404 support
  const INVALID_TAB_ID = '$invalid$';

  return (
    <>
      <Tabs selectedTabId={selectedTabPath ?? INVALID_TAB_ID} className={clsMb40} id={randomId} large>
        {tabs.map((tab) => (
          <Tab id={tab.path} key={tab.path}>
            <Link to={join(parentUrl, tab.path)} className={clsTabLink}>
              {tab.label}
            </Link>
          </Tab>
        ))}
      </Tabs>

      <RouteSwitch
        defaultGuard={defaultGuard}
        defaultPath={defaultPath}
        routes={tabs.map((tab) => ({
          path: tab.path,
          render: () => {
            const TabComponent = tab.component;

            return (
              <>
                <DocumentTitle title={tab.label} />
                <TabComponent />
              </>
            );
          },
        }))}
      />
    </>
  );
});
