import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { approveRecipeText, getRecipesWithFlaggedTexts, rejectRecipeText } from '../api';

import { RecipeWithFlaggedText } from './types';

export class RecipesWithFlaggedTextQueueStore extends BaseStore {
  private readonly recipesWithFlaggedTexts = observable.map<string, RecipeWithFlaggedText>([], { deep: false });

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.recipesWithFlaggedTexts.clear();
    this.loader.reset();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get recipes(): RecipeWithFlaggedText[] {
    return Array.from(this.recipesWithFlaggedTexts.values());
  }

  @action private readonly removeImageFromList = (id: string) => {
    this.recipesWithFlaggedTexts.delete(id);
  };

  @action loadRecipesWithFlaggedTexts = async () => {
    const { cancelled, data, error } = await this.loader.load(() => getRecipesWithFlaggedTexts());

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.recipesWithFlaggedTexts.replace(
        data.recipes.map((recipe, id) => {
          const entityId = `${recipe.id}-${id}`;
          return [entityId, { ...recipe, entityId }];
        })
      );

      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action approveRecipeText = async (recipe: RecipeWithFlaggedText) => {
    const { cancelled, success, error } = await this.loader.load(() => approveRecipeText({ recipeId: recipe.id }));

    if (cancelled) {
      return;
    }

    if (success) {
      getStore('toast').success('Recipe text approved');
      this.removeImageFromList(recipe.entityId);
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action rejectRecipeText = async (recipe: RecipeWithFlaggedText) => {
    const { cancelled, success, error } = await this.loader.load(() => rejectRecipeText({ recipeId: recipe.id }));

    if (cancelled) {
      return;
    }

    if (success) {
      getStore('toast').success('Recipe text rejected');
      this.removeImageFromList(recipe.entityId);
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
