import {
  whisk_backoffice_b2c_v1_CommunityTopic,
  whisk_backoffice_b2c_v1_CommunityTopicAPI_GetTopics,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export type CommunityTopic = whisk_backoffice_b2c_v1_CommunityTopic;

const api = b2cGrpc();

export const getTopics = () => api(whisk_backoffice_b2c_v1_CommunityTopicAPI_GetTopics);
