import { isDefined, isFunction, isString } from '@whisklabs/typeguards';
import { Fragment, useCallback } from 'react';

import { typedMemo } from 'common/helpers/react';

import { TableRow } from '../table-row';
import { ColumnId, RenderRowGetCellProps, RenderRowGetRowProps, TableBodyProps } from '../types';

import { TableCell } from './cell';
import { clsTableBody } from './styles';

export const TableBody = typedMemo(
  <T, U extends ColumnId>({
    data,
    columns,
    renderRow: renderRowProp,
    renderNewRow: renderNewRowProp,
    rowKey,
    rowHeight,
    paddingVertical,
    paddingHorizontal,
  }: TableBodyProps<T, U>) => {
    const getRowKey = (datum: T) => (isFunction(rowKey) ? rowKey(datum) : datum[rowKey]) as string | number;
    const getRowProps: RenderRowGetRowProps = useCallback(() => ({ rowKind: 'default' }), []);
    const getNewRowProps: RenderRowGetRowProps = useCallback(() => ({ rowKind: 'new' }), []);
    const getCellProps: RenderRowGetCellProps<T, U> = useCallback(
      (columnOrId) => ({
        column: isString(columnOrId) ? columns.find((col) => col.id === columnOrId) : columnOrId,
        rowHeight,
        paddingVertical,
        paddingHorizontal,
      }),
      [columns, rowHeight, paddingVertical, paddingHorizontal]
    );

    const renderRow = isDefined(renderRowProp)
      ? (datum: T, index: number) => (
          <Fragment key={getRowKey(datum)}>
            {renderRowProp({
              rowKind: 'default',
              datum,
              index,
              data,
              columns,
              getRowProps,
              getCellProps,
            })}
          </Fragment>
        )
      : (datum: T, index: number) => (
          <TableRow key={getRowKey(datum)}>
            {columns.map((column) => (
              <TableCell key={`cell-${column.id}`} {...getCellProps(column)}>
                {column.render?.(datum, index, data) ?? null}
              </TableCell>
            ))}
          </TableRow>
        );

    const newRow = isDefined(renderNewRowProp) ? (
      <Fragment key="row-new">
        {renderNewRowProp({
          rowKind: 'new',
          data,
          columns,
          getRowProps: getNewRowProps,
          getCellProps,
        })}
      </Fragment>
    ) : null;

    return (
      <div className={clsTableBody}>
        <>{data.map(renderRow)}</>
        {newRow}
      </div>
    );
  }
);
