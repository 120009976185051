import { isDefined, isUndefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable, observe } from 'mobx';

import { hasToken, removeToken, setToken } from 'common/helpers/auth';
import { DataLoader } from 'common/helpers/data-loader';
import { getLogger } from 'common/helpers/logger';
import { getOAuthCode, hasOAuthCode, removeOAuthCode } from 'login/oauth';

import { BaseStore } from '../base';

import { User, authenticateUser, getUser, stubUser } from './api';
import { AuthStatus } from './config';

export class UserStore extends BaseStore {
  private readonly logger = getLogger('UserStore');

  @observable.ref user: User = stubUser();
  @observable authStatus: AuthStatus = AuthStatus.pending;

  readonly authLoader = new DataLoader();
  readonly loader = new DataLoader();

  @action storeInit = () => {
    void this.runInitialAuthFlow();
  };

  @action storeReset = () => {
    this.authLoader.reset();
    this.loader.reset();
    this.user = stubUser();
    this.authStatus = AuthStatus.pending;
  };

  reactToLogOut = (listener: () => void, options?: { fireImmediately?: boolean }) =>
    observe(
      this,
      'authStatus',
      ({ oldValue, newValue }) => {
        if (oldValue === AuthStatus.loggedIn && newValue === AuthStatus.anonymous) {
          listener();
        }
      },
      options?.fireImmediately
    );

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get isAdmin(): boolean {
    return this.user.roles[0] === 'admin';
  }

  @action runInitialAuthFlow = async () => {
    // Consider token to have priority and discard oauth code
    if (hasToken() && hasOAuthCode()) {
      removeOAuthCode();
    }

    if (hasOAuthCode()) {
      await this.authenticateUser();
    } else if (hasToken()) {
      await this.loadUser();
    } else {
      this.becomeAnonymous();
    }
  };

  @action authenticateUser = async () => {
    if (this.authStatus === AuthStatus.loggedIn) {
      return this.logger.error('[authenticateUser]: User is already logged in');
    }

    const code = getOAuthCode();
    removeOAuthCode(); // one-time use code

    if (isUndefined(code)) {
      return this.logger.error('[authenticateUser]: OAuth code not found');
    }

    const { cancelled, data, error } = await this.authLoader.load(() => authenticateUser(code));

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.user)) {
      this.becomeLoggedIn(data.user, data.token);
      this.authLoader.ok();
    } else {
      this.becomeAnonymous();
      this.authLoader.fail(error);
    }
  };

  @action unauthenticateUser = () => {
    if (this.authStatus === AuthStatus.anonymous) {
      return this.logger.error('[unauthenticateUser]: User is already anonymous');
    }
    this.becomeAnonymous();
  };

  @action private readonly loadUser = async () => {
    if (hasToken()) {
      const { cancelled, data, error } = await this.loader.load(() => getUser());

      if (cancelled) {
        return;
      }

      if (isDefined(data) && isDefined(data.user)) {
        this.becomeLoggedIn(data.user, data.token);
        this.loader.ok();
      } else {
        this.becomeAnonymous();
        this.loader.fail(error);
      }
    }
  };

  @action private readonly becomeLoggedIn = (user: User, token: string) => {
    removeOAuthCode();
    setToken(token);
    this.authStatus = AuthStatus.loggedIn;
    this.user = user;
  };

  @action private readonly becomeAnonymous = () => {
    removeOAuthCode();
    removeToken();
    this.authStatus = AuthStatus.anonymous;
    this.user = stubUser();
  };
}
