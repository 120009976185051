import {
  whisk_backoffice_b2c_v1_ApproveRecipeImageRequest as ApproveRecipeImageRequest,
  whisk_backoffice_b2c_v1_RejectRecipeImageRequest as RejectRecipeImageRequest,
  whisk_backoffice_b2c_v1_RecipeAPI_ApproveRecipeImage,
  whisk_backoffice_b2c_v1_RecipeAPI_GetSuspiciousRecipeImages,
  whisk_backoffice_b2c_v1_RecipeAPI_RejectRecipeImage,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

const api = b2cGrpc();

export const getFlaggedImages = () => api(whisk_backoffice_b2c_v1_RecipeAPI_GetSuspiciousRecipeImages);

export const approveImage = (payload: ApproveRecipeImageRequest) =>
  api(whisk_backoffice_b2c_v1_RecipeAPI_ApproveRecipeImage, payload);

export const rejectImage = (payload: RejectRecipeImageRequest) =>
  api(whisk_backoffice_b2c_v1_RecipeAPI_RejectRecipeImage, payload);
