export const createSort =
  <T = unknown>(item: (el: T) => string | number | undefined, dir: 'asc' | 'desc') =>
  (ar: T, br: T): 1 | -1 | 0 => {
    const a = item(ar);
    const b = item(br);
    const direction = dir === 'asc' ? 1 : -1;

    if (a === b) {
      return 0;
    }
    if (a == null) {
      return 1;
    }
    if (b == null) {
      return -1;
    }
    if (a < b) {
      return -direction as 1 | -1;
    }

    return direction;
  };
