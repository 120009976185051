import { isDefined, isPresent } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { approveImage, getFlaggedImages, rejectImage } from 'b2c/flagged-image-queue/api';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { RecipeImage } from './types';

export class FlaggedImageQueueStore extends BaseStore {
  private readonly flaggedImages = observable.map<string, RecipeImage>([], { deep: false });

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.flaggedImages.clear();
    this.loader.reset();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get images(): RecipeImage[] {
    return Array.from(this.flaggedImages.values());
  }

  @action private readonly removeImageFromList = (id: string) => {
    this.flaggedImages.delete(id);
  };

  @action loadFlaggedImages = async () => {
    const { cancelled, data, error } = await this.loader.load(() => getFlaggedImages());

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.flaggedImages.replace(
        data.recipeImages
          .map((x, id) =>
            isPresent(x.image)
              ? ([
                  `${x.image.imageId}-${id}`,
                  {
                    ...x.image,
                    recipeId: x.recipeId,
                    entityId: `${x.image.imageId}-${id}`,
                    isFromTrustedUser: x.isFromTrustedUser,
                  },
                ] as [string, RecipeImage])
              : undefined
          )
          .filter(isPresent)
      );

      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action approveImage = async (image: RecipeImage) => {
    const { cancelled, success, error } = await this.loader.load(() =>
      approveImage({ recipeId: image.recipeId, imageId: image.imageId })
    );

    if (cancelled) {
      return;
    }

    if (success) {
      getStore('toast').success('Image approved');
      this.removeImageFromList(image.entityId);
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action rejectImage = async (image: RecipeImage) => {
    const { cancelled, success, error } = await this.loader.load(() =>
      rejectImage({ recipeId: image.recipeId, imageId: image.imageId })
    );

    if (cancelled) {
      return;
    }

    if (success) {
      getStore('toast').success('Image rejected');
      this.removeImageFromList(image.entityId);
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
