import { identity } from 'lodash';
import { observer } from 'mobx-react-lite';

import { useAppDetailsStoreContext } from 'b2b/apps/details/context';
import { Card, CardTitle } from 'common/components/card';
import { Multiselect } from 'common/components/form/multi-select';
import { useStore } from 'common/hooks/use-store';

import { AuthProvider } from './api';
import { AppAuthProvidersStore } from './store';

interface SelectItem {
  value: AuthProvider;
  label: string;
}

const items: SelectItem[] = [
  {
    value: AuthProvider.AUTH_PROVIDER_GOOGLE,
    label: 'Google',
  },
  {
    value: AuthProvider.AUTH_PROVIDER_WHISK,
    label: 'Whisk',
  },
  {
    value: AuthProvider.AUTH_PROVIDER_FACEBOOK,
    label: 'Facebook',
  },
  {
    value: AuthProvider.AUTH_PROVIDER_SAMSUNG,
    label: 'Samsung',
  },
];

export const AppAuthProviders = observer(() => {
  const appDetailsStore = useAppDetailsStoreContext();
  const { appAuthProviders, save } = useStore(() => new AppAuthProvidersStore(appDetailsStore));

  return (
    <Card header={<CardTitle>Auth providers</CardTitle>}>
      <Multiselect items={items} value={appAuthProviders} getValueKey={identity} onChange={save} minimal large />
    </Card>
  );
});
