import {
  whisk_backoffice_shared_v1_Cursors as Cursors,
  whisk_backoffice_users_v1_TrustedUserInfo as TrustedUserInfo,
} from '@whisklabs/b2c-backoffice-protobuf';
import { isDefined, isText } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { deleteUserFromTrusted, getTrustedUsers } from 'b2c/trusted-users/api';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { UserType } from './types';

export class TrustedUsersListStore extends BaseStore {
  @observable private cursors: Cursors | undefined;
  @observable.ref private users: UserType[] = [];

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.users = [];
    this.loader.reset();
  };

  constructor() {
    super();
    makeObservable(this);
  }

  storeInit() {
    void this.loadData();
  }

  @computed get listUsers(): UserType[] {
    return this.users;
  }

  @action private readonly removeUser = (id: string) => {
    this.users = this.users.filter((user) => user.userId !== id);
  };

  @action loadData = async () => {
    const { cancelled, data, error } = await this.loader.load(() =>
      getTrustedUsers({
        limit: 50,
        cursors: this.cursors,
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      const cursor = data.paging?.cursors;
      this.mergeUsers(data.users);

      if (isText(cursor?.after) && cursor?.after !== this.cursors?.after) {
        this.setCursor(cursor);
        void this.loadData();
      } else {
        this.loader.ok();
      }
    } else {
      this.loader.fail(error);
    }
  };

  @action mergeUsers = (users: TrustedUserInfo[]) => {
    this.users = this.users.concat(users);
  };

  @action rejectRecipeText = async (user: UserType) => {
    const { cancelled, success, error, data } = await this.loader.load(() =>
      deleteUserFromTrusted({ userId: user.userId })
    );

    if (cancelled) {
      return;
    }

    if (success) {
      if (data.result?.oneof === 'ok') {
        getStore('toast').success('User removed');
        this.removeUser(user.userId);
        this.loader.ok();
      } else {
        this.loader.warn(data.result);
      }
    } else {
      this.loader.fail(error);
    }
  };

  @action private setCursor(cursors?: Cursors) {
    this.cursors = cursors;
  }
}
