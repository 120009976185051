import {
  whisk_backoffice_b2c_v1_DirectoryAPI_AddRule as AddRuleApi,
  whisk_backoffice_b2c_v1_AddRuleRequest as AddRuleRequest,
  whisk_backoffice_b2c_v1_DirectoryAPI_BatchAddRules as BatchAddRulesApi,
  whisk_backoffice_b2c_v1_BatchAddRulesRequest as BatchAddRulesRequest,
  whisk_backoffice_shared_v1_Cursors as Cursors,
  whisk_backoffice_b2c_v1_DirectoryAPI_DeleteRules as DeleteRulesApi,
  whisk_backoffice_b2c_v1_DeleteRulesRequest as DeleteRulesRequest,
  whisk_backoffice_b2c_v1_DirectoryAPI_DownloadRules as DownloadRulesApi,
  whisk_backoffice_b2c_v1_DownloadRulesRequest as DownloadRulesRequest,
  whisk_backoffice_b2c_v1_DirectoryAPI_GetRules as GetRulesApi,
  whisk_backoffice_b2c_v1_GetRulesRequest as GetRulesRequest,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { AddRuleRequest, BatchAddRulesRequest, DeleteRulesRequest, DownloadRulesRequest, GetRulesRequest, Cursors };

const api = b2cGrpc();

export const getRules = (payload: GetRulesRequest) => api(GetRulesApi, payload);
export const downloadRules = (payload: DownloadRulesRequest) => api(DownloadRulesApi, payload);
export const addRule = (payload: AddRuleRequest) => api(AddRuleApi, payload);
export const batchAddRules = (payload: BatchAddRulesRequest) => api(BatchAddRulesApi, payload);
export const deleteRules = (payload: DeleteRulesRequest) => api(DeleteRulesApi, payload);
