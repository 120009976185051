import { Icon } from '@blueprintjs/core';
import { css } from 'linaria';
import { matchSorter } from 'match-sorter';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { Img, Link } from 'b2c/components';
import { recipePath } from 'b2c/helpers/routes';
import { Table } from 'common/components/client-pagination-table';
import { NonEmptyString } from 'common/components/non-empty-string';
import { Column } from 'common/components/table-view';
import { COLORS } from 'common/helpers/colors';
import { createSearchFunction } from 'common/helpers/search';
import { boolCompareAsc, stringCompareAsc } from 'common/helpers/sort';
import { clsLink } from 'common/styles/link';

import { Actions } from './actions';
import { FlaggedImageQueueStore } from './store';
import { RecipeImage } from './types';

const clsTable = css`
  word-break: break-all;
`;

type ColumnId = 'recipeId' | 'imageUrl' | 'imagePreview' | 'actions' | 'isTrustedUser';

const searchFunction = createSearchFunction<RecipeImage>([
  (image) => image.recipeId,
  { key: (image) => image.recipeId, threshold: matchSorter.rankings.EQUAL },
]);

interface Props {
  store: FlaggedImageQueueStore;
}

export const FlaggedImagesTable = observer(({ store }: Props) => {
  const columns = useMemo(
    (): Column<RecipeImage, ColumnId>[] => [
      {
        id: 'isTrustedUser',
        title: (
          <div title="Trusted User">
            <Icon icon={'endorsed'} color={COLORS.GREEN} />
          </div>
        ),
        width: '20px',
        sorter: (a, b) => boolCompareAsc(a.isFromTrustedUser, b.isFromTrustedUser),
        render: (recipe) => (
          <div>
            {recipe.isFromTrustedUser ? (
              <Icon icon={'endorsed'} color={COLORS.GREEN} />
            ) : (
              <Icon icon={'ban-circle'} color={COLORS.RED} />
            )}
          </div>
        ),
      },
      {
        id: 'recipeId',
        title: 'Recipe Id',
        sorter: (a, b) => stringCompareAsc(a.recipeId, b.recipeId),
        render: (image) => (
          <a className={clsLink} href={recipePath(image.recipeId)} rel="noreferrer" target="_blank">
            <NonEmptyString value={image.recipeId} />
          </a>
        ),
      },
      {
        id: 'imageUrl',
        title: 'Image Url',
        sorter: (a, b) => stringCompareAsc(a.url, b.url),
        render: (image) => (
          <div>
            <Link title="Image Url" small link={image.url} />
          </div>
        ),
      },
      {
        id: 'imagePreview',
        title: 'Preview',
        sorter: (a, b) => stringCompareAsc(a.url, b.url),
        render: (image) => <Img imageUrl={image.url} position="left" />,
      },
      {
        id: 'actions',
        width: '140px',
        title: 'Actions',
        align: 'center',
        render: (image) => <Actions image={image} onApprove={store.approveImage} onReject={store.rejectImage} />,
      },
    ],
    [store.approveImage, store.rejectImage]
  );

  return (
    <Table
      loader={store.loader}
      tableClassName={clsTable}
      cellPaddingVertical={10}
      data={store.images}
      withUnstableHeight
      columns={columns}
      rowKey="entityId"
      searchFunction={searchFunction}
    />
  );
});
