import { observer } from 'mobx-react-lite';

import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';

import { BanCommunityFormStore } from './store';

export const BanCommunityForm = observer(() => {
  const { form, banLoader, banCommunity } = useStore(() => new BanCommunityFormStore());

  return (
    <Card>
      <Form form={form} loader={banLoader} onSubmit={banCommunity} errorTitle="Failed to ban community">
        <div className={clsMb30}>
          <FieldInput field={form.fields.id} label="Community ID" placeholder="e.g. 6cabec8c4b9245d993d641b58a0bb0b9" />
        </div>
        <ButtonSubmit text="Ban" large />
      </Form>
    </Card>
  );
});
