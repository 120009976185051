import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';

import { WidgetFormFields, WidgetFormStore } from '../common/widget-form';

import { WidgetDetailsStore } from './store';

interface Props {
  store: WidgetDetailsStore;
}

export const WidgetDetailsForm = observer(({ store }: Props) => {
  const { widget, saveWidget, saveLoader } = store;
  const formStore = useStore(() => new WidgetFormStore());

  const handleSubmit = useCallback(() => {
    void saveWidget(formStore.getData());
  }, [saveWidget, formStore]);

  useEffect(() => {
    formStore.fill(widget);
  }, [formStore, widget]);

  return (
    <Form form={formStore.form} loader={saveLoader} onSubmit={handleSubmit} errorTitle="Failed to update widget">
      <WidgetFormFields store={formStore} />
      <ButtonSubmit kind="save" text="Save" large />
    </Form>
  );
});
