import { css } from 'linaria';

export const clsNoMargin = css`
  margin: 0 !important;
`;

export const clsMb5 = css`
  margin-bottom: 5px !important;
`;

export const clsMb10 = css`
  margin-bottom: 10px !important;
`;

export const clsMb20 = css`
  margin-bottom: 20px !important;
`;

export const clsMb30 = css`
  margin-bottom: 30px !important;
`;

export const clsMb40 = css`
  margin-bottom: 40px !important;
`;

export const clsMt30 = css`
  margin-top: 30px !important;
`;

export const clsMr20 = css`
  margin-right: 20px !important;
`;

export const clsMr10 = css`
  margin-right: 10px !important;
`;
