import {
  whisk_backoffice_users_v1_UsersAPI_BlockUser as BlockUser,
  whisk_backoffice_users_v1_BlockUserRequest as BlockUserRequest,
  whisk_backoffice_users_v1_UsersAPI_UnblockUser as UnblockUser,
  whisk_backoffice_users_v1_UnblockUserRequest as UnblockUserRequest,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { BlockUserRequest, UnblockUserRequest };

const api = b2cGrpc();

export const blockUser = (payload: BlockUserRequest) => api(BlockUser, payload);
export const unblockUser = (payload: UnblockUserRequest) => api(UnblockUser, payload);
