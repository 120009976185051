import { observer } from 'mobx-react-lite';

import { useAppDetailsStoreContext } from 'b2b/apps/details/context';
import { FieldCheckbox } from 'common/components/fields/field-checkbox';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';
import { clsMt30 } from 'common/styles/margin-padding';

import { AppCommunitiesStore } from './store';

export const AppCommunitiesPage = observer(() => {
  const appDetailsStore = useAppDetailsStoreContext();
  const { form, save, saveLoader } = useStore(() => new AppCommunitiesStore(appDetailsStore));

  return (
    <Form form={form} onSubmit={save} loader={saveLoader} errorTitle="Failed to update community settings">
      <FieldCheckbox field={form.fields.includeCommunityRecipesInSearch} label="Include community recipes in search" />
      <ButtonSubmit kind="save" text="Save" className={clsMt30} large />
    </Form>
  );
});
