import { Intent } from '@blueprintjs/core';
import { isString } from '@whisklabs/typeguards';
import { identity } from 'lodash';
import { memo, useMemo } from 'react';

import { Multiselect, MultiselectItem } from 'common/components/form/multi-select';
import { stringCompareAsc } from 'common/helpers/sort';

import { Country } from './api';
import { CountryFlag } from './country-flag';

export interface CountriesMultiselectProps {
  countries: Country[];
  value: (string | Country)[];
  onChange: (selectedCountryCodes: string[]) => void;
  onBlur?: () => void;
  intent?: Intent;
  tagColumns?: number;
  tagWidthPx?: number;
  placeholder?: string;
  disabled?: boolean;
}

export const CountriesMultiselect = memo(
  ({
    countries,
    value,
    onChange,
    onBlur,
    intent,
    disabled,
    tagColumns,
    placeholder = 'Search countries',
  }: CountriesMultiselectProps) => {
    const items: MultiselectItem<string>[] = useMemo(
      () =>
        countries
          .map((country) => ({
            value: country.code,
            label: country.name,
            icon: <CountryFlag code={country.code} />,
            comment: country.code,
          }))
          .sort((a, b) => stringCompareAsc(a.label, b.label)),
      [countries]
    );

    const selectedValues: string[] = useMemo(
      () => value.map((countryOrCode) => (isString(countryOrCode) ? countryOrCode : countryOrCode.code)),
      [value]
    );

    return (
      <Multiselect
        items={items}
        value={selectedValues}
        getValueKey={identity}
        onChange={onChange}
        onBlur={onBlur}
        intent={intent}
        placeholder={placeholder}
        disabled={disabled}
        tagColumns={tagColumns}
        filterByQuery
        large
        minimal
      />
    );
  }
);
