import { action, makeObservable } from 'mobx';

import { BusinessUser, removeUser } from 'b2b/businesses/details/users/api';
import { DataLoader } from 'common/helpers/data-loader';
import { Dialog } from 'common/helpers/dialog';
import { BaseStore, getStore } from 'common/stores';

import { BusinessUsersTableStore } from '../store';

export class RemoveUserStore extends BaseStore {
  readonly dialog = new Dialog();
  readonly loader = new DataLoader();

  constructor(private readonly businessUsersTableStore: BusinessUsersTableStore) {
    super();
    makeObservable(this);
  }

  @action storeReset() {
    this.loader.reset();
    this.dialog.reset();
  }

  @action removeUser = async (user: BusinessUser) => {
    const { success, error } = await this.loader.load(() =>
      removeUser({
        id: this.businessUsersTableStore.business.id,
        userId: user.id,
      })
    );

    if (success) {
      this.businessUsersTableStore.handleUserRemoved(user);
      getStore('toast').success(`User (${user.email}) has been removed from the team`);
      this.storeReset();
    } else {
      getStore('toast').error(`Failed to remove (${user.email})`, error);
      this.loader.fail(error);
    }
  };
}
