import { Classes, Intent, MenuItem } from '@blueprintjs/core';
import {
  MultiSelect as BlueprintMultiSelect,
  IMultiSelectProps,
  ItemListRenderer,
  ItemRenderer,
  ItemsEqualProp,
} from '@blueprintjs/select';
import { isDefined, isPresent, isText } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { isElement } from 'react-is';

import { useConstantItemsRef } from 'common/components/form/hooks/use-constant-items-ref';
import { areListsEqual } from 'common/helpers/functional';
import { sharedPopoverProps } from 'common/popover';

import {
  filterSortItems,
  getDefaultItemsEqual,
  getSelectedItems,
  getTagStyle,
  renderNoResults,
  renderText,
} from './helpers';
import { clsBreak, clsMenuItem, clsMultiselect, clsTag, clsTagContainer, clsTagInput } from './styles';
import { MultiselectItem, MultiselectValue, MultiselectValueKey } from './types';

type BlueprintMultiSelectProps<T extends MultiselectValue> = IMultiSelectProps<MultiselectItem<T>>;

export interface MultiselectProps<T extends MultiselectValue> {
  items: MultiselectItem<T>[];
  // only use values if items get loaded completely(not an infinite list)
  value: MultiselectItem<T>[] | T[];
  getValueKey: (value: T) => MultiselectValueKey;
  onChange: (newSelectedValues: T[], newSelectedItems: MultiselectItem<T>[]) => void;
  itemListRenderer?: ItemListRenderer<MultiselectItem<T>>;
  itemsEqual?: ItemsEqualProp<MultiselectItem<T>>;
  onBlur?: () => void;
  large?: boolean;
  loading?: boolean;
  disabled?: boolean;
  minimal?: boolean;
  placeholder?: string;
  intent?: Intent;
  tagIntent?: Intent;
  className?: string;
  tagClassName?: string;
  tagColumns?: number;
  popoverProps?: BlueprintMultiSelectProps<T>['popoverProps'];
  filterByQuery?: boolean;
  resetOnSelect?: boolean;
}

export const Multiselect = <T extends MultiselectValue>({
  items,
  value,
  getValueKey,
  itemListRenderer,
  itemsEqual = getDefaultItemsEqual(getValueKey),
  onChange,
  onBlur,
  intent,
  large,
  placeholder = 'Search',
  minimal,
  loading,
  disabled,
  tagIntent,
  className,
  tagClassName,
  tagColumns,
  popoverProps,
  filterByQuery,
  resetOnSelect,
}: MultiselectProps<T>) => {
  const [searchQuery, setSearchQuery] = useState('');

  const selectedItemsProp: MultiselectItem<T>[] = useMemo(() => getSelectedItems(value, items), [value, items]);
  const selectedValuesKeysPropSet: Set<MultiselectValueKey> = useMemo(
    () => new Set(selectedItemsProp.map((item) => getValueKey(item.value))),
    [selectedItemsProp, getValueKey]
  );

  const [selectedItems, setSelectedItems] = useState<MultiselectItem<T>[]>(selectedItemsProp);
  const selectedValuesMap: Map<MultiselectValueKey, T> = useMemo(
    () => new Map(selectedItems.map((item) => [getValueKey(item.value), item.value])),
    [selectedItems, getValueKey]
  );

  useEffect(() => {
    setSelectedItems(selectedItemsProp);
  }, [selectedItemsProp]);

  const renderTag = useCallback(
    (item: MultiselectItem<T>): ReactNode => (
      // pass item value as data- attribute, so we can delete it when tag is removed
      <span data-item-value={getValueKey(item.value)} className={clsTagContainer} title={item.label}>
        {renderText(item)}
      </span>
    ),
    [getValueKey]
  );

  const renderItem: ItemRenderer<MultiselectItem<T>> = useCallback(
    (item, { modifiers, handleClick }) => {
      if (!modifiers.matchesPredicate) {
        return null;
      }

      return (
        <MenuItem
          active={modifiers.active}
          className={clsMenuItem}
          icon={selectedValuesMap.has(getValueKey(item.value)) ? 'tick' : 'blank'}
          key={String(getValueKey(item.value))}
          onClick={handleClick}
          text={renderText(item)}
          labelElement={isText(item.comment) ? <span className={clsBreak}>{item.comment}</span> : null}
        />
      );
    },
    [selectedValuesMap, getValueKey]
  );

  // Workaround for buggy blueprint which doesn't react to change of `renderItem`
  const renderItemList: ItemListRenderer<MultiselectItem<T>> | undefined = useMemo(() => {
    if (isPresent(itemListRenderer)) {
      return (...args) => itemListRenderer(...args);
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemListRenderer, renderItem]);

  const handleItemToggle = useCallback(
    (item: MultiselectItem<T>) => {
      setSelectedItems((curSelectedItems) => {
        const index = curSelectedItems.findIndex(
          (selectedItem) => getValueKey(selectedItem.value) === getValueKey(item.value)
        );
        if (index > -1) {
          return [...curSelectedItems.slice(0, index), ...curSelectedItems.slice(index + 1)];
        } else {
          return [...curSelectedItems, item];
        }
      });
    },
    [getValueKey]
  );

  const finalPopoverProps: BlueprintMultiSelectProps<T>['popoverProps'] = useMemo(
    () => ({
      ...sharedPopoverProps,
      ...popoverProps,
      onClosing: (...args) => {
        setSearchQuery('');
        if (!areListsEqual(selectedValuesMap.keys(), selectedValuesKeysPropSet)) {
          onChange(Array.from(selectedValuesMap.values()), selectedItems);
        }
        onBlur?.();
        popoverProps?.onClosing?.(...args);
      },
    }),
    [popoverProps, onChange, onBlur, selectedValuesMap, selectedValuesKeysPropSet, selectedItems]
  );

  const tagInputProps: BlueprintMultiSelectProps<T>['tagInputProps'] = useMemo(
    () => ({
      onRemove: (element) => {
        setSelectedItems((curSelectedItems) => {
          // Retrieve index from item value we saved in `renderTag`. @blueprint provides no other way of getting it
          const elementProps = isElement(element) ? (element.props as { 'data-item-value': unknown }) : undefined;
          const itemValue = elementProps?.['data-item-value'];

          if (isDefined(itemValue)) {
            const index = curSelectedItems.findIndex((item) => getValueKey(item.value) === itemValue);
            if (index > -1) {
              return [...curSelectedItems.slice(0, index), ...curSelectedItems.slice(index + 1)];
            }
          }
          return curSelectedItems;
        });
      },
      addOnBlur: true,
      intent,
      className: clsTagInput,
      tagProps: {
        intent: tagIntent,
        interactive: true,
        minimal,
        className: cx(clsTag, tagClassName),
        style: getTagStyle(tagColumns),
      },
      large,
      disabled: loading || disabled,
    }),
    [intent, minimal, large, loading, disabled, tagClassName, tagColumns, tagIntent, getValueKey]
  );

  const itemsAsRef = useConstantItemsRef(items);

  return (
    <BlueprintMultiSelect
      items={itemsAsRef}
      selectedItems={selectedItems}
      itemRenderer={renderItem}
      itemListRenderer={renderItemList}
      itemListPredicate={filterByQuery ? filterSortItems : undefined}
      onQueryChange={setSearchQuery}
      onItemSelect={handleItemToggle}
      query={searchQuery}
      noResults={renderNoResults()}
      itemsEqual={itemsEqual}
      tagRenderer={renderTag}
      tagInputProps={tagInputProps}
      popoverProps={finalPopoverProps}
      className={cx(clsMultiselect, className, large ? Classes.LARGE : undefined)}
      placeholder={placeholder}
      resetOnSelect={resetOnSelect}
      openOnKeyDown={false}
      fill
    />
  );
};
