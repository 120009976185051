import {
  whisk_backoffice_b2c_v1_UrlBlockingRuleAPI_CreateRule as CreateRuleAPI,
  whisk_backoffice_b2c_v1_CreateRuleRequest as CreateRuleRequest,
  whisk_backoffice_b2c_v1_UrlBlockingRuleAPI_DeleteRule as DeleteRulAPI,
  whisk_backoffice_b2c_v1_DeleteRuleRequest as DeleteRuleRequest,
  whisk_backoffice_b2c_v1_DeleteRuleResponse as DeleteRuleResponse,
  whisk_backoffice_b2c_v1_UrlBlockingRuleAPI_ListRules as ListRulesAPI,
  whisk_backoffice_b2c_v1_ListRulesRequest as ListRulesRequestBE,
  whisk_backoffice_b2c_v1_UrlBlockingRuleAPI_UpdateRule as UpdateRuleAPI,
  whisk_backoffice_b2c_v1_UpdateRuleRequest as UpdateRuleRequest,
} from '@whisklabs/b2c-backoffice-protobuf';
import { GOutput } from '@whisklabs/grpc';

import { b2cGrpc } from 'b2c/grpc';

const api = b2cGrpc();

export const removePattern = (payload: DeleteRuleRequest): Promise<GOutput<DeleteRuleResponse>> =>
  api(DeleteRulAPI, payload);
export const updatePattern = (payload: UpdateRuleRequest) => api(UpdateRuleAPI, payload);
export const createPattern = (payload: CreateRuleRequest) => api(CreateRuleAPI, payload);
export const loadPatterns = (payload: ListRulesRequestBE) => api(ListRulesAPI, payload);
