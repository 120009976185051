import { observer } from 'mobx-react-lite';

import { AppsTable, AppsTableStore } from 'b2b/apps';
import { useBusinessDetailsStoreContext } from 'b2b/businesses/details/context';
import { useStore } from 'common/hooks/use-store';

export const BusinessAppsPage = observer(() => {
  const store = useStore(() => new AppsTableStore());
  const { business } = useBusinessDetailsStoreContext();

  return <AppsTable store={store} businessId={business.id} />;
});
