import { cx } from 'linaria';

import { TableRowProps } from '../types';

import { clsRow, rowClasses } from './styles';

export const TableRow = ({ children, innerRef, className, rowKind = 'default' }: TableRowProps) => (
  <div ref={innerRef} className={cx(clsRow, className, rowClasses[rowKind])}>
    {children}
  </div>
);
