import { action, makeObservable, observable } from 'mobx';

import { RecordType, RuleType } from 'b2c/indexing-tool/types';
import { BaseStore } from 'common/stores';

export class ControlStore extends BaseStore {
  @observable ruleType: RuleType = RuleType.DIRECTORY_RULE_TYPE_NO_INDEX;
  @observable recordType: RecordType = RecordType.DIRECTORY_RECORD_TYPE_COMMUNITY_RECIPE;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  setRuleType = (value: RuleType) => {
    this.ruleType = value;
  };

  @action
  setRecordType = (value: RecordType) => {
    this.recordType = value;
  };

  @action
  storeReset = () => {
    this.setRuleType(RuleType.DIRECTORY_RULE_TYPE_NO_INDEX);
    this.setRecordType(RecordType.DIRECTORY_RECORD_TYPE_COMMUNITY_RECIPE);
  };
}
