import { clsField } from 'common/components/form/styles';
import { FieldModel } from 'common/helpers/form';
import { FormGroupProps } from 'common/types/blueprint';

export const getFieldFormGroupProps = <T>(field: FieldModel<T>): FormGroupProps => ({
  labelFor: field.id,
  intent: field.intent,
  helperText: field.error,
  labelInfo: field.isRequired ? '(required)' : field.isReadonly ? '(readonly)' : undefined,
  className: clsField,
  disabled: field.isDisabled,
});

export const getFieldControlProps = <T>(field: FieldModel<T>) => ({
  id: field.id,
  intent: field.intent,
  value: field.value,
  onBlur: field.handleBlur,
  onChange: field.handleValueChange,
  disabled: field.isDisabled,
  readOnly: field.isReadonly,
});

export const getFieldInputProps = <T extends string | undefined>(field: FieldModel<T>) => ({
  ...getFieldControlProps<T>(field),
  onChange: field.handleInput,
});

export const getFieldCheckProps = <T extends boolean | undefined>(field: FieldModel<T>) => {
  const { value, ...restControlProps } = getFieldControlProps<T>(field);

  return {
    ...restControlProps,
    checked: value,
    onChange: field.handleChecked,
  };
};
