import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';

import { UserFormFields, UserFormStore } from '../common/user-form';

import { UserDetailsStore } from './store';

interface Props {
  store: UserDetailsStore;
}

export const UserDetailsForm = observer(({ store }: Props) => {
  const { user, saveRole, saveRoleLoader } = store;
  const formStore = useStore(() => new UserFormStore());

  useEffect(() => {
    formStore.fill(user);
  }, [formStore, user]);

  return (
    <Form form={formStore.form} loader={saveRoleLoader} onSubmit={saveRole} errorTitle="Failed to update user">
      <UserFormFields store={formStore} />
      <ButtonSubmit kind="save" text="Save" large />
    </Form>
  );
});
