import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable, observable } from 'mobx';

import { BusinessDetailsStore } from 'b2b/businesses/details/store';
import { DataLoader } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { WidgetFormPayload, createTrackingId, createWidget } from '../api';

export class WidgetCreateStore extends BaseStore {
  readonly createLoader = new DataLoader();

  readonly createTrackingIdLoader = new DataLoader();

  @observable.ref trackingId = '';

  constructor(private readonly businessDetailsStore: BusinessDetailsStore) {
    super();
    makeObservable(this);
  }

  @action storeReset = () => {
    this.createLoader.reset();
    this.createTrackingIdLoader.reset();
    this.trackingId = '';
  };

  @action createTrackingId = async () => {
    const { cancelled, data, error } = await this.createTrackingIdLoader.load(() =>
      createTrackingId({
        businessId: this.businessDetailsStore.business.id,
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.trackingId = data.trackingId;
      this.createTrackingIdLoader.ok();
    } else {
      this.createTrackingIdLoader.fail(error);
    }
  };

  @action create = async (widgetPayload: WidgetFormPayload) => {
    const { cancelled, data, error } = await this.createLoader.load(() =>
      createWidget({
        widget: {
          ...widgetPayload,
          businessId: this.businessDetailsStore.business.id,
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.widget)) {
      getStore('router').push(`/b2b/businesses/${this.businessDetailsStore.business.id}/widgets/${data.widget.id}`);
      getStore('toast').success('Widget created');
      this.createLoader.ok();
    } else {
      this.createLoader.fail(error);
    }
  };
}
