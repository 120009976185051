import { Intent, Tag } from '@blueprintjs/core';
import { isPresent } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { Info, Link, User } from 'b2c/components';
import { communityPath, recipePath } from 'b2c/helpers/routes';
import { Box } from 'common/components/box';

import { FlaggedRecipe } from '../types';

import { ActionsSelect } from './actions';
import { FlaggedRecipeQueueStore } from './store';

const clsRow = css`
  &:hover {
    background-color: #f5f8fa;
  }
`;

interface Props {
  store: FlaggedRecipeQueueStore;
  recipe: FlaggedRecipe;
}

export const Row = observer(({ recipe, store }: Props) => {
  const [full, setFull] = useState(false);

  return (
    <Box display="block" border="1px solid #DDD" borderRadius="18px" p={4} mb={4} className={clsRow}>
      <Box alignItems="stretch">
        <Box flexGrow={1} flexDirection="row">
          <Box flexDirection="column" width="50%" alignItems="flex-start">
            <Info type="Recipe" text={<Link title={recipe.name} small link={recipePath(recipe.id)} />} />
            <Info type="Email" text={recipe.email} />
          </Box>

          <Box flexDirection="column" width="50%" alignItems="flex-start">
            <Info
              type="Community"
              text={
                isPresent(recipe.community) ? (
                  <Link title={recipe.community.name} small link={communityPath(recipe.community.id)} />
                ) : null
              }
            />
            <Info type="Comment" text={recipe.comment} />
          </Box>
        </Box>

        <Box flexDirection="column" alignItems="flex-end" mr={4} width="110px" justifyContent="space-between">
          <Box mb={4}>
            <Tag large intent={Intent.PRIMARY} title={recipe.flagReason}>
              {recipe.flagReason}
            </Tag>
          </Box>
          <Tag
            rightIcon={full ? 'double-chevron-up' : 'double-chevron-down'}
            large
            interactive
            onClick={() => setFull((i) => !i)}
          >
            {full ? 'Less' : 'More'}
          </Tag>
        </Box>

        <Box width="36px">
          <ActionsSelect onClick={store.applyAction} recipe={recipe} />
        </Box>
      </Box>

      {full ? (
        <Box mt={4}>
          <User type="Reporter" {...recipe.reporter} id={recipe.reporter?.id ?? ''} />
        </Box>
      ) : null}
    </Box>
  );
});
