import { Icon } from '@blueprintjs/core';
import { isText } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { useState } from 'react';

import { Box } from 'common/components/box';

const clsImgPreview = css`
  display: none;
  background: #fff;
  position: absolute;
  left: 100%;
  right: 0;

  & > img {
    position: absolute;
    transform: translate(0, -50%);
    border-radius: 8px;
    display: block;
    max-width: 500px;
    max-height: 500px;
    width: auto;
    height: auto;
    border: 4px solid #fff;
    background: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    z-index: 102;
  }
`;

const clsImg = css`
  &:hover .${clsImgPreview} {
    display: block;
  }
`;

const clsImgPreviewLeft = css`
  .${clsImgPreview}& {
    left: 0;

    & > img {
      right: 100%;
      left: auto;
    }
  }
`;

const clsImgSmall = css`
  max-height: 60px;
  border-radius: 12px;
`;

interface Props {
  imageUrl?: string;
  position?: 'right' | 'left';
}

export const Img: React.FC<Props> = ({ imageUrl, position = 'right' }) => {
  const [error, setError] = useState(false);

  return (
    <Box width="60px" minWidth="60px" height="60px" borderRadius="12px" mr={4} position="relative" className={clsImg}>
      {error ? (
        <Icon icon={'remove'} iconSize={60} color="#C73C47" />
      ) : isText(imageUrl) ? (
        <>
          <a href={imageUrl} rel="noreferrer" target="_blank">
            {
              <img
                width="100%"
                height="100%"
                src={imageUrl}
                aria-hidden
                alt={imageUrl}
                className={clsImgSmall}
                onError={() => setError(true)}
              />
            }
          </a>
          <a
            href={imageUrl}
            rel="noreferrer"
            target="_blank"
            className={`${clsImgPreview} ${position === 'left' ? clsImgPreviewLeft : ''}`}
          >
            <img width="100%" height="100%" src={imageUrl} aria-hidden alt={imageUrl} />
          </a>
        </>
      ) : (
        <Icon icon="disable" iconSize={60} color="#eee" />
      )}
    </Box>
  );
};
