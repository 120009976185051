import { observer } from 'mobx-react-lite';
import { createContext } from 'react';

import { useStore } from 'common/hooks/use-store';

import { RulesListStore } from './list/store';
import { FieldFormStore } from './record-input/field/store';
import { FileFormStore } from './record-input/file/store';
import { ControlStore } from './settings/store';

interface IndexingTool {
  listStore: RulesListStore;
  fieldStore: FieldFormStore;
  fileStore: FileFormStore;
  controlStore: ControlStore;
}

export const IndexingToolContext = createContext<IndexingTool>({} as IndexingTool);

export const IndexingToolContextWrapper = observer(({ children }) => {
  const controlStore = useStore(() => new ControlStore());
  const listStore = useStore(() => new RulesListStore(controlStore));
  const fileStore = useStore(() => new FileFormStore(controlStore));
  const fieldStore = useStore(() => new FieldFormStore(controlStore));

  return (
    <IndexingToolContext.Provider value={{ listStore, fileStore, controlStore, fieldStore }}>
      {children}
    </IndexingToolContext.Provider>
  );
});
