import { Icon, IconName } from '@blueprintjs/core';
import { isDefined, isFunction } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { MouseEventHandler, useCallback } from 'react';

import { clsFlex, clsFlexAlignItemsCenter, clsFlexGrow, clsFlexJustifyEnd } from 'common/styles/layout';
import { SortOrder } from 'common/types/types';

import { DEFAULT_HEAD_HEIGHT, DEFAULT_PADDING_HORISONTAL, DEFAULT_PADDING_VERTICAL } from '../../constants';
import { ColumnId, TableHeadCellProps } from '../../types';

import { clsHead, clsSortIconContainer, clsSortable, headClasses } from './styles';

const nextSortDir: Record<SortOrder, SortOrder> = {
  asc: 'desc',
  desc: 'asc',
};

const iconBySortDir: Record<SortOrder, IconName> = {
  asc: 'caret-up',
  desc: 'caret-down',
};

export const TableHeadCell = <T, U extends ColumnId>({
  headHeight = DEFAULT_HEAD_HEIGHT,
  paddingVertical = DEFAULT_PADDING_VERTICAL,
  paddingHorizontal = DEFAULT_PADDING_HORISONTAL,
  children,
  column,
  sort,
  onSort,
}: TableHeadCellProps<T, U>) => {
  const sorter = column.sorter === true || isFunction(column.sorter);
  const sortOrder = sorter && isDefined(sort) && sort.sortKey === column.id ? sort.sortOrder : undefined;

  const handleSort: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.preventDefault();
      if (isDefined(onSort)) {
        onSort({
          sortKey: column.id,
          sortOrder: isDefined(sortOrder) ? nextSortDir[sortOrder] : 'asc',
        });
      }
    },
    [column.id, sortOrder, onSort]
  );

  const sortProps = sorter
    ? {
        role: 'button',
        tabIndex: 0,
        onClick: handleSort,
      }
    : undefined;

  return (
    <div
      style={{
        height: headHeight,
        paddingTop: column.paddingVertical ?? paddingVertical,
        paddingBottom: column.paddingVertical ?? paddingVertical,
        paddingLeft: column.paddingHorizontal ?? paddingHorizontal,
        paddingRight: column.paddingHorizontal ?? paddingHorizontal,
      }}
      className={cx(clsHead, sorter ? clsSortable : null)}
      {...(!column.titleHasControl ? sortProps : undefined)}
    >
      <div className={cx(clsFlex, clsFlexAlignItemsCenter, headClasses[column.align ?? 'left'])}>
        <div>{children}</div>
        {sorter ? (
          <div
            {...(column.titleHasControl ? sortProps : undefined)}
            className={cx(clsFlexGrow, clsFlex, clsFlexJustifyEnd, clsFlexAlignItemsCenter, clsSortIconContainer)}
          >
            <Icon icon={isDefined(sortOrder) ? iconBySortDir[sortOrder] : 'double-caret-vertical'} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
