import { isObject, isString, isText } from '@whisklabs/typeguards';

export const tryFormatError = (error: unknown): string | undefined => {
  const message =
    isObject(error) && isString(error.message) ? error.message.trim() : isString(error) ? error : undefined;

  if (!isText(message)) {
    return undefined;
  }

  try {
    return JSON.stringify(JSON.parse(message), null, 2);
  } catch {
    return message;
  }
};
