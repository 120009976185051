import { isDefined } from '@whisklabs/typeguards';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

import { useAppDetailsStoreContext } from 'b2b/apps/details/context';
import { FieldInput } from 'common/components/fields/field-input';
import { Form } from 'common/components/form/form';
import { RenderRowProps, RowActions, TableCell, TableRow } from 'common/components/table-view';
import { useStore } from 'common/hooks/use-store';
import { clsNoMargin } from 'common/styles/margin-padding';

import { AppRedirectUrlStore } from './store';

export type ColumnId = 'url' | 'action';

export const AppRedirectUrlsRow = observer(
  ({ datum, index, getRowProps, getCellProps, rowKind }: RenderRowProps<string, ColumnId>) => {
    const appDetailsStore = useAppDetailsStoreContext();
    const { form, addLoader, save, add, saveLoader, removeLoader, remove } = useStore(
      () => new AppRedirectUrlStore(appDetailsStore)
    );

    useEffect(() => {
      form.reset({ redirectUrl: datum ?? '' });
    }, [form, datum]);

    const handleSubmit = useCallback(() => {
      if (isDefined(index)) {
        void save(form.values.redirectUrl, index);
      } else {
        void add(form.values.redirectUrl);
      }
    }, [form, index, add, save]);

    return (
      <TableRow {...getRowProps()}>
        <TableCell {...getCellProps('url')}>
          <Form
            form={form}
            loader={rowKind === 'new' ? addLoader : saveLoader}
            onSubmit={handleSubmit}
            errorTitle={rowKind === 'new' ? 'Failed to add redirect url' : 'Failed to update redirect urls'}
            fill
          >
            <FieldInput
              field={form.fields.redirectUrl}
              className={clsNoMargin}
              placeholder="e.g. https://example.com/oauth"
            />
          </Form>
        </TableCell>

        <TableCell {...getCellProps('action')}>
          <RowActions
            rowKind={rowKind}
            form={form}
            addLoader={addLoader}
            saveLoader={saveLoader}
            removeLoader={removeLoader}
            onRemove={isDefined(index) ? async () => await remove(index) : undefined}
          />
        </TableCell>
      </TableRow>
    );
  }
);
