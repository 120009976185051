import { BaseModifier } from 'popper.js';

/**
 * Make sure width of popper is not less than width of ref element
 */
export const contentMinWidthModifier = (opts?: BaseModifier): BaseModifier => ({
  enabled: true,
  order: 849,
  fn: (data) => {
    const { width: refWidth, left: refLeft } = data.offsets.reference;
    const { width: contentWidth, left: contentLeft } = data.offsets.popper;

    data.styles.minWidth = `${refWidth}px`;
    data.offsets.popper.width = Math.max(refWidth, contentWidth);
    data.offsets.popper.left = Math.min(refLeft, contentLeft);

    return data;
  },
  ...opts,
});
