import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { isPresent } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';

import { sharedPopoverProps } from 'common/popover';

import { ActionId, FlaggedRecipe } from '../types';

interface Props {
  recipe: FlaggedRecipe;
  onClick: (args: { actionId: ActionId; recipe: FlaggedRecipe }) => void;
}

const clsActionButton = css`
  min-width: 100% !important;
`;

const clsActionSelect = css`
  &,
  div,
  button {
    height: 100%;
  }
`;

const clsActionItem = css`
  padding: 10px;
`;

interface ActionItem {
  id: ActionId;
  text: string;
}

const ActionSelect = Select.ofType<ActionItem>();

const renderActionItem: ItemRenderer<ActionItem> = (item, { handleClick, modifiers }) => (
  <MenuItem className={clsActionItem} active={modifiers.active} key={item.id} onClick={handleClick} text={item.text} />
);

export const ActionsSelect = observer(({ recipe, onClick }: Props) => {
  const handleClick = useCallback(
    (item: ActionItem) => {
      onClick({ actionId: item.id, recipe });
    },
    [recipe, onClick]
  );

  const items: ActionItem[] = useMemo(
    () =>
      [
        {
          id: ActionId.Approve,
          text: isPresent(recipe.community) ? 'Approve recipe for this community' : 'Approve recipe',
        },
        isPresent(recipe.community)
          ? {
              id: ActionId.FlagInCommunity,
              text: 'Leave flagged for this community',
            }
          : undefined,
        {
          id: ActionId.FlagAllCommunities,
          text: 'Flag recipe for all communities',
        },
        {
          id: ActionId.Ban,
          text: 'Ban recipe from Whisk',
        },
      ].filter(isPresent),
    [recipe.community]
  );

  return (
    <ActionSelect
      items={items}
      disabled={recipe.loading}
      itemRenderer={renderActionItem}
      onItemSelect={handleClick}
      popoverProps={sharedPopoverProps}
      filterable={false}
      className={clsActionSelect}
    >
      <Button className={clsActionButton} disabled={recipe.loading} icon="more" fill large />
    </ActionSelect>
  );
});
