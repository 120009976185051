import { css, cx } from 'linaria';
import { memo } from 'react';

import { CountryFlagImage } from './country-flag-image';
import { getFlagEmoji, isFlagEmojiSupported } from './flag-emjoi';

const clsFlag = css`
  display: inline-block;
  vertical-align: text-bottom;
  line-height: 1;
`;

const clsEmoji = css`
  font-size: 1.3em;
  vertical-align: text-bottom;
`;

interface Props {
  code: string; // ISO 3166-1 alpha-2 country code, lowercase or uppercase
  className?: string;
}

export const CountryFlag = memo(({ code, className }: Props) => (
  <span className={cx(clsFlag, className)}>
    {isFlagEmojiSupported() ? <span className={clsEmoji}>{getFlagEmoji(code)}</span> : <CountryFlagImage code={code} />}
  </span>
));
