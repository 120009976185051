import { css, cx } from 'linaria';

import { clsField, clsForm } from 'common/components/form/styles';

import { clsTd } from './table-body/cell/styles';
import { Layout } from './types';

const clsTable = css`
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;

  .${clsForm} {
    max-width: none;
  }

  .${clsField} {
    margin: 0;
  }

  & .${clsTd} > a:first-child:last-child {
    font-weight: 500;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
  }
`;

const clsLayoutFixed = css`
  table-layout: fixed;
`;

const clsLayoutAuto = css`
  table-layout: auto;
`;

interface Props {
  layout?: Layout;
  className?: string;
}

export const TableLayout: React.FC<Props> = ({ className, layout = 'auto', children }) => (
  <div className={cx(clsTable, layout === 'fixed' ? clsLayoutFixed : clsLayoutAuto, className)}>{children}</div>
);
