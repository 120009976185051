import { isText } from '@whisklabs/typeguards';

export function isLight(hex?: string | null) {
  if (!isText(hex)) {
    return false;
  }

  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 145;
}
