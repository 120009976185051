import { Button, Intent } from '@blueprintjs/core';
import { css } from 'linaria';
import React, { useCallback } from 'react';

import { RecipeWithFlaggedText } from '../types';

interface Props {
  recipe: RecipeWithFlaggedText;
  onApprove: (recipe: RecipeWithFlaggedText) => void;
  onReject: (image: RecipeWithFlaggedText) => void;
}

const clsButton = css`
  text-align: center;

  & + & {
    margin-left: 30px;
  }
`;

export const Actions: React.FC<Props> = ({ recipe, onApprove, onReject }) => {
  const handleApprove = useCallback(() => {
    onApprove(recipe);
  }, [recipe, onApprove]);

  const handleReject = useCallback(() => {
    onReject(recipe);
  }, [recipe, onReject]);

  return (
    <div>
      <Button className={clsButton} rightIcon="tick" onClick={handleApprove} large intent={Intent.SUCCESS} />
      <Button className={clsButton} rightIcon="cross" onClick={handleReject} large intent={Intent.DANGER} />
    </div>
  );
};
