import { isDefined, isString } from '@whisklabs/typeguards';
import { action, autorun, computed, makeObservable, observable } from 'mobx';

import { App, updateApp } from 'b2b/apps/api';
import { AppDetailsStore } from 'b2b/apps/details/store';
import { Retailer, RetailersStore } from 'b2b/retailers';
import { DataLoader } from 'common/helpers/data-loader';
import { areListsEqual } from 'common/helpers/functional';
import { BaseStore, getStore } from 'common/stores';

export interface RetailerDatum {
  retailer: Retailer;
  isSelected: boolean;
}

export class AppRetailersStore extends BaseStore {
  readonly saveLoader = new DataLoader();

  @observable.shallow retailers: RetailerDatum[] = [];

  constructor(private readonly appDetailsStore: AppDetailsStore, private readonly retailersStore: RetailersStore) {
    super();
    makeObservable(this);
  }

  @action storeInit = () => {
    this.sub(
      autorun(() => {
        this.retailers = this.retailersStore.retailers.map((retailer) => ({
          retailer,
          isSelected: this.appRetailerIdsSet.has(retailer.id),
        }));
      })
    );
  };

  @action storeReset = () => {
    this.saveLoader.reset();
  };

  private get app(): App {
    return this.appDetailsStore.app;
  }

  @computed private get appRetailerIdsSet() {
    return new Set(
      (this.app.settings?.retailers ?? [])
        .filter(isString)
        .filter((appRetailerId) => this.retailersStore.hasRetailer(appRetailerId))
    );
  }

  @computed get selectedIdsSet() {
    return new Set(this.retailers.filter(({ isSelected }) => isSelected).map(({ retailer }) => retailer.id));
  }

  @computed get selectionChanged() {
    return !areListsEqual(this.selectedIdsSet, this.appRetailerIdsSet);
  }

  @action save = async () => {
    const { cancelled, data, error } = await this.saveLoader.load(() =>
      updateApp(this.app.appId, {
        settings: {
          retailers: Array.from(this.selectedIdsSet),
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.app)) {
      getStore('toast').success('Updated retailers');
      this.appDetailsStore.setApp(data.app);
      this.saveLoader.ok();
    } else {
      getStore('toast').error('Failed to update retailers', error);
      this.saveLoader.fail(error);
    }
  };
}
