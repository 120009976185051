import { memo } from 'react';

import { RouteSwitch } from 'common/components/routes';

import { UserCreatePage } from './create';
import { UserDetailsPage } from './details';
import { UsersTablePage } from './table';

export const UsersPage = memo(() => (
  <RouteSwitch
    routes={[
      { path: '/', exact: true, component: UsersTablePage },
      { path: '/new', component: UserCreatePage },
      { path: '/:userId', component: UserDetailsPage },
    ]}
  />
));
