import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { textOrFallback } from 'common/helpers/data';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { Retailer, getRetailers } from './api';

export class RetailersStore extends BaseStore {
  private readonly retailerById = observable.map<string, Retailer>([], { deep: false });

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.loader.reset();
    this.retailerById.clear();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get retailers(): Retailer[] {
    return Array.from(this.retailerById.values(), (retailer) => ({
      ...retailer,
      displayName: textOrFallback(retailer.displayName, 'Retailer ""'),
    }));
  }

  hasRetailer = (id: string) => this.retailerById.has(id);

  @action loadRetailers = async () => {
    const { cancelled, data, error } = await this.loader.load(() => getRetailers());

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.retailerById.replace(data.retailers.map((retailer) => [retailer.id, retailer]));
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
