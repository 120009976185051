import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { CommunityStore } from 'b2c/community';
import { LoadingWrapper } from 'common/components/loading-wrapper';
import { PageHeader } from 'common/components/page-header';
import { useStore } from 'common/hooks/use-store';

import { UpdateCommunityTopicsForm } from './form';

interface Params {
  communityId: string;
}

export const UpdateCommunityTopicsPage = observer(() => {
  const communityStore = useStore(() => new CommunityStore());
  const { loader, loadCommunity, community } = communityStore;
  const { communityId } = useParams<Params>();

  useEffect(() => {
    void loadCommunity(communityId);
  }, [loadCommunity, communityId]);

  return (
    <div>
      <LoadingWrapper
        loader={loader}
        errorProps={{
          title: 'Failed to load community',
        }}
      >
        <PageHeader title={`Community "${community.name}"`} description={community.description} />
        <UpdateCommunityTopicsForm communityStore={communityStore} />
      </LoadingWrapper>
    </div>
  );
});
