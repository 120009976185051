import { API_B2C } from 'common/config';
import { getApiStandUrl } from 'common/helpers/api-stand';
import { createGrpcClient } from 'common/helpers/grpc';
import { initialQuery } from 'common/helpers/router';

const API_STAND_TEMPLATE = 'https://backoffice-grpc-web%ENV.whisk-dev.com';

const templateStands: Record<string, string> = {
  dev: '',
  b2c: '.b2c',
};

const queryApiUrl = getApiStandUrl(initialQuery['api-b2c'], API_STAND_TEMPLATE, templateStands);

export const b2cGrpc = () => createGrpcClient(queryApiUrl ?? API_B2C);
