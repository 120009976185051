import { Checkbox, Classes, ICheckboxProps } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { observer } from 'mobx-react-lite';

import { useFormContext } from 'common/components/form/context';
import { getFieldCheckProps } from 'common/components/form/helpers';
import { clsField } from 'common/components/form/styles';
import { FieldModel } from 'common/helpers/form';
import { clsNoMargin } from 'common/styles/margin-padding';

interface Props extends ICheckboxProps {
  field?: FieldModel<boolean>;
  formId?: string;
  noMargin?: boolean;
  loading?: boolean;
}

export const FieldCheckbox = observer(
  ({
    field,
    formId,
    className,
    large = true,
    noMargin = false,
    loading: loadingProp,
    disabled,
    ...checkboxProps
  }: Props) => {
    const { loader } = useFormContext();
    const loading = loadingProp ?? loader?.isPending;

    const fieldCheckProps: ICheckboxProps = isDefined(field) ? getFieldCheckProps(field) : {};

    return (
      <Checkbox
        {...fieldCheckProps}
        form={formId}
        large={large}
        disabled={(disabled ?? fieldCheckProps.disabled) || loading}
        className={cx(clsField, large ? Classes.LARGE : undefined, noMargin ? clsNoMargin : undefined, className)}
        {...checkboxProps}
      />
    );
  }
);
