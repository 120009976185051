import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable, observable } from 'mobx';

import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores/base';

import { Community, getCommunity, stubCommunity } from './api';

export class CommunityStore extends BaseStore {
  @observable.ref community: Community = stubCommunity();

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.loader.reset();
    this.community = stubCommunity();
  };

  @action loadCommunity = async (communityId: string) => {
    const { cancelled, data, error } = await this.loader.load(() => getCommunity({ communityId }));

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.community)) {
      this.community = data.community;
      this.loader.ok();
    } else {
      this.community = stubCommunity();
      this.loader.fail(error);
    }
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
