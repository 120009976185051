import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { Business } from 'b2b/businesses/api';
import { BusinessUserRoleType } from 'b2b/businesses/details/common/user-role';
import { BusinessDetailsStore } from 'b2b/businesses/details/store';
import { textOrFallback } from 'common/helpers/data';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { BusinessUser, getUsers } from '../api';

export class BusinessUsersTableStore extends BaseStore {
  readonly userById = observable.map<string, BusinessUser>([], { deep: false });

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  constructor(private readonly businessDetailsStore: BusinessDetailsStore) {
    super();
    makeObservable(this);
  }

  @action storeReset = () => {
    this.loader.reset();
    this.userById.clear();
  };

  get business(): Business {
    return this.businessDetailsStore.business;
  }

  @computed get users(): BusinessUser[] {
    return Array.from(this.userById.values(), (user) => ({
      ...user,
      name: textOrFallback(user.name, 'User ""'),
    }));
  }

  @action loadUsers = async () => {
    const { cancelled, data, error } = await this.loader.load(() =>
      getUsers({
        id: this.business.id,
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.userById.replace(data.users.map((user) => [user.id, user]));
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action handleUserRemoved = (user: BusinessUser) => {
    this.userById.delete(user.id);
  };

  @action handleUserRoleChanged = (user: BusinessUser, role: BusinessUserRoleType) => {
    this.userById.set(user.id, {
      ...user,
      userRole: {
        rules: user.userRole?.rules ?? [],
        role,
      },
    });
  };
}
