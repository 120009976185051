import ClipboardJS from 'clipboard';
import { RefObject, useEffect } from 'react';

export const useClipboard = (
  targetRef: RefObject<HTMLElement>,
  value: string,
  onSuccess: (event: ClipboardJS.Event) => void
) => {
  useEffect(() => {
    if (targetRef.current !== null) {
      const clipboard = new ClipboardJS(targetRef.current, {
        text: () => value,
      });

      clipboard.on('success', (event) => {
        event.clearSelection();
        onSuccess(event);
      });

      clipboard.on('error', (event) => {
        event.clearSelection();
      });

      return () => clipboard.destroy();
    }
  }, [targetRef, value, onSuccess]);
};
