import { observer } from 'mobx-react-lite';

import { PageHeader } from 'common/components/page-header';

import { BlockedUrlsTable } from './form';

export const BlockedUrls = observer(() => (
  <>
    <PageHeader title="Blocked Urls" />
    <BlockedUrlsTable />
  </>
));
