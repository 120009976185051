import { action, makeObservable } from 'mobx';

import { App } from 'b2b/apps/api';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { BaseStore } from 'common/stores';

export interface AppInfoPayload {
  name: string;
  description: string;
  businessId: string;
  trackingId: string;
  isTest: boolean;
  isTrial: boolean;
}

export class AppInfoFormFieldsStore extends BaseStore {
  readonly form = new FormModel({
    name: new FieldModel('').validators(validateRequired),
    description: new FieldModel(''),
    businessId: new FieldModel(''),
    trackingId: new FieldModel(''),
    isTest: new FieldModel(false),
    isTrial: new FieldModel(false),
  });

  @action fill = (app: App) => {
    this.form.reset({
      name: app.name,
      description: app.description ?? '',
      businessId: app.businessId ?? '',
      trackingId: app.trackingId ?? '',
      isTest: app.isTest,
      isTrial: app.isTrial,
    });
  };

  @action storeReset = () => {
    this.form.reset();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
