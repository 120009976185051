import { Intent, Tag } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';

import { BusinessActivationStatusType } from './api';

const clsStatus = css`
  flex-shrink: 0;
  vertical-align: middle;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  margin-left: 15px;
  &:first-child {
    margin-left: 0;
  }
`;

export const statusLabels: Record<BusinessActivationStatusType, string> = {
  [BusinessActivationStatusType.ACTIVATION_STATUS_ACCEPTED]: 'Active',
  [BusinessActivationStatusType.ACTIVATION_STATUS_REQUESTED]: 'Requested',
  [BusinessActivationStatusType.ACTIVATION_STATUS_NOT_REQUESTED]: 'Inactive',
};

const statusProps: Record<BusinessActivationStatusType, { standaloneLabel?: string; intent: Intent }> = {
  [BusinessActivationStatusType.ACTIVATION_STATUS_NOT_REQUESTED]: {
    intent: Intent.NONE,
  },
  [BusinessActivationStatusType.ACTIVATION_STATUS_REQUESTED]: {
    standaloneLabel: 'Activation Requested',
    intent: Intent.WARNING,
  },
  [BusinessActivationStatusType.ACTIVATION_STATUS_ACCEPTED]: {
    intent: Intent.SUCCESS,
  },
};

interface Props {
  status?: BusinessActivationStatusType;
  defaultStatus?: BusinessActivationStatusType;
  standalone?: boolean;
  className?: string;
}

export const BusinessActivationStatus = observer(
  ({
    defaultStatus = BusinessActivationStatusType.ACTIVATION_STATUS_NOT_REQUESTED,
    status = defaultStatus,
    standalone,
    className,
  }: Props) => {
    const label = statusLabels[status];
    const { standaloneLabel, intent } = statusProps[status];

    return (
      <Tag className={cx(clsStatus, className)} intent={intent} large>
        {standalone && isDefined(standaloneLabel) ? standaloneLabel : label}
      </Tag>
    );
  }
);
