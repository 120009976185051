import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { BusinessUser } from 'b2b/businesses/details/users/api';

import { RemoveUserStore } from './store';

interface Props {
  store: RemoveUserStore;
  user: BusinessUser;
}

export const RemoveUserDialog = observer(({ store, user }: Props) => {
  const { dialog, loader } = store;

  const handleUserRemove = useCallback(async () => await store.removeUser(user), [store, user]);

  return (
    <Dialog title="Remove user" isOpen={dialog.isOpen} onClose={dialog.close}>
      {isDefined(user) ? (
        <>
          <div className={Classes.DIALOG_BODY}>
            <p>User ({user.email}) will no longer be able to access this account.</p>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={dialog.close} text="Cancel" large />
              <Button
                onClick={handleUserRemove}
                text="Remove user"
                intent={Intent.DANGER}
                loading={loader.isPending}
                large
              />
            </div>
          </div>
        </>
      ) : null}
    </Dialog>
  );
});
