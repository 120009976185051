import { Button, FormGroup, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';

import { UserRoleType } from 'b2b/users/api';
import { userRoleLabels } from 'b2b/users/common/user-role';
import { getFieldFormGroupProps } from 'common/components/form/helpers';
import { FieldModel } from 'common/helpers/form';
import { sharedPopoverProps } from 'common/popover';

const clsRoleButton = css`
  min-width: 100% !important;
`;

const FieldRole = Select.ofType<UserRoleType>();

const selectItems: UserRoleType[] = [
  UserRoleType.USER_ROLE_BASIC,
  UserRoleType.USER_ROLE_EDITOR,
  UserRoleType.USER_ROLE_ADMIN,
];

const roleItemsProps: Record<UserRoleType, { label: string }> = {
  [UserRoleType.USER_ROLE_BASIC]: { label: userRoleLabels[UserRoleType.USER_ROLE_BASIC] },
  [UserRoleType.USER_ROLE_EDITOR]: { label: userRoleLabels[UserRoleType.USER_ROLE_EDITOR] },
  [UserRoleType.USER_ROLE_ADMIN]: { label: userRoleLabels[UserRoleType.USER_ROLE_ADMIN] },
};

const renderRole: ItemRenderer<UserRoleType> = (item, { handleClick, modifiers }) =>
  modifiers.matchesPredicate ? (
    <MenuItem active={modifiers.active} key={`${item}`} onClick={handleClick} text={roleItemsProps[item].label} />
  ) : null;

interface Props {
  field: FieldModel<UserRoleType>;
  label?: string;
}

export const UserFieldRole = observer(({ field, label }: Props) => (
  <FormGroup label={label} {...getFieldFormGroupProps(field)}>
    <FieldRole
      items={selectItems}
      itemRenderer={renderRole}
      onItemSelect={field.handleValueChange}
      popoverProps={sharedPopoverProps}
      filterable={false}
    >
      <Button
        className={clsRoleButton}
        text={roleItemsProps[field.value].label}
        alignText="left"
        rightIcon="double-caret-vertical"
        fill
        large
      />
    </FieldRole>
  </FormGroup>
));
