import { action, makeObservable } from 'mobx';

import { DataLoadersMap } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { Widget, deleteWidget } from '../api';

export class WidgetDeleteStore extends BaseStore {
  readonly deleteLoaders = new DataLoadersMap();

  @action storeReset = () => {
    this.deleteLoaders.reset();
  };

  @action deleteWidget = async (widget: Widget) => {
    const deleteLoader = this.deleteLoaders.get(widget.id);
    const { success, error } = await deleteLoader.load(() => deleteWidget({ widgetId: widget.id }));

    if (success) {
      getStore('toast').success(`Deleted widget "${widget.name}"`);
      deleteLoader.ok();
    } else {
      getStore('toast').error(`Failed to delete widget "${widget.name}"`, error);
      deleteLoader.fail(error);
    }
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
