import { Button, ButtonGroup, InputGroup } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { Box } from 'common/components/box';
import { Card } from 'common/components/card';

import { PatternEntity } from './pattern-entity';
import { BlockedUrlsStore } from './store';

const clsCard = css`
  width: 100%;
  && {
    padding: 12px;
  }
`;

const clsActions = css`
  margin-left: auto;
`;

interface Props {
  store: BlockedUrlsStore;
  pattern: PatternEntity;
}

export const Row = observer<Props>(({ pattern, store }) => {
  const [isEdit, setIsEdit] = useState(false);
  const handleSave = useCallback(async () => {
    await pattern.editPattern();
    setIsEdit(false);
  }, [pattern]);

  const handleDelete = useCallback(async () => {
    await pattern.removePattern();
    store.removeFromList(pattern.id);
  }, [pattern, store]);

  return (
    <Box mb={12}>
      <Card className={clsCard}>
        <Box alignItems="center">
          {isEdit ? (
            <InputGroup value={pattern.value} large onChange={(ev) => pattern.setValue(ev.currentTarget.value)} />
          ) : (
            <code className="bp4-code-block">{pattern.value}</code>
          )}

          <ButtonGroup className={clsActions} large>
            {!isEdit ? (
              <Button
                icon="edit"
                disabled={pattern.removeLoader.isPending}
                text="Edit"
                onClick={() => setIsEdit(true)}
              />
            ) : (
              <Button
                icon="tick"
                disabled={pattern.removeLoader.isPending}
                loading={pattern.editLoader.isPending}
                text="Save"
                onClick={handleSave}
              />
            )}
            <Button
              icon="trash"
              disabled={pattern.editLoader.isPending}
              loading={pattern.removeLoader.isPending}
              onClick={handleDelete}
              text="Delete"
            />
          </ButtonGroup>
        </Box>
      </Card>
    </Box>
  );
});
