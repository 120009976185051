import { isString } from '@whisklabs/typeguards';
import { History, LocationDescriptor, Path, createBrowserHistory } from 'history';

import { AppLocationState } from './config';

const withPrevLocation = (history: History<AppLocationState>): History<AppLocationState> => {
  const methodNames = ['push', 'replace'] as const;

  for (const name of methodNames) {
    const originalFn = history[name];
    history[name] = (pathOrLocation: Path | LocationDescriptor<AppLocationState>, maybeState?: AppLocationState) => {
      const prevLocation = history.location;

      if (isString(pathOrLocation)) {
        return originalFn(pathOrLocation, { prevLocation, ...maybeState });
      }

      return originalFn({
        ...pathOrLocation,
        state: {
          ...pathOrLocation.state,
          prevLocation,
        },
      });
    };
  }

  return history;
};

export const createHistory = (): History<AppLocationState> => {
  const history = createBrowserHistory<AppLocationState>();

  return withPrevLocation(history);
};
