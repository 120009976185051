import { Callout, Intent } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { clsLink } from 'common/styles/link';
import { clsMb20 } from 'common/styles/margin-padding';

interface Props {
  title: string;
  linkText: string;
  onClick?: () => void;
  href?: string;
  className?: string;
}

export const SuccessPlaceholder = memo(({ title, linkText, onClick, href, className }: Props) => {
  let linkElement = null;
  if (isDefined(href)) {
    linkElement = (
      <Link to={href} className={clsLink}>
        {linkText}
      </Link>
    );
  } else if (isDefined(onClick)) {
    linkElement = (
      <a onClick={onClick} className={clsLink}>
        {linkText}
      </a>
    );
  }

  return (
    <div className={className}>
      <Callout intent={Intent.SUCCESS} title={title} className={clsMb20} />
      <div>{linkElement}</div>
    </div>
  );
});
