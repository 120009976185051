import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable } from 'mobx';

import { updateApp } from 'b2b/apps/api';
import { AppInfoPayload } from 'b2b/apps/common/info-form';
import { AppDetailsStore } from 'b2b/apps/details/store';
import { DataLoader } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

export class AppInfoStore extends BaseStore {
  readonly saveLoader = new DataLoader();

  constructor(private readonly appDetailsStore: AppDetailsStore) {
    super();
    makeObservable(this);
  }

  @action storeReset = () => {
    this.saveLoader.reset();
  };

  @action save = async (appInfo: AppInfoPayload) => {
    const { cancelled, data, error } = await this.saveLoader.load(() =>
      updateApp(this.appDetailsStore.app.appId, appInfo)
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.app)) {
      this.appDetailsStore.setApp(data.app);
      getStore('toast').success('Updated app information');
      this.saveLoader.ok();
    } else {
      this.saveLoader.fail(error);
    }
  };
}
