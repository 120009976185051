import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { textOrFallback } from 'common/helpers/data';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { Country, getCountries } from './api';

export class CountriesStore extends BaseStore {
  private readonly countryById = observable.map<string, Country>([], { deep: false });

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.loader.reset();
    this.countryById.clear();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get countries(): Country[] {
    return Array.from(this.countryById.values(), (country) => ({
      ...country,
      name: textOrFallback(country.name, 'Country ""'),
    }));
  }

  @action loadCountries = async () => {
    const { cancelled, data, error } = await this.loader.load(() => getCountries());

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.countryById.replace(data.countries.map((country) => [country.code, country]));
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
