import { isPresent } from '@whisklabs/typeguards';
import { History } from 'history';
import { css, cx } from 'linaria';

import { ProtectedRouteProps, RouteGuardProp, RouteSwitch } from 'common/components/routes';
import { AppLocationState } from 'common/stores/router';
import { clsPageContainer } from 'common/styles/layout';

import { Header } from './header';

const clsAdmin = css`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const clsContent = css`
  flex: 1 1 auto;
  display: flex;
`;

interface AdminItem extends ProtectedRouteProps {
  label: string;
}

interface Props {
  defaultGuard?: RouteGuardProp;
  defaultPath?: History.LocationDescriptor<AppLocationState>;
  className?: string;
  items: (AdminItem | null | undefined)[];
}

export const AdminLayout = ({ items: itemsProp, defaultGuard, defaultPath, className }: Props) => {
  const items = itemsProp.filter(isPresent);

  return (
    <div className={cx(clsAdmin, className)}>
      <Header navItems={items.map((item) => ({ to: item.path, label: item.label }))} />
      <div className={cx(clsContent, clsPageContainer)}>
        <RouteSwitch routes={items} defaultGuard={defaultGuard} defaultPath={defaultPath} />
      </div>
    </div>
  );
};
