import { ReportReason } from 'common/types/types';

const reportReasonMap: Record<ReportReason, string> = {
  [ReportReason.REPORT_REASON_IP_INFRINGEMENT]: 'IP infringement',
  [ReportReason.REPORT_REASON_NSFW]: 'NSFW',
  [ReportReason.REPORT_REASON_SPAM]: 'Spam',
};

export const displayReportReason = (reportReason: ReportReason | undefined) =>
  reportReasonMap[reportReason ?? ReportReason.REPORT_REASON_SPAM];
