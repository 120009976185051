import { Button, Intent } from '@blueprintjs/core';
import { css } from 'linaria';
import React, { useCallback } from 'react';

import { UserType } from './types';

interface Props {
  user: UserType;
  onReject: (image: UserType) => void;
}

const clsButton = css`
  text-align: center;

  & + & {
    margin-left: 30px;
  }
`;

export const Actions: React.FC<Props> = ({ user, onReject }) => {
  const handleReject = useCallback(() => {
    onReject(user);
  }, [user, onReject]);

  return (
    <div>
      <Button className={clsButton} rightIcon="cross" onClick={handleReject} large intent={Intent.DANGER} />
    </div>
  );
};
