import { observer } from 'mobx-react-lite';

import { Box } from 'common/components/box';
import { Search } from 'common/components/search';

import { CreateForm } from './create-form';
import { DeleteForm } from './delete-form';
import { ClaimedDomaisStore } from './store';
import { DomainsTable } from './table';

interface Props {
  store: ClaimedDomaisStore;
}

export const Content = observer(({ store }: Props) => {
  return (
    <>
      <div>
        <CreateForm store={store} />
        <DeleteForm store={store} />
        <Box alignItems="flex-start" mb={20}>
          <Box flexGrow={1}>
            <Search search={store.searchText} placeholder="Filter by any field" onChange={store.search} />
          </Box>
        </Box>
      </div>
      <DomainsTable store={store} />
    </>
  );
});
