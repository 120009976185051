import { action, makeObservable } from 'mobx';

import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { parseUrlId } from 'common/helpers/url';
import { BaseStore, getStore } from 'common/stores';

import { AddRuleRequest, addRule as addRuleApi } from '../../../api';
import { ControlStore } from '../../settings/store';

export class FieldFormStore extends BaseStore {
  readonly loader = new DataLoader();

  readonly form = new FormModel({
    data: new FieldModel(''),
  });

  constructor(private readonly controlStore: ControlStore) {
    super();
    makeObservable(this);
  }

  @action
  storeReset = () => {
    this.loader.reset();
    this.form.reset();
  };

  @action
  submit = ({ data }: { data: string }) => {
    void this.addRule({
      urlOrRecordId: parseUrlId(data),
      ruleType: this.controlStore.ruleType,
      recordType: this.controlStore.recordType,
    });
  };

  @action
  addRule = async (payload: AddRuleRequest) => {
    const { success, error } = await this.loader.load(() => addRuleApi(payload));

    if (success) {
      getStore('toast').success('Record Added');
      this.form.reset();
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
