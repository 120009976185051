import {
  whisk_backoffice_publishers_v1_PublishersAPI_GetPublishers as GetPublishers,
  whisk_backoffice_publishers_v1_GetPublishersRequest as GetPublishersRequest,
  whisk_backoffice_publishers_v1_Publisher as Publisher,
} from '@whisklabs/b2b-backoffice-protobuf';
import { Default } from '@whisklabs/grpc';

import { b2bGrpc } from 'b2b/grpc';

export { Publisher, GetPublishersRequest };

export const stubPublisher = (): Publisher => Default(Publisher);

const api = b2bGrpc();

export const getPublishers = (payload: GetPublishersRequest) => api(GetPublishers, payload);
