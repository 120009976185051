import { Icon } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { ComponentProps } from 'react';

import { PopoverMenu, PopoverMenuItem } from 'common/components/popover-menu';
import { clsInlineBlock } from 'common/styles/layout';

const clsMoreIcon = css`
  padding: 5px;
  transform: rotate(90deg);
  transition: color 200ms ease-out;
  color: #909bab;

  &:hover {
    color: #828282;
  }
`;

export const DotsMenuItem = PopoverMenuItem;

export const DotsMenu = ({ targetClassName, ...popoverMenuProps }: ComponentProps<typeof PopoverMenu>) => (
  <PopoverMenu
    fill={false}
    target={<Icon icon="more" iconSize={14} className={clsMoreIcon} />}
    targetClassName={cx(clsInlineBlock, targetClassName)}
    {...popoverMenuProps}
  />
);
