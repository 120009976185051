import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { BusinessDetailsStore } from 'b2b/businesses/details/store';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { GetWidgetsRequest, Widget, getWidgets } from '../api';

export class WidgetsTableStore extends BaseStore {
  private readonly widgetById = observable.map<string, Widget>([], { deep: false });

  constructor(private readonly businessDetailsStore: BusinessDetailsStore) {
    super();
    makeObservable(this);
  }

  @observable totalItems = 0;

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.loader.reset();
    this.widgetById.clear();
    this.totalItems = 0;
  };

  @computed get widgets(): Widget[] {
    return Array.from(this.widgetById.values());
  }

  @action loadWidgets = async (payload?: GetWidgetsRequest) => {
    const { cancelled, data, error } = await this.loader.load(() =>
      getWidgets({
        ...payload,
        query: {
          ...payload?.query,
          businessId: this.businessDetailsStore.business.id,
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.widgetById.replace(data.widgets.map((widget) => [widget.id, widget]));
      this.totalItems = data.totalCount;
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action handleWidgetDeleted = (widget: Widget) => {
    this.widgetById.delete(widget.id);
    this.totalItems = this.totalItems - 1;
  };
}
