import { MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { FieldInput } from 'common/components/fields/field-input';
import { sharedPopoverProps } from 'common/popover';
import { clsMb30 } from 'common/styles/margin-padding';

import { ActionId } from '../types';

interface Props {
  value: ActionId;
  onClick: (actionId: ActionId) => void;
}

const clsActionItem = css`
  padding: 10px;
`;

interface ActionItem {
  id: ActionId;
  text: string;
}

const ActionSelect = Select.ofType<ActionItem>();

const itemsMap: Record<ActionId, string> = {
  [ActionId.RemoveFromAllCommunities]: 'Remove from all communities',
  [ActionId.RemoveFromCommunity]: 'Remove from a specific community',
  [ActionId.Ban]: 'Ban recipe from Whisk',
};

const items = Object.entries(itemsMap).map(([id, text]) => ({ id, text })) as ActionItem[];

const renderActionItem: ItemRenderer<ActionItem> = (item, { handleClick, modifiers }) => (
  <MenuItem className={clsActionItem} active={modifiers.active} key={item.id} onClick={handleClick} text={item.text} />
);

export const ActionsSelect = observer(({ value, onClick }: Props) => {
  const handleClick = useCallback(
    (item: ActionItem) => {
      onClick(item.id);
    },
    [onClick]
  );

  return (
    <ActionSelect
      items={items}
      itemRenderer={renderActionItem}
      onItemSelect={handleClick}
      popoverProps={sharedPopoverProps}
      filterable={false}
    >
      <FieldInput className={clsMb30} readOnly value={itemsMap[value]} label="Choose action" />
    </ActionSelect>
  );
});
