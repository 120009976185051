import { memo } from 'react';

import { RouteSwitch } from 'common/components/routes';

import { CommunityIdPage } from './community-id';
import { UpdateCommunityTopicsPage } from './update-topics';

export const UpdateCommunitiesPage = memo(() => (
  <RouteSwitch
    routes={[
      { exact: true, path: '/', component: CommunityIdPage },
      { path: '/:communityId', component: UpdateCommunityTopicsPage },
    ]}
  />
));
