import { FlaggedRecipeBE } from './api';

export enum ActionId {
  FlagAllCommunities = 'flagAllCommunities',
  Ban = 'ban',
  FlagInCommunity = 'flagInCommunity',
  Approve = 'approve',
}

export interface FlaggedRecipe extends FlaggedRecipeBE {
  entityId: string;
  loading: boolean;
  index: number;
}
