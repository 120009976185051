import { isPresent } from '@whisklabs/typeguards';
import { RefObject, useEffect, useState } from 'react';
import shallowEqual from 'shallowequal';

import { TypedOmit } from 'common/types/types';

interface Options extends TypedOmit<IntersectionObserverInit, 'root'> {
  root?: RefObject<HTMLElement | null>;
}

export const useIntersection = (
  ref: RefObject<HTMLElement | null>,
  rawOptions: Options,
  callback: (entry?: IntersectionObserverEntry) => void
) => {
  const [options, setOptions] = useState(rawOptions);

  if (!shallowEqual(options, rawOptions)) {
    setOptions(rawOptions);
  }

  useEffect(() => {
    if (!isPresent(ref.current)) {
      return undefined;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        callback(entry);
      },
      {
        ...options,
        root: options.root?.current,
      }
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, options, callback]);
};
