import { Icon } from '@blueprintjs/core';
import { css } from 'linaria';
import { matchSorter } from 'match-sorter';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { recipePath } from 'b2c/helpers/routes';
import { Table } from 'common/components/client-pagination-table';
import { NonEmptyString } from 'common/components/non-empty-string';
import { Column } from 'common/components/table-view';
import { COLORS } from 'common/helpers/colors';
import { createSearchFunction } from 'common/helpers/search';
import { boolCompareAsc, stringCompareAsc } from 'common/helpers/sort';
import { clsLink } from 'common/styles/link';

import { Actions } from './actions';
import { RecipesWithFlaggedTextQueueStore } from './store';
import { RecipeWithFlaggedText } from './types';

const clsTable = css`
  word-break: break-all;
`;

type ColumnId = 'recipeId' | 'suspiciousText' | 'actions' | 'isTrustedUser';

const searchFunction = createSearchFunction<RecipeWithFlaggedText>([
  (recipe) => recipe.id,
  { key: (recipe) => recipe.id, threshold: matchSorter.rankings.EQUAL },
]);

interface Props {
  store: RecipesWithFlaggedTextQueueStore;
}

export const FlaggedTextsTable = observer(({ store }: Props) => {
  const columns = useMemo(
    (): Column<RecipeWithFlaggedText, ColumnId>[] => [
      {
        id: 'isTrustedUser',
        title: (
          <div title="Trusted User">
            <Icon icon={'endorsed'} color={COLORS.GREEN} />
          </div>
        ),
        width: '20px',
        sorter: (a, b) => boolCompareAsc(a.isFromTrustedUser, b.isFromTrustedUser),
        render: (recipe) => (
          <div>
            {recipe.isFromTrustedUser ? (
              <Icon icon={'endorsed'} color={COLORS.GREEN} />
            ) : (
              <Icon icon={'ban-circle'} color={COLORS.RED} />
            )}
          </div>
        ),
      },
      {
        id: 'recipeId',
        title: 'Recipe Id',
        width: '344px',
        sorter: (a, b) => stringCompareAsc(a.id, b.id),
        render: (recipe) => (
          <a className={clsLink} href={recipePath(recipe.id)} rel="noreferrer" target="_blank">
            <NonEmptyString value={recipe.id} />
          </a>
        ),
      },
      {
        id: 'suspiciousText',
        title: 'Suspicious Text',
        sorter: (a, b) => stringCompareAsc(a.moderationDetailsMessage, b.moderationDetailsMessage),
        render: (recipe) => <NonEmptyString value={recipe.moderationDetailsMessage} />,
      },
      {
        id: 'actions',
        width: '140px',
        title: 'Actions',
        align: 'center',
        render: (recipe) => (
          <Actions recipe={recipe} onApprove={store.approveRecipeText} onReject={store.rejectRecipeText} />
        ),
      },
    ],
    [store.approveRecipeText, store.rejectRecipeText]
  );

  return (
    <Table
      loader={store.loader}
      tableClassName={clsTable}
      cellPaddingVertical={10}
      data={store.recipes}
      withUnstableHeight
      columns={columns}
      rowKey="entityId"
      searchFunction={searchFunction}
    />
  );
});
