import { isFunction, isNumber, isObject, isString } from '@whisklabs/typeguards';

type ObjectCheck<T> = T extends Record<PropertyKey, unknown> ? T : Record<PropertyKey, unknown>;

export function isObjectLike<T>(source: T): source is T & ObjectCheck<NonNullable<T>> {
  return isObject(source) || isFunction(source);
}

export function isObjectKey(source: unknown): source is PropertyKey {
  return isString(source) || isNumber(source) || typeof source === 'symbol';
}

export function hasOwnKey<T, K extends PropertyKey>(obj: T, key: K): obj is NonNullable<T> & Record<K, unknown> {
  return isObjectKey(key) && isObjectLike(obj) && Object.prototype.hasOwnProperty.call(obj, key);
}
