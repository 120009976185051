import { IPopoverProps } from '@blueprintjs/core';

import { contentMinWidthModifier } from 'common/popover/modifiers';

export const sharedPopoverProps: IPopoverProps = {
  minimal: true,
  fill: true,
  boundary: 'viewport',
  position: 'auto-start',
  modifiers: {
    offset: {
      offset: '0, 4px',
    },
    contentWidth: contentMinWidthModifier(),
  },
};
