import { action, makeObservable } from 'mobx';

import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { BaseStore, getStore } from 'common/stores';

import { mergeUsers } from './api';

export class MergeUsersFormStore extends BaseStore {
  readonly loader = new DataLoader();

  readonly form = new FormModel({
    fromUserId: new FieldModel('').validators(validateRequired),
    toUserId: new FieldModel('').validators(validateRequired),
  });

  constructor() {
    super();

    makeObservable(this);
  }

  @action storeReset = () => {
    this.loader.reset();
  };

  @action mergeUsers = async () => {
    const { success, error, data } = await this.loader.load(() =>
      mergeUsers({ toUserId: this.form.fields.toUserId.value, fromUserId: this.form.fields.fromUserId.value })
    );

    if (data?.result?.oneof !== 'ok' && success) {
      getStore('toast').warning('Please check the warning before merging');
      this.loader.warn(data?.result);
      return;
    }

    if (success) {
      getStore('toast').success('Users have been merged');
      this.form.reset();
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
