import { Classes, Elevation } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { ReactNode } from 'react';

const clsCard = css`
  &.${Classes.CARD} {
    padding: 24px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const clsHeader = css`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

interface Props {
  children: ReactNode;
  header?: ReactNode;
  elevation?: Elevation;
  interactive?: boolean;
  className?: string;
}

export const Card = ({ children, header, elevation = Elevation.ZERO, interactive = false, className }: Props) => (
  <div
    className={cx(
      clsCard,
      Classes.CARD,
      Classes.elevationClass(elevation),
      interactive && Classes.INTERACTIVE,
      className
    )}
  >
    {isDefined(header) ? <div className={clsHeader}>{header}</div> : null}
    <div>{children}</div>
  </div>
);
