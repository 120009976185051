import { FormGroup } from '@blueprintjs/core';
import { ItemListRenderer } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';
import { ReactNode, useCallback, useMemo } from 'react';

import { getFieldControlProps, getFieldFormGroupProps } from 'common/components/form/helpers';
import { Multiselect, MultiselectItem } from 'common/components/form/multi-select';
import { InfiniteList } from 'common/components/infinite-list';
import { FieldModel } from 'common/helpers/form';
import { getItemId } from 'common/helpers/selectors';
import { useStore } from 'common/hooks/use-store';

import { Brand } from './api';
import { BrandsStore } from './store';

const brandToItem = (brand: Brand): MultiselectItem<Brand> => ({
  value: brand,
  label: brand.canonicalName,
  comment: brand.id,
});

interface Props {
  field: FieldModel<Brand[]>;
  label?: ReactNode;
}

export const FieldBrands = observer(({ field, label }: Props) => {
  const store = useStore(() => new BrandsStore());
  const { brands } = store;

  const items: MultiselectItem<Brand>[] = useMemo(() => brands.map(brandToItem), [brands]);
  const selectedItems: MultiselectItem<Brand>[] = useMemo(() => field.value.map(brandToItem), [field.value]);

  const renderItemList: ItemListRenderer<MultiselectItem<Brand>> = useCallback(
    (itemListRendererProps) => <InfiniteList itemListRendererProps={itemListRendererProps} store={store} />,
    [store]
  );

  return (
    <FormGroup {...getFieldFormGroupProps(field)} label={label}>
      <Multiselect
        {...getFieldControlProps(field)}
        items={items}
        value={selectedItems}
        getValueKey={getItemId}
        itemListRenderer={renderItemList}
        placeholder="Search brands"
        large
        minimal
      />
    </FormGroup>
  );
});
