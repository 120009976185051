import { MenuItem } from '@blueprintjs/core';
import { ItemListRenderer, ItemRenderer } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { getFieldControlProps } from 'common/components/form/helpers';
import { Suggest } from 'common/components/form/suggest';
import { InfiniteList } from 'common/components/infinite-list';
import { FieldModel } from 'common/helpers/form';
import { useStore } from 'common/hooks/use-store';

import { Publisher } from './api';
import { PublishersStore } from './store';

const renderValue = (item: Publisher) => item.name;

const renderItem: ItemRenderer<Publisher> = (item, { handleClick, modifiers }) => (
  <MenuItem active={modifiers.active} key={item.id} onClick={handleClick} text={item.name} label={item.domain} />
);

interface Props {
  field: FieldModel<Publisher>;
}

export const FieldPublisher = observer(({ field }: Props) => {
  const store = useStore(() => new PublishersStore());
  const [searchQuery, setSearchQuery] = useState('');

  const renderItemList: ItemListRenderer<Publisher> = useCallback(
    (itemListRendererProps) => <InfiniteList itemListRendererProps={itemListRendererProps} store={store} />,
    [store]
  );

  const { value, onChange, ...fieldProps } = getFieldControlProps(field);

  return (
    <Suggest
      {...fieldProps}
      items={store.publishers}
      selectedItem={value}
      onItemSelect={onChange}
      placeholder="Search publishers"
      inputValueRenderer={renderValue}
      itemRenderer={renderItem}
      itemListRenderer={renderItemList}
      query={searchQuery}
      onQueryChange={setSearchQuery}
    />
  );
});
