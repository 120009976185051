import { GOutput } from '@whisklabs/grpc';
import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable } from 'mobx';

import { Business } from 'b2b/businesses/api';
import { BusinessActivationStatusType } from 'b2b/businesses/common/activation-status/api';
import { BusinessDetailsStore } from 'b2b/businesses/details/store';
import { DataLoadersMap, LoadStrategy } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { activateApi, activateShoppableMedia, activateStudio } from './api';

export type BusinessProduct = {
  id: string;
  name: string;
  activationStatus: BusinessActivationStatusType | undefined;
  apiMethod: ({ id }: { id: string }) => Promise<GOutput<{ value?: Business }>>;
};

export class BusinessProductsActivationStore extends BaseStore {
  readonly activateLoaders = new DataLoadersMap({
    strategy: LoadStrategy.takeFirst,
  });

  constructor(private readonly businessDetailsStore: BusinessDetailsStore) {
    super();
    makeObservable(this);
  }

  @computed get products(): BusinessProduct[] {
    const { business } = this.businessDetailsStore;

    return [
      { id: 'api', name: 'API', activationStatus: business.apiStatus, apiMethod: activateApi },
      { id: 'studio', name: 'Studio', activationStatus: business.studioStatus, apiMethod: activateStudio },
      {
        id: 'shoppableMedia',
        name: 'Shoppable Media',
        activationStatus: business.shoppableMediaStatus,
        apiMethod: activateShoppableMedia,
      },
    ];
  }

  @action storeReset = () => {
    this.activateLoaders.reset();
  };

  @computed get allActivated(): boolean {
    return this.products.every((product) => this.isActivated(product));
  }

  isActivated = (product: BusinessProduct): boolean =>
    this.businessDetailsStore.isStatusActive(product.activationStatus);

  @action activate = async (product: BusinessProduct) => {
    const activateLoader = this.activateLoaders.get(product.id);
    const { cancelled, data, error } = await activateLoader.load(() =>
      product.apiMethod({ id: this.businessDetailsStore.business.id })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.value)) {
      this.businessDetailsStore.setBusiness(data.value);
      getStore('toast').success(`Activated ${product.name}`);
      activateLoader.ok();
    } else {
      getStore('toast').error(`Failed to activate ${product.name}`, error);
      activateLoader.fail(error);
    }
  };
}
