import { action, computed, makeObservable } from 'mobx';

import { BusinessUserRoleType } from 'b2b/businesses/details/common/user-role/api';
import { BusinessUser, changeUserRole } from 'b2b/businesses/details/users/api';
import { DataLoader } from 'common/helpers/data-loader';
import { Dialog } from 'common/helpers/dialog';
import { BaseStore, getStore } from 'common/stores';

import { BusinessUsersTableStore } from '../store';

export class ChangeUserRoleStore extends BaseStore {
  readonly dialog = new Dialog();
  readonly loader = new DataLoader();

  constructor(private readonly businessUsersTableStore: BusinessUsersTableStore) {
    super();
    makeObservable(this);
  }

  @action storeReset() {
    this.loader.reset();
    this.dialog.reset();
  }

  @computed get availableRoles(): BusinessUserRoleType[] {
    return [
      // FIXME: https://samsungnext.atlassian.net/browse/WBI-36
      // FIXME: https://samsungnext.atlassian.net/browse/WBI-37
      // BusinessUserRoleType.ROLE_OWNER,
      BusinessUserRoleType.ROLE_ADMIN,
      BusinessUserRoleType.ROLE_EDITOR,
      BusinessUserRoleType.ROLE_VIEW_ONLY,
      BusinessUserRoleType.ROLE_CREATOR,
    ];
  }

  @action changeUserRole = async (user: BusinessUser, role: BusinessUserRoleType) => {
    const { success, error } = await this.loader.load(() =>
      changeUserRole({
        id: this.businessUsersTableStore.business.id,
        userId: user.id,
        role,
      })
    );

    if (success) {
      this.businessUsersTableStore.handleUserRoleChanged(user, role);
      getStore('toast').success(`The role of user (${user.email}) has been updated`);
      this.storeReset();
    } else {
      getStore('toast').error(`Failed to update role of user (${user.email})`, error);
      this.loader.fail(error);
    }
  };
}
