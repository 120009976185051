import { isText } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

const clsImgHidden = css``;

const clsImg = css`
  display: inline-block;
  vertical-align: middle;
  width: ${4 / 3}em;
  height: 1em;
  &.${clsImgHidden} {
    /* keep image placeholder to avoid text shifting */
    visibility: hidden;
  }
`;

interface Props {
  code: string; // ISO 3166-1 alpha-2 country code, lowercase or uppercase
}

export const CountryFlagImage = memo(({ code }: Props) => {
  const [hasError, setHasError] = useState(false);

  const href = useMemo(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const imageModule = require(`./country-flags/${code.toLowerCase()}.png`) as { default: string };
      return imageModule.default;
    } catch {
      /**/
    }
  }, [code]);

  useEffect(() => {
    setHasError(false);
  }, [code]);

  const handleError = useCallback(() => {
    setHasError(true);
  }, []);

  return (
    <img
      src={href}
      aria-hidden
      alt={code}
      className={cx(clsImg, hasError || !isText(href) ? clsImgHidden : undefined)}
      onError={handleError}
    />
  );
});
