type Cb = () => void;

export abstract class BaseStore {
  private readonly cbs = new Set<Cb>();
  private readonly stores = new Set<BaseStore>();

  storeInit() {
    /* */
  }

  storeReset() {
    /* */
  }

  storeDestroy() {
    this.unsubAll();
    this.storeReset();
    this.destroyStores();
  }

  protected sub = (cb: Cb) => {
    this.cbs.add(cb);
  };

  protected unsubAll = () => {
    this.cbs.forEach((cb) => cb());
    this.cbs.clear();
  };

  protected addStore = <T extends BaseStore>(storeFactory: () => T): T => {
    const instance = storeFactory();
    instance.storeInit();
    this.stores.add(instance);
    return instance;
  };

  protected destroyStores = () => {
    this.stores.forEach((store) => store.storeDestroy());
  };
}
