import { isDefined } from '@whisklabs/typeguards';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { Table } from 'common/components/client-pagination-table';
import { NonEmptyString } from 'common/components/non-empty-string';
import { Column, Sort } from 'common/components/table-view';
import { DATE_FORMAT_TEXT_SHORT } from 'common/config';
import { formatDate } from 'common/helpers/date';
import { fromTimestampToMillis } from 'common/helpers/grpc';
import { join } from 'common/helpers/router';
import { createSearchFunction, searchRankings } from 'common/helpers/search';
import { googleTimestampCompareAsc, orderCompareAsc, stringCompareAsc } from 'common/helpers/sort';
import { clsLink } from 'common/styles/link';

import { BusinessActivationStatus, BusinessActivationStatusType } from '../common/activation-status';

import { BusinessAccountPreview } from './api';
import { BusinessesTableStore } from './store';

type ColumnId = 'name' | 'activationStatus' | 'tcAcceptedOn';

const createColumns = (url: string): Column<BusinessAccountPreview, ColumnId>[] => [
  {
    id: 'name',
    title: 'Name',
    width: '100%',
    sorter: (a, b) => stringCompareAsc(a.name ?? '', b.name ?? ''),
    render: (business) => (
      <Link className={clsLink} to={join(url, `/${business.id}`)}>
        <NonEmptyString value={business.name} />
      </Link>
    ),
  },
  {
    id: 'activationStatus',
    title: 'Activation status',
    width: '250px',
    sorter: (a, b) =>
      orderCompareAsc([
        BusinessActivationStatusType.ACTIVATION_STATUS_REQUESTED,
        BusinessActivationStatusType.ACTIVATION_STATUS_ACCEPTED,
        BusinessActivationStatusType.ACTIVATION_STATUS_NOT_REQUESTED,
      ])(
        a.activationStatus ?? BusinessActivationStatusType.ACTIVATION_STATUS_NOT_REQUESTED,
        b.activationStatus ?? BusinessActivationStatusType.ACTIVATION_STATUS_NOT_REQUESTED
      ),
    render: (business) => <BusinessActivationStatus status={business.activationStatus} />,
  },
  {
    id: 'tcAcceptedOn',
    title: 'T&C accepted',
    sorter: (a, b) => googleTimestampCompareAsc(a.termsAndConditionsTime, b.termsAndConditionsTime),
    render: (business) =>
      isDefined(business.termsAndConditionsTime)
        ? formatDate(fromTimestampToMillis(business.termsAndConditionsTime), DATE_FORMAT_TEXT_SHORT)
        : null,
  },
];

const searchFunction = createSearchFunction<BusinessAccountPreview>([
  (business) => business.name ?? '',
  { key: (business) => business.id, threshold: searchRankings.EQUAL },
]);

const DEFAULT_SORT: Sort<ColumnId> = { sortKey: 'name', sortOrder: 'asc' };

interface Props {
  store: BusinessesTableStore;
}

export const BusinessesTable = observer(({ store }: Props) => {
  const { url } = useRouteMatch();
  const columns = useMemo(() => createColumns(url), [url]);

  useEffect(() => {
    void store.loadBusinesses();
  }, [store]);

  return (
    <Table
      data={store.businesses}
      columns={columns}
      rowKey="id"
      searchFunction={searchFunction}
      defaultSort={DEFAULT_SORT}
      loader={store.loader}
    />
  );
});
