import { observer } from 'mobx-react-lite';

import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';

import { ActionsSelect } from './actions';
import { BanRecipeFormStore } from './store';
import { ActionId } from './types';

export const BanRecipeForm = observer(() => {
  const { form, banLoader, sumbitForm, chooseAction } = useStore(() => new BanRecipeFormStore());

  return (
    <Card>
      <Form form={form} loader={banLoader} onSubmit={sumbitForm} errorTitle="Failed to ban recipe">
        <FieldInput
          field={form.fields.recipeId}
          label="Recipe ID"
          className={clsMb30}
          placeholder="e.g. 101cb436a7b9b65b20b62b08ee190eb63bd1835f9fb"
        />
        <ActionsSelect onClick={chooseAction} value={form.values.actionId} />
        {form.values.actionId === ActionId.RemoveFromCommunity ? (
          <FieldInput
            className={clsMb30}
            field={form.fields.communityId}
            label="Community ID"
            placeholder="e.g. a5e1d32f48294b829c517b5a1c1eb391"
          />
        ) : null}
        <ButtonSubmit text="Ban" large />
      </Form>
    </Card>
  );
});
