import { observer } from 'mobx-react-lite';

import { useBusinessDetailsStoreContext } from 'b2b/businesses/details/context';
import { BusinessUsersTableStore } from 'b2b/businesses/details/users/table';
import { useStore } from 'common/hooks/use-store';

import { BusinessInvitationsTableStore } from './store';
import { BusinessInvitationsTable } from './table';

export const BusinessInvitationsTablePage = observer(() => {
  const businessDetailsStore = useBusinessDetailsStoreContext();
  const store = useStore(() => new BusinessInvitationsTableStore(businessDetailsStore));
  const businessUsersTableStore = useStore(() => new BusinessUsersTableStore(businessDetailsStore));

  return <BusinessInvitationsTable store={store} businessUsersTableStore={businessUsersTableStore} />;
});
