import {
  whisk_backoffice_business_v1_BusinessUser as BusinessUser,
  whisk_backoffice_business_v1_BusinessUsersAPI_ChangeUserRole as ChangeUserRole,
  whisk_backoffice_business_v1_ChangeUserRoleRequest as ChangeUserRoleRequest,
  whisk_backoffice_business_v1_BusinessUsersAPI_GetUsers as GetUsers,
  whisk_backoffice_business_v1_GetUsersRequest as GetUsersRequest,
  whisk_backoffice_business_v1_BusinessUsersAPI_InviteUser as InviteUser,
  whisk_backoffice_business_v1_InviteUserRequest as InviteUserRequest,
  whisk_backoffice_business_v1_BusinessUsersAPI_RemoveUser as RemoveUser,
  whisk_backoffice_business_v1_RemoveUserRequest as RemoveUserRequest,
  whisk_backoffice_business_v1_BusinessUsersAPI_RequestResetUserPassword as RequestResetUserPassword,
  whisk_backoffice_business_v1_RequestResetUserPasswordRequest as RequestResetUserPasswordRequest,
} from '@whisklabs/b2b-backoffice-protobuf';
import { Default } from '@whisklabs/grpc';

import { b2bGrpc } from 'b2b/grpc';

export const stubBusinessUser = (): BusinessUser => Default(BusinessUser);

export { BusinessUser, RemoveUserRequest, ChangeUserRoleRequest, RequestResetUserPasswordRequest, InviteUserRequest };

const api = b2bGrpc();

export const getUsers = (payload: GetUsersRequest) => api(GetUsers, payload);

export const removeUser = (payload: RemoveUserRequest) => api(RemoveUser, payload);

export const changeUserRole = (payload: ChangeUserRoleRequest) => api(ChangeUserRole, payload);

export const requestResetUserPassword = (payload: RequestResetUserPasswordRequest) =>
  api(RequestResetUserPassword, payload);

export const inviteUser = (payload: InviteUserRequest) => api(InviteUser, payload);
