import { observer } from 'mobx-react-lite';

import { DashboardLayout } from 'common/components/layouts/dashboard';
import { getStore } from 'common/stores';

import { AppsPage } from './apps';
import { BusinessesPage } from './businesses';
import { CampaignsPage } from './campaigns';
import { UsersPage } from './users';

export const B2BPage = observer(() => {
  const userStore = getStore('user');

  return (
    <DashboardLayout
      items={[
        { path: '/businesses', icon: 'briefcase', label: 'Businesses', component: BusinessesPage },
        { path: '/api-keys', icon: 'applications', label: 'API Keys', component: AppsPage },
        { path: '/campaigns', icon: 'link', label: 'Campaigns', component: CampaignsPage },
        userStore.isAdmin
          ? { path: '/users', icon: 'user', label: 'Backoffice Users', component: UsersPage }
          : undefined,
      ]}
    />
  );
});
