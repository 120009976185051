import { observer } from 'mobx-react-lite';

import { Card } from 'common/components/card';

import { FieldForm } from './field/form';
import { FileForm } from './file/form';

export const AddRecordForm = observer(() => {
  return (
    <Card>
      <FieldForm />
      <FileForm />
    </Card>
  );
});
