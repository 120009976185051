import { isDefined, isText } from '@whisklabs/typeguards';
import { uniqBy } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';

import { hasSearch } from 'b2c/helpers/has-search';
import { InfiniteListStoreType } from 'common/components/infinite-list';
import { DataLoader } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';
import { Sort } from 'common/types/types';

import { createPattern, loadPatterns } from '../api';

import { PatternEntity } from './pattern-entity';

interface SortFn extends Sort {
  fn?: (i: PatternEntity) => string | number | undefined;
}

const DEFAULT_SORT: SortFn = {
  sortTitle: 'Date',
  sortKey: 'date',
  sortOrder: 'asc',
  fn: (r) => r.value,
};

export class BlockedUrlsStore extends BaseStore implements InfiniteListStoreType {
  constructor() {
    super();

    makeObservable(this);
  }

  @observable sort: SortFn = DEFAULT_SORT;
  @observable private readonly limit = 100;
  @observable newPatternValue = '';
  createLoader = new DataLoader();
  loader = new DataLoader();
  @observable private blockedUrls: PatternEntity[] = [];

  @observable hasMoreData = true;

  @observable after = '';

  @observable searchText = '';

  @action storeReset = () => {
    this.blockedUrls = [];
    this.createLoader.reset();
    this.after = '';
    this.newPatternValue = '';
  };

  @computed get patternsArray(): PatternEntity[] {
    return this.blockedUrls;
  }

  @computed get patternsFiltered(): PatternEntity[] {
    return this.patternsArray.filter((item) => hasSearch(this.searchText, item));
  }

  @computed get total(): number {
    return this.patternsFiltered.length;
  }

  @action search = (query: string) => {
    this.searchText = query;
  };

  @action setSort = (sort?: Sort): void => {
    this.sort = sort ?? DEFAULT_SORT;
  };

  loadInitialData = () => void this.loaadBlockedUrls();

  loadMoreData = () => void this.loaadBlockedUrls();

  @action loaadBlockedUrls = async () => {
    const { cancelled, data, error } = await this.loader.load(() =>
      loadPatterns({
        limit: this.limit,
        cursors: {
          after: this.after,
          before: '',
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      const rules = data.rules.map(
        (item) =>
          new PatternEntity({
            id: item.id,
            pattern: item.rule,
          })
      );

      const after = data.paging?.cursors?.after;

      this.blockedUrls = uniqBy(this.blockedUrls.concat(rules), 'id');
      this.after = after ?? '';
      this.hasMoreData = data.rules.length > 0 && isText(after);
      this.loader.ok();
    } else {
      this.loader.fail(error);
      getStore('toast').error('Smth went wrong');
    }
  };

  @action createPattern = async () => {
    const { cancelled, data, error } = await this.createLoader.load(() =>
      createPattern({
        rule: this.newPatternValue,
      })
    );

    const rule = data?.rule;

    if (cancelled) {
      return;
    }

    if (isDefined(rule)) {
      this.newPatternValue = '';
      getStore('toast').success('Rule has been created');
      const pattern = new PatternEntity({
        id: rule.id,
        pattern: rule.rule,
      });

      this.blockedUrls.unshift(pattern);
      this.createLoader.ok();
    } else {
      this.createLoader.fail(error);
      getStore('toast').error('Smth went wrong');
    }
  };

  @action removeFromList = (id: string) => {
    this.blockedUrls = this.blockedUrls.filter((item) => item.id !== id);
  };

  @action updateNewPatternValue = (value: string) => {
    this.newPatternValue = value;
  };
}
