import { type CookieAttributes } from 'js-cookie';

import { parseQuery } from 'common/helpers/query';
import { hasOwnKey } from 'common/helpers/typeguards';

import { envCreate, initEnv } from './util';

export const AppEnv = initEnv();
export const env = envCreate(AppEnv);

const urlEnv = (def: string) => (AppEnv.env === 'base' || AppEnv.env === 'local' ? def : `.${AppEnv.env}`);

export const IS_LOCAL = AppEnv.env === 'local';

export const IS_PROD = !AppEnv.dev;

export const APP_VERSION = process.env.APP_VERSION;

export const SITE_TITLE = process.env.SITE_TITLE as string;

export const SENTRY_DSN = 'https://953a144c906a490b80dc705994819cc8@o21533.ingest.sentry.io/5257716';

export const DEVTOOLS = true as boolean;

export const DATE_FORMAT_TEXT_SHORT = 'MMM D, YYYY';

export const DATE_FORMAT_TIME = 'HH:MM:ss';

export const DATE_FORMAT_NUMERIC = 'DD/MM/YYYY';

const query = parseQuery(window.location.search);

export const FORCE_LOG = hasOwnKey(query, 'log') || hasOwnKey(query, 'whisk-log');

export const FORCE_LOG_GRPC = FORCE_LOG || hasOwnKey(query, 'log-grpc') || hasOwnKey(query, 'whisk-log-grpc');

export const API_B2B = env({
  base: `https://b2b-be-backoffice-grpc-web${urlEnv('-dev')}.whisk-dev.com`,
  production: 'https://b2b-be-backoffice-grpc-web.whisk.com',
});

export const API_B2C = env({
  base: `https://backoffice-grpc-web${urlEnv('')}.whisk-dev.com`,
  production: 'https://backoffice-grpc-web.whisk.com',
});

export const APP_URL_B2C = env({
  base: `https://my${urlEnv('')}.whisk-dev.com`,
  production: 'https://my.whisk.com',
});

export const OAUTH_CODE_COOKIE = 'backstage.OAUTH_CODE';

export const OAUTH_INITIAL_CALLBACK_ORIGIN =
  AppEnv.envOriginal === 'local'
    ? 'http://localhost:3200'
    : env({
        base: 'https://backstage.whisk-dev.com',
        production: 'https://backstage.whisk.com',
      });

export const OAUTH_GOOGLE_CLIENT_ID = '1034335430073-ra31qou5nhna0hh4ve5uj9knsuobjl7s.apps.googleusercontent.com';

export const TOKEN_COOKIE = 'backstage.USER_TOKEN';

export const TOKEN_COOKIE_CONFIG: CookieAttributes = {
  domain: window.location.hostname === 'localhost' ? undefined : `.${window.location.hostname}`,
  path: '/',
  secure: window.location.protocol === 'https:',
  sameSite: 'lax',
  expires: 365,
};
