import { Button, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { BusinessProduct, BusinessProductsActivationStore } from './store';

interface Props {
  product: BusinessProduct;
  store: BusinessProductsActivationStore;
}

export const ProductActivationButton = observer(({ store, product }: Props) => {
  const handleActivate = useCallback(async () => await store.activate(product), [store, product]);
  const activateLoader = store.activateLoaders.get(product.id);

  if (store.isActivated(product)) {
    return null;
  }

  return (
    <Button intent={Intent.SUCCESS} text="Activate" loading={activateLoader.isPending} onClick={handleActivate} large />
  );
});
