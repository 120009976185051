import { init } from '@sentry/browser';
import { CaptureConsole, Dedupe, ExtraErrorData } from '@sentry/integrations';

import { APP_VERSION, AppEnv, IS_PROD, SENTRY_DSN } from 'common/config';

import { ignored } from './filter';
import { hasRateLimit } from './rate';

init({
  integrations: [
    new Dedupe(),
    new ExtraErrorData(),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  beforeSend(event, hint) {
    const block = hasRateLimit(hint.originalException) || ignored(hint.originalException);

    return block ? null : event;
  },
  enabled: IS_PROD,
  environment: AppEnv.env,
  release: `b2b-fe-backoffice@${APP_VERSION ?? ''}`,
  dsn: SENTRY_DSN,
});
