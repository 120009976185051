import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { textOrFallback } from 'common/helpers/data';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { BusinessAccountPreview, getBusinesses } from './api';

export class BusinessesTableStore extends BaseStore {
  private readonly businessById = observable.map<string, BusinessAccountPreview>([], { deep: false });

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.loader.reset();
    this.businessById.clear();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get businesses(): BusinessAccountPreview[] {
    return Array.from(this.businessById.values(), (business) => ({
      ...business,
      name: textOrFallback(business.name, 'Business ""'),
    }));
  }

  @action loadBusinesses = async () => {
    const { cancelled, data, error } = await this.loader.load(() => getBusinesses());

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.businessById.replace(data.value.map((business) => [business.id, business]));
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
