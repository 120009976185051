import { DateRange } from '@blueprintjs/datetime';
import { isPresent, isText } from '@whisklabs/typeguards';
import dayjs from 'dayjs';
import { action, makeObservable } from 'mobx';

import { Campaign, CampaignBrand, CampaignPayload, CampaignProduct, CampaignPublisher } from 'b2b/campaigns/api';
import { fromUTC, toUTC } from 'common/helpers/date';
import { FieldModel, FieldValidator, FormModel } from 'common/helpers/form';
import { createRequiredValidator, validateCurrencyAmount, validateRequired } from 'common/helpers/form/validators';
import { fromMoney, toMoney } from 'common/helpers/grpc';
import { BaseStore } from 'common/stores';

const validateDateRangeRequired: FieldValidator<DateRange> = createRequiredValidator(
  (dateRange) => dateRange.every(isPresent),
  'Both start and end dates are required'
);

export class CampaignFormStore extends BaseStore {
  readonly form = new FormModel({
    name: new FieldModel('').validators(validateRequired),
    dateRange: new FieldModel<DateRange>([null, null]).validators(validateDateRangeRequired),
    brands: new FieldModel<CampaignBrand[]>([]).validators(validateRequired),
    products: new FieldModel<CampaignProduct[]>([]).validators(validateRequired),
    countries: new FieldModel<string[]>([]),
    publishers: new FieldModel<CampaignPublisher[]>([]),
    cpa: new FieldModel<string>('').validators(validateCurrencyAmount),
  });

  @action storeReset = () => {
    this.form.reset();
  };

  @action fill = (campaign: Campaign): void => {
    this.form.reset({
      name: campaign.name,
      dateRange: [fromUTC(campaign.startAt), fromUTC(campaign.endAt)],
      brands: campaign.brands,
      products: campaign.products,
      countries: campaign.countries,
      publishers: campaign.publishers,
      cpa: isPresent(campaign.cpa) ? fromMoney(campaign.cpa).amount.toString() : '',
    });
  };

  @action getData = (): CampaignPayload => {
    const { dateRange, cpa, ...restValues } = this.form.values;

    // Campaigns start and end at 2 PM
    const withTime = (date: Date) => dayjs(date).startOf('day').set('hour', 14).toDate();
    const toUTCTimeStamp = (date: Date | null): number => (isPresent(date) ? toUTC(withTime(date)).getTime() : 0);

    const cpaAmount: number = isText(cpa) ? parseFloat(cpa.replace(',', '')) : NaN;

    return {
      ...restValues,
      startAt: toUTCTimeStamp(dateRange[0]),
      endAt: toUTCTimeStamp(dateRange[1]),
      cpa: !isNaN(cpaAmount) ? toMoney('USD', cpaAmount) : undefined,
    };
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
