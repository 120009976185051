import { observer } from 'mobx-react-lite';

import { PageHeader } from 'common/components/page-header';

import { BlockedIndexingTable } from './form';

export const BlockedIndexingDomains = observer(() => (
  <>
    <PageHeader title="Blocked Indexing Domains" />
    <BlockedIndexingTable />
  </>
));
