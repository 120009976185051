import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { Box } from 'common/components/box';
import { InfiniteScroll } from 'common/components/infinite-scroll';
import { Search } from 'common/components/search';

import { AddUrlForm } from './add-url-form';
import { Row } from './row';
import { NoIndexingDomainsStore } from './store';

const clsScroller = css`
  && {
    width: 100%;
    padding: 1px;
  }
`;

const clsHeader = css`
  position: sticky;
  z-index: 10;
  padding-bottom: 10px;
  background-color: white;
  top: 0;
`;

interface Props {
  store: NoIndexingDomainsStore;
}

export const Table = observer(({ store }: Props) => {
  useEffect(() => {
    store.loadInitialData();
  }, []);

  return (
    <>
      <div className={clsHeader}>
        <AddUrlForm store={store} />
        <Box alignItems="flex-start" mb={20}>
          <Box flexGrow={1}>
            <Search search={store.searchText} placeholder="Filter by any field" onChange={store.search} />
          </Box>
        </Box>
      </div>
      <InfiniteScroll
        className={clsScroller}
        loadMore={store.loadMoreData}
        hasMore={store.hasMoreData}
        isEmpty={store.domainsFiltered.length === 0}
        emptyElement={<div>No items</div>}
        isLoading={store.loader.isPending}
        useScrollContentAsRoot={false}
      >
        {store.domainsFiltered.map((item) => (
          <Row key={item.id} domain={item} store={store} />
        ))}
      </InfiniteScroll>
    </>
  );
});
