import { memo } from 'react';

import { PageHeader } from 'common/components/page-header';

import { BlockUserForm } from './form';

export const BlockUserPage = memo(() => (
  <div>
    <PageHeader title="Block user" />
    <BlockUserForm />
  </div>
));
