import {
  whisk_backoffice_brands_v1_Brand as Brand,
  whisk_backoffice_brands_v1_BrandsAPI_SearchBrands as SearchBrands,
  whisk_backoffice_brands_v1_SearchBrandsRequest as SearchBrandsRequest,
} from '@whisklabs/b2b-backoffice-protobuf';

import { b2bGrpc } from 'b2b/grpc';

export { Brand, SearchBrandsRequest };

const api = b2bGrpc();

export const searchBrands = (payload: SearchBrandsRequest) => api(SearchBrands, payload);
