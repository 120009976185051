import { action, makeObservable } from 'mobx';

import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { BaseStore, getStore } from 'common/stores';

import { BanCommunityRequest, banCommunity } from './api';

export class BanCommunityFormStore extends BaseStore {
  readonly banLoader = new DataLoader();

  readonly form = new FormModel({
    id: new FieldModel('').validators(validateRequired),
  });

  @action storeReset = () => {
    this.banLoader.reset();
    this.form.reset();
  };

  @action banCommunity = async (payload: BanCommunityRequest) => {
    const { success, error } = await this.banLoader.load(() => banCommunity(payload));

    if (success) {
      getStore('toast').success('Banned community');
      this.form.reset();
      this.banLoader.ok();
    } else {
      this.banLoader.fail(error);
    }
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
