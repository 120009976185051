import { observer } from 'mobx-react-lite';

import { PageHeader } from 'common/components/page-header';

import { FlaggedRecipeTable } from './form';

export const FlaggedRecipeQueuePage = observer(() => (
  <>
    <PageHeader title="Flagged Recipe Approval Queue" />
    <FlaggedRecipeTable />
  </>
));
