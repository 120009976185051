import { Classes } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { MouseEventHandler, ReactNode } from 'react';

const clsItem = css`
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;
  font-size: 16px;
  :hover {
    color: #4db690;
  }
`;

const clsText = css`
  display: inline-block;
  padding-right: 10px;
  padding-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  className?: string;
  onClick: MouseEventHandler<HTMLDivElement>;
  children: ReactNode;
}

export const PopoverMenuItem = ({ children, onClick, className }: Props) => (
  <div className={cx(className, clsItem, Classes.POPOVER_DISMISS)} onClick={onClick}>
    <div className={clsText}>{children}</div>
  </div>
);
