import { isDefined, isFunction, isObject } from '@whisklabs/typeguards';
import { useCallback, useEffect, useRef, useState } from 'react';
import shallowEqual from 'shallowequal';

import {
  ColumnId,
  DEFAULT_PAGE_SIZE,
  Pagination,
  SetTableState,
  Sort,
  TableProps,
  TableState,
  getOffset,
} from 'common/components/table-view';

const DEFAULT_PAGINATION: Pagination = { page: 1, pageSize: DEFAULT_PAGE_SIZE };

const getControlledSort = <T, U extends ColumnId>(props: TableProps<T, U>): Sort<U> | undefined =>
  props.sort ?? undefined;

const getControlledSearch = <T, U extends ColumnId>(props: TableProps<T, U>): string | undefined =>
  props.search ?? (isDefined(props.onChange) ? '' : undefined);

const getControlledPagination = <T, U extends ColumnId>({ pagination }: TableProps<T, U>): Pagination | undefined =>
  isObject(pagination) ? pagination : undefined;

export const useControlledTableState = <T, U extends ColumnId>(
  props: TableProps<T, U>
): [TableState<U>, SetTableState<U>] => {
  const [state, setState] = useState<TableState<U>>(() => ({
    sort: getControlledSort(props),
    search: getControlledSearch(props),
    pagination: getControlledPagination(props),
  }));

  const prevPropsRef = useRef<TableProps<T, U>>(props);

  useEffect(() => {
    if (!shallowEqual(prevPropsRef.current, props)) {
      let newState = state;

      const newSort = getControlledSort(props);
      if (!shallowEqual(newSort, getControlledSort(prevPropsRef.current)) && !shallowEqual(newSort, state.sort)) {
        newState = { ...state, sort: newSort };
      }

      const newSearch = getControlledSearch(props);
      if (newSearch !== getControlledSearch(prevPropsRef.current) && newSearch !== state.search) {
        newState = { ...state, search: newSearch };
      }

      const newPagination = getControlledPagination(props);
      if (
        !shallowEqual(newPagination, getControlledPagination(prevPropsRef.current)) &&
        !shallowEqual(newPagination, state.pagination)
      ) {
        newState = { ...state, pagination: newPagination };
      }

      if (newState !== state) {
        setState(newState);
      }
    }

    prevPropsRef.current = props;
  }, [props, state]);

  return [state, setState];
};

export const useUncontrolledTableState = <T, U extends ColumnId>(
  props: TableProps<T, U>
): [TableState<U>, SetTableState<U>] => {
  const [state, setState] = useState<TableState<U>>(() => ({
    sort: props.defaultSort ?? undefined,
    search: props.defaultSearch ?? (isDefined(props.searchFunction) || isDefined(props.onChange) ? '' : undefined),
    pagination:
      props.defaultPagination === false
        ? undefined
        : isObject(props.defaultPagination)
        ? { ...DEFAULT_PAGINATION, ...props.defaultPagination }
        : DEFAULT_PAGINATION,
  }));

  return [state, setState];
};

export const useTableState = <T, U extends ColumnId>(props: TableProps<T, U>): [TableState<U>, SetTableState<U>] => {
  const isControlledMode = isDefined(props.search) || isDefined(props.sort) || isDefined(props.pagination);
  const initialIsControlledModeRef = useRef(isControlledMode);

  useEffect(() => {
    if (isControlledMode !== initialIsControlledModeRef.current) {
      throw new Error('Table: cannot switch between controlled and uncontrolled mode');
    }
    if (isControlledMode && !isDefined(props.onChange)) {
      throw new Error('Table is in controlled mode and "onChange" is not defined');
    }
  }, [isControlledMode, props.onChange]);

  const [controlledState, setControlledState] = useControlledTableState(props);
  const [uncontrolledState, setUncontrolledState] = useUncontrolledTableState(props);

  const [state, setState] = isControlledMode
    ? [controlledState, setControlledState]
    : [uncontrolledState, setUncontrolledState];

  const callOnChangeRef = useRef(false);

  const handleSetState: SetTableState<U> = useCallback(
    (newStateParam) => {
      setState((curState) => {
        const newState = isFunction(newStateParam) ? newStateParam(curState) : newStateParam;

        if (!shallowEqual(newState, curState)) {
          // We can't call onChange here because it triggers component update and React throws an error
          callOnChangeRef.current = true;
          return newState;
        }

        return curState;
      });
    },
    [setState]
  );

  useEffect(() => {
    if (callOnChangeRef.current) {
      callOnChangeRef.current = false;
      props.onChange?.({
        ...state,
        offset: getOffset(state.pagination),
        limit: state.pagination?.pageSize,
        query: state.search ?? '',
      });
    }
  });

  return [state, handleSetState];
};
