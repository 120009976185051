import { action, makeObservable } from 'mobx';

import { DataLoadersMap } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { User, deleteUser } from '../api';

export class UserDeleteStore extends BaseStore {
  readonly deleteLoaders = new DataLoadersMap();

  @action storeReset = () => {
    this.deleteLoaders.reset();
  };

  @action deleteUser = async (user: User) => {
    const deleteLoader = this.deleteLoaders.get(user.email);
    const { success, error } = await deleteLoader.load(() => deleteUser({ email: user.email }));

    if (success) {
      getStore('toast').success(`Deleted user "${user.email}"`);
      deleteLoader.ok();
    } else {
      getStore('toast').error(`Failed to delete user "${user.email}"`, error);
      deleteLoader.fail(error);
    }
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
