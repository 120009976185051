import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable, reaction } from 'mobx';

import { updateApp } from 'b2b/apps/api';
import { AppDetailsStore } from 'b2b/apps/details/store';
import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { BaseStore, getStore } from 'common/stores';

export interface AppCommunitiesPayload {
  includeCommunityRecipesInSearch: boolean;
}

export class AppCommunitiesStore extends BaseStore {
  readonly saveLoader = new DataLoader();

  readonly form = new FormModel({
    includeCommunityRecipesInSearch: new FieldModel<boolean>(false),
  });

  constructor(private readonly appDetailsStore: AppDetailsStore) {
    super();
    makeObservable(this);
  }

  @action storeInit = () => {
    this.sub(
      reaction(
        () => this.appDetailsStore.app,
        (app) => {
          this.form.reset({
            includeCommunityRecipesInSearch: app.settings?.communityConfig?.includeCommunityRecipesInSearch ?? false,
          });
        },
        { fireImmediately: true }
      )
    );
  };

  @action storeReset = () => {
    this.saveLoader.reset();
  };

  @action save = async (payload: AppCommunitiesPayload) => {
    const { cancelled, data, error } = await this.saveLoader.load(() =>
      updateApp(this.appDetailsStore.app.appId, {
        settings: {
          communityConfig: payload,
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.app)) {
      this.appDetailsStore.setApp(data.app);
      getStore('toast').success('Updated community settings');
      this.saveLoader.ok();
    } else {
      this.saveLoader.fail(error);
    }
  };
}
