import { isPresent, isString } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { ReactNode } from 'react';

import { clsMb30 } from 'common/styles/margin-padding';

import { PageDescription, clsPageDescription } from './page-description';
import { PageTitle } from './page-title';

const clsPageHeader = css`
  & .${clsPageDescription} {
    margin: 0;
  }
`;

const clsTitleRow = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const clsTitleSide = css`
  margin-left: 30px;
`;

interface Props {
  title: ReactNode;
  rightElement?: ReactNode;
  description?: ReactNode;
}

export const PageHeader = ({ title, rightElement, description }: Props) => (
  <div className={cx(clsPageHeader, clsMb30)}>
    <div className={clsTitleRow}>
      {isString(title) ? <PageTitle title={title} /> : title}
      {isPresent(rightElement) ? <div className={clsTitleSide}>{rightElement}</div> : null}
    </div>
    {isString(description) ? <PageDescription description={description} /> : description}
  </div>
);
