import { isText } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { FieldCheckbox } from 'common/components/fields/field-checkbox';
import { FieldInput } from 'common/components/fields/field-input';
import { FieldTextArea } from 'common/components/fields/field-textarea';
import { clsRelativeContainer } from 'common/styles/layout';
import { clsLink } from 'common/styles/link';
import { clsMb30 } from 'common/styles/margin-padding';

import { AppInfoFormFieldsStore } from './store';

const clsLinkOutside = css`
  position: absolute;
  left: 100%;
  top: 35px;
  margin-left: 10px;
  white-space: nowrap;
`;

interface Props {
  store: AppInfoFormFieldsStore;
}

export const AppInfoFormFields = observer(({ store }: Props) => {
  const { form } = store;

  return (
    <div className={clsMb30}>
      <FieldInput field={form.fields.name} label="Name" maxLength={255} />
      <FieldTextArea field={form.fields.description} label="Description" maxLength={1000} />
      <div className={clsRelativeContainer}>
        <FieldInput
          field={form.fields.businessId}
          label="Business ID"
          maxLength={255}
          placeholder="e.g. 8dfd1e8a-839d-4f66-90fe-e4ddf04746c4"
        />
        {form.fields.businessId.isValid && isText(form.fields.businessId.value.trim()) ? (
          <Link to={`/b2b/businesses/${form.fields.businessId.value}`} className={cx(clsLink, clsLinkOutside)}>
            Go to business
          </Link>
        ) : null}
      </div>
      <FieldInput field={form.fields.trackingId} label="Tracking ID" maxLength={1000} />
      <div>
        <FieldCheckbox field={form.fields.isTest} label="Test" inline />
        <FieldCheckbox field={form.fields.isTrial} label="Sandbox" inline />
      </div>
    </div>
  );
});
