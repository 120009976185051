import { ModeratedEntityType } from 'b2c/helpers/reported/api';
import { PageHeader } from 'common/components/page-header';

import { Page } from './page';

export const Reported: React.FC<{ title: string; entity: ModeratedEntityType }> = ({ title, entity }) => (
  <>
    <PageHeader title={title} />
    <Page entity={entity} />
  </>
);
