import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable, observable } from 'mobx';

import { DataLoader } from 'common/helpers/data-loader';
import { getStore } from 'common/stores';

import { removeDomain, updateDomain } from '../api';

export interface Params {
  domain: string;
  id: string;
}
export class DomainEntity {
  editLoader = new DataLoader();
  removeLoader = new DataLoader();
  @observable value = '';
  id = '';
  constructor(params: Params) {
    this.value = params.domain;
    this.id = params.id;
    makeObservable(this);
  }

  @action removeDomain = async () => {
    const { cancelled, data, error } = await this.removeLoader.load(() => removeDomain({ domainId: this.id }));

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      getStore('toast').success('Domain has been deleted');
      this.removeLoader.ok();
    } else {
      this.removeLoader.fail(error);
      getStore('toast').error('Smth went wrong');
    }
  };

  @action editDomain = async () => {
    const { cancelled, data, error } = await this.editLoader.load(() =>
      updateDomain({ domainId: this.id, domain: this.value })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      getStore('toast').success('Domain has been updated');
      this.editLoader.ok();
    } else {
      this.editLoader.fail(error);
      getStore('toast').error('Smth went wrong');
    }
  };

  @action setValue = (value: string) => {
    this.value = value;
  };
}
