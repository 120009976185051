import { observer } from 'mobx-react-lite';

import { PageHeader } from 'common/components/page-header';

import { FlaggedImageTable } from './form';

export const FlaggedImageQueuePage = observer(() => (
  <>
    <PageHeader title="Flagged Image Approval Queue" />
    <FlaggedImageTable />
  </>
));
