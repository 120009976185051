import { isDefined, isText } from '@whisklabs/typeguards';
import { action, makeObservable } from 'mobx';

import {
  AppPublisher,
  AppPublisherLicence,
  addAppPublisher,
  removeAppPublishers,
  updateAppPublisher,
} from 'b2b/apps/details/publishers/api';
import { AppPublishersStore } from 'b2b/apps/details/publishers/store';
import { Publisher, stubPublisher } from 'b2b/publishers';
import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { createRequiredValidator, validateRequired } from 'common/helpers/form/validators';
import { BaseStore, getStore } from 'common/stores';

export const DEFAULT_PUBLISHER_LICENCE = AppPublisherLicence.LICENCE_TYPE_FAIRUSE;

export class AppPublisherStore extends BaseStore {
  readonly addLoader = new DataLoader();
  readonly removeLoader = new DataLoader();
  readonly saveLoader = new DataLoader();

  readonly form = new FormModel({
    publisher: new FieldModel<Publisher>(stubPublisher()).validators(createRequiredValidator(({ id }) => isText(id))),
    licence: new FieldModel<AppPublisherLicence>(DEFAULT_PUBLISHER_LICENCE).validators(validateRequired),
    globalRegion: new FieldModel<boolean>(false),
    countries: new FieldModel<string[]>([]),
  });

  constructor(private readonly appPublishersStore: AppPublishersStore) {
    super();
    makeObservable(this);
  }

  @action storeReset = () => {
    this.addLoader.reset();
    this.removeLoader.reset();
    this.saveLoader.reset();
    this.form.reset();
    this.form.fields.countries.validators(
      createRequiredValidator((countries) => this.form.values.globalRegion || countries.length > 0)
    );
  };

  @action add = async (newAppPublisher: AppPublisher) => {
    const { cancelled, data, error } = await this.addLoader.load(() =>
      addAppPublisher({
        appId: this.appPublishersStore.app.appId,
        publisher: newAppPublisher,
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.appPublisher)) {
      getStore('toast').success('Added publisher');
      this.appPublishersStore.appPublisherById.set(data.appPublisher.id, data.appPublisher);
      this.form.reset();
      this.addLoader.ok();
    } else {
      getStore('toast').error('Failed to add publisher', error);
      this.addLoader.fail(error);
    }
  };

  @action save = async (appPublisher: AppPublisher) => {
    const { cancelled, data, error } = await this.saveLoader.load(() =>
      updateAppPublisher({
        appId: this.appPublishersStore.app.appId,
        publisher: appPublisher,
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.appPublisher)) {
      this.appPublishersStore.appPublisherById.set(data.appPublisher.id, data.appPublisher);
      getStore('toast').success('Updated publisher');
      this.saveLoader.ok();
    } else {
      getStore('toast').error('Failed to update publisher', error);
      this.saveLoader.fail(error);
    }
  };

  @action remove = async (appPublisher: AppPublisher) => {
    const { success, error } = await this.removeLoader.load(() =>
      removeAppPublishers({
        appId: this.appPublishersStore.app.appId,
        appPublishers: [appPublisher.id],
      })
    );

    if (success) {
      this.appPublishersStore.appPublisherById.delete(appPublisher.id);
      getStore('toast').success('Removed publisher');
      this.removeLoader.ok();
    } else {
      getStore('toast').error('Failed to remove publisher', error);
      this.removeLoader.fail(error);
    }
  };
}
