import { observer } from 'mobx-react-lite';

import { PageHeader } from 'common/components/page-header';
import { useStore } from 'common/hooks/use-store';

import { AppsTableStore } from './store';
import { AppsTable } from './table';

export const AppsTablePage = observer(() => {
  const store = useStore(() => new AppsTableStore());

  return (
    <div>
      <PageHeader title="API Keys" />
      <AppsTable store={store} />
    </div>
  );
});
