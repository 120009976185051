import { Button, Intent, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';

import { FieldModel } from 'common/helpers/form';
import { sharedPopoverProps } from 'common/popover';

import { AppPublisherLicence } from '../api';

const clsLicenceButton = css`
  min-width: 140px !important;
`;

const LicenceSelect = Select.ofType<AppPublisherLicence>();

const selectItems = [AppPublisherLicence.LICENCE_TYPE_FAIRUSE, AppPublisherLicence.LICENCE_TYPE_EXTENDED];

const licenceItemsProps: Record<AppPublisherLicence, { intent: Intent; label: string }> = {
  [AppPublisherLicence.LICENCE_TYPE_FAIRUSE]: { intent: Intent.PRIMARY, label: 'Fair Use' },
  [AppPublisherLicence.LICENCE_TYPE_EXTENDED]: { intent: Intent.SUCCESS, label: 'Extended' },
};

const renderLicence: ItemRenderer<AppPublisherLicence> = (item, { handleClick, modifiers }) =>
  modifiers.matchesPredicate ? (
    <MenuItem active={modifiers.active} key={`${item}`} onClick={handleClick} text={licenceItemsProps[item].label} />
  ) : null;

interface Props {
  field: FieldModel<AppPublisherLicence>;
}

export const AppPublisherFieldLicence = observer(({ field }: Props) => (
  <LicenceSelect
    items={selectItems}
    itemRenderer={renderLicence}
    onItemSelect={field.handleValueChange}
    popoverProps={sharedPopoverProps}
    filterable={false}
    disabled={field.isDisabled}
  >
    <Button
      className={clsLicenceButton}
      text={licenceItemsProps[field.value].label}
      alignText="left"
      rightIcon="double-caret-vertical"
      intent={licenceItemsProps[field.value].intent}
      disabled={field.isDisabled}
      fill
      large
    />
  </LicenceSelect>
));
