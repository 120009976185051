import { css, cx } from 'linaria';
import { ReactNode } from 'react';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';

import { WhiskLogo } from 'common/components/whisk-logo';
import { join } from 'common/helpers/router';
import { clsPageContainer } from 'common/styles/layout';
import { clsNavLink, clsNavLinkActive } from 'common/styles/link';

import { UserMenu } from './user-menu';

const clsHeaderWrapper = css`
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
  height: 60px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 3px 12px rgba(79, 79, 106, 0.1);
  backdrop-filter: blur(20px);
`;

const clsHeader = css`
  padding: 0 16px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const clsMenu = css`
  display: flex;
  margin-left: auto;
`;

const clsMenuItem = css`
  display: flex;
  height: 40px;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  padding-right: 8px;
  margin-right: -8px;
  font-size: 18px;
  transition: background-color 200ms ease-out;

  &:hover {
    background-color: #f6f6f9;
  }
`;

const clsHeaderNav = css`
  display: flex;
  align-items: center;
  margin-left: 50px;
  height: 100%;

  @media (min-width: 960px) {
    margin-left: 150px;
  }
`;

const clsHeaderNavLink = css`
  font-size: 18px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 2px solid transparent;

  &:last-child {
    margin-right: 0;
  }

  &.${clsNavLinkActive} {
    border-bottom: 2px solid;
  }
`;

interface NavItem {
  to: string;
  label: ReactNode;
}

interface Props {
  navItems: NavItem[];
}

export const Header = ({ navItems }: Props) => {
  const { path, url } = useRouteMatch();

  return (
    <div className={clsHeaderWrapper}>
      <div className={cx(clsHeader, clsPageContainer)}>
        <Link to={path}>
          <WhiskLogo size="small" />
        </Link>

        <nav className={clsHeaderNav}>
          {navItems.map((item) => (
            <NavLink
              key={item.to}
              to={join(url, item.to)}
              className={cx(clsNavLink, clsHeaderNavLink)}
              activeClassName={clsNavLinkActive}
            >
              {item.label}
            </NavLink>
          ))}
        </nav>

        <div className={clsMenu}>
          <UserMenu className={clsMenuItem} />
        </div>
      </div>
    </div>
  );
};
