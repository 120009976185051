import { observer } from 'mobx-react-lite';

import { useStore } from 'common/hooks/use-store';

import { NoIndexingDomainsStore } from './store';
import { Table } from './table';

export const BlockedIndexingTable = observer(() => {
  const store = useStore(() => new NoIndexingDomainsStore());

  return (
    <>
      <Table store={store} />
    </>
  );
});
