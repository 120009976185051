import { Button, Intent } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { RuleType } from 'b2c/indexing-tool/types';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { clsMb30 } from 'common/styles/margin-padding';

import { IndexingToolContext } from '../../context';

const inputWrapper = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 20px;
`;

const clsInput = css`
  & > input {
    box-shadow: none;
    height: auto !important;
  }
`;

const clsControls = css`
  flex: 1 0 max-content;
  display: flex;
  margin-top: 10px;
`;

export const FileForm = observer(() => {
  const {
    fileStore: { form, loader, submit, downloadRules },
    controlStore: { ruleType },
  } = useContext(IndexingToolContext);

  const isIndexed = ruleType === RuleType.DIRECTORY_RULE_TYPE_ALWAYS_INDEX;

  return (
    <Form
      form={form}
      loader={loader}
      onSubmit={submit}
      errorTitle={`Failed to ${isIndexed ? 'index' : 'deindex'} records`}
    >
      <div className={cx(inputWrapper, clsMb30)}>
        <FieldInput
          className={clsInput}
          contentClassName={clsInput}
          inputClassName={clsInput}
          type="file"
          accept=".csv"
          onChange={(event) => {
            form.fields.file.handleValueChange(event.target.files?.[0]);
          }}
        />
        <div className={clsControls}>
          <ButtonSubmit
            text={`Submit csv for ${isIndexed ? 'index' : 'deindex'}`}
            intent={isIndexed ? undefined : Intent.DANGER}
            fill
          />
        </div>
      </div>
      <Button
        onClick={() => {
          void downloadRules();
        }}
        icon="cloud-download"
      >
        Download CSV
      </Button>
    </Form>
  );
});
