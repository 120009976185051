import { observer } from 'mobx-react-lite';

import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';

import { DeleteUserFormStore } from './store';

export const DeleteUserForm = observer(() => {
  const { loader, deleteUser, form } = useStore(() => new DeleteUserFormStore());

  return (
    <Card>
      <Form form={form} loader={loader} onSubmit={deleteUser} errorTitle="Failed to delete user">
        <div className={clsMb30}>
          <FieldInput
            field={form.fields.userId}
            label="User id"
            placeholder="e.g. 102ea21f92db5084836ada740d19c295dl3"
          />
        </div>
        <ButtonSubmit text="Delete" large />
      </Form>
    </Card>
  );
});
