export const COLORS = {
  GREEN: '#3DC795',
  GREEN_DARK: '#13B077',
  GREEN_LIGHT: '#DAF8ED',

  BLUEBERRY: '#233748',
  BLUEBERRY_600: '#132432',
  BLUEBERRY_500: '#717F94',
  BLUEBERRY_400: '#909BAB',
  BLUEBERRY_300: '#E4E6EA',
  BLUEBERRY_200: '#F6F7FA',
  BLUEBERRY_100: '#F7FAFC',

  BLUE: '#008BF7',
  BLUE_DARK: '#007AD9',
  BLUE_LIGHT: '#CAE5FF',

  INDIGO: '#7371E8',
  INDIGO_DARK: '#615FCE',
  INDIGO_LIGHT: '#D6D5F9',

  PURPLE: '#93307D',
  PURPLE_DARK: '#7B1D66',
  PURPLE_LIGHT: '#F7CFEE',

  PINK: '#CC67E8',
  PINK_DARK: '#B748D6',
  PINK_LIGHT: '#FFE8FA',

  RED: '#EE4055',
  RED_DARK: '#C73C47',
  RED_LIGHT: '#FCE2E5',

  ORANGE: '#FF9334',
  ORANGE_DARK: '#E0781D',
  ORANGE_LIGHT: '#FFDEC1',

  YELLOW: '#FED400',
  YELLOW_DARK: '#ECC500',
  YELLOW_LIGHT: '#FFF5C5',

  LIME: '#49CC39',
  LIME_DARK: '#3EAE30',
  LIME_LIGHT: '#D7F8D3',

  WHITE: '#fff',
  BLACK: '#000',
  TRANSPARENT: 'transparent',
} as const;
