import { createContext, useContext } from 'react';

import { DataLoader } from 'common/helpers/data-loader';
import { FormFields, FormModel } from 'common/helpers/form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FormContextType<T extends FormFields = any> {
  form: FormModel<T>;
  loader?: DataLoader;
}

const FormContext = createContext<FormContextType>({
  form: new FormModel({}),
  loader: undefined,
});

export const FormContextProvider = FormContext.Provider;

export const useFormContext = <T extends FormFields>() => useContext(FormContext) as FormContextType<T>;
