import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable } from 'mobx';

import { Business, UpdateBusinessRequest, updateBusiness } from 'b2b/businesses/api';
import { DataLoader } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { BusinessDetailsStore } from '../store';

export class BusinessAccountStore extends BaseStore {
  readonly updateLoader = new DataLoader();

  constructor(private readonly businessDetailsStore: BusinessDetailsStore) {
    super();
    makeObservable(this);
  }

  get business(): Business {
    return this.businessDetailsStore.business;
  }

  @action updateBusiness = async (payload: Omit<UpdateBusinessRequest, 'id'>) => {
    const { cancelled, data, error } = await this.updateLoader.load(() =>
      updateBusiness({
        id: this.business.id,
        ...payload,
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.value)) {
      getStore('toast').success('Business account updated');
      this.businessDetailsStore.setBusiness(data.value);
      this.updateLoader.ok();
    } else {
      this.updateLoader.fail(error);
    }
  };
}
