import { captureException } from '@sentry/browser';
import { isPresent } from '@whisklabs/typeguards';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';

import { ErrorMessage } from 'common/components/error-message';

export const ErrorBoundaryFallback = ({ error }: FallbackProps) => {
  useEffect(() => {
    if (isPresent(error)) {
      captureException(error);
    }
  }, [error]);

  return <ErrorMessage title="Fatal Exception" error={error} />;
};
