import { action, makeObservable, observable } from 'mobx';

export class Dialog {
  @observable isOpen = false;

  @action open = () => {
    this.isOpen = true;
  };

  @action close = () => {
    this.isOpen = false;
  };

  @action reset = () => {
    this.isOpen = false;
  };

  constructor() {
    makeObservable(this);
  }
}
