import { observer } from 'mobx-react-lite';

import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { clsMb10, clsMb30 } from 'common/styles/margin-padding';

import { ClaimedDomaisStore } from './store';

interface Props {
  store: ClaimedDomaisStore;
}

export const CreateForm = observer<Props>(({ store: { createForm, createLoader, addDomain } }) => {
  return (
    <Card>
      <Form form={createForm} loader={createLoader} onSubmit={addDomain} errorTitle="Failed to add domain">
        <div className={clsMb30}>
          <FieldInput
            className={clsMb10}
            field={createForm.fields.userId}
            label="User Id"
            placeholder="e.g. 102ea21f92db5084836ada740d19c295dl3"
          />
          <FieldInput field={createForm.fields.domain} label="Domain" placeholder="e.g. example.com" />
        </div>
        <ButtonSubmit disabled={createLoader.isPending} text="Add domain" large />
      </Form>
    </Card>
  );
});
