import {
  whisk_backoffice_business_v1_BusinessAPI_ActivateApi as ActivateApi,
  whisk_backoffice_business_v1_ActivateApiRequest as ActivateApiRequest,
  whisk_backoffice_business_v1_BusinessAPI_ActivateShoppableMedia as ActivateShoppableMedia,
  whisk_backoffice_business_v1_ActivateShoppableMediaRequest as ActivateShoppableMediaRequest,
  whisk_backoffice_business_v1_BusinessAPI_ActivateStudio as ActivateStudio,
  whisk_backoffice_business_v1_ActivateStudioRequest as ActivateStudioRequest,
} from '@whisklabs/b2b-backoffice-protobuf';

import { b2bGrpc } from 'b2b/grpc';

const api = b2bGrpc();

export const activateApi = (payload: ActivateApiRequest) => api(ActivateApi, payload);

export const activateStudio = (payload: ActivateStudioRequest) => api(ActivateStudio, payload);

export const activateShoppableMedia = (payload: ActivateShoppableMediaRequest) => api(ActivateShoppableMedia, payload);
