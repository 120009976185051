import { memo } from 'react';

import { AppAuthProviders } from './auth-providers';
import { AppCredentials } from './credentials';
import { AppOAuthScopes } from './oauth-scopes';
import { AppRedirectUrls } from './redirect-urls';

export const AppAuthPage = memo(() => (
  <div>
    <AppCredentials />
    <AppAuthProviders />
    <AppOAuthScopes />
    <AppRedirectUrls />
  </div>
));
