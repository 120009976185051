import { isObject, isString, isText } from '@whisklabs/typeguards';

export const WARNING_TEXTS: Record<string, string> = {
  notVerified: 'Input data is not verified',
  notFound: 'Input data entity was not found',
};

export const tryFormatWarning = (warning: unknown): string | undefined => {
  const messageKey = isObject(warning) && isString(warning.oneof) ? warning.oneof : '';

  if (!isText(messageKey)) {
    return undefined;
  }

  return `Key: ${messageKey}\nMessage: ${WARNING_TEXTS[messageKey] ?? ''}`;
};
