import { DEVTOOLS, FORCE_LOG } from 'common/config';

import { isLight } from './color';
import { entries } from './functional';
import { noop } from './noop';

const style = (hex = '#333') =>
  `background: ${hex}; color: ${isLight(hex) ? '#000' : '#FFF'}; padding: 2px; border-radius: 3px;`;

export const getLogFn =
  (group: string, color?: string, type: 'info' | 'warn' | 'error' = 'info') =>
  (...data: unknown[]) => {
    // eslint-disable-next-line no-console
    console[type](`%c ${group} `, style(color), ...data);
  };

export const getLogFnStub = () => noop;

type LogFunction = ReturnType<typeof getLogFn>;

export const loggers = {
  info: { method: 'info', color: '#0cc1c8' },
  error: { method: 'error', color: '#f00' },
  warn: { method: 'warn', color: '#ffc107' },
} as const;

export const getLogger = (group: string, enabled = DEVTOOLS || FORCE_LOG) => {
  const result: Record<string, LogFunction> = {};
  for (const [loggerMethod, { method, color }] of entries(loggers)) {
    result[loggerMethod] = enabled ? getLogFn(group, color, method) : getLogFnStub();
  }

  return result as Record<keyof typeof loggers, LogFunction>;
};

export type Logger = ReturnType<typeof getLogger>;
