import { isDefined, isText } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';
import { nanoid } from 'nanoid/non-secure';

import { entries, values } from 'common/helpers/functional';

import { FieldModel, FieldValue } from './field';

export type FormFields = Record<PropertyKey, FieldModel>;
export type FormValues<T extends FormFields> = { [K in keyof T]: FieldValue<T[K]> };
export type FormValidator<T extends FormFields> = (values: FormValues<T>) => true | string;
export type FormOnSubmit<T extends FormFields> = (values: FormValues<T>) => void;
type TypedFormFields<T extends FormFields> = { [K in keyof T]: FieldModel<FieldValue<T[K]>> };

export class FormModel<T extends FormFields> {
  private formOnSubmit?: FormOnSubmit<T>;
  private formValidator?: FormValidator<T>;

  readonly id = nanoid();

  @observable.shallow fields: TypedFormFields<T>;

  constructor(fields: T) {
    makeObservable(this);
    this.fields = fields;
  }

  @computed get values() {
    const result = {} as FormValues<T>;

    for (const [fieldName, { value }] of entries(this.fields)) {
      // assertion because of weird behavior in TS4.0+ which causes no-unsafe-any error
      result[fieldName] = isText(value) ? (value as string).trim() : value;
    }

    return result;
  }

  @computed get isTouched() {
    return values(this.fields).some((field) => field.isTouched);
  }

  @computed get isDirty() {
    return values(this.fields).some((field) => field.isDirty);
  }

  @computed get isValid() {
    return values(this.fields).every((field) => field.isValid);
  }

  // convenience property for disabling submit buttons in pre-filled edit forms
  @computed get isValidAndDirty() {
    return this.isValid && this.isDirty;
  }

  @action validate = () => {
    for (const field of values(this.fields)) {
      field.validate();
    }

    this.formValidator?.(this.values);
  };

  @action markTouched = () => {
    values(this.fields).forEach((field) => {
      field.markTouched();
    });
  };

  @action reset = (fieldValues?: FormValues<T>) => {
    for (const [fieldName, field] of entries(this.fields)) {
      field.reset(isDefined(fieldValues) ? fieldValues[fieldName] : field.initialValue);
    }
  };

  @action submit = () => {
    this.validate();
    this.markTouched();

    if (isDefined(this.formOnSubmit) && this.isValid) {
      this.formOnSubmit(this.values);
    }
  };

  @action validator = (formValidator?: FormValidator<T>) => {
    this.formValidator = formValidator;
    return this;
  };

  setOnSubmit = (formOnSubmit: FormOnSubmit<T> | undefined) => {
    this.formOnSubmit = formOnSubmit;
    return this;
  };
}
