import { observer } from 'mobx-react-lite';

import { CommunityStore } from 'b2c/community';
import { FieldCommunityTopics } from 'b2c/community-topics';
import { Card } from 'common/components/card';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { SuccessPlaceholder } from 'common/components/success-placeholder';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';

import { UpdateCommunityTopicsFormStore } from './store';

interface Props {
  communityStore: CommunityStore;
}

export const UpdateCommunityTopicsForm = observer(({ communityStore }: Props) => {
  const { form, updateLoader, updateCommunityTopics } = useStore(
    () => new UpdateCommunityTopicsFormStore(communityStore)
  );
  const { community } = communityStore;

  return (
    <Card>
      {updateLoader.isOk ? (
        <SuccessPlaceholder
          title={`Community "${community.name}" is updated`}
          href="/b2c/update-communities"
          linkText="Back to the main form"
        />
      ) : (
        <Form
          form={form}
          loader={updateLoader}
          onSubmit={updateCommunityTopics}
          errorTitle="Failed to update community"
          fill
        >
          <div className={clsMb30}>
            <FieldCommunityTopics field={form.fields.topicIds} />
          </div>
          <ButtonSubmit text="Update" large />
        </Form>
      )}
    </Card>
  );
});
