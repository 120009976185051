import { memo } from 'react';

import { useBusinessDetailsStoreContext } from 'b2b/businesses/details/context';
import { useStore } from 'common/hooks/use-store';

import { WidgetsTableStore } from './store';
import { WidgetsTable } from './table';

export const WidgetsTablePage = memo(() => {
  const businessDetailsStore = useBusinessDetailsStoreContext();
  const store = useStore(() => new WidgetsTableStore(businessDetailsStore));

  return <WidgetsTable store={store} />;
});
