import { Button } from '@blueprintjs/core';
import { isText } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { useAppDetailsStoreContext } from 'b2b/apps/details/context';
import { Card, CardTitle } from 'common/components/card';

import { AppCredentialsField } from './field';

const clsContent = css`
  display: flex;
  align-items: center;
`;

const clsFields = css`
  flex: 1 1 auto;
`;

const clsVisibilityButton = css`
  margin-left: auto;
`;

export const AppCredentials = observer(() => {
  const { app } = useAppDetailsStoreContext();
  const [keysHidden, setKeysHidden] = useState(true);
  const { clientId, secret, apiKey } = app.credentials ?? {};

  const keys = [
    { value: clientId, label: 'Client ID' },
    { value: secret, label: 'Secret' },
    { value: apiKey, label: 'API Key' },
  ];

  return (
    <Card
      header={
        <>
          <CardTitle>Credentials</CardTitle>
          <Button
            className={clsVisibilityButton}
            onClick={() => setKeysHidden((curKeysHidden) => !curKeysHidden)}
            icon={keysHidden ? 'eye-open' : 'eye-off'}
          >
            {keysHidden ? 'Show' : 'Hide'} keys
          </Button>
        </>
      }
    >
      <div className={clsContent}>
        <div className={clsFields}>
          {keys.map(({ value, label }) =>
            isText(value) ? <AppCredentialsField key={label} value={value} label={label} isHidden={keysHidden} /> : null
          )}
        </div>
      </div>
    </Card>
  );
});
