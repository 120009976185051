import { observer } from 'mobx-react-lite';

import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { clsMb30 } from 'common/styles/margin-padding';

import { ClaimedDomaisStore } from './store';

interface Props {
  store: ClaimedDomaisStore;
}

export const DeleteForm = observer<Props>(({ store: { deleteForm, deleteLoader, deleteDomain } }) => {
  return (
    <Card>
      <Form form={deleteForm} loader={deleteLoader} onSubmit={deleteDomain} errorTitle="Failed to delete domain">
        <div className={clsMb30}>
          <FieldInput field={deleteForm.fields.domain} label="Domain" placeholder="e.g. example.com" />
        </div>
        <ButtonSubmit disabled={deleteLoader.isPending} text="Delete domain" large />
      </Form>
    </Card>
  );
});
