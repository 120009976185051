import { memo } from 'react';

import { useAppDetailsStoreContext } from 'b2b/apps/details/context';
import { useStore } from 'common/hooks/use-store';

import { AppPublishersStore } from './store';
import { AppPublishersTable } from './table';

export const AppPublishersPage = memo(() => {
  const appDetailsStore = useAppDetailsStoreContext();
  const appPublishersStore = useStore(() => new AppPublishersStore(appDetailsStore));

  return <AppPublishersTable store={appPublishersStore} />;
});
