import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AppDelete } from 'b2b/apps/delete';
import { LoadingWrapper } from 'common/components/loading-wrapper';
import { NoticeDelayedChanges } from 'common/components/notice-delayed-changes';
import { PageHeader } from 'common/components/page-header';
import { RouterTab, RouterTabs } from 'common/components/router-tabs';
import { useStore } from 'common/hooks/use-store';

import { AppAuthPage } from './auth';
import { AppCommunitiesPage } from './communities';
import { AppDetailsStoreContextProvider } from './context';
import { AppInfoPage } from './info';
import { AppPublishersPage } from './publishers';
import { AppRetailersPage } from './retailers';
import { AppDetailsStore } from './store';

const tabs: RouterTab[] = [
  { path: '/info', label: 'Info', component: AppInfoPage },
  { path: '/auth', label: 'Auth', component: AppAuthPage },
  { path: '/publishers', label: 'Publishers', component: AppPublishersPage },
  { path: '/retailers', label: 'Retailers', component: AppRetailersPage },
  { path: '/communities', label: 'Communities', component: AppCommunitiesPage },
];

interface RouteParams {
  apiKeyId: string;
}

export const AppDetailsPage = observer(() => {
  const store = useStore(() => new AppDetailsStore());
  const { loader, loadApp, app, handleAppDeleted } = store;
  const { apiKeyId } = useParams<RouteParams>();

  useEffect(() => {
    void loadApp(apiKeyId);
  }, [loadApp, apiKeyId]);

  return (
    <AppDetailsStoreContextProvider value={store}>
      <LoadingWrapper loader={loader}>
        <PageHeader
          title={`API Key "${app.name}"`}
          rightElement={<AppDelete app={app} onDeleted={handleAppDeleted} large />}
          description={app.description}
        />
        <NoticeDelayedChanges />
        <RouterTabs tabs={tabs} />
      </LoadingWrapper>
    </AppDetailsStoreContextProvider>
  );
});
