import { Callout, Intent } from '@blueprintjs/core';
import { isText, isUndefined } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { memo, useMemo } from 'react';

import { tryFormatWarning } from 'common/helpers/warning';
import { clsMb30 } from 'common/styles/margin-padding';
import { clsTextAlignLeft } from 'common/styles/text';

export interface WarningMessageProps {
  title?: string;
  warning?: unknown;
  className?: string;
  noMargin?: boolean;
}

export const WarningMessage = memo(({ title, warning, className, noMargin }: WarningMessageProps) => {
  const message: string | undefined = useMemo(() => tryFormatWarning(warning), [warning]);

  if (isUndefined(warning)) {
    return null;
  }

  return (
    <Callout
      intent={Intent.WARNING}
      title={title ?? 'Warning'}
      className={cx(noMargin ? undefined : clsMb30, clsTextAlignLeft, className)}
    >
      {isText(message) ? <pre>{message}</pre> : null}
    </Callout>
  );
});
