import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { PageHeader } from 'common/components/page-header';
import { useStore } from 'common/hooks/use-store';

import { CampaignFormFields, CampaignFormStore } from '../common/campaign-form';

import { CampaignCreateStore } from './store';

export const CampaignCreatePage = observer(() => {
  const { createLoader, create } = useStore(() => new CampaignCreateStore());
  const formStore = useStore(() => new CampaignFormStore());
  const { form } = formStore;

  const handleSubmit = useCallback(() => {
    void create(formStore.getData());
  }, [create, formStore]);

  return (
    <div>
      <PageHeader title="New Campaign" />
      <Form form={form} loader={createLoader} onSubmit={handleSubmit} errorTitle="Failed to create campaign">
        <CampaignFormFields store={formStore} />
        <ButtonSubmit kind="add" text="Create" large />
      </Form>
    </div>
  );
});
