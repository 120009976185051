import { observer } from 'mobx-react-lite';

import { useDocumentTitle } from 'common/hooks/use-document-title';

interface Props {
  title: string;
}

export const DocumentTitle = observer(({ title }: Props) => {
  useDocumentTitle(title);

  return null;
});
