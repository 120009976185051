import { isText } from '@whisklabs/typeguards';
import { action, makeObservable } from 'mobx';

import { OAUTH_GOOGLE_CLIENT_ID } from 'common/config';
import { stringifyQuery } from 'common/helpers/query';
import { initialQuery } from 'common/helpers/router';
import { BaseStore } from 'common/stores';

import { OAUTH_INITIAL_CALLBACK_URL } from './oauth';

export class LoginStore extends BaseStore {
  @action loginWithGoogle = () => {
    const initialQueryString = stringifyQuery(initialQuery);

    const oAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?${stringifyQuery({
      client_id: OAUTH_GOOGLE_CLIENT_ID,
      scope: 'email profile',
      access_type: 'offline',
      response_type: 'code',
      redirect_uri: OAUTH_INITIAL_CALLBACK_URL,
      state: `${window.location.origin}${isText(initialQueryString) ? `?${initialQueryString}` : ''}`,
    })}`;

    window.location.assign(oAuthUrl);
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
