import { action, makeObservable } from 'mobx';

import { deleteApp } from 'b2b/apps/api';
import { DataLoadersMap } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { DeletedApp } from './types';

export class AppDeleteStore extends BaseStore {
  readonly deleteLoaders = new DataLoadersMap();

  @action storeReset = () => {
    this.deleteLoaders.reset();
  };

  @action deleteApp = async (app: DeletedApp) => {
    const deleteLoader = this.deleteLoaders.get(app.appId);
    const { cancelled, success, error } = await deleteLoader.load(() => deleteApp({ appId: app.appId }));

    if (cancelled) {
      return;
    }

    if (success) {
      getStore('toast').success(`Deleted API Key "${app.name}"`);
      deleteLoader.ok();
    } else {
      getStore('toast').error(`Deleted to remove API Key "${app.name}"`, error);
      deleteLoader.fail(error);
    }
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
