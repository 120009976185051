import { StatusCode, grpcHTTP } from '@whisklabs/grpc';
import { isDefined, isText } from '@whisklabs/typeguards';

import { DEVTOOLS, FORCE_LOG_GRPC } from 'common/config';
import { eventEmitter } from 'common/event-emitter';
import { getToken } from 'common/helpers/auth';
import { getLogger } from 'common/helpers/logger';

export const createGrpcClient = (server: string) =>
  grpcHTTP({
    server,
    devtool: DEVTOOLS || FORCE_LOG_GRPC,
    logger: getLogger('GRPC'),
    timeout: 1e6,
    transformRequest: ({ xhr }) => {
      const token = getToken();
      if (isText(token)) {
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      }
    },
    transformResponse: ({ data: response }) => {
      if (isDefined(response.error)) {
        if (response.error.grpcCode === StatusCode.UNAUTHENTICATED || response.error.httpStatus === 401) {
          eventEmitter.emit('authTokenInvalid', response.error);
        }
      }
      return response;
    },
  });
