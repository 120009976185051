import { Icon, IconName, MaybeElement, MenuItem } from '@blueprintjs/core';
import { isNumber, isObject, isPresent, isText } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { matchSorter } from 'match-sorter';
import { CSSProperties, ReactNode } from 'react';

import { clsLabel, clsLabelWithIcon, clsLabelWithRightIcon, clsText } from './styles';
import { GetValueKey, MultiselectItem, MultiselectValue } from './types';

const TAG_SIDE_MARGIN = 5;

export function getTagStyle(tagColumns?: number): CSSProperties {
  if (isNumber(tagColumns)) {
    return {
      flex: `0 0 calc(${100 / tagColumns}% - ${TAG_SIDE_MARGIN}px)`,
    };
  }
  return {};
}

export const getDefaultItemsEqual =
  <T extends MultiselectValue>(getValueKey: GetValueKey<T>) =>
  (a: MultiselectItem<T>, b: MultiselectItem<T>) =>
    getValueKey(a.value) === getValueKey(b.value);

export const filterSortItems = <T extends MultiselectValue>(query: string, allItems: MultiselectItem<T>[]) => {
  const normalizedQuery = query.trim();
  if (!isText(normalizedQuery)) {
    return allItems;
  }

  return matchSorter(allItems, normalizedQuery, {
    keys: [(item) => item.label, (item) => item.comment ?? ''],
  });
};

export const renderIcon = (icon?: IconName | MaybeElement): ReactNode => (isText(icon) ? <Icon icon={icon} /> : icon);

export const renderText = <T extends MultiselectValue>(item: MultiselectItem<T>): ReactNode => (
  <span className={clsText}>
    {renderIcon(item.icon)}
    <span
      className={cx(
        clsLabel,
        isPresent(item.icon) ? clsLabelWithIcon : undefined,
        isPresent(item.rightIcon) ? clsLabelWithRightIcon : undefined
      )}
    >
      {item.label}
    </span>
    {renderIcon(item.rightIcon)}
  </span>
);

export const renderNoResults = (): ReactNode => <MenuItem disabled={true} text="No results." />;

export const getSelectedItems = <T extends MultiselectValue>(
  selectedItemsOrValues: (MultiselectItem<T> | T)[],
  items: MultiselectItem<T>[]
): MultiselectItem<T>[] => {
  if (selectedItemsOrValues.every((itemOrValue) => isObject(itemOrValue))) {
    return selectedItemsOrValues as MultiselectItem<T>[];
  }
  const selectedValuesSet = new Set(selectedItemsOrValues as T[]);
  return items.filter((item) => selectedValuesSet.has(item.value));
};
