import { MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { RecordType } from 'b2c/indexing-tool/types';
import { FieldInput } from 'common/components/fields/field-input';
import { sharedPopoverProps } from 'common/popover';
import { clsMb30 } from 'common/styles/margin-padding';

interface Props {
  value: RecordType;
  onClick: (actionId: RecordType) => void;
}

const clsActionItem = css`
  padding: 10px;
`;

interface ActionItem {
  id: RecordType;
  text: string;
}

const ActionSelect = Select.ofType<ActionItem>();

const itemsMap: Record<RecordType, string> = {
  [RecordType.DIRECTORY_RECORD_TYPE_COMMUNITY_RECIPE]: 'Recipes',
  [RecordType.DIRECTORY_RECORD_TYPE_COMMUNITY]: 'Communities',
  [RecordType.DIRECTORY_RECORD_TYPE_COMMUNITY_CONVERSATION]: 'Conversations',
  [RecordType.DIRECTORY_RECORD_TYPE_DISH]: 'Dish pages',
  [RecordType.DIRECTORY_RECORD_TYPE_FOODPEDIA_PRODUCT]: 'Ingredient pages',
  [RecordType.DIRECTORY_RECORD_TYPE_PROFILE]: 'Profiles',
};

const items = Object.entries(itemsMap).map(([id, text]) => ({ id: Number(id), text })) as ActionItem[];

const renderActionItem: ItemRenderer<ActionItem> = (item, { handleClick, modifiers }) => (
  <MenuItem className={clsActionItem} active={modifiers.active} key={item.id} onClick={handleClick} text={item.text} />
);

export const ActionsSelect = observer(({ value, onClick }: Props) => {
  const handleClick = useCallback(
    (item: ActionItem) => {
      onClick(item.id);
    },
    [onClick]
  );

  return (
    <ActionSelect
      items={items}
      itemRenderer={renderActionItem}
      onItemSelect={handleClick}
      popoverProps={sharedPopoverProps}
      filterable={false}
    >
      <FieldInput className={clsMb30} readOnly value={itemsMap[value]} label="Record type" />
    </ActionSelect>
  );
});
