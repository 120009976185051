import { isDefined, isText, isUndefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { hasSearch } from 'b2c/helpers/has-search';
import { adaptReported } from 'b2c/helpers/reported/adapt';
import { EntityMap, ModeratedEntityType, Reported, approve, getReported, reject } from 'b2c/helpers/reported/api';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';
import { Sort } from 'common/types/types';

const DEFAULT_SORT: Sort = {
  sortTitle: 'Reported At',
  sortKey: 'reportedAt',
  sortOrder: 'desc',
};

export class ReportedStore<T extends ModeratedEntityType> extends BaseStore {
  constructor(private readonly entity: T) {
    super();

    makeObservable(this);

    void this.loadReported();
  }

  private readonly pageSize = 50;
  private readonly reportedEntities = observable.map<string, Reported<EntityMap[T]>>([], {
    deep: false,
  });
  @observable after?: string;
  @observable sort = DEFAULT_SORT;
  @observable sortItems: Sort[] = [
    { sortTitle: 'Reported at', sortKey: 'reportedAt', sortOrder: 'desc' },
    { sortTitle: 'Reported at', sortKey: 'reportedAt', sortOrder: 'asc' },
  ];
  @observable searchText = '';

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  readonly entityLoader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.reportedEntities.clear();
    this.loader.reset();
  };

  @action search = (query: string) => {
    this.searchText = query;
  };

  @computed get reported(): Reported<EntityMap[T]>[] {
    const result: Reported<EntityMap[T]>[] = [];

    this.reportedEntities.forEach((item) => {
      if (hasSearch(this.searchText, item)) {
        result.push(item);
      }
    });

    return result;
  }

  @computed get canLoadMore(): boolean {
    return isText(this.after);
  }

  @action loadReported = async ({ after }: { after?: string } = {}) => {
    const { cancelled, data, error } = await this.loader.load(() =>
      getReported({
        entity: this.entity,
        limit: this.pageSize,
        after,
        ...this.sort,
      })
    );

    if (cancelled) {
      return;
    }

    const reported = adaptReported(this.entity, data);

    if (isDefined(data)) {
      const method = isDefined(after) ? 'merge' : 'replace';

      this.reportedEntities[method](
        reported.map((report) => [report.reportId, report] as [string, Reported<EntityMap[T]>])
      );

      this.after = data.paging?.cursors?.after;

      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  loadMoreReported = (): void => {
    if (isUndefined(this.after)) {
      return;
    }

    void this.loadReported({
      after: this.after,
    });
  };

  @action setSort = (sort?: Sort): void => {
    this.sort = sort ?? DEFAULT_SORT;

    void this.loadReported();
  };

  @action removeEntity(id: string): void {
    this.reportedEntities.delete(id);

    if (this.reported.length === 0) {
      void this.loadReported();
    }
  }

  @action approve = async (reportId: string) => {
    const { cancelled, success, error } = await this.entityLoader.load(() => approve(reportId));

    if (cancelled) {
      return;
    }

    if (success) {
      getStore('toast').success('Approved');
      this.removeEntity(reportId);
      this.entityLoader.ok();
    } else {
      getStore('toast').error('Approve error');
      this.entityLoader.fail(error);
    }
  };

  @action reject = async (reportId: string) => {
    const { cancelled, success, error } = await this.entityLoader.load(() => reject(reportId));

    if (cancelled) {
      return;
    }

    if (success) {
      getStore('toast').success('Banned');
      this.removeEntity(reportId);
      this.entityLoader.ok();
    } else {
      getStore('toast').error('Ban error');
      this.entityLoader.fail(error);
    }
  };
}
