import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable, observable } from 'mobx';

import { DataLoader } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { User, UserPayload, getAllUsers, stubUser, updateUserRole } from '../api';

export class UserDetailsStore extends BaseStore {
  @observable.ref user: User = stubUser();

  readonly saveRoleLoader = new DataLoader();

  readonly loader = new DataLoader();

  @action storeReset = () => {
    this.loader.reset();
    this.saveRoleLoader.reset();
    this.user = stubUser();
  };

  @action loadUser = async (userId: string) => {
    const { cancelled, data, error } = await this.loader.load(() => getAllUsers());

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.user)) {
      const user: User | undefined = data.user.find(({ id }) => id === userId);

      if (isDefined(user)) {
        this.user = user;
        this.loader.ok();
      } else {
        this.loader.fail(new Error('User not found'));
      }
    } else {
      this.loader.fail(error);
    }
  };

  @action saveRole = async (userPayload: UserPayload) => {
    const { cancelled, data, error } = await this.saveRoleLoader.load(() => updateUserRole(userPayload));

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      getStore('toast').success('User updated');
      this.saveRoleLoader.ok();
      getStore('router').replace('/b2b/users');
    } else {
      this.saveRoleLoader.fail(error);
    }
  };

  @action handleUserDeleted = () => {
    getStore('router').replace('/b2b/users');
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
