import { action, computed, makeObservable, observable } from 'mobx';
import { nanoid } from 'nanoid/non-secure';

export const enum LoadStatus {
  idle,
  pending,
  ok,
  fail,
  warning,
}

export const enum LoadStrategy {
  takeFirst,
  takeLast,
}

export interface DataLoaderOptions {
  initialStatus?: LoadStatus;
  strategy?: LoadStrategy;
}

type FetchFn<T> = () => Promise<T>;

interface LoadState {
  status: LoadStatus;
  token?: string;
  error?: unknown;
  warning?: unknown;
}

type LoadResult<T> = ({ cancelled: true } & Partial<T>) | ({ cancelled?: false } & T);

export class DataLoader {
  private readonly options: Required<DataLoaderOptions>;
  @observable.ref private state: LoadState;

  constructor(options: DataLoaderOptions = {}) {
    makeObservable(this);
    this.options = {
      initialStatus: LoadStatus.idle,
      strategy: LoadStrategy.takeLast,
      ...options,
    };
    this.state = { status: this.options.initialStatus };
  }

  get status(): LoadStatus {
    return this.state.status;
  }

  get error(): unknown {
    return this.state.error;
  }

  get warning(): unknown {
    return this.state.warning;
  }

  @computed get isIdle(): boolean {
    return this.status === LoadStatus.idle;
  }

  @computed get isPending(): boolean {
    return this.status === LoadStatus.pending;
  }

  @computed get isOk(): boolean {
    return this.status === LoadStatus.ok;
  }

  @computed get isFail(): boolean {
    return this.status === LoadStatus.fail;
  }

  @computed get isWarning(): boolean {
    return this.status === LoadStatus.warning;
  }

  @action load = async <T>(fetchFn: FetchFn<T>): Promise<LoadResult<T>> => {
    if (this.options.strategy === LoadStrategy.takeFirst && this.isPending) {
      return { cancelled: true };
    }

    const requestToken = nanoid();
    this.pending(requestToken);

    const response: T = await fetchFn();

    if (this.options.strategy === LoadStrategy.takeLast && this.state.token !== requestToken) {
      return { cancelled: true };
    }

    return response;
  };

  @action pending = (token: string): void => {
    this.state = {
      status: LoadStatus.pending,
      token,
    };
  };

  @action ok = (): void => {
    this.state = {
      ...this.state,
      status: LoadStatus.ok,
      error: undefined,
      warning: undefined,
    };
  };

  @action fail = (error: unknown): void => {
    this.state = {
      ...this.state,
      status: LoadStatus.fail,
      error,
    };
  };

  @action warn = (warning: unknown): void => {
    this.state = {
      ...this.state,
      status: LoadStatus.warning,
      warning,
    };
  };

  @action reset = (): void => {
    this.state = { status: this.options.initialStatus };
  };
}
