import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { BusinessActivationStatus } from 'b2b/businesses/common/activation-status';
import { useBusinessDetailsStoreContext } from 'b2b/businesses/details/context';
import { Table } from 'common/components/client-pagination-table';
import { Column } from 'common/components/table-view';
import { useStore } from 'common/hooks/use-store';

import { ProductActivationButton } from './activation-button';
import { BusinessProduct, BusinessProductsActivationStore } from './store';

type ColumnId = 'product' | 'activationStatus' | 'action';

const createColumns = (store: BusinessProductsActivationStore): Column<BusinessProduct, ColumnId>[] => [
  {
    id: 'product',
    title: 'Product',
    width: '100%',
    render: (product) => product.name,
  },
  {
    id: 'activationStatus',
    title: 'Activation status',
    width: '250px',
    render: (product) => <BusinessActivationStatus status={product.activationStatus} />,
  },
  {
    id: 'action',
    title: 'Action',
    hidden: store.allActivated,
    width: '150px',
    align: 'center',
    render: (product) => <ProductActivationButton product={product} store={store} />,
  },
];

export const BusinessProductsActivationTable = observer(() => {
  const businessDetailsStore = useBusinessDetailsStoreContext();
  const store = useStore(() => new BusinessProductsActivationStore(businessDetailsStore));
  const columns = useMemo(() => createColumns(store), [store]);

  return <Table data={store.products} columns={columns} rowKey="id" rowHeight={50} />;
});
