import {
  whisk_backoffice_general_v1_AuthAPI_AuthenticateUser,
  whisk_backoffice_general_v1_AuthAPI_GetAuthenticatedUser,
  whisk_backoffice_general_v1_User,
} from '@whisklabs/b2b-backoffice-protobuf';
import { Default } from '@whisklabs/grpc';

import { commonGrpc } from 'common/grpc';
import { OAUTH_INITIAL_CALLBACK_URL } from 'login/oauth';

export type User = whisk_backoffice_general_v1_User;

export const stubUser = (): User => Default(whisk_backoffice_general_v1_User);

const api = commonGrpc();

export const authenticateUser = (code: string) =>
  api(whisk_backoffice_general_v1_AuthAPI_AuthenticateUser, { code, redirectUri: OAUTH_INITIAL_CALLBACK_URL });

export const getUser = () => api(whisk_backoffice_general_v1_AuthAPI_GetAuthenticatedUser);
