import { cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { CampaignStatus } from 'b2b/campaigns/common/status';
import { CampaignDelete } from 'b2b/campaigns/delete';
import { LoadingWrapper } from 'common/components/loading-wrapper';
import { PageHeader, PageTitle } from 'common/components/page-header';
import { useStore } from 'common/hooks/use-store';
import { clsFlex, clsFlexAlignItemsCenter } from 'common/styles/layout';

import { CampaignDetailsForm } from './form';
import { CampaignDetailsStore } from './store';

interface RouteParams {
  campaignId: string;
}

export const CampaignDetailsPage = observer(() => {
  const store = useStore(() => new CampaignDetailsStore());
  const { campaign, loader, handleCampaignDeleted } = store;
  const { campaignId } = useParams<RouteParams>();

  useEffect(() => {
    void store.loadCampaign({ campaignId });
  }, [store, campaignId]);

  return (
    <LoadingWrapper loader={loader}>
      <PageHeader
        title={
          <div className={cx(clsFlex, clsFlexAlignItemsCenter)}>
            <PageTitle title={`Campaign "${campaign.name}"`} />
            <CampaignStatus campaign={campaign} />
          </div>
        }
        rightElement={<CampaignDelete campaign={campaign} onDeleted={handleCampaignDeleted} large />}
      />
      <CampaignDetailsForm store={store} />
    </LoadingWrapper>
  );
});
