import { observer } from 'mobx-react-lite';

import { useStore } from 'common/hooks/use-store';

import { FlaggedRecipeQueueStore } from './store';
import { FlaggedRecipesTable } from './table';

export const FlaggedRecipeTable = observer(() => {
  const store = useStore(() => new FlaggedRecipeQueueStore());

  return <FlaggedRecipesTable store={store} />;
});
