import { isString } from '@whisklabs/typeguards';

import { DEVTOOLS } from 'common/config';

function isAllowedUrl(url: string): boolean {
  try {
    const { hostname } = new URL(url);

    const isHttp = /^https?:\/\//i.test(url);

    const isWhisk = isHttp && (hostname.endsWith('.whisk.com') || hostname.endsWith('.whisk-dev.com'));
    const isLocal = isHttp && (hostname.endsWith('.local') || ['localhost', '127.0.0.1', '0.0.0.0'].includes(hostname));

    return isWhisk || isLocal;
  } catch {
    return false;
  }
}

export const getApiStandUrl = (
  apiStand: string | undefined,
  template: string,
  templateStands: Record<string, string>,
  devtools = DEVTOOLS
): string | undefined => {
  if (!devtools || !isString(apiStand)) {
    return undefined;
  }

  return isAllowedUrl(apiStand)
    ? apiStand
    : isString(templateStands[apiStand])
    ? template.replace('%ENV', templateStands[apiStand] ?? '')
    : undefined;
};
