import { isDefined, isFunction } from '@whisklabs/typeguards';
import { Observer, observer } from 'mobx-react-lite';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { RouteGuard, anyGuard, privateGuard, publicOnlyGuard } from './guards';

export type RouteGuardProp = 'any' | 'private' | 'publicOnly' | RouteGuard;

export interface ProtectedRouteProps extends Omit<RouteProps, 'path'> {
  path: string;
  guard?: RouteGuardProp;
}

export const ProtectedRoute = observer(
  ({ guard: guardProp = anyGuard, path, exact, component: Component, render, children }: ProtectedRouteProps) => (
    <Route
      path={path}
      exact={exact}
      render={(routeProps) => (
        <Observer>
          {() => {
            const guard: RouteGuard =
              guardProp === 'any'
                ? anyGuard
                : guardProp === 'private'
                ? privateGuard
                : guardProp === 'publicOnly'
                ? publicOnlyGuard
                : guardProp;

            const { active, redirectTo } = guard(routeProps);

            if (active) {
              return isFunction(children) ? (
                <>{children(routeProps)}</>
              ) : isDefined(children) ? (
                <>{children}</>
              ) : isDefined(render) ? (
                <>{render(routeProps)}</>
              ) : isDefined(Component) ? (
                <Component {...routeProps} />
              ) : (
                <></>
              );
            }

            return <Redirect to={redirectTo} />;
          }}
        </Observer>
      )}
    />
  )
);
