import { memo } from 'react';

import { RouteSwitch } from 'common/components/routes';

import { CampaignCreatePage } from './create';
import { CampaignDetailsPage } from './details';
import { CampaignsTablePage } from './table';

export const CampaignsPage = memo(() => (
  <RouteSwitch
    routes={[
      { path: '/', exact: true, component: CampaignsTablePage },
      { path: '/new', component: CampaignCreatePage },
      { path: '/:campaignId', component: CampaignDetailsPage },
    ]}
  />
));
