import {
  whisk_backoffice_b2c_v1_ClaimedDomainAPI_AddDomain as AddDomainAPI,
  whisk_backoffice_b2c_v1_AddDomainRequest as AddDomainRequestBE,
  whisk_backoffice_b2c_v1_ClaimedDomain as ClaimedDomain,
  whisk_backoffice_b2c_v1_ClaimedDomainAPI_DeleteDomain as DeleteDomainAPI,
  whisk_backoffice_b2c_v1_DeleteDomainRequest as DeleteDomainRequestBE,
  whisk_backoffice_b2c_v1_ClaimedDomainAPI_ListDomains as ListDomainsAPI,
  whisk_backoffice_b2c_v1_ListDomainsRequest as ListDomainsRequestBE,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { ClaimedDomain, AddDomainRequestBE, DeleteDomainRequestBE };

const api = b2cGrpc();

export const deleteDomain = (payload: DeleteDomainRequestBE) => api(DeleteDomainAPI, payload);
export const addDomain = (payload: AddDomainRequestBE) => api(AddDomainAPI, payload);
export const loadDomains = (payload: ListDomainsRequestBE) => api(ListDomainsAPI, payload);
