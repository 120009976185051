import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { DeletePopover } from 'common/components/delete-popover';
import { useStore } from 'common/hooks/use-store';

import { Campaign } from '../api';

import { CampaignDeleteStore } from './store';

interface Props {
  campaign: Campaign;
  onDeleted: (campaign: Campaign) => void;
  className?: string;
  large?: boolean;
}

export const CampaignDelete = observer(({ campaign, onDeleted, className, large = false }: Props) => {
  const { deleteCampaign, deleteLoaders } = useStore(() => new CampaignDeleteStore());
  const deleteLoader = deleteLoaders.get(campaign.id);

  const handleDeleteConfirm = useCallback(async () => {
    await deleteCampaign(campaign);
    if (deleteLoader.isOk) {
      onDeleted(campaign);
    }
  }, [deleteCampaign, campaign, onDeleted, deleteLoader]);

  return (
    <DeletePopover
      entityType="campaign"
      entityName={campaign.name}
      onConfirmDelete={handleDeleteConfirm}
      isDeleting={deleteLoader.isPending}
      large={large}
      className={className}
    />
  );
});
