import { action, makeObservable } from 'mobx';

import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { BaseStore, getStore } from 'common/stores';

import { blockUser, unblockUser } from './api';

export class BlockUserFormStore extends BaseStore {
  readonly loader = new DataLoader();

  readonly form = new FormModel({
    userId: new FieldModel('').validators(validateRequired),
  });

  constructor() {
    super();

    makeObservable(this);
  }

  @action storeReset = () => {
    this.loader.reset();
  };

  @action blockUser = async () => {
    const { success, error, data } = await this.loader.load(() => blockUser({ userId: this.form.fields.userId.value }));

    if (data?.result?.oneof !== 'ok' && success) {
      getStore('toast').warning('Please check the warning before blocking');
      this.loader.warn(data?.result);
      return;
    }

    if (success) {
      getStore('toast').success('User has been blocked');
      this.form.reset();
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action unblockUser = async () => {
    const { success, error, data } = await this.loader.load(() =>
      unblockUser({ userId: this.form.fields.userId.value })
    );

    if (data?.result?.oneof !== 'ok' && success) {
      getStore('toast').warning('Please check the warning before unblocking');
      this.loader.warn(data?.result);
      return;
    }

    if (success) {
      getStore('toast').success('User has been unblocked');
      this.form.reset();
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
