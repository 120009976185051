import {
  whisk_backoffice_users_v1_UsersAPI_AddUserToTrusted as AddUserToTrusted,
  whisk_backoffice_users_v1_AddUserToTrustedRequest as AddUserToTrustedRequest,
  whisk_backoffice_shared_v1_Cursors as Cursors,
  whisk_backoffice_users_v1_UsersAPI_DeleteUserFromTrusted as DeleteUserFromTrusted,
  whisk_backoffice_users_v1_DeleteUserFromTrustedRequest as DeleteUserFromTrustedRequest,
  whisk_backoffice_users_v1_UsersAPI_GetTrustedUsers as GetTrustedUsers,
  whisk_backoffice_users_v1_GetTrustedUsersRequest as GetTrustedUsersRequest,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { AddUserToTrustedRequest, DeleteUserFromTrustedRequest, GetTrustedUsersRequest, Cursors };

const api = b2cGrpc();

export const addUserToTrusted = (payload: AddUserToTrustedRequest) => api(AddUserToTrusted, payload);
export const deleteUserFromTrusted = (payload: DeleteUserFromTrustedRequest) => api(DeleteUserFromTrusted, payload);
export const getTrustedUsers = (payload: GetTrustedUsersRequest) => api(GetTrustedUsers, payload);
