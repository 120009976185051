import { Entries } from 'common/types/types';

export const omit = <T extends object, K extends (keyof T)[]>(obj: T, keysToOmit: K): Omit<T, K[number]> => {
  // for..in returns object keys as strings, so we need to convert keysToOmit to strings for `.has()` check to work
  const keysToOmitSet = new Set(keysToOmit.map(String));

  const result: T = { ...obj };

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && keysToOmitSet.has(key)) {
      delete result[key];
    }
  }

  return result as Omit<T, K[number]>;
};

export const values = <T extends object>(obj: T) => Object.values(obj) as T[keyof T][];

export const entries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

export const areListsEqual = <T>(listA: Iterable<T>, listB: Iterable<T>) => {
  const arrA = Array.from(listA);
  const setB = listB instanceof Set ? listB : new Set(listB);

  return arrA.length === setB.size && arrA.every((value) => setB.has(value));
};

export const once = <T extends unknown[], U, R>(fn: (this: U, ...args: T) => R) => {
  let wasCalled = false;
  let result: R;

  return function wrappedInOnce(this: U, ...args: T): R {
    if (!wasCalled) {
      result = fn.call(this, ...args);
      wasCalled = true;
    }

    return result;
  };
};
