import {
  whisk_backoffice_b2c_v1_CommunityAPI_UpdateCommunityTopics as UpdateCommunityTopics,
  whisk_backoffice_b2c_v1_UpdateCommunityTopicsRequest as UpdateCommunityTopicsRequest,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { UpdateCommunityTopicsRequest };

const api = b2cGrpc();

export const updateCommunityTopics = (payload: UpdateCommunityTopicsRequest) => api(UpdateCommunityTopics, payload);
