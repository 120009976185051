import {
  whisk_backoffice_b2c_v1_GetReportedEntitiesResponse as GetReportedEntitiesResponse,
  whisk_backoffice_b2c_v1_ModeratedEntity as ModeratedEntity,
  whisk_backoffice_b2c_v1_ModeratedEntity_CommunityConversation as ModeratedEntityItem,
  whisk_backoffice_b2c_v1_ModeratedEntity_CommunityConversationReply as ModeratedEntityItemReply,
  whisk_backoffice_b2c_v1_ModeratedEntityType as ModeratedEntityType,
  whisk_backoffice_b2c_v1_ModeratedEntity_Post as PostEntity,
  whisk_backoffice_b2c_v1_ModeratedEntity_PostReply as PostReplyEntity,
  whisk_backoffice_b2c_v1_ModeratedEntity_RecipeReview as RecipeReview,
  whisk_backoffice_b2c_v1_ModeratedEntity_RecipeReviewReply as RecipeReviewReply,
  whisk_backoffice_b2c_v1_ModerationSortBy as SortBy,
  whisk_backoffice_shared_v1_SortDirection as SortDirection,
  whisk_backoffice_b2c_v1_ModeratedEntity,
  whisk_backoffice_b2c_v1_ModerationAPI_ApproveEntity,
  whisk_backoffice_b2c_v1_ModerationAPI_BanEntity,
  whisk_backoffice_b2c_v1_ModerationAPI_GetReportedEntities,
} from '@whisklabs/b2c-backoffice-protobuf';
import { isDefined } from '@whisklabs/typeguards';

import { b2cGrpc } from 'b2c/grpc';
import { SortOrder } from 'common/types/types';

const api = b2cGrpc();

export interface GetReportedRequest {
  limit: number;
  entity: ModeratedEntityType;
  sortKey?: string;
  sortOrder?: SortOrder;
  after?: string;
}

export {
  GetReportedEntitiesResponse,
  ModeratedEntityItem,
  ModeratedEntityType,
  ModeratedEntityItemReply,
  RecipeReview,
  RecipeReviewReply,
  PostEntity,
  PostReplyEntity,
};

export interface Reported<T = EntityVal> extends Omit<ModeratedEntity, 'value' | 'type'> {
  item: T;
}

export type Entity = Exclude<whisk_backoffice_b2c_v1_ModeratedEntity['value'], undefined>['oneof'];
export type EntityVal = Exclude<
  Exclude<whisk_backoffice_b2c_v1_ModeratedEntity['value'], undefined>['value'],
  undefined
>;

export const entityMap = {
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_RECIPE_REVIEW]: 'recipeReview' as const,
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_COMMUNITY_CONVERSATION]: 'communityConversation' as const,
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_COMMUNITY_CONVERSATION_REPLY]: 'communityConversationReply' as const,
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_RECIPE_REVIEW_REPLY]: 'recipeReviewReply' as const,
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_POST]: 'post' as const,
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_POST_REPLY]: 'postReply' as const,
};

export interface EntityMap {
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_RECIPE_REVIEW]: RecipeReview;
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_COMMUNITY_CONVERSATION]: ModeratedEntityItem;
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_COMMUNITY_CONVERSATION_REPLY]: ModeratedEntityItemReply;
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_RECIPE_REVIEW_REPLY]: RecipeReviewReply;
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_POST]: PostEntity;
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_POST_REPLY]: PostReplyEntity;
}

const sortOrderMap: Record<SortOrder, SortDirection> = {
  asc: SortDirection.SORT_DIRECTION_ASC,
  desc: SortDirection.SORT_DIRECTION_DESC,
};

const sortKeyMap: Record<string, SortBy | undefined> = {
  reportedAt: SortBy.MODERATION_SORT_BY_DATE,
};

export const getReported = ({ entity, limit, after, sortKey, sortOrder }: GetReportedRequest) =>
  api(whisk_backoffice_b2c_v1_ModerationAPI_GetReportedEntities, {
    entity,
    limit,
    sortBy: isDefined(sortKey) ? sortKeyMap[sortKey] : undefined,
    sortDirection: isDefined(sortOrder) ? sortOrderMap[sortOrder] : undefined,
    cursors: isDefined(after) ? { after, before: '' } : undefined,
  });

export const approve = (id: string) => api(whisk_backoffice_b2c_v1_ModerationAPI_ApproveEntity, { id });

export const reject = (id: string) => api(whisk_backoffice_b2c_v1_ModerationAPI_BanEntity, { id });
