import { History, Location } from 'history';
import { action, computed, makeObservable, observable } from 'mobx';

import { parseQuery } from 'common/helpers/query';
import { getLocationPath } from 'common/helpers/router';

import { BaseStore } from '../base';

import { AppLocationState } from './config';
import { createHistory } from './create-history';

export class RouterStore extends BaseStore {
  readonly history: History<AppLocationState> = createHistory();
  @observable.ref location: Location<AppLocationState> = this.history.location;

  storeInit = () => {
    this.listen(
      action((location) => {
        this.location = location;
      })
    );
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  get pathname() {
    return this.location.pathname;
  }

  @computed get query() {
    return parseQuery(this.location.search);
  }

  get hash() {
    return this.location.hash;
  }

  @computed get path() {
    return getLocationPath(this.location);
  }

  @computed get prevLocation() {
    return this.location.state?.prevLocation;
  }

  push(...args: Parameters<History<AppLocationState>['push']>) {
    return this.history.push(...args);
  }

  replace(...args: Parameters<History<AppLocationState>['replace']>) {
    return this.history.replace(...args);
  }

  listen(...args: Parameters<History<AppLocationState>['listen']>) {
    return this.history.listen(...args);
  }
}
