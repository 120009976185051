import { Callout, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { PageHeader } from 'common/components/page-header';
import { FieldValidator } from 'common/helpers/form';
import { validateIfHasValue } from 'common/helpers/form/validators';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';

import { UserFormFields, UserFormStore } from '../common/user-form';

import { UserCreateStore } from './store';

// FIXME when this bug on BE is fixed https://github.com/whisklabs/atlas/blob/b8d543389abdb239da26156cd2a033f6f8cf2d5e/src/scala/whisk/business/backoffice/auth/core/AuthService.scala#L45-L47
const validateEmailDomain: FieldValidator<string> = validateIfHasValue((email) => {
  return !email.trimRight().endsWith('@samsungnext.com') || 'This domain is not supported';
});

export const UserCreatePage = observer(() => {
  const { createLoader, create } = useStore(() => new UserCreateStore());
  const formStore = useStore(() => new UserFormStore());
  const { form } = formStore;

  useEffect(() => {
    form.fields.email.validators(...form.fields.email.getValidators(), validateEmailDomain);
  }, [form]);

  return (
    <div>
      <PageHeader title="New User" />

      <Callout intent={Intent.PRIMARY} className={clsMb30}>
        <p>
          Adding users with <strong>@samsungnext.com</strong> email is not supported.
        </p>
        <p>
          Instead they should perform at least 1 login attempt, then you can change their role to{' '}
          <strong>Editor</strong>.
        </p>
      </Callout>

      <Form form={form} loader={createLoader} onSubmit={create} errorTitle="Failed to create user">
        <UserFormFields store={formStore} withEmailField />
        <ButtonSubmit kind="add" text="Create" large />
      </Form>
    </div>
  );
});
