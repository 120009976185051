import { Colors } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';

import { ErrorMessage } from 'common/components/error-message';
import { WhiskLogo } from 'common/components/whisk-logo';
import { useStore } from 'common/hooks/use-store';
import { getStore } from 'common/stores';

import { LoginStore } from './store';

const clsContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 50px 0;
`;

const clsCard = css`
  text-align: center;
`;

const clsLogo = css`
  text-align: center;
  margin-bottom: 80px;
`;

const clsButton = css`
  height: 40px;
  width: 264px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 40px;
  border: 1px solid rgba(16, 22, 26, 0.15);
  background: #fff;
  font-weight: normal;
  text-align: center;
  &:hover {
    box-shadow: none;
    background-color: ${Colors.LIGHT_GRAY5};
    color: #444444;
  }
`;

export const LoginPage = observer(() => {
  const { loginWithGoogle } = useStore(() => new LoginStore());
  const { authLoader } = getStore('user');

  return (
    <div className={clsContainer}>
      <div className={clsCard}>
        <WhiskLogo size="large" className={clsLogo} />

        {authLoader.isFail ? (
          <ErrorMessage title="Authentication failed. Please, try again." error={authLoader.error} />
        ) : null}

        <button type="button" className={clsButton} onClick={loginWithGoogle}>
          Sign in with Google
        </button>
      </div>
    </div>
  );
});
