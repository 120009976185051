import { isText } from '@whisklabs/typeguards';
import Cookies from 'js-cookie';

import { OAUTH_CODE_COOKIE } from 'common/config';

import { getOauthCookieConfig } from './config';

export const getOAuthCode = () => Cookies.get(OAUTH_CODE_COOKIE);

export const hasOAuthCode = () => isText(Cookies.get(OAUTH_CODE_COOKIE));

export const setOAuthCode = (code: string) => Cookies.set(OAUTH_CODE_COOKIE, code, getOauthCookieConfig());

export const removeOAuthCode = () => {
  const { domain, path } = getOauthCookieConfig();
  Cookies.remove(OAUTH_CODE_COOKIE, { domain, path });
};
