import {
  whisk_backoffice_b2c_v1_ApproveRecipeTextRequest as ApproveRecipeTextRequest,
  whisk_backoffice_b2c_v1_RejectRecipeTextRequest as RejectRecipeTextRequest,
  whisk_backoffice_b2c_v1_RecipeAPI_ApproveRecipeText,
  whisk_backoffice_b2c_v1_RecipeAPI_GetRecipesWithSuspiciousText,
  whisk_backoffice_b2c_v1_RecipeAPI_RejectRecipeText,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

const api = b2cGrpc();

export const getRecipesWithFlaggedTexts = () => api(whisk_backoffice_b2c_v1_RecipeAPI_GetRecipesWithSuspiciousText);

export const approveRecipeText = (payload: ApproveRecipeTextRequest) =>
  api(whisk_backoffice_b2c_v1_RecipeAPI_ApproveRecipeText, payload);

export const rejectRecipeText = (payload: RejectRecipeTextRequest) =>
  api(whisk_backoffice_b2c_v1_RecipeAPI_RejectRecipeText, payload);
