import { observer } from 'mobx-react-lite';

import { SortSel } from 'b2c/components';
import { Box } from 'common/components/box';
import { LoadingWrapper } from 'common/components/loading-wrapper';
import { Search } from 'common/components/search';
import { TablePagination } from 'common/components/table-view';

import { Row } from './row';
import { FlaggedRecipeQueueStore } from './store';

interface Props {
  store: FlaggedRecipeQueueStore;
}

export const FlaggedRecipesTable = observer(({ store }: Props) => (
  <>
    <Box alignItems="flex-start" mb={20}>
      <Box flexGrow={1}>
        <Search search={store.searchText} placeholder="Filter by any field" onChange={store.search} />
      </Box>
      <Box>
        <SortSel active={store.sort} items={store.sortItems} onSelect={store.setSort} />
      </Box>
    </Box>

    <LoadingWrapper loader={store.loader}>
      <Box display="block">
        {store.recipes.map((recipe) => (
          <Row key={recipe.entityId} recipe={recipe} store={store} />
        ))}
      </Box>
    </LoadingWrapper>

    <TablePagination
      page={store.page}
      pageSize={store.pageSize}
      totalItems={store.total}
      onPageChange={store.pageChange}
      loading={store.loader.isPending}
    />
  </>
));
