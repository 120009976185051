import { History } from 'history';
import { RouteComponentProps } from 'react-router-dom';

import { getStore } from 'common/stores';
import { AppLocationState } from 'common/stores/router';
import { AuthStatus } from 'common/stores/user';

export type RouteGuard = (routeProps: RouteComponentProps) => {
  active: boolean;
  redirectTo: History.LocationDescriptor<AppLocationState>;
};

export const anyGuard: RouteGuard = () => ({
  active: true,
  redirectTo: '',
});

export const privateGuard: RouteGuard = () => ({
  active: getStore('user').authStatus === AuthStatus.loggedIn,
  redirectTo: { pathname: '/login', state: { nextLocationAfterLogin: getStore('router').location } },
});

export const publicOnlyGuard: RouteGuard = () => ({
  active: getStore('user').authStatus !== AuthStatus.loggedIn,
  redirectTo: getStore('router').location.state?.nextLocationAfterLogin ?? '/',
});
