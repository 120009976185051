import { observer } from 'mobx-react-lite';

import { useBusinessDetailsStoreContext } from '../context';

import { BusinessActivation } from './business-activation';
import { BusinessProductsActivationTable } from './products-activation-table';

export const BusinessActivationsPage = observer(() => {
  const { businessActive } = useBusinessDetailsStoreContext();

  return businessActive ? <BusinessProductsActivationTable /> : <BusinessActivation />;
});
