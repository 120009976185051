import { IconName } from '@blueprintjs/core';
import { isPresent } from '@whisklabs/typeguards';
import { History } from 'history';
import { css, cx } from 'linaria';
import { ComponentType } from 'react';

import { RouteGuardProp, RouteSwitch } from 'common/components/routes';
import { AppLocationState } from 'common/stores/router';

import { SidebarItem } from './sidebar-item';

const clsDashboard = css`
  display: flex;
  width: 100%;
  min-height: 100%;
`;

const clsSidebar = css`
  margin: 0;
  padding: 40px 8px;
  width: 160px;
  flex: 0 0 auto;

  @media (min-width: 960px) {
    padding: 40px 16px;
    width: 260px;
  }
`;

const clsMain = css`
  flex: 1 1 auto;
  padding: 30px 16px 100px;
  box-shadow: 0px 3px 12px rgba(79, 79, 106, 0.1);
  background: #fff;
`;

interface DashboardItem {
  path: string;
  label: string;
  icon: IconName;
  component: ComponentType;
  guard?: RouteGuardProp;
}

interface Props {
  defaultGuard?: RouteGuardProp;
  defaultPath?: History.LocationDescriptor<AppLocationState>;
  className?: string;
  items: (DashboardItem | null | undefined)[];
}

export const DashboardLayout = ({ items: itemsProp, defaultGuard, defaultPath, className }: Props) => {
  const items = itemsProp.filter(isPresent);

  return (
    <div className={cx(clsDashboard, className)}>
      <div className={clsSidebar}>
        {items.map((item) => (
          <SidebarItem key={item.path} to={item.path} label={item.label} icon={item.icon} />
        ))}
      </div>
      <main className={clsMain}>
        <RouteSwitch routes={items} defaultGuard={defaultGuard} defaultPath={defaultPath} />
      </main>
    </div>
  );
};
