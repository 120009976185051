import { action, makeObservable } from 'mobx';

import {
  GrpcToWidgetTypeEnum,
  Widget,
  WidgetConfig,
  WidgetFormPayload,
  WidgetType,
  WidgetTypeGrpc,
  WidgetTypeToGrpcEnum,
} from 'b2b/businesses/details/widgets/api';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { BaseStore } from 'common/stores';

export class WidgetFormStore extends BaseStore {
  readonly form = new FormModel({
    name: new FieldModel('').validators(validateRequired),
    trackingId: new FieldModel(''),
    type: new FieldModel<string>(WidgetType.B2B_SHOPPING_LIST).disabled(true),
    // TODO add JSON validation or more complex component in the future
    config: new FieldModel('{}').disabled(true),
  });

  @action fill = (widget: Widget): void => {
    this.form.reset({
      name: widget.name,
      trackingId: widget.trackingId ?? '',
      type: GrpcToWidgetTypeEnum[widget.type ?? WidgetTypeGrpc.WIDGET_TYPE_B2C_COMMUNITY],
      config: JSON.stringify(widget.config),
    });

    this.form.fields.type.readonly(true);
    this.form.fields.config.readonly(true);
  };

  @action getData = (): WidgetFormPayload => {
    const { type, config, ...restValues } = this.form.values;

    return {
      ...restValues,
      type: WidgetTypeToGrpcEnum[type as WidgetType],
      config: JSON.parse(config) as WidgetConfig,
    };
  };

  @action storeReset = () => {
    this.form.reset();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
