import { Button, ButtonGroup, InputGroup } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';

import { Box } from 'common/components/box';
import { Card } from 'common/components/card';

import { DomainEntity } from './domain-entity';
import { NoIndexingDomainsStore } from './store';

const clsCard = css`
  width: 100%;
  && {
    padding: 12px;
  }
`;

const clsActions = css`
  margin-left: auto;
`;

interface Props {
  store: NoIndexingDomainsStore;
  domain: DomainEntity;
}

export const Row = observer<Props>(({ domain, store }) => {
  const [isEdit, setIsEdit] = useState(false);
  const handleSave = useCallback(async () => {
    await domain.editDomain();
    setIsEdit(false);
  }, [domain]);

  const handleDelete = useCallback(async () => {
    await domain.removeDomain();
    store.removeFromList(domain.id);
  }, [domain, store]);

  return (
    <Box mb={12}>
      <Card className={clsCard}>
        <Box alignItems="center">
          {isEdit ? (
            <InputGroup value={domain.value} large onChange={(ev) => domain.setValue(ev.currentTarget.value)} />
          ) : (
            <code className="bp4-code-block">{domain.value}</code>
          )}

          <ButtonGroup className={clsActions} large>
            {!isEdit ? (
              <Button
                icon="edit"
                disabled={domain.removeLoader.isPending}
                text="Edit"
                onClick={() => setIsEdit(true)}
              />
            ) : (
              <Button
                icon="tick"
                disabled={domain.removeLoader.isPending}
                loading={domain.editLoader.isPending}
                text="Save"
                onClick={handleSave}
              />
            )}
            <Button
              icon="trash"
              disabled={domain.editLoader.isPending}
              loading={domain.removeLoader.isPending}
              onClick={handleDelete}
              text="Delete"
            />
          </ButtonGroup>
        </Box>
      </Card>
    </Box>
  );
});
