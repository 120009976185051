import { css } from 'linaria';

import { Box } from 'common/components/box';

const clsTime = css`
  color: #555;
  font-size: 80%;
  font-weight: 700;
`;

interface Props {
  time: string;
}

export const Time: React.FC<Props> = ({ time }) => <Box className={clsTime}>{time}</Box>;
