import { GError } from '@whisklabs/grpc';
import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { Business, GetBusinessRequest, getBusiness, stubBusiness } from 'b2b/businesses/api';
import { BusinessActivationStatusType } from 'b2b/businesses/common/activation-status';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

export class BusinessDetailsStore extends BaseStore {
  @observable.ref business: Business = stubBusiness();
  @observable.ref error?: GError['error'];

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.loader.reset();
    this.business = stubBusiness();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get businessActive() {
    return this.isStatusActive(this.business.activationStatus);
  }

  isStatusActive = (status?: BusinessActivationStatusType) =>
    status === BusinessActivationStatusType.ACTIVATION_STATUS_ACCEPTED;

  @action loadBusiness = async (payload: GetBusinessRequest) => {
    const { cancelled, data, error } = await this.loader.load(() => getBusiness(payload));

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.value)) {
      this.setBusiness(data.value);
      this.loader.ok();
    } else {
      this.setError(error);
      this.loader.fail(error);
    }
  };

  @action setBusiness = (business?: Business) => {
    this.business = isDefined(business) ? business : stubBusiness();
    this.error = undefined;
  };

  @action setError = (error?: GError['error']) => {
    this.error = error;
    this.business = stubBusiness();
  };
}
