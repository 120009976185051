import { isPresent } from '@whisklabs/typeguards';
import { memo } from 'react';

import { SortComparator, orderCompareAsc } from 'common/helpers/sort';

import { BusinessUserRoleType } from './api';

export const businessUserRoleLabels: Record<BusinessUserRoleType, string> = {
  [BusinessUserRoleType.ROLE_OWNER]: 'Owner',
  [BusinessUserRoleType.ROLE_ADMIN]: 'Admin',
  [BusinessUserRoleType.ROLE_EDITOR]: 'Editor',
  [BusinessUserRoleType.ROLE_VIEW_ONLY]: 'View only',
  [BusinessUserRoleType.ROLE_CREATOR]: 'Creator',
};

export const businessUserRolesCompareAsc: SortComparator<BusinessUserRoleType | undefined> = (
  a = BusinessUserRoleType.ROLE_VIEW_ONLY,
  b = BusinessUserRoleType.ROLE_VIEW_ONLY
) => {
  return orderCompareAsc([
    BusinessUserRoleType.ROLE_OWNER,
    BusinessUserRoleType.ROLE_ADMIN,
    BusinessUserRoleType.ROLE_CREATOR,
    BusinessUserRoleType.ROLE_EDITOR,
    BusinessUserRoleType.ROLE_VIEW_ONLY,
  ])(a, b);
};

export const businessUserRoleSearchKey = (role?: BusinessUserRoleType) =>
  isPresent(role) ? businessUserRoleLabels[role] : '';

interface Props {
  defaultRole?: BusinessUserRoleType;
  role?: BusinessUserRoleType;
}

export const BusinessUserRole = memo(
  ({ defaultRole = BusinessUserRoleType.ROLE_VIEW_ONLY, role = defaultRole }: Props) => {
    const label = businessUserRoleLabels[role];

    return <span>{label}</span>;
  }
);
