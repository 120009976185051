import { css } from 'linaria';
import { observer } from 'mobx-react-lite';

import { LoadingWrapper } from 'common/components/loading-wrapper';

import { InfiniteScroll, Props as InfiniteScrollProps } from '../infinite-scroll';
import { ColumnId, NoData, TableBody, TableHead, TableLayout, TableProps } from '../table-view';

type Props<T, U extends ColumnId> = TableProps<T, U> & InfiniteScrollProps;

const clsInfinite = css`
  overflow: initial;
`;

export const Table = observer(
  <T, U extends ColumnId>({
    loader,
    rowKey,
    rowHeight,
    renderRow,
    data,
    cellPaddingVertical,
    cellPaddingHorizontal,
    columns,
    tableClassName,
    layout,
    ...infiniteScrollProps
  }: Props<T, U>) => {
    return (
      <div>
        <LoadingWrapper loader={loader} pending={loader?.isPending && data.length === 0}>
          {data.length > 0 ? (
            <InfiniteScroll className={clsInfinite} {...infiniteScrollProps}>
              <TableLayout className={tableClassName} layout={layout}>
                <TableHead
                  data={data}
                  columns={columns}
                  paddingVertical={cellPaddingVertical}
                  paddingHorizontal={cellPaddingHorizontal}
                />

                <TableBody
                  data={data}
                  columns={columns}
                  rowKey={rowKey}
                  rowHeight={rowHeight}
                  renderRow={renderRow}
                  paddingVertical={cellPaddingVertical}
                  paddingHorizontal={cellPaddingHorizontal}
                />
              </TableLayout>
            </InfiniteScroll>
          ) : (
            <NoData />
          )}
        </LoadingWrapper>
      </div>
    );
  }
);
