import { memo } from 'react';

import { RouteSwitch } from 'common/components/routes';

import { WidgetCreatePage } from './create';
import { WidgetDetailsPage } from './details';
import { WidgetsTablePage } from './table';

export const BusinessWidgetsPage = memo(() => (
  <RouteSwitch
    routes={[
      { path: '/', exact: true, component: WidgetsTablePage },
      { path: '/new', component: WidgetCreatePage },
      { path: '/:widgetId', component: WidgetDetailsPage },
    ]}
  />
));
