import { css } from 'linaria';
import { matchSorter } from 'match-sorter';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { profilePath } from 'b2c/helpers/routes';
import { Table } from 'common/components/client-pagination-table';
import { NonEmptyString } from 'common/components/non-empty-string';
import { Column } from 'common/components/table-view';
import { createSearchFunction } from 'common/helpers/search';
import { stringCompareAsc } from 'common/helpers/sort';
import { clsLink } from 'common/styles/link';

import { Actions } from './actions';
import { TrustedUsersListStore } from './store';
import { UserType } from './types';

const clsTable = css`
  word-break: break-all;
`;

type ColumnId = 'userId' | 'username' | 'actions';

const searchFunction = createSearchFunction<UserType>(
  [(user) => user.userId, { key: (user) => [user.userId, user.username] }],
  { threshold: matchSorter.rankings.CONTAINS }
);

interface Props {
  store: TrustedUsersListStore;
}

export const UserListTable = observer(({ store }: Props) => {
  const columns = useMemo(
    (): Column<UserType, ColumnId>[] => [
      {
        id: 'userId',
        title: 'User Id',
        width: '344px',
        sorter: (a, b) => stringCompareAsc(a.userId, b.userId),
        render: (recipe) => (
          <a className={clsLink} href={profilePath(recipe.userId)} rel="noreferrer" target="_blank">
            <NonEmptyString value={recipe.userId} />
          </a>
        ),
      },
      {
        id: 'username',
        title: 'User Name',
        sorter: (a, b) => stringCompareAsc(a.username, b.username),
        render: (recipe) => <NonEmptyString value={recipe.username} />,
      },
      {
        id: 'actions',
        width: '140px',
        title: 'Actions',
        align: 'center',
        render: (user) => <Actions user={user} onReject={store.rejectRecipeText} />,
      },
    ],
    [store.rejectRecipeText]
  );

  return (
    <Table
      loader={store.loader}
      tableClassName={clsTable}
      cellPaddingVertical={10}
      data={store.listUsers}
      withUnstableHeight
      columns={columns}
      rowKey="userId"
      searchFunction={searchFunction}
    />
  );
});
