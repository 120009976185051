import { useEffect } from 'react';

import { AppInfoFormFields, AppInfoFormFieldsStore } from 'b2b/apps/common/info-form';
import { AppCreateStore } from 'b2b/apps/create/store';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';
import { getStore } from 'common/stores';

interface Props {
  store: AppCreateStore;
}

export const AppCreateForm = ({ store }: Props) => {
  const { create, createLoader } = store;
  const formStore = useStore(() => new AppInfoFormFieldsStore());
  const { form } = formStore;
  const { businessId } = getStore('router').query;

  useEffect(() => {
    form.fields.businessId.reset(businessId ?? '');
  }, [form, businessId]);

  return (
    <Form form={form} onSubmit={create} loader={createLoader} errorTitle="Failed to create app">
      <AppInfoFormFields store={formStore} />
      <ButtonSubmit kind="add" text="Create" large />
    </Form>
  );
};
