import { useEffect, useState } from 'react';
import shallowEqual from 'shallowequal';

import { BaseStore } from 'common/stores';

export function useStore<T extends BaseStore>(storeFactory: () => T, deps: unknown[] = []): T {
  const getInstance = (): T => {
    const instance = storeFactory();
    instance.storeInit();
    return instance;
  };

  const [store, setStore] = useState(getInstance);
  const [prevDeps, setPrevDeps] = useState<unknown[]>(deps);

  if (!shallowEqual(deps, prevDeps)) {
    setPrevDeps(deps);
    setStore(getInstance);
  }

  useEffect(
    () => () => {
      store.storeDestroy();
    },
    [store]
  );

  return store;
}
