import { Classes, FormGroup, IInputGroupProps2, InputGroup } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { observer } from 'mobx-react-lite';

import { useFormContext } from 'common/components/form/context';
import { clsField } from 'common/components/form/styles';
import { FieldModel } from 'common/helpers/form';
import { useRandomId } from 'common/hooks/use-random-id';
import { clsNoMargin } from 'common/styles/margin-padding';
import { FormGroupProps } from 'common/types/blueprint';

import { getFieldFormGroupProps, getFieldInputProps } from '../form/helpers';

interface Props extends IInputGroupProps2 {
  field?: FieldModel<string>;
  formId?: string;
  label?: FormGroupProps['label'];
  labelInfo?: FormGroupProps['labelInfo'];
  helperText?: FormGroupProps['helperText'];
  inline?: FormGroupProps['inline'];
  loading?: boolean;
  className?: string;
  contentClassName?: string;
  inputClassName?: string;
  noMargin?: boolean;
}

export const FieldInput = observer(
  ({
    field,
    id,
    formId,
    label,
    loading: loadingProp,
    disabled,
    readOnly,
    className,
    contentClassName,
    inputClassName,
    intent,
    helperText,
    labelInfo,
    autoComplete = 'off',
    autoCapitalize = 'off',
    autoCorrect = 'off',
    spellCheck = false,
    large = true,
    inline = false,
    noMargin = false,
    ...inputProps
  }: Props) => {
    const { loader } = useFormContext();
    const loading = loadingProp ?? loader?.isPending;
    const randomId = useRandomId();
    const inputId = id ?? field?.id ?? randomId;

    const formGroupProps = isDefined(field) ? getFieldFormGroupProps(field) : undefined;
    const fieldInputProps: IInputGroupProps2 = isDefined(field) ? getFieldInputProps(field) : {};

    return (
      <FormGroup
        labelFor={inputId}
        labelInfo={labelInfo ?? formGroupProps?.labelInfo}
        label={label}
        helperText={helperText ?? formGroupProps?.helperText}
        intent={intent ?? formGroupProps?.intent}
        contentClassName={contentClassName}
        disabled={(formGroupProps?.disabled ?? disabled) || loading}
        inline={inline}
        className={cx(
          formGroupProps?.className ?? clsField,
          large ? Classes.LARGE : undefined,
          noMargin ? clsNoMargin : undefined,
          className
        )}
      >
        <InputGroup
          {...fieldInputProps}
          id={inputId}
          form={formId}
          autoComplete={autoComplete}
          autoCapitalize={autoCapitalize}
          autoCorrect={autoCorrect}
          spellCheck={spellCheck}
          className={inputClassName}
          large={large}
          intent={intent}
          disabled={(disabled ?? fieldInputProps.disabled) || loading}
          readOnly={readOnly ?? fieldInputProps.readOnly}
          {...inputProps}
        />
      </FormGroup>
    );
  }
);
