import { observer } from 'mobx-react-lite';

import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';

import { ApproveRecipeFormStore } from './store';

export const ApproveRecipeForm = observer(() => {
  const { approveLoader, approveRecipe, form } = useStore(() => new ApproveRecipeFormStore());

  return (
    <Card>
      <Form form={form} loader={approveLoader} onSubmit={approveRecipe} errorTitle="Failed to approve recipe">
        <div className={clsMb30}>
          <FieldInput
            field={form.fields.recipeId}
            label="Recipe ID"
            placeholder="e.g. 101304934920773f60c22edda1eb1e4ae3ec3761fac"
          />
        </div>
        <ButtonSubmit text="Approve" large />
      </Form>
    </Card>
  );
});
