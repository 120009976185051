import { action, makeObservable, reaction } from 'mobx';

import { CommunityStore } from 'b2c/community';
import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { BaseStore } from 'common/stores/base';

import { UpdateCommunityTopicsRequest, updateCommunityTopics } from './api';

export class UpdateCommunityTopicsFormStore extends BaseStore {
  readonly updateLoader = new DataLoader();

  readonly form = new FormModel({
    topicIds: new FieldModel<string[]>([]).validators(validateRequired),
  });

  constructor(private readonly communityStore: CommunityStore) {
    super();
    makeObservable(this);
  }

  get community() {
    return this.communityStore.community;
  }

  @action storeInit = () => {
    this.sub(
      reaction(
        () => this.community,
        (community) => {
          this.form.fields.topicIds.reset(community.topicIds);
        },
        { fireImmediately: true }
      )
    );
  };

  @action storeReset = () => {
    this.updateLoader.reset();
    this.form.reset();
  };

  @action updateCommunityTopics = async (payload: Omit<UpdateCommunityTopicsRequest, 'communityId'>) => {
    const { success, error } = await this.updateLoader.load(() =>
      updateCommunityTopics({
        communityId: this.community.id,
        ...payload,
      })
    );

    if (success) {
      this.form.reset();
      this.updateLoader.ok();
    } else {
      this.updateLoader.fail(error);
    }
  };
}
