import { Button } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { memo, useCallback, useEffect, useMemo } from 'react';

import { clsFlex, clsFlexAlignItemsCenter, clsFlexJustifyBetween } from 'common/styles/layout';
import { clsMt30 } from 'common/styles/margin-padding';

import { TablePaginationProps } from '../types';

import { PAGE_SKIP } from './constants';
import { getPager, getTotalPages } from './helpers';

const clsCurPage = css`
  border: 1px solid #4db690 !important;
  color: #4db690 !important;
`;

const clsPage = css`
  padding: 0 2px !important;
  margin-right: 4px;
  &:last-child {
    margin-right: 0;
  }
`;

const clsSkip = css`
  cursor: default !important;
`;

const clsPager = css`
  display: inline-block;
  vertical-align: middle;
  margin: 0 4px;
`;

const clsNavArrow = css`
  &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    margin-left: 10px;
  }
`;

export const TablePagination = memo(
  ({ totalItems, onPageChange, page, pageSize, loading = false }: TablePaginationProps) => {
    const totalPages = useMemo(() => Math.max(1, getTotalPages(totalItems, pageSize)), [totalItems, pageSize]);
    const pager = getPager(page, totalPages);

    useEffect(() => {
      if (page > totalPages) {
        onPageChange(totalPages);
      }
    }, [page, totalPages, onPageChange]);

    const handlePrev = useCallback(() => {
      onPageChange(page - 1);
    }, [onPageChange, page]);

    const handleNext = useCallback(() => {
      onPageChange(page + 1);
    }, [onPageChange, page]);

    if (pager.length <= 1) {
      return null;
    }

    const fromItem = (page - 1) * pageSize + 1;
    const toItem = Math.min(totalItems, fromItem + pageSize - 1);

    return (
      <div className={cx(clsFlex, clsFlexAlignItemsCenter, clsFlexJustifyBetween, clsMt30)}>
        <div>
          Showing {fromItem} - {toItem} of {totalItems}
        </div>

        <div>
          <Button onClick={handlePrev} disabled={page === 1} large>
            <span className={clsNavArrow}>←</span>
            <span>Previous</span>
          </Button>
          <div className={clsPager}>
            {pager.map((pageNum, index) =>
              pageNum === PAGE_SKIP ? (
                <Button key={`skip-${index}`} text="..." className={cx(clsSkip, clsPage)} disabled minimal large />
              ) : (
                <Button
                  key={pageNum}
                  text={pageNum}
                  onClick={() => onPageChange(pageNum)}
                  className={cx(pageNum === page ? clsCurPage : undefined, clsPage)}
                  loading={loading && pageNum === page}
                  large
                />
              )
            )}
          </div>
          <Button onClick={handleNext} disabled={page === totalPages} large>
            <span>Next</span>
            <span className={clsNavArrow}>→</span>
          </Button>
        </div>
      </div>
    );
  }
);
