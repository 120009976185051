import { observer } from 'mobx-react-lite';

import { LinkButton } from 'common/components/links/link-button';

import { InviteUserDialog } from './dialog';
import { InviteUserStore } from './store';

interface Props {
  store: InviteUserStore;
  onInvited?: () => void;
}

export const InviteUser = observer(({ store, onInvited }: Props) => {
  return (
    <>
      <InviteUserDialog store={store} onInvited={onInvited} />
      <LinkButton onClick={store.dialog.open} text="Invite user" />
    </>
  );
});
