import { isPresent } from '@whisklabs/typeguards';
import { render } from 'react-dom';

import { AppPage } from 'app';

function bootstrap() {
  const div = document.getElementById('root');

  if (isPresent(div)) {
    render(<AppPage />, div);
  }
}

void bootstrap();
