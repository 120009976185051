import { Button, Classes, Dialog, Radio, RadioGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { businessUserRoleLabels } from 'b2b/businesses/details/common/user-role';
import { BusinessUserRoleType } from 'b2b/businesses/details/common/user-role/api';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { clsMb30 } from 'common/styles/margin-padding';

import { InviteUserPayload, InviteUserStore } from './store';

interface Props {
  store: InviteUserStore;
  onInvited?: () => void;
}

export const InviteUserDialog = observer(({ store, onInvited }: Props) => {
  const { dialog, inviteLoader, inviteUser, form, availableRoles, storeReset } = store;

  const handleInviteUser = useCallback(
    async (payload: InviteUserPayload) => {
      await inviteUser(payload);
      if (inviteLoader.isOk) {
        onInvited?.();
        storeReset();
      }
    },
    [inviteUser, inviteLoader, onInvited, storeReset]
  );

  return (
    <Dialog title="Invite user" isOpen={dialog.isOpen} onClose={dialog.close}>
      <Form form={form} loader={inviteLoader} onSubmit={handleInviteUser}>
        <div className={Classes.DIALOG_BODY}>
          <FieldInput field={form.fields.email} type="email" label="Email" className={clsMb30} />
          <RadioGroup
            label="User role"
            onChange={(event) =>
              form.fields.role.handleValueChange(Number(event.currentTarget.value) as BusinessUserRoleType)
            }
            selectedValue={form.values.role}
          >
            {availableRoles.map((role) => (
              <Radio key={role} label={businessUserRoleLabels[role]} value={role} />
            ))}
          </RadioGroup>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={dialog.close} text="Cancel" large />
            <ButtonSubmit text="Invite" large />
          </div>
        </div>
      </Form>
    </Dialog>
  );
});
