import { AnchorButton } from '@blueprintjs/core';
import { isString, isText } from '@whisklabs/typeguards';
import { css } from 'linaria';

const clsAnchor = css`
  margin-left: 4px;

  && {
    text-align: left;
  }
`;

interface Props {
  title?: string;
  link?: string;
  small?: boolean;
}

export const Link: React.FC<Props> = ({ title, link, small }) =>
  isString(link) && isText(title) ? (
    <AnchorButton
      text={title.trim()}
      rightIcon="share"
      href={link}
      small={small}
      rel="noreferrer"
      target="_blank"
      className={clsAnchor}
    />
  ) : null;
