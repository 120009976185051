import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';

import { BusinessAccountFormFields, BusinessAccountFormStore } from 'b2b/businesses/common/account-form';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';

import { useBusinessDetailsStoreContext } from '../context';

import { BusinessAccountStore } from './store';

export const BusinessAccountPage = observer(() => {
  const businessDetailsStore = useBusinessDetailsStoreContext();
  const { business, updateBusiness, updateLoader } = useStore(() => new BusinessAccountStore(businessDetailsStore));
  const formStore = useStore(() => new BusinessAccountFormStore());

  useEffect(() => {
    formStore.fill(business);
  }, [business, formStore]);

  const handleSubmit = useCallback(async () => await updateBusiness(formStore.getData()), [updateBusiness, formStore]);

  return (
    <div>
      <Form
        form={formStore.form}
        loader={updateLoader}
        onSubmit={handleSubmit}
        errorTitle="Failed to update business"
        fill
      >
        <BusinessAccountFormFields store={formStore} />
        <ButtonSubmit kind="save" text="Save" large />
      </Form>
    </div>
  );
});
