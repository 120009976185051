import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { App } from 'b2b/apps/api';
import { AppDetailsStore } from 'b2b/apps/details/store';
import { textOrFallback } from 'common/helpers/data';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { AppPublisher, getAppPublishers } from './api';

export class AppPublishersStore extends BaseStore {
  readonly appPublisherById = observable.map<string, AppPublisher>([], { deep: false });

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  constructor(private readonly appDetailsStore: AppDetailsStore) {
    super();
    makeObservable(this);
  }

  @action storeReset = () => {
    this.appPublisherById.clear();
    this.loader.reset();
  };

  get app(): App {
    return this.appDetailsStore.app;
  }

  @computed get appPublishers(): AppPublisher[] {
    return Array.from(this.appPublisherById.values(), (publisher) => ({
      ...publisher,
      name: textOrFallback(publisher.name, 'Publisher ""'),
    }));
  }

  @action loadAppPublishers = async () => {
    const { cancelled, data, error } = await this.loader.load(() => getAppPublishers({ appId: this.app.appId }));

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.appPublisherById.replace(data.appPublishers.map((appPublisher) => [appPublisher.id, appPublisher]));
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
