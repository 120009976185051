import { isPresent } from '@whisklabs/typeguards';
import { cx } from 'linaria';

import { DEFAULT_PADDING_HORISONTAL, DEFAULT_PADDING_VERTICAL } from '../../constants';
import { ColumnId, TableCellProps } from '../../types';

import { cellClasses, clsTd } from './styles';

export const TableCell = <T, U extends ColumnId>({
  column,
  rowHeight,
  paddingVertical = DEFAULT_PADDING_VERTICAL,
  paddingHorizontal = DEFAULT_PADDING_HORISONTAL,
  children,
}: TableCellProps<T, U>) => {
  if (!isPresent(column)) {
    return null;
  }

  return (
    <div
      style={{
        height: rowHeight,
        minWidth: column.width ?? column.maxWidth,
        width: column.width ?? column.maxWidth,
        maxWidth: column.maxWidth,
        paddingTop: column.paddingVertical ?? paddingVertical,
        paddingBottom: column.paddingVertical ?? paddingVertical,
        paddingLeft: column.paddingHorizontal ?? paddingHorizontal,
        paddingRight: column.paddingHorizontal ?? paddingHorizontal,
      }}
      className={cx(clsTd, cellClasses[column.align ?? 'left'], cellClasses[column.vAlign ?? 'middle'])}
    >
      {children}
    </div>
  );
};
