import { isDefined } from '@whisklabs/typeguards';

import { GoogleTimestamp, fromTimestampToMillis } from 'common/helpers/grpc';

export type SortComparator<T> = (a: T, b: T) => number;

// eslint-disable-next-line @typescript-eslint/unbound-method
export const stringCompareAsc: SortComparator<string> = new Intl.Collator(undefined, { numeric: true }).compare;

// first true, then false
export const boolCompareAsc: SortComparator<boolean> = (a, b) => Number(b) - Number(a);

export const orderCompareAsc =
  <T>(list: T[]): SortComparator<T> =>
  (a, b) => {
    const aIndex = list.indexOf(a);
    const bIndex = list.indexOf(b);

    if (aIndex === bIndex) return 0;
    if (aIndex < 0) return 1;
    if (bIndex < 0) return -1;

    return aIndex - bIndex < 0 ? -1 : 1;
  };

export const googleTimestampCompareAsc: SortComparator<GoogleTimestamp | undefined> = (a, b) => {
  if (a === b) return 0;
  if (!isDefined(a)) return 1;
  if (!isDefined(b)) return -1;

  const result = fromTimestampToMillis(a) - fromTimestampToMillis(b);

  return result === 0 ? 0 : result < 0 ? -1 : 1;
};
