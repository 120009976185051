import dayjs from 'dayjs';

export const changeTimezone = (date: dayjs.ConfigType, fromTz?: string, toTz?: string): Date =>
  dayjs.tz(date, fromTz).tz(toTz, true).toDate();

export const toUTC = (date: dayjs.ConfigType): Date => changeTimezone(date, undefined, 'UTC');

export const fromUTC = (date: dayjs.ConfigType): Date => changeTimezone(date, 'UTC');

export const formatDate = (date: dayjs.ConfigType, format: string): string => dayjs(date).format(format);

export const parseDate = (dateString: string, format: dayjs.OptionType, locale?: string, strict = true): Date =>
  dayjs(dateString, format, locale, strict).toDate();
