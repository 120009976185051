import { action, makeObservable } from 'mobx';

import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { BaseStore, getStore } from 'common/stores';

import { DeleteUserRequest, deleteUser } from './api';

export class DeleteUserFormStore extends BaseStore {
  readonly loader = new DataLoader();

  readonly form = new FormModel({
    userId: new FieldModel('').validators(validateRequired),
  });

  @action storeReset = () => {
    this.loader.reset();
  };

  @action deleteUser = async (payload: DeleteUserRequest) => {
    const { success, error, data } = await this.loader.load(() => deleteUser(payload));

    if (data?.result?.oneof !== 'ok' && success) {
      getStore('toast').warning('Please check the warning before deleting');
      this.loader.warn(data?.result);
      return;
    }

    if (success) {
      getStore('toast').success('User has been deleted');
      this.form.reset();
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
