import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { textOrFallback } from 'common/helpers/data';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { CommunityTopic, getTopics } from './api';

export class CommunityTopicsStore extends BaseStore {
  private readonly topicById = observable.map<string, CommunityTopic>([], { deep: false });

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.topicById.clear();
    this.loader.reset();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get topics(): CommunityTopic[] {
    return Array.from(this.topicById.values(), (topic) => ({
      ...topic,
      name: textOrFallback(topic.name, 'Topic ""'),
    }));
  }

  @action loadTopics = async () => {
    const { cancelled, data, error } = await this.loader.load(() => getTopics());

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.topicById.replace(data.topics.map((topic) => [topic.id, topic]));
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
