import { observer } from 'mobx-react-lite';

import { useAppDetailsStoreContext } from 'b2b/apps/details/context';
import { RetailersStore } from 'b2b/retailers';
import { useStore } from 'common/hooks/use-store';

import { AppRetailersStore } from './store';
import { AppRetailersTable } from './table';

export const AppRetailersPage = observer(() => {
  const appDetailsStore = useAppDetailsStoreContext();
  const retailersStore = useStore(() => new RetailersStore());
  const store = useStore(() => new AppRetailersStore(appDetailsStore, retailersStore));

  return <AppRetailersTable store={store} retailersStore={retailersStore} />;
});
