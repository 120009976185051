import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { textOrFallback } from 'common/helpers/data';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { Campaign, GetCampaignsRequest, getCampaigns } from '../api';

export class CampaignsTableStore extends BaseStore {
  private readonly campaignById = observable.map<string, Campaign>([], { deep: false });

  @observable totalItems = 0;

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @action storeReset() {
    this.loader.reset();
    this.campaignById.clear();
  }

  @computed get campaigns(): Campaign[] {
    return Array.from(this.campaignById.values(), (campaign) => ({
      ...campaign,
      name: textOrFallback(campaign.name, 'Campaign ""'),
    }));
  }

  @action loadCampaigns = async (payload: GetCampaignsRequest) => {
    const { cancelled, data, error } = await this.loader.load(() => getCampaigns(payload));

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.campaignById.replace(data.campaigns.map((campaign) => [campaign.id, campaign]));
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action handleCampaignDeleted = (campaign: Campaign) => {
    this.campaignById.delete(campaign.id);
    this.totalItems -= 1;
  };
}
