import {
  whisk_backoffice_business_v1_BusinessUsersAPI_GetAllInvitations as GetInvitations,
  whisk_backoffice_business_v1_GetAllInvitationsRequest as GetInvitationsRequest,
  whisk_backoffice_business_v1_Invitation as UserInvitation,
} from '@whisklabs/b2b-backoffice-protobuf';

import { b2bGrpc } from 'b2b/grpc';

export { UserInvitation, GetInvitationsRequest };

const api = b2bGrpc();

export const getInvitations = (payload: GetInvitationsRequest) => api(GetInvitations, payload);
