import { observer } from 'mobx-react-lite';

import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';

import { UserRemoveFormStore } from './store';

export const UserForm = observer(() => {
  const { form, loader, sumbitForm } = useStore(() => new UserRemoveFormStore());

  return (
    <Card>
      <Form form={form} loader={loader} onSubmit={sumbitForm} errorTitle="Failed to remove user">
        <FieldInput
          field={form.fields.userId}
          label="User ID"
          className={clsMb30}
          placeholder="e.g. 101cb436a7b9b65b20b62b08ee190eb63bd1835f9fb"
        />

        <ButtonSubmit text="Remove user" large />
      </Form>
    </Card>
  );
});
