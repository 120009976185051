import { observer } from 'mobx-react-lite';

import { PageHeader } from 'common/components/page-header';

import { IndexingRules } from './form';

export const IndexingTool = observer(() => {
  return (
    <>
      <PageHeader title="Indexing Tool" />
      <IndexingRules />
    </>
  );
});
