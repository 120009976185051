import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { DeletePopover } from 'common/components/delete-popover';
import { useStore } from 'common/hooks/use-store';

import { User } from '../api';

import { UserDeleteStore } from './store';

interface Props {
  user: User;
  onDeleted: (User: User) => void;
  className?: string;
  large?: boolean;
}

export const UserDelete = observer(({ user, onDeleted, className, large = false }: Props) => {
  const { deleteUser, deleteLoaders } = useStore(() => new UserDeleteStore());
  const deleteLoader = deleteLoaders.get(user.email);

  const handleDeleteConfirm = useCallback(async () => {
    await deleteUser(user);
    if (deleteLoader.isOk) {
      onDeleted(user);
    }
  }, [deleteUser, deleteLoader, user, onDeleted]);

  return (
    <DeletePopover
      entityType="user"
      entityName={user.email}
      onConfirmDelete={handleDeleteConfirm}
      isDeleting={deleteLoader.isPending}
      large={large}
      className={className}
    />
  );
});
