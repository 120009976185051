import { Classes } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';

import { InviteUser, InviteUserStore } from 'b2b/businesses/details/common/invite-user';
import {
  BusinessUserRole,
  businessUserRoleSearchKey,
  businessUserRolesCompareAsc,
} from 'b2b/businesses/details/common/user-role';
import { BusinessUsersTableStore } from 'b2b/businesses/details/users/table';
import { Table } from 'common/components/client-pagination-table';
import { Column, Sort } from 'common/components/table-view';
import { DATE_FORMAT_TEXT_SHORT } from 'common/config';
import { formatDate } from 'common/helpers/date';
import { fromTimestampToMillis } from 'common/helpers/grpc';
import { createSearchFunction, searchRankings } from 'common/helpers/search';
import { googleTimestampCompareAsc, stringCompareAsc } from 'common/helpers/sort';
import { useStore } from 'common/hooks/use-store';
import { clsMb10 } from 'common/styles/margin-padding';

import { UserInvitation } from '../api';

import { BusinessInvitationsTableStore } from './store';

type ColumnId = 'email' | 'role' | 'inviter' | 'sentOn';

const createColumns = (): Column<UserInvitation, ColumnId>[] => [
  {
    id: 'email',
    title: 'Email',
    width: '35%',
    sorter: (a, b) => stringCompareAsc(a.email, b.email),
    render: ({ email }) => email,
  },
  {
    id: 'role',
    title: 'Role',
    width: '15%',
    sorter: (a, b) => businessUserRolesCompareAsc(a.role, b.role),
    render: ({ role }) => <BusinessUserRole role={role} />,
  },
  {
    id: 'inviter',
    title: 'Inviter',
    width: '35%',
    sorter: (a, b) => stringCompareAsc(a.email, b.email),
    render: ({ whoInviteEmail, whoInviteName }) => (
      <div>
        <div className={clsMb10}>{whoInviteName}</div>
        <div className={cx(Classes.TEXT_MUTED, Classes.TEXT_SMALL)}>Email: {whoInviteEmail}</div>
      </div>
    ),
  },
  {
    id: 'sentOn',
    title: 'Sent on',
    sorter: (a, b) => googleTimestampCompareAsc(a.createdAt, b.createdAt),
    render: ({ createdAt }) =>
      isDefined(createdAt) ? formatDate(fromTimestampToMillis(createdAt), DATE_FORMAT_TEXT_SHORT) : null,
  },
];

const searchFunction = createSearchFunction<UserInvitation>([
  (invitation) => invitation.email,
  (invitation) => invitation.whoInviteEmail,
  (invitation) => invitation.whoInviteName,
  (invitation) => businessUserRoleSearchKey(invitation.role),
  { key: (invitation) => invitation.id, threshold: searchRankings.EQUAL },
]);

const DEFAULT_SORT: Sort<ColumnId> = { sortKey: 'sentOn', sortOrder: 'desc' };

interface Props {
  store: BusinessInvitationsTableStore;
  businessUsersTableStore: BusinessUsersTableStore;
}

export const BusinessInvitationsTable = observer(({ store, businessUsersTableStore }: Props) => {
  const columns = useMemo(() => createColumns(), []);
  const inviteUserStore = useStore(() => new InviteUserStore(businessUsersTableStore));

  useEffect(() => {
    void businessUsersTableStore.loadUsers();
  }, [businessUsersTableStore]);

  useEffect(() => {
    void store.loadInvitations();
  }, [store]);

  return (
    <Table
      data={store.invitations}
      columns={columns}
      rowKey="id"
      rowHeight={60}
      searchFunction={searchFunction}
      defaultSort={DEFAULT_SORT}
      loader={store.loader}
      headerAction={<InviteUser store={inviteUserStore} onInvited={store.loadInvitations} />}
    />
  );
});
