import { FormGroup } from '@blueprintjs/core';
import { ItemListRenderer } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';
import { ReactNode, useCallback, useMemo } from 'react';

import { getFieldControlProps, getFieldFormGroupProps } from 'common/components/form/helpers';
import { Multiselect, MultiselectItem } from 'common/components/form/multi-select';
import { InfiniteList } from 'common/components/infinite-list';
import { FieldModel } from 'common/helpers/form';
import { getItemId } from 'common/helpers/selectors';
import { useStore } from 'common/hooks/use-store';

import { Product } from './api';
import { ProductsStore } from './store';

const productToItem = (product: Product): MultiselectItem<Product> => ({
  value: product,
  label: product.canonicalName,
  comment: product.id,
});

interface Props {
  field: FieldModel<Product[]>;
  label?: ReactNode;
}

export const FieldProducts = observer(({ field, label }: Props) => {
  const store = useStore(() => new ProductsStore());
  const { products } = store;

  const items: MultiselectItem<Product>[] = useMemo(() => products.map(productToItem), [products]);
  const selectedItems: MultiselectItem<Product>[] = useMemo(() => field.value.map(productToItem), [field.value]);

  const renderItemList: ItemListRenderer<MultiselectItem<Product>> = useCallback(
    (itemListRendererProps) => (
      <InfiniteList itemListRendererProps={itemListRendererProps} store={store} queryRequired />
    ),
    [store]
  );

  return (
    <FormGroup {...getFieldFormGroupProps(field)} label={label}>
      <Multiselect
        {...getFieldControlProps(field)}
        items={items}
        value={selectedItems}
        getValueKey={getItemId}
        itemListRenderer={renderItemList}
        placeholder="Search products"
        large
        minimal
      />
    </FormGroup>
  );
});
