import { makeObservable, observable } from 'mobx';

import { BaseStore } from './base';
export class SizeStore extends BaseStore {
  @observable currentWidth = window.innerWidth;
  @observable currentHeight = window.innerHeight;

  private setSize() {
    this.currentWidth = window.innerWidth;
    this.currentHeight = window.innerHeight;
  }

  constructor() {
    super();
    makeObservable(this);
    this.init();
  }

  private init() {
    window.addEventListener('resize', () => {
      this.setSize();
    });
  }
}
