import { Classes } from '@blueprintjs/core';
import { css } from 'linaria';

export const clsMultiselect = css`
  display: inline-block;
  vertical-align: baseline;
`;

export const clsBreak = css`
  white-space: break-spaces;
  padding-left: 50px;
`;

export const clsText = css`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

export const clsLabel = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const clsLabelWithIcon = css`
  margin-left: 5px;
`;

export const clsLabelWithRightIcon = css`
  margin-right: 5px;
`;

export const clsTagContainer = css`
  width: 100%;
`;

export const clsTagInput = css`
  &.${Classes.TAG_INPUT} {
    min-width: auto;
  }
  &.${Classes.DISABLED} {
    & input {
      display: none;
    }
  }
`;

export const clsTag = css`
  &.${Classes.LARGE} .${Classes.FILL} {
    margin-right: 4px;
  }
`;

export const clsMenuItem = css`
  display: flex !important;
  align-items: center !important;
  & .${Classes.ICON} {
    margin-top: 0 !important;
  }
`;
