import { Button, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { ComponentProps } from 'react';

import { useFormContext } from 'common/components/form/context';

import { ButtonAdd, ButtonSave } from '../buttons';

export interface ButtonSubmitProps extends ComponentProps<typeof Button> {
  kind?: 'add' | 'save' | 'submit';
}

export const ButtonSubmit = observer(
  ({ kind = 'submit', disabled, loading: loadingProp, ...buttonProps }: ButtonSubmitProps) => {
    const { form, loader } = useFormContext();
    const loading = loadingProp ?? loader?.isPending;

    return (
      <>
        {kind === 'add' ? (
          <ButtonAdd {...buttonProps} disabled={disabled ?? !form.isValid} loading={loading} type="submit" />
        ) : kind === 'save' ? (
          <ButtonSave {...buttonProps} disabled={disabled ?? !form.isValidAndDirty} loading={loading} type="submit" />
        ) : (
          <Button
            {...buttonProps}
            intent={buttonProps.intent ?? Intent.PRIMARY}
            disabled={disabled ?? !form.isValid}
            loading={loading}
            type="submit"
          />
        )}
      </>
    );
  }
);
