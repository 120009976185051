import { css } from 'linaria';

import { Box } from 'common/components/box';

const clsUser = css`
  font-size: 80%;
  border: 1px solid #ddd;
  background: white;
`;

const clsUserType = css`
  color: #555;
  font-weight: 700;
`;

const clsUserData = css`
  border-left: 1px solid #ddd;
  font-family: monospace;
`;

interface Props {
  type: string;
  id: string;
  email?: string;
  name?: string;
}

export const User: React.FC<Props> = ({ type, id, email, name }) => (
  <Box className={clsUser} display="inline-flex" p={4} borderRadius="12px">
    <Box className={clsUserType} pr={4}>
      {type}
    </Box>
    <Box className={clsUserData} flexDirection="column" alignItems="flex-start" pl={4}>
      <Box>{id}</Box>
      <Box>{name}</Box>
      <Box>{email}</Box>
    </Box>
  </Box>
);
