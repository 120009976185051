import { Button, Classes, Intent, Popover, Position } from '@blueprintjs/core';
import { isText } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { memo, useCallback, useEffect, useRef, useState } from 'react';

import { FieldInput } from 'common/components/fields/field-input';

import { useClipboard } from './use-clipboard';
import { SelectAllHandlers, useSelectAllInput } from './use-select-all';

const clsField = css`
  display: flex;
  flex: 1 1 auto;
  align-items: center !important;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const clsFormGroup = css`
  flex: 1 1 auto;
  display: flex;
  align-items: center;

  & .${Classes.LABEL} {
    width: 75px;
    flex: 0 0 auto;
    margin-bottom: 0;
  }

  & .${Classes.INPUT} {
    cursor: text !important;
  }
`;

const clsFormContent = css`
  flex-grow: 1;
`;

const clsButtonCopy = css`
  margin-left: 15px;
`;

const clsCopied = css`
  .${Classes.POPOVER_CONTENT} {
    padding: 4px 8px;
  }
`;

interface Props {
  value: string;
  label: string;
  isHidden?: boolean;
}

export const AppCredentialsField = memo(({ value, label, isHidden }: Props) => {
  const [isSuccessShown, setIsSuccessShown] = useState(false);
  const copyRef = useRef<HTMLButtonElement | null>(null);

  const handleCopied = useCallback(() => {
    setIsSuccessShown(true);
  }, []);

  useClipboard(copyRef, value, handleCopied);

  // Hide tooltip after timeout
  useEffect(() => {
    if (isSuccessShown) {
      const timeoutId = setTimeout(() => {
        setIsSuccessShown(false);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isSuccessShown]);

  const selectAllHandlers: SelectAllHandlers = useSelectAllInput();

  return (
    <div className={clsField}>
      <FieldInput
        label={label}
        className={clsFormGroup}
        contentClassName={clsFormContent}
        value={isHidden && isText(value) ? '•'.repeat(value.length) : value}
        inputClassName={Classes.DISABLED}
        inline
        fill
        noMargin
        disabled={isHidden}
        readOnly={!isHidden}
        {...selectAllHandlers}
      />
      <Popover
        targetTagName="div"
        wrapperTagName="div"
        boundary="viewport"
        position={Position.TOP}
        content={<div>Copied</div>}
        isOpen={isSuccessShown}
        popoverClassName={cx(clsCopied, Classes.DARK)}
      >
        <Button
          title="Copy to clipboard"
          text="Copy"
          icon="duplicate"
          elementRef={copyRef}
          intent={Intent.PRIMARY}
          className={clsButtonCopy}
          large
        />
      </Popover>
    </div>
  );
});
