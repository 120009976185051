import { isNumber, isString } from '@whisklabs/typeguards';
import type { Property } from 'csstype';
import { memo, useEffect, useRef } from 'react';

const toPx = (value?: number | string, base?: number | string) =>
  isNumber(value)
    ? `${value}px`
    : isString(value)
    ? value
    : isNumber(base)
    ? `${base}px`
    : isString(base)
    ? base
    : undefined;

type BoxProps = {
  flex?: string;
  alignItems?: Property.AlignItems;
  alignContent?: Property.AlignContent;
  alignSelf?: Property.AlignSelf;
  alignTracks?: Property.AlignTracks;
  justifyContent?: Property.JustifyContent;
  flexBasis?: Property.FlexBasis;
  flexDirection?: Property.FlexDirection;
  flexFlow?: Property.FlexFlow;
  flexGrow?: Property.FlexGrow;
  flexShrink?: Property.FlexShrink;
  flexWrap?: Property.FlexWrap;
  width?: Property.Width;
  minWidth?: Property.MinWidth;
  maxWidth?: Property.MaxWidth;
  height?: Property.Height;
  minHeight?: Property.MinHeight;
  maxHeight?: Property.MaxHeight;
  top?: Property.Top;
  bottom?: Property.Bottom;
  left?: Property.Left;
  right?: Property.Right;
  position?: Property.Position;
  zIndex?: Property.ZIndex;
  color?: Property.Color;
  background?: Property.Background;
  border?: Property.Border;
  fill?: Property.Fill;
  display?: Property.Display;
  textAlign?: Property.TextAlign;
  borderRadius?: Property.BorderRadius;
  transform?: Property.Transform;
  transition?: Property.Transition;
  m?: number | string;
  mb?: number | string;
  mt?: number | string;
  ml?: number | string;
  mr?: number | string;
  p?: number | string;
  pt?: number | string;
  pb?: number | string;
  pl?: number | string;
  pr?: number | string;
  style?: React.CSSProperties;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Box = memo<BoxProps>(
  ({
    m,
    mb,
    mt,
    ml,
    mr,
    p,
    pt,
    pb,
    pl,
    pr,
    color,
    background,
    border,
    fill,
    alignItems = 'center',
    display = 'flex',
    textAlign,
    alignContent,
    alignSelf,
    alignTracks,
    justifyContent,
    flexBasis,
    flex,
    flexDirection,
    flexFlow,
    flexGrow,
    flexShrink,
    flexWrap,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    top,
    bottom,
    left,
    right,
    position,
    zIndex,
    borderRadius,
    transform,
    transition = '',
    style,
    ...props
  }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (!ref.current) {
        return;
      }

      const st: React.CSSProperties = {
        display,
        textAlign,
        alignItems,
        alignContent,
        alignSelf,
        alignTracks,
        justifyContent,
        flex,
        flexBasis,
        flexDirection,
        flexFlow,
        flexGrow,
        flexShrink,
        flexWrap,
        color,
        background,
        border,
        fill,
        width,
        minWidth,
        maxWidth,
        height,
        minHeight,
        maxHeight,
        top,
        bottom,
        left,
        right,
        position,
        transform,
        transition,
        zIndex,
        borderRadius,
        marginBottom: toPx(mb, m),
        marginTop: toPx(mt, m),
        marginLeft: toPx(ml, m),
        marginRight: toPx(mr, m),
        paddingBottom: toPx(pb, p),
        paddingTop: toPx(pt, p),
        paddingLeft: toPx(pl, p),
        paddingRight: toPx(pr, p),
      };

      Object.assign(ref.current.style, style, st);
    });

    return <div ref={ref} {...props} />;
  }
);
