import { Button, Callout, InputGroup, Text } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { Box } from 'common/components/box';
import { Card } from 'common/components/card';

import { BlockedUrlsStore } from './store';

interface Props {
  store: BlockedUrlsStore;
}

export const AddUrlForm = observer<Props>(({ store }) => {
  return (
    <Card>
      <Box mb={12}>
        <Callout title="Patterns notes:">
          <Text>1. example.love - rule for domain (also matches subdomains, e.g. sub.example.love)</Text>
          <Text>2. sub.example.love - rule for specific subdomain</Text>
          <Text>3. *.love - rule for zone</Text>
          <Text>4. sex.example.com - 3rd </Text>
          <Text>4. example.* - rule for domain in all zones (example.love, example.click, etc)</Text>
        </Callout>
      </Box>
      <Box>
        <Box mr={12}>
          <InputGroup
            value={store.newPatternValue}
            onChange={(ev) => store.updateNewPatternValue(ev.currentTarget.value)}
            large
            id="text-input"
            placeholder="Add url pattern"
          />
        </Box>
        <Button onClick={store.createPattern} large intent="primary" loading={store.createLoader.isPending}>
          Add
        </Button>
      </Box>
    </Card>
  );
});
