import { Callout, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { FieldBrands } from 'b2b/brands';
import { CountriesStore, FieldCountries } from 'b2b/countries';
import { FieldProducts } from 'b2b/products';
import { FieldPublishers } from 'b2b/publishers';
import { FieldInput } from 'common/components/fields/field-input';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';

import { CampaignFormFieldDateRange } from './field-daterange';
import { CampaignFormStore } from './store';

interface Props {
  store: CampaignFormStore;
}

export const CampaignFormFields = observer(({ store }: Props) => {
  const countriesStore = useStore(() => new CountriesStore());

  useEffect(() => {
    void countriesStore.loadCountries();
  }, [countriesStore]);

  return (
    <div className={clsMb30}>
      <Callout intent={Intent.WARNING} className={clsMb30}>
        Product MUST NOT be in multiple campaigns if they are active on the same dates for the same publisher in the
        same country
      </Callout>

      <FieldInput label="Name" field={store.form.fields.name} maxLength={255} />
      <CampaignFormFieldDateRange label="Start and end dates, at 2PM UTC" field={store.form.fields.dateRange} />
      <FieldBrands label="Brands" field={store.form.fields.brands} />
      <FieldProducts label="Products" field={store.form.fields.products} />
      <FieldPublishers label="Publishers, all by default" field={store.form.fields.publishers} />
      <FieldCountries
        label="Countries, all by default"
        field={store.form.fields.countries}
        countries={countriesStore.countries}
        tagColumns={4}
      />
      <FieldInput label="CPA, $" field={store.form.fields.cpa} maxLength={256} placeholder="e.g. 1.05" />
    </div>
  );
});
