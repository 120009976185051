import { runInAction } from 'mobx';

import { DEVTOOLS, FORCE_LOG } from 'common/config';
import { eventEmitter } from 'common/event-emitter';
import { omit, values } from 'common/helpers/functional';

import { BaseStore } from './base';
import { DocumentStore } from './document';
import { RouterStore } from './router';
import { SizeStore } from './size';
import { ToastStore } from './toast';
import { UserStore } from './user';

class RootStore {
  router = new RouterStore();
  user = new UserStore();
  toast = new ToastStore();
  document = new DocumentStore();
  size = new SizeStore();
}

export { BaseStore };

export type Stores = RootStore;
export type Store = keyof Stores;

const stores: Stores = new RootStore();

export const getStore = <T extends Store>(key: T): Stores[T] => stores[key];

export const getStores = (): Stores => stores;

if (DEVTOOLS || FORCE_LOG) {
  window.stores = stores;
}

stores.user.reactToLogOut(() => {
  values(omit(stores, ['user'])).forEach((store) => {
    store.storeReset();
  });
});

eventEmitter.on('authTokenInvalid', (error) => {
  stores.user.unauthenticateUser();
  stores.toast.error(
    `Authentication token invalid. Please, try to sign in again or contact admins.`,
    error,
    'auth-token'
  );
});

runInAction(() => {
  values(stores).forEach((store) => {
    store.storeInit();
  });
});
