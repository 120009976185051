import { action, makeObservable } from 'mobx';

import { Business, BusinessAddress, BusinessDetails } from 'b2b/businesses/api';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateEmail, validateRequired } from 'common/helpers/form/validators';
import { BaseStore } from 'common/stores';

export class BusinessAccountFormStore extends BaseStore {
  readonly form = new FormModel({
    name: new FieldModel('').validators(validateRequired),
    website: new FieldModel(''),
    industry: new FieldModel('').validators(validateRequired),
    socialMediaUrl: new FieldModel(''),
    useCaseDescription: new FieldModel('').validators(validateRequired),
    billingContactName: new FieldModel(''),
    billingContactEmail: new FieldModel('').validators(validateEmail),
    techContactName: new FieldModel(''),
    techContactEmail: new FieldModel('').validators(validateEmail),

    streetAddress: new FieldModel('').validators(validateRequired),
    city: new FieldModel('').validators(validateRequired),
    stateOrProvince: new FieldModel('').validators(validateRequired),
    country: new FieldModel('').validators(validateRequired),
    zipCode: new FieldModel('').validators(validateRequired),
    phoneNumber: new FieldModel('').validators(validateRequired),
  });

  @action storeReset = () => {
    this.form.reset();
  };

  @action fill = ({ details, address }: Business) => {
    this.form.reset({
      name: details?.name ?? '',
      website: details?.website ?? '',
      industry: details?.industry ?? '',
      socialMediaUrl: details?.socialMediaUrl ?? '',
      useCaseDescription: details?.useCaseDescription ?? '',
      billingContactName: details?.billingContacts?.fullName ?? '',
      billingContactEmail: details?.billingContacts?.email ?? '',
      techContactName: details?.technicalContacts?.fullName ?? '',
      techContactEmail: details?.technicalContacts?.email ?? '',

      streetAddress: address?.streetAddress ?? '',
      city: address?.city ?? '',
      stateOrProvince: address?.stateOrProvince ?? '',
      country: address?.country ?? '',
      zipCode: address?.zipCode ?? '',
      phoneNumber: address?.phoneNumber ?? '',
    });
  };

  private readonly getDetails = (): BusinessDetails => {
    const {
      name,
      website,
      industry,
      socialMediaUrl,
      useCaseDescription,
      billingContactName,
      billingContactEmail,
      techContactName,
      techContactEmail,
    } = this.form.values;

    return {
      name,
      website,
      industry,
      socialMediaUrl,
      useCaseDescription,
      billingContacts: {
        fullName: billingContactName,
        email: billingContactEmail,
      },
      technicalContacts: {
        fullName: techContactName,
        email: techContactEmail,
      },
    };
  };

  private readonly getAddress = (): BusinessAddress => {
    const { streetAddress, city, stateOrProvince, country, zipCode, phoneNumber } = this.form.values;

    return {
      streetAddress,
      city,
      stateOrProvince,
      country,
      zipCode,
      phoneNumber,
    };
  };

  getData = () => ({
    details: this.getDetails(),
    address: this.getAddress(),
  });

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
