import {
  whisk_backoffice_b2c_v1_ApproveRecipeRequest as ApproveRecipeRequest,
  whisk_backoffice_b2c_v1_BanRecipeRequest as BanRecipeRequest,
  whisk_backoffice_b2c_v1_GetFlaggedRecipesResponse_Community as Community,
  whisk_backoffice_b2c_v1_GetFlaggedRecipesResponse_FlaggedRecipe as FlaggedRecipeBE,
  whisk_backoffice_b2c_v1_RecipeAPI_ApproveRecipe,
  whisk_backoffice_b2c_v1_RecipeAPI_BanRecipe,
  whisk_backoffice_b2c_v1_RecipeAPI_GetFlaggedRecipes,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { FlaggedRecipeBE, ApproveRecipeRequest, BanRecipeRequest, Community };

const api = b2cGrpc();

export const getFlaggedRecipes = () => api(whisk_backoffice_b2c_v1_RecipeAPI_GetFlaggedRecipes);

export const approveRecipe = (payload: ApproveRecipeRequest) =>
  api(whisk_backoffice_b2c_v1_RecipeAPI_ApproveRecipe, payload);

export const banRecipe = (payload: BanRecipeRequest) => api(whisk_backoffice_b2c_v1_RecipeAPI_BanRecipe, payload);
