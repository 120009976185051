import { IToastProps, IToaster, IToasterProps, Intent, Position, Toaster } from '@blueprintjs/core';
import { isObject, isString, isText } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { createElement } from 'react';

import { BaseStore } from './base';

export interface ToastStoreParams extends IToasterProps {
  timeout?: number;
  errorTimeout?: number;
}

// TODO: bug in blueprintjs
const clsToaster = css`
  && {
    position: fixed;
  }
`;

export class ToastStore extends BaseStore {
  private readonly toaster: IToaster;
  private readonly timeout: number;
  private readonly errorTimeout: number;

  constructor({ timeout = 4000, errorTimeout = -1, ...toasterProps }: ToastStoreParams = {}) {
    super();
    this.toaster = Toaster.create({
      position: Position.TOP,
      maxToasts: 2,
      usePortal: true,
      className: clsToaster,
      ...toasterProps,
    });
    this.timeout = timeout;
    this.errorTimeout = errorTimeout;
  }

  storeReset = () => {
    this.toaster.clear();
  };

  show = (props: IToastProps, key?: string) =>
    this.toaster.show(
      {
        timeout: this.timeout,
        ...props,
      },
      key
    );

  success = (propsOrMessage: IToastProps | string, key?: string) =>
    this.show(
      {
        intent: Intent.SUCCESS,
        icon: 'tick',
        ...this.normalizePropsOrMessage(propsOrMessage),
      },
      key
    );

  warning = (propsOrMessage: IToastProps | string, key?: string) =>
    this.show(
      {
        intent: Intent.WARNING,
        icon: 'time',
        ...this.normalizePropsOrMessage(propsOrMessage),
      },
      key
    );

  error = (propsOrMessage: IToastProps | string, error?: unknown, key?: string) => {
    const { message, ...props } = this.normalizePropsOrMessage(propsOrMessage);
    const errorMessage = isObject(error) ? error.message : undefined;

    return this.show(
      {
        intent: Intent.DANGER,
        icon: 'error',
        timeout: this.errorTimeout,
        message: isText(errorMessage)
          ? createElement('div', null, message, createElement('br'), createElement('br'), 'Error: ', errorMessage)
          : message,
        ...props,
      },
      key
    );
  };

  info = (propsOrMessage: IToastProps | string, key?: string) =>
    this.show(
      {
        intent: Intent.PRIMARY,
        icon: 'info-sign',
        ...this.normalizePropsOrMessage(propsOrMessage),
      },
      key
    );

  clear = () => this.toaster.clear();

  private readonly normalizePropsOrMessage = (propsOrMessage: IToastProps | string) =>
    isString(propsOrMessage) ? { message: propsOrMessage } : propsOrMessage;
}
