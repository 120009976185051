import { isText } from '@whisklabs/typeguards';
import { useEffect } from 'react';

import { useRandomId } from 'common/hooks/use-random-id';
import { getStore } from 'common/stores';

export const useDocumentTitle = (title?: string) => {
  const id = useRandomId();
  const documentStore = getStore('document');

  useEffect(() => {
    if (!isText(title)) {
      return;
    }

    documentStore.setTitle({
      id,
      title,
    });

    return () => {
      documentStore.removeTitle(id);
    };
  }, [documentStore, id, title]);
};
