import { Intent, Tag } from '@blueprintjs/core';
import { css, cx } from 'linaria';

import { Campaign } from 'b2b/campaigns/api';

import { CampaignStatusType } from './api';

const clsStatus = css`
  vertical-align: middle;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  margin-left: 15px;
  &:first-child {
    margin-left: 0;
  }
`;

const statusProps: Record<CampaignStatusType, { intent: Intent; label: string }> = {
  [CampaignStatusType.CAMPAIGN_STATUS_ENDED]: {
    label: 'Ended',
    intent: Intent.NONE,
  },
  [CampaignStatusType.CAMPAIGN_STATUS_ACTIVE]: {
    label: 'Active',
    intent: Intent.SUCCESS,
  },
  [CampaignStatusType.CAMPAIGN_STATUS_SCHEDULED]: {
    label: 'Scheduled',
    intent: Intent.PRIMARY,
  },
};

interface Props {
  campaign: Campaign;
  className?: string;
}

export const CampaignStatus = ({ campaign, className }: Props) => {
  const status: CampaignStatusType | undefined = campaign.status ?? CampaignStatusType.CAMPAIGN_STATUS_ENDED;
  const { label, intent } = statusProps[status];

  return (
    <Tag className={cx(clsStatus, className)} intent={intent} large>
      {label}
    </Tag>
  );
};
