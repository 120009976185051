import { Button, Intent } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';

import { ClaimedDomain } from 'b2c/claimed-domains/api';
import { Table } from 'common/components/infinite-scroll-table';
import { NonEmptyString } from 'common/components/non-empty-string';
import { Column } from 'common/components/table-view';

import { ClaimedDomaisStore } from './store';

const clsTable = css`
  word-break: break-all;
`;

type ColumnId = 'domain' | 'userId' | 'userName' | 'actions';

interface Props {
  store: ClaimedDomaisStore;
}

export const DomainsTable = observer(({ store }: Props) => {
  const columns = useMemo(
    (): Column<ClaimedDomain, ColumnId>[] => [
      {
        id: 'domain',
        title: 'Domain',
        render: (domain) => (
          <code>
            <NonEmptyString value={domain.domain} />
          </code>
        ),
      },
      {
        id: 'userId',
        title: 'User Id',
        render: (domain) => <NonEmptyString value={domain.user?.id} />,
      },
      {
        id: 'userName',
        title: 'User Name',
        render: (domain) => <NonEmptyString value={domain.user?.name} />,
      },
      {
        id: 'actions',
        width: '175px',
        title: 'Actions',
        align: 'center',
        render: (domain) => (
          <Button
            rightIcon="cross"
            onClick={() => store.deleteDomain({ domain: domain.domain })}
            large
            intent={Intent.DANGER}
          />
        ),
      },
    ],
    [store]
  );

  useEffect(() => {
    store.loadInitialData();
  }, []);

  return (
    <Table
      loader={store.loader}
      tableClassName={clsTable}
      cellPaddingVertical={10}
      data={store.claimedDomains}
      columns={columns}
      useScrollContentAsRoot={false}
      rowKey="domain"
      loadMore={store.loadMoreData}
      hasMore={store.hasMoreData}
      isEmpty={store.claimedDomains.length === 0}
      emptyElement={<div>No items</div>}
      isLoading={store.loader.isPending}
    />
  );
});
