import { isText } from '@whisklabs/typeguards';
import { identity } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';

import { useAppDetailsStoreContext } from 'b2b/apps/details/context';
import { Table } from 'common/components/client-pagination-table';
import { Column, RenderRowFunction } from 'common/components/table-view';

import { AppRedirectUrlsRow, ColumnId } from './row';

const columns: Column<string, ColumnId>[] = [
  {
    id: 'url',
    title: 'URL',
    width: '100%',
  },
  {
    id: 'action',
    title: 'Action',
    vAlign: 'top',
  },
];

export const AppRedirectUrlsTable = observer(() => {
  const appDetailsStore = useAppDetailsStoreContext();

  const appRedirectUrls = useMemo(
    () => appDetailsStore.app.credentials?.redirectUrls.map((url) => url.trim()).filter(isText) ?? [],
    [appDetailsStore.app.credentials?.redirectUrls]
  );

  const renderRow: RenderRowFunction<string, ColumnId> = useCallback((props) => <AppRedirectUrlsRow {...props} />, []);
  const renderNewRow: RenderRowFunction<string, ColumnId> = useCallback(
    (props) => <AppRedirectUrlsRow {...props} />,
    []
  );

  return (
    <Table
      data={appRedirectUrls}
      rowKey={identity}
      columns={columns}
      renderRow={renderRow}
      renderNewRow={renderNewRow}
      cellPaddingVertical={7}
      defaultPagination={false}
    />
  );
});
