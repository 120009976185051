import { observer } from 'mobx-react-lite';

import { FieldInput } from 'common/components/fields/field-input';
import { clsMb30 } from 'common/styles/margin-padding';

import { WidgetFormStore } from './store';

interface Props {
  store: WidgetFormStore;
}

export const WidgetFormFields = observer(({ store }: Props) => (
  <div className={clsMb30}>
    <FieldInput field={store.form.fields.name} label="Name" maxLength={255} />
    <FieldInput field={store.form.fields.trackingId} label="Tracking ID" maxLength={255} />
    <FieldInput field={store.form.fields.type} label="Type" />
    <FieldInput field={store.form.fields.config} label="Config" />
  </div>
));
