import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable } from 'mobx';

import { BusinessUserRoleType } from 'b2b/businesses/details/common/user-role/api';
import { InviteUserRequest, inviteUser } from 'b2b/businesses/details/users/api';
import { BusinessUsersTableStore } from 'b2b/businesses/details/users/table/store';
import { DataLoader } from 'common/helpers/data-loader';
import { Dialog } from 'common/helpers/dialog';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateEmail, validateRequired } from 'common/helpers/form/validators';
import { BaseStore, getStore } from 'common/stores';

export type InviteUserPayload = Omit<InviteUserRequest, 'id'>;

export class InviteUserStore extends BaseStore {
  readonly inviteLoader = new DataLoader();
  readonly dialog = new Dialog();

  readonly form = new FormModel({
    email: new FieldModel<string>('').validators(
      validateRequired,
      validateEmail,
      (email) => !this.hasUserWithEmail(email) || 'User with this email already belongs to this business'
    ),
    role: new FieldModel<BusinessUserRoleType>(BusinessUserRoleType.ROLE_EDITOR).validators(validateRequired),
  });

  constructor(private readonly businessUsersTableStore: BusinessUsersTableStore) {
    super();
    makeObservable(this);
  }

  @action storeReset = () => {
    this.inviteLoader.reset();
    this.dialog.reset();
  };

  @computed get businessHasOwner() {
    return this.businessUsersTableStore.users.some((user) => user.userRole?.role === BusinessUserRoleType.ROLE_OWNER);
  }

  @computed get availableRoles(): BusinessUserRoleType[] {
    return [
      // FIXME: https://samsungnext.atlassian.net/browse/WBI-34
      !this.businessHasOwner ? BusinessUserRoleType.ROLE_OWNER : undefined,
      BusinessUserRoleType.ROLE_ADMIN,
      BusinessUserRoleType.ROLE_EDITOR,
      BusinessUserRoleType.ROLE_CREATOR,
      BusinessUserRoleType.ROLE_VIEW_ONLY,
    ].filter(isDefined);
  }

  hasUserWithEmail = (email: string) => {
    const trimmedEmail = email.trim();

    return this.businessUsersTableStore.users.some((user) => user.email === trimmedEmail);
  };

  @action inviteUser = async ({ email, ...restPayload }: InviteUserPayload) => {
    const { success, error } = await this.inviteLoader.load(() =>
      inviteUser({ id: this.businessUsersTableStore.business.id, email, ...restPayload })
    );

    if (success) {
      getStore('toast').success(`Invitation has been sent to ${email}`);
      this.inviteLoader.ok();
    } else {
      getStore('toast').error(`Failed to send invite to ${email}`, error);
      this.inviteLoader.fail(error);
    }
  };
}
