import { Button, Intent } from '@blueprintjs/core';
import { css } from 'linaria';

const clsContainer = css`
  text-align: center;
  margin-top: 20px;
`;

interface Props {
  onClick: () => void;
  loading?: boolean;
}

export const LoadMore: React.FC<Props> = ({ onClick, loading }) => (
  <div className={clsContainer}>
    <Button intent={Intent.PRIMARY} large type="button" onClick={onClick} loading={loading}>
      Load more
    </Button>
  </div>
);
