import { isDefined, isText } from '@whisklabs/typeguards';
import { uniqBy } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';

import { hasSearch } from 'b2c/helpers/has-search';
import { InfiniteListStoreType } from 'common/components/infinite-list';
import { DataLoader } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';
import { Sort } from 'common/types/types';

import { createDomain, loadDomains } from '../api';

import { DomainEntity } from './domain-entity';

interface SortFn extends Sort {
  fn?: (i: DomainEntity) => string | number | undefined;
}

const DEFAULT_SORT: SortFn = {
  sortTitle: 'Date',
  sortKey: 'date',
  sortOrder: 'asc',
  fn: (r) => r.value,
};

export class NoIndexingDomainsStore extends BaseStore implements InfiniteListStoreType {
  constructor() {
    super();

    makeObservable(this);
  }

  @observable sort: SortFn = DEFAULT_SORT;
  @observable private readonly limit = 100;
  @observable newPatternValue = '';
  createLoader = new DataLoader();
  loader = new DataLoader();
  @observable private domains: DomainEntity[] = [];

  @observable hasMoreData = true;

  @observable after = '';

  @observable searchText = '';

  @action storeReset = () => {
    this.domains = [];
    this.createLoader.reset();
    this.after = '';
    this.newPatternValue = '';
  };

  @computed get domainsArray(): DomainEntity[] {
    return this.domains;
  }

  @computed get domainsFiltered(): DomainEntity[] {
    return this.domains.filter((item) => hasSearch(this.searchText, item));
  }

  @computed get total(): number {
    return this.domainsFiltered.length;
  }

  @action search = (query: string) => {
    this.searchText = query;
  };

  @action setSort = (sort?: Sort): void => {
    this.sort = sort ?? DEFAULT_SORT;
  };

  loadInitialData = () => void this.loadNoIndexingDomains();

  loadMoreData = () => void this.loadNoIndexingDomains();

  @action loadNoIndexingDomains = async () => {
    const { cancelled, data, error } = await this.loader.load(() =>
      loadDomains({
        limit: this.limit,
        cursors: {
          after: this.after,
          before: '',
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      const list = data.domains.map(
        (item) =>
          new DomainEntity({
            id: item.domainId,
            domain: item.domain,
          })
      );

      const after = data.paging?.cursors?.after;

      this.domains = uniqBy(this.domains.concat(list), 'id');
      this.after = after ?? '';
      this.hasMoreData = data.domains.length > 0 && isText(after);
      this.loader.ok();
    } else {
      this.loader.fail(error);
      getStore('toast').error('Smth went wrong');
    }
  };

  @action createDomain = async () => {
    const { cancelled, data, error } = await this.createLoader.load(() =>
      createDomain({
        domain: this.newPatternValue,
      })
    );

    const domain = data?.domain;

    if (cancelled) {
      return;
    }

    if (isDefined(domain)) {
      this.newPatternValue = '';
      getStore('toast').success('Domain has been created');
      const pattern = new DomainEntity({
        id: domain.domainId,
        domain: domain.domain,
      });

      this.domains.unshift(pattern);
      this.createLoader.ok();
    } else {
      this.createLoader.fail(error);
      getStore('toast').error('Smth went wrong');
    }
  };

  @action removeFromList = (id: string) => {
    this.domains = this.domains.filter((item) => item.id !== id);
  };

  @action updateNewPatternValue = (value: string) => {
    this.newPatternValue = value;
  };
}
