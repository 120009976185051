import { isDefined } from '@whisklabs/typeguards';

import { Pagination } from '../types';

import { PAGE_SKIP } from './constants';

export const getOffset = (pagination: Pagination | undefined): number =>
  isDefined(pagination) && pagination.page > 0 && pagination.pageSize > 0
    ? (pagination.page - 1) * pagination.pageSize
    : 0;

export const getTotalPages = (totalItems: number, pageSize?: number): number =>
  isDefined(pageSize) && pageSize > 0 ? Math.ceil(totalItems / pageSize) : 1;

export const getPager = (cur: number, total: number): number[] => {
  if (cur < 1 || cur > total) {
    return [];
  }

  const inclusiveRange = (start: number, end: number) => Array.from(Array(end - start + 1), (_, i) => start + i);

  if (total <= 7) {
    return inclusiveRange(1, total);
  }

  const groupStart = Math.max(1, cur - 1);
  const groupEnd = Math.min(total, groupStart + 2);

  // Excluding edge number and page skip at the other end
  const edgeGroupSize = 5;

  // group at the left edge
  if (groupEnd <= edgeGroupSize) {
    return [...inclusiveRange(1, edgeGroupSize), PAGE_SKIP, total];
  }

  // group at the right edge
  if (groupStart >= total - edgeGroupSize + 1) {
    return [1, PAGE_SKIP, ...inclusiveRange(total - edgeGroupSize + 1, total)];
  }

  // group in the middle
  return [1, PAGE_SKIP, ...inclusiveRange(groupStart, groupEnd), PAGE_SKIP, total];
};
