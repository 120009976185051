import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { InfiniteListStoreType, LoadInfiniteListParams } from 'common/components/infinite-list';
import { textOrFallback } from 'common/helpers/data';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { Product, SearchProductsRequest, searchProducts } from './api';

export class ProductsStore extends BaseStore implements InfiniteListStoreType {
  private readonly productById = observable.map<string, Product>([], { deep: false });

  @observable hasMoreData = true;

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.productById.clear();
    this.loader.reset();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get products(): Product[] {
    return Array.from(this.productById.values(), (product) => ({
      ...product,
      canonicalName: textOrFallback(product.canonicalName, 'Product ""'),
    }));
  }

  @action loadInitialData = async ({ query, limit }: LoadInfiniteListParams) => {
    await this.loadData({
      query,
      limit,
      offset: 0,
    });
  };

  @action loadMoreData = async ({ query, limit }: LoadInfiniteListParams) => {
    if (this.hasMoreData) {
      await this.loadData({
        query,
        limit,
        offset: this.productById.size,
      });
    }
  };

  @action private readonly loadData = async (payload: SearchProductsRequest) => {
    const { cancelled, data, error } = await this.loader.load(() => searchProducts(payload));

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      if ((payload.offset ?? 0) > 0) {
        data.products.forEach((product) => this.productById.set(product.id, product));
      } else {
        this.productById.replace(data.products.map((product) => [product.id, product]));
      }
      this.hasMoreData = data.products.length >= (payload.limit ?? 0);
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
