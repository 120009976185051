import { isDefined, isFunction, isText } from '@whisklabs/typeguards';
import { useMemo } from 'react';

import { Column, ColumnId, SearchFunction, Sort } from 'common/components/table-view';

const getSearched = <T,>(data: T[], searchFunction?: SearchFunction<T>, search?: string): T[] => {
  if (isText(search) && isDefined(searchFunction)) {
    return searchFunction(data, search);
  }
  return data;
};

const getSorted = <T, U extends ColumnId>(data: T[], columns: Column<T, U>[], sort?: Sort<U>): T[] => {
  if (isDefined(sort)) {
    const column = columns.find((col) => col.id === sort.sortKey);
    if (isDefined(column)) {
      const sortCompareAsc = column.sorter;
      if (isFunction(sortCompareAsc)) {
        const dirMultiplier = sort.sortOrder === 'asc' ? 1 : -1;
        return Array.from(data).sort((a, b) => sortCompareAsc(a, b) * dirMultiplier);
      }
    }
  }
  return data;
};

interface Options<T, U extends ColumnId> {
  searchFunction?: SearchFunction<T>;
  search?: string;
  sort?: Sort<U>;
}

export const useTableData = <T, U extends ColumnId>(
  rawData: T[],
  columns: Column<T, U>[],
  { sort, search, searchFunction }: Options<T, U>
): T[] => {
  let data = rawData;
  data = useMemo(() => getSearched(data, searchFunction, search), [data, search, searchFunction]);
  data = useMemo(() => getSorted(data, columns, sort), [data, columns, sort]);
  return data;
};
