import { Button } from '@blueprintjs/core';
import { isDefined, isPresent, isString, isText } from '@whisklabs/typeguards';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useCallback, useMemo } from 'react';
import shallowEqual from 'shallowequal';

import { LoadingWrapper } from 'common/components/loading-wrapper';
import { DEFAULT_SEARCH_DEBOUNCE_DELAY, Search } from 'common/components/search';
import {
  Column,
  ColumnId,
  DEFAULT_HEAD_HEIGHT,
  DEFAULT_ROW_HEIGHT,
  NoData,
  PageChangeHandler,
  SearchHandler,
  SortHandler,
  TableBody,
  TableHead,
  TableLayout,
  TablePagination,
  TableProps,
} from 'common/components/table-view';

import { TableHeader } from './header';
import { toPx } from './helpers';
import { getPageData, useRestoreSortAfterSearch, useTableData, useTableState } from './hooks';

export const Table = observer(<T, U extends ColumnId>(props: TableProps<T, U>) => {
  const {
    loader,
    data: dataProp,
    columns: columnsProp,
    totalItems: totalItemsProp,
    renderRow,
    renderNewRow,
    rowKey,
    tableClassName,
    headHeight = DEFAULT_HEAD_HEIGHT,
    rowHeight = DEFAULT_ROW_HEIGHT,
    cellPaddingVertical,
    cellPaddingHorizontal,
    headerAction,
    fill = true,
    height = 'auto',
    width = fill ? '100%' : 'auto',
    layout = 'auto',
    searchFunction,
    searchDebounceDelay,
    defaultSort,
    withUnstableHeight = false,
    onChange,
  } = props;
  const [state, setState] = useTableState<T, U>(props);
  const { sort, search, pagination } = state;
  const columns: Column<T, U>[] = useMemo(() => columnsProp.filter((col) => !col.hidden), [columnsProp]);
  const data: T[] = useTableData(dataProp, columns, {
    sort,
    search,
    searchFunction,
  });
  const pageData: T[] = useMemo(() => getPageData(data, pagination), [data, pagination]);
  const hasTitles: boolean = useMemo(() => columns.some((col) => isPresent(col.title)), [columns]);
  const showTableHead: boolean = hasTitles && pageData.length > 0;
  const totalItems: number = (totalItemsProp ?? data.length) + (isDefined(renderNewRow) ? 1 : 0);

  const tableStyles: CSSProperties = useMemo(
    () => ({
      width: toPx(width),
      height:
        height === 'auto' && isDefined(pagination) && !withUnstableHeight
          ? `calc(${showTableHead ? toPx(headHeight) : 0} + ${toPx(rowHeight)} * ${pagination.pageSize})`
          : toPx(height),
    }),
    [width, height, pagination, showTableHead, headHeight, rowHeight, withUnstableHeight]
  );

  const shouldOfferSortByRelevance: boolean = useMemo(() => isDefined(sort) && isText(search), [sort, search]);

  const shouldOfferSortReset: boolean = useMemo(() => isDefined(sort) && !isDefined(defaultSort), [sort, defaultSort]);

  useRestoreSortAfterSearch(state, setState);

  const handleSort: SortHandler<U> = useCallback(
    (newSort) => {
      setState((curState) => {
        if (!shallowEqual(newSort, curState.sort)) {
          return { ...curState, sort: newSort };
        }
        return curState;
      });
    },
    [setState]
  );

  const handleSearch: SearchHandler = useCallback(
    (newSearch) => {
      setState((curState) => {
        if (newSearch !== curState.search) {
          return {
            ...curState,
            sort: undefined,
            pagination: isDefined(curState.pagination) ? { ...curState.pagination, page: 1 } : undefined,
            search: newSearch,
          };
        }
        return curState;
      });
    },
    [setState]
  );

  const handlePageChange: PageChangeHandler = useCallback(
    (newPage) => {
      setState((curState) => {
        if (isDefined(curState.pagination) && newPage !== curState.pagination.page) {
          return {
            ...curState,
            pagination: { ...curState.pagination, page: newPage },
          };
        }
        return curState;
      });
    },
    [setState]
  );

  const handleSortClear = useCallback(() => {
    handleSort(undefined);
  }, [handleSort]);

  const handleSortReset = useCallback(() => {
    handleSort(defaultSort ?? undefined);
  }, [handleSort, defaultSort]);

  return (
    <div>
      <TableHeader
        searchInput={
          isString(search) ? (
            <Search
              search={search}
              onChange={handleSearch}
              debounceDelay={searchDebounceDelay ?? (isPresent(onChange) ? DEFAULT_SEARCH_DEBOUNCE_DELAY : 0)}
            />
          ) : undefined
        }
        sortAction={
          shouldOfferSortByRelevance ? (
            <Button text="Sort by relevance" rightIcon="sort" onClick={handleSortClear} minimal large />
          ) : shouldOfferSortReset ? (
            <Button text="Reset sort" rightIcon="repeat" onClick={handleSortReset} minimal large />
          ) : null
        }
        rightAction={headerAction}
      />

      <div style={tableStyles}>
        <LoadingWrapper loader={loader} pending={loader?.isPending && data.length === 0}>
          {data.length > 0 || isDefined(renderNewRow) ? (
            <TableLayout className={tableClassName} layout={layout}>
              {showTableHead ? (
                <TableHead
                  data={pageData}
                  columns={columns}
                  paddingVertical={cellPaddingVertical}
                  paddingHorizontal={cellPaddingHorizontal}
                  sort={sort}
                  onSort={handleSort}
                />
              ) : null}

              <TableBody
                data={pageData}
                columns={columns}
                rowKey={rowKey}
                rowHeight={rowHeight}
                renderRow={renderRow}
                renderNewRow={renderNewRow}
                paddingVertical={cellPaddingVertical}
                paddingHorizontal={cellPaddingHorizontal}
              />
            </TableLayout>
          ) : (
            <NoData />
          )}
        </LoadingWrapper>
      </div>

      {isDefined(pagination) && totalItems > 0 ? (
        <TablePagination
          page={pagination.page}
          pageSize={pagination.pageSize}
          totalItems={totalItems}
          onPageChange={handlePageChange}
          loading={loader?.isPending}
        />
      ) : null}
    </div>
  );
});
