import { isPresent } from '@whisklabs/typeguards';

import { once } from 'common/helpers/functional';

export function getFlagEmoji(codeA2: string): string {
  // offset between uppercase ascii and country flag emojis
  const FLAG_CODE_OFFSET = 127397;
  return codeA2.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + FLAG_CODE_OFFSET));
}

// on Windows flag emojis show as text code, like "GB"
export const isFlagEmojiSupported = once((): boolean => {
  const canvas = document.createElement('canvas');
  canvas.height = 10;
  canvas.width = canvas.height * 2;

  const ctx = canvas.getContext('2d');

  if (isPresent(ctx)) {
    // black bg
    ctx.rect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = 'rgb(0, 0, 0)';
    ctx.fill();

    // black text
    ctx.font = `${canvas.height}px sans-serif`;
    ctx.fillStyle = 'rgb(0, 0, 0)';
    const nonBlackFlagEmoji = getFlagEmoji('JP');
    ctx.fillText(nonBlackFlagEmoji, 0, canvas.height);

    const { data } = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const RGBA_CHANNELS = 4;

    for (let i = 0; i < data.length - RGBA_CHANNELS; i += RGBA_CHANNELS) {
      // instead of flag emoji Windows just renders regular text which is black
      const isBlack = data[i] === 0 && data[i + 1] === 0 && data[i + 2] === 0;
      if (!isBlack) {
        return true;
      }
    }
  }

  return false;
});
