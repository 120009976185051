import { isDefined, isText } from '@whisklabs/typeguards';

import { entries } from 'common/helpers/functional';

export type QueryObject = Record<string, string | undefined>;

const tryDecodeURIComponent = (value = ''): string => {
  try {
    return decodeURIComponent(value.replace(/\+/g, '%20'));
  } catch {
    return value;
  }
};

export const parseQuery = (queryString: string): QueryObject => {
  const normalized = queryString.charAt(0) === '?' ? queryString.slice(1) : queryString;
  const result: QueryObject = {};

  if (normalized.length > 0) {
    for (const pair of normalized.split('&')) {
      const [key, value] = pair.split('=') as [string, string | undefined];
      const decodedKey = tryDecodeURIComponent(key);
      const decodedValue = tryDecodeURIComponent(value);

      if (isText(decodedKey) || isText(decodedValue)) {
        result[decodedKey] = decodedValue;
      }
    }
  }

  return result;
};

export const stringifyQuery = (query: QueryObject): string => {
  const result: string[] = [];

  for (const [key, value] of entries(query)) {
    if (isDefined(value)) {
      result.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }

  return result.join('&');
};
