import {
  whisk_backoffice_apps_v1_AppsAPI_AddAppPublisher as AddAppPublisher,
  whisk_backoffice_apps_v1_AddAppPublisherRequest as AddAppPublishersRequest,
  whisk_backoffice_apps_v1_AppPublisher as AppPublisher,
  whisk_backoffice_apps_v1_LicenceType as AppPublisherLicence,
  whisk_backoffice_apps_v1_AppsAPI_GetAppPublishers as GetAppPublishers,
  whisk_backoffice_apps_v1_GetAppPublishersRequest as GetAppPublishersRequest,
  whisk_backoffice_apps_v1_AppsAPI_RemoveAppPublishers as RemoveAppPublishers,
  whisk_backoffice_apps_v1_RemoveAppPublishersRequest as RemoveAppPublishersRequest,
  whisk_backoffice_apps_v1_AppsAPI_UpdateAppPublisher as UpdateAppPublisher,
  whisk_backoffice_apps_v1_UpdateAppPublisherRequest as UpdateAppPublisherRequest,
} from '@whisklabs/b2b-backoffice-protobuf';

import { b2bGrpc } from 'b2b/grpc';

export { AppPublisher, AppPublisherLicence };

const api = b2bGrpc();

export const getAppPublishers = (payload: GetAppPublishersRequest) => api(GetAppPublishers, payload);

export const addAppPublisher = (payload: AddAppPublishersRequest) => api(AddAppPublisher, payload);

export const updateAppPublisher = (payload: UpdateAppPublisherRequest) => api(UpdateAppPublisher, payload);

export const removeAppPublishers = (payload: RemoveAppPublishersRequest) => api(RemoveAppPublishers, payload);
