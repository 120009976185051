import { Colors, Icon, Text } from '@blueprintjs/core';
import { isText } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';

import { PopoverMenuItem } from 'common/components/popover-menu';
import { PopoverMenu } from 'common/components/popover-menu/popover-menu';
import { getStore } from 'common/stores';

export const clsSelectContent = css`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: border 200ms ease-out;
  user-select: none;
  svg {
    flex-shrink: 0;
    fill: ${Colors.GRAY3};
  }
  :hover {
    border-color: ${Colors.GRAY2};
  }
`;

const clsUserMenu = css`
  display: flex;
  align-items: center;
`;

const clsAvatar = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  overflow: hidden;
  margin-right: 8px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  background-color: #233748;
`;

const clsAvatarImage = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

const clsUserName = css`
  font-size: 16px;
  margin-right: 9px;
  color: #333;
`;

interface Props {
  className?: string;
}

export const UserMenu = observer((props: Props) => {
  const { className } = props;
  const { user, unauthenticateUser } = getStore('user');
  const { imageUrl, displayName } = user;

  return (
    <PopoverMenu
      target={
        <div className={clsSelectContent}>
          <div className={cx(clsUserMenu, className)}>
            <div className={clsAvatar}>
              {isText(displayName) ? displayName.charAt(0) : null}
              {isText(imageUrl) ? (
                <div
                  className={clsAvatarImage}
                  style={isText(imageUrl) ? { backgroundImage: `url("${imageUrl}")` } : undefined}
                />
              ) : null}
            </div>
            {isText(displayName) ? <Text className={clsUserName}>{displayName}</Text> : null}
            <Icon icon="chevron-down" iconSize={22} color="#bdbdbd" />
          </div>
        </div>
      }
      content={
        <>
          <PopoverMenuItem onClick={unauthenticateUser}>Log out</PopoverMenuItem>
        </>
      }
    />
  );
});
