import {
  whisk_backoffice_business_v1_BusinessAPI_ActivateAccount as ActivateBusiness,
  whisk_backoffice_business_v1_ActivateAccountRequest as ActivateBusinessRequest,
} from '@whisklabs/b2b-backoffice-protobuf';

import { b2bGrpc } from 'b2b/grpc';

const api = b2bGrpc();

export const activateBusiness = (payload: ActivateBusinessRequest) => api(ActivateBusiness, payload);
