import { isObject, isString, isText } from '@whisklabs/typeguards';

export function hasSearch(search: string, obj: unknown) {
  if (!isText(search) || !isObject(obj)) {
    return true;
  }
  const q = search.toLocaleLowerCase();

  for (const item of Object.values(obj)) {
    if ((isString(item) && item.toLocaleLowerCase().includes(q)) || (isObject(item) && hasSearch(q, item))) {
      return true;
    }
  }

  return false;
}
