import { Spinner } from '@blueprintjs/core';
import { cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';

import { B2BPage } from 'b2b';
import { B2CPage } from 'b2c';
import { ErrorBoundaryFallback } from 'common/components/error-boundary-fallback';
import { AdminLayout } from 'common/components/layouts/admin';
import { RouteSwitch } from 'common/components/routes';
import { getStore } from 'common/stores';
import { AuthStatus } from 'common/stores/user';
import { clsFlex, clsFlexAlignItemsCenter, clsFlexJustifyCenter } from 'common/styles/layout';
import { LoginPage } from 'login';

import { clsApp, clsGlobals, clsSpinner } from './styles';

export const AppPage = observer(() => {
  const { authStatus } = getStore('user');
  const { history } = getStore('router');

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <Router history={history}>
        <HelmetProvider>
          <div className={cx(clsApp, clsGlobals)}>
            {authStatus !== AuthStatus.pending ? (
              <RouteSwitch
                defaultPath={null}
                routes={[
                  { path: '/login', guard: 'publicOnly', component: LoginPage },
                  {
                    path: '/',
                    guard: 'private',
                    render: () => (
                      <AdminLayout
                        items={[
                          { path: '/b2b', label: 'Business', component: B2BPage },
                          { path: '/b2c', label: 'Whisk B2C', component: B2CPage },
                        ]}
                      />
                    ),
                  },
                ]}
              />
            ) : (
              <div className={cx(clsSpinner, clsFlex, clsFlexJustifyCenter, clsFlexAlignItemsCenter)}>
                <Spinner size={Spinner.SIZE_LARGE} />
              </div>
            )}
          </div>
        </HelmetProvider>
      </Router>
    </ErrorBoundary>
  );
});
