import { isText } from '@whisklabs/typeguards';

export const textOrFallback = (text: string | undefined, fallback: string | undefined): string => {
  const trimmedText = text?.trim();
  if (isText(trimmedText)) {
    return trimmedText;
  }

  return fallback ?? '';
};
