import { NonIdealState, Spinner } from '@blueprintjs/core';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';
import { ComponentProps, ReactNode } from 'react';

import { ErrorMessage, ErrorMessageProps } from 'common/components/error-message';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { MarkOptional } from 'common/types/types';

const clsEmpty = css`
  margin-top: 30px;
  height: auto !important;
`;

interface Props {
  loader?: DataLoader;
  status?: LoadStatus;
  pending?: boolean;
  fail?: boolean;
  empty?: boolean;
  pendingProps?: ComponentProps<typeof Spinner>;
  emptyProps?: MarkOptional<ComponentProps<typeof NonIdealState>, 'icon' | 'title' | 'description'>;
  errorProps?: MarkOptional<ComponentProps<typeof ErrorMessage>, 'title' | 'error'>;
  error?: ErrorMessageProps['error'];
  children: ReactNode;
}

export const LoadingWrapper = observer(
  ({
    loader,
    status = loader?.status,
    pending = status === LoadStatus.idle || status === LoadStatus.pending,
    fail = status === LoadStatus.fail,
    error = loader?.error,
    empty,
    pendingProps,
    emptyProps,
    errorProps,
    children,
  }: Props) => {
    if (pending) {
      return <Spinner {...pendingProps} />;
    }

    if (fail) {
      return <ErrorMessage title="Failed to load data" error={error} {...errorProps} />;
    }

    if (empty) {
      return <NonIdealState icon="search" title="No data found" className={clsEmpty} {...emptyProps} />;
    }

    return <>{children}</>;
  }
);
