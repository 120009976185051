import { Classes, Switch } from '@blueprintjs/core';
import { oneis, oneof } from '@whisklabs/grpc';
import { isDefined } from '@whisklabs/typeguards';
import { css, cx } from 'linaria';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { CountriesStore, FieldCountries } from 'b2b/countries';
import { FieldPublisher, stubPublisher } from 'b2b/publishers';
import { NonEmptyString } from 'common/components/non-empty-string';
import { RenderRowProps, RowActions, TableCell, TableRow } from 'common/components/table-view';
import { useStore } from 'common/hooks/use-store';
import { clsMb10, clsMb5 } from 'common/styles/margin-padding';
import { textTruncate } from 'common/styles/text';

import { AppPublisher } from '../api';
import { AppPublishersStore } from '../store';
import { ColumnId } from '../types';

import { AppPublisherFieldLicence } from './field-licence';
import { AppPublisherStore, DEFAULT_PUBLISHER_LICENCE } from './store';

const clsNameCell = css`
  min-height: 40px;
`;

const clsText = css`
  word-break: break-word;
`;

const clsGlobalRegionSwitch = css`
  line-height: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface Props extends RenderRowProps<AppPublisher, ColumnId> {
  appPublishersStore: AppPublishersStore;
  countriesStore: CountriesStore;
}

export const AppPublisherRow = observer(
  ({ appPublishersStore, countriesStore, rowKind, datum, getRowProps, getCellProps }: Props) => {
    const { form, add, addLoader, save, saveLoader, remove, removeLoader } = useStore(
      () => new AppPublisherStore(appPublishersStore)
    );

    useEffect(() => {
      form.setOnSubmit(
        action(({ publisher, globalRegion, countries, ...values }) => {
          const data: AppPublisher = {
            ...publisher,
            ...values,
            availableCountries: globalRegion
              ? { oneof: 'global', value: {} }
              : { oneof: 'countryList', value: { countries } },
            // legacy, required field
            countries: [],
          };

          if (rowKind === 'new') {
            void add(data);
          } else {
            void save(data);
          }
        })
      );
    }, [rowKind, form, save, add]);

    useEffect(() => {
      form.reset({
        publisher: isDefined(datum)
          ? {
              id: datum.id,
              name: datum.name ?? '',
              domain: datum.domain ?? '',
            }
          : stubPublisher(),
        licence: datum?.licence ?? DEFAULT_PUBLISHER_LICENCE,
        globalRegion: isDefined(datum) ? oneis(datum.availableCountries, 'global') : true,
        countries: oneof(datum?.availableCountries, 'countryList', (countryList) => countryList?.countries ?? []) ?? [],
      });
    }, [form, datum]);

    return (
      <>
        <TableRow {...getRowProps()}>
          <TableCell {...getCellProps('publisher')}>
            {rowKind === 'new' ? (
              <FieldPublisher field={form.fields.publisher} />
            ) : (
              <div className={cx(clsNameCell)}>
                <div className={cx(clsText, clsMb10)}>
                  <NonEmptyString value={form.values.publisher.name} />
                </div>
                <div className={cx(clsText, clsMb5, Classes.TEXT_SMALL, Classes.TEXT_MUTED)}>
                  Domain: <NonEmptyString value={form.values.publisher.domain} />
                </div>
                <div className={cx(clsText, Classes.TEXT_SMALL, Classes.TEXT_MUTED)}>
                  ID: <NonEmptyString value={form.values.publisher.id} />
                </div>
              </div>
            )}
          </TableCell>

          <TableCell {...getCellProps('countries')}>
            <Switch
              className={clsGlobalRegionSwitch}
              labelElement={<span className={textTruncate}>&#x1F30E; All countries</span>}
              checked={form.values.globalRegion}
              onChange={form.fields.globalRegion.handleChecked}
              large
            />
            {!form.values.globalRegion ? (
              <FieldCountries field={form.fields.countries} countries={countriesStore.countries} tagColumns={2} />
            ) : null}
          </TableCell>

          <TableCell {...getCellProps('licence')}>
            <AppPublisherFieldLicence field={form.fields.licence} />
          </TableCell>

          <TableCell {...getCellProps('action')}>
            <RowActions
              rowKind={rowKind}
              form={form}
              addLoader={addLoader}
              saveLoader={saveLoader}
              removeLoader={removeLoader}
              onRemove={() => (isDefined(datum) ? remove(datum) : undefined)}
            />
          </TableCell>
        </TableRow>
      </>
    );
  }
);
