import { isPresent } from '@whisklabs/typeguards';
import { cx } from 'linaria';

import { TableHeaderProps } from 'common/components/table-view';
import { clsFlex, clsFlexAlignItemsCenter, clsFlexJustifyBetween } from 'common/styles/layout';
import { clsMb20, clsMr10 } from 'common/styles/margin-padding';

export const TableHeader = ({ searchInput, sortAction, rightAction }: TableHeaderProps) => {
  if (!(isPresent(searchInput) || isPresent(sortAction) || isPresent(rightAction))) {
    return null;
  }

  return (
    <div className={cx(clsMb20, clsFlex, clsFlexAlignItemsCenter, clsFlexJustifyBetween)}>
      <div className={cx(clsFlex, clsFlexAlignItemsCenter)}>
        {isPresent(searchInput) ? <div className={clsMr10}>{searchInput}</div> : null}
        {sortAction}
      </div>
      {rightAction}
    </div>
  );
};
