import { Button, Classes, Dialog, Intent, Radio, RadioGroup } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { BusinessUserRoleType, businessUserRoleLabels } from 'b2b/businesses/details/common/user-role';
import { BusinessUser } from 'b2b/businesses/details/users/api';

import { ChangeUserRoleStore } from './store';

interface Props {
  store: ChangeUserRoleStore;
  user: BusinessUser;
}

export const ChangeUserRoleDialog = observer(({ store, user }: Props) => {
  const currentRole = user.userRole?.role;
  const { dialog, loader, availableRoles } = store;
  const [selectedRole, setSelectedRole] = useState<BusinessUserRoleType | undefined>(currentRole);

  useEffect(() => {
    setSelectedRole(user.userRole?.role);
  }, [setSelectedRole, user]);

  const handleRoleChange = useCallback(() => {
    if (isDefined(selectedRole)) {
      return store.changeUserRole(user, selectedRole);
    }
  }, [store, user, selectedRole]);

  return (
    <Dialog title="Edit user role" isOpen={dialog.isOpen} onClose={dialog.close}>
      {isDefined(user) ? (
        <>
          <div className={Classes.DIALOG_BODY}>
            <RadioGroup
              label={`Edit role for (${user.email})`}
              selectedValue={selectedRole}
              onChange={(event) => setSelectedRole(Number(event.currentTarget.value) as BusinessUserRoleType)}
            >
              {availableRoles.map((role) => (
                <Radio
                  key={role}
                  label={`${businessUserRoleLabels[role]}${role === currentRole ? ' (current)' : ''}`}
                  value={role}
                />
              ))}
            </RadioGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={dialog.close} text="Cancel" large />
              <Button
                onClick={handleRoleChange}
                text="Update"
                intent={Intent.PRIMARY}
                disabled={currentRole === selectedRole}
                loading={loader.isPending}
                large
              />
            </div>
          </div>
        </>
      ) : null}
    </Dialog>
  );
});
