import {
  whisk_backoffice_general_v1_Country as Country,
  whisk_backoffice_general_v1_GeneralInfoAPI_GetCountries as GetCountries,
} from '@whisklabs/b2b-backoffice-protobuf';

import { commonGrpc } from 'common/grpc';

export { Country };

const api = commonGrpc();

export const getCountries = () => api(GetCountries);
