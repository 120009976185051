import {
  whisk_backoffice_b2c_v1_CommunityAPI_BanCommunity as BanCommunity,
  whisk_backoffice_b2c_v1_BanCommunityRequest as BanCommunityRequest,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { BanCommunityRequest };

const api = b2cGrpc();

export const banCommunity = (payload: BanCommunityRequest) => api(BanCommunity, payload);
