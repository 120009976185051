import { isText } from '@whisklabs/typeguards';
import Cookies from 'js-cookie';

import { TOKEN_COOKIE, TOKEN_COOKIE_CONFIG } from 'common/config';

export const getToken = () => Cookies.get(TOKEN_COOKIE);

export const hasToken = () => isText(Cookies.get(TOKEN_COOKIE));

export const setToken = (token: string) => Cookies.set(TOKEN_COOKIE, token, TOKEN_COOKIE_CONFIG);

export const removeToken = () => {
  const { domain, path } = TOKEN_COOKIE_CONFIG;
  Cookies.remove(TOKEN_COOKIE, { domain, path });
};
