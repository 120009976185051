import { isDefined } from '@whisklabs/typeguards';

import { Pagination } from 'common/components/table-view';

export const getPageData = <T>(data: T[], pagination?: Pagination): T[] => {
  if (!isDefined(pagination) || data.length <= pagination.pageSize) {
    return data;
  }

  const startIndex = Math.max((pagination.page - 1) * pagination.pageSize, 0);
  const endIndex = Math.min(startIndex + pagination.pageSize, data.length);

  return startIndex > 0 || endIndex < data.length ? data.slice(startIndex, endIndex) : data;
};
