import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { DeletePopover } from 'common/components/delete-popover';
import { useStore } from 'common/hooks/use-store';

import { Widget } from '../api';

import { WidgetDeleteStore } from './store';

interface Props {
  widget: Widget;
  onDeleted: (Widget: Widget) => void;
  className?: string;
  large?: boolean;
}

export const WidgetDelete = observer(({ widget, onDeleted, className, large = false }: Props) => {
  const { deleteWidget, deleteLoaders } = useStore(() => new WidgetDeleteStore());
  const deleteLoader = deleteLoaders.get(widget.id);

  const handleDeleteConfirm = useCallback(async () => {
    await deleteWidget(widget);
    if (deleteLoader.isOk) {
      onDeleted(widget);
    }
  }, [deleteWidget, deleteLoader, widget, onDeleted]);

  return (
    <DeletePopover
      entityType="widget"
      entityName={widget.name}
      onConfirmDelete={handleDeleteConfirm}
      isDeleting={deleteLoader.isPending}
      large={large}
      className={className}
    />
  );
});
