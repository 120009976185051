import { oneof } from '@whisklabs/grpc';
import { isDefined, isUndefined } from '@whisklabs/typeguards';

import { GetReportedEntitiesResponse, ModeratedEntityType, Reported, entityMap } from './api';

export const adaptReported = (type: ModeratedEntityType, data?: GetReportedEntitiesResponse): Reported[] => {
  if (isUndefined(data)) {
    return [];
  }

  return data.entities
    .map(({ value, ...other }) => {
      const item = oneof(value, entityMap[type]);

      if (isUndefined(item)) {
        return;
      }

      const reported: Reported = {
        ...other,
        item,
      };

      return reported;
    })
    .filter(isDefined);
};
