import { MenuItem } from '@blueprintjs/core';
import { ItemRenderer } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';

import { getFieldFormGroupProps } from 'common/components/form/helpers';
import { Suggest } from 'common/components/form/suggest';
import { FieldModel } from 'common/helpers/form';
import { createSearchFunction, searchRankings } from 'common/helpers/search';

interface Item {
  value: string;
  label: string;
}

const items: Item[] = [
  { value: 'AR', label: 'Argentina' },
  { value: 'AT', label: 'Austria' },
  { value: 'AU', label: 'Australia' },
  { value: 'CA', label: 'Canada' },
  { value: 'DE', label: 'Germany' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'ES', label: 'Spain' },
  { value: 'FR', label: 'France' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'MX', label: 'Mexico' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'PL', label: 'Poland' },
  { value: 'US', label: 'United States' },
  { value: 'OTHER', label: 'Other' },
];

const renderItem: ItemRenderer<Item> = (item, { handleClick, modifiers }) =>
  modifiers.matchesPredicate ? (
    <MenuItem active={modifiers.active} key={item.value} onClick={handleClick} text={item.label} />
  ) : null;

const renderInputValue = (item: Item) => item.label;

const filterFunction = createSearchFunction<Item>([
  (item) => item.label,
  { key: (item) => item.value, threshold: searchRankings.EQUAL },
]);

interface Props {
  field: FieldModel<string>;
  label: string;
  className?: string;
}

export const BusinessAccountFormFieldCountry = observer(({ field, label, className }: Props) => {
  const handleItemSelect = useCallback(
    (item: Item) => {
      field.handleValueChange(item.value);
    },
    [field]
  );

  const selectedItem = useMemo(() => items.find((item) => item.value === field.value), [field.value]);
  const { labelInfo, helperText, intent } = getFieldFormGroupProps(field);

  return (
    <Suggest
      id={field.id}
      className={className}
      label={label}
      labelInfo={labelInfo}
      helperText={helperText}
      intent={intent}
      placeholder=""
      inputValueRenderer={renderInputValue}
      items={items}
      itemRenderer={renderItem}
      onItemSelect={handleItemSelect}
      disabled={field.isDisabled}
      selectedItem={selectedItem ?? null}
      onBlur={field.handleBlur}
      filterFunction={filterFunction}
    />
  );
});
