import { css } from 'linaria';

const clsNoData = css`
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const clsNoDataTitle = css`
  font-size: 28px;
`;

export const NoData = () => (
  <div className={clsNoData}>
    <h4 className={clsNoDataTitle}>No data</h4>
  </div>
);
