import { FormGroup } from '@blueprintjs/core';
import { identity } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';

import { getFieldControlProps, getFieldFormGroupProps } from 'common/components/form/helpers';
import { Multiselect, MultiselectItem } from 'common/components/form/multi-select';
import { FieldModel } from 'common/helpers/form';
import { stringCompareAsc } from 'common/helpers/sort';
import { useStore } from 'common/hooks/use-store';

import { CommunityTopicsStore } from './store';

interface Props {
  field: FieldModel<string[]>;
  disabled?: boolean;
}

export const FieldCommunityTopics = observer(({ field, disabled }: Props) => {
  const { loadTopics, topics } = useStore(() => new CommunityTopicsStore());

  useEffect(() => {
    void loadTopics();
  }, [loadTopics]);

  const items: MultiselectItem<string>[] = useMemo(
    () =>
      topics
        .map((topic) => ({
          value: topic.id,
          label: topic.name,
        }))
        .sort((a, b) => stringCompareAsc(a.label, b.label)),
    [topics]
  );

  return (
    <FormGroup {...getFieldFormGroupProps(field)} label="Topics">
      <Multiselect
        {...getFieldControlProps(field)}
        items={items}
        getValueKey={identity}
        placeholder="Search topics"
        disabled={disabled}
        minimal
        large
      />
    </FormGroup>
  );
});
