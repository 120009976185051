import { Classes, MenuItem } from '@blueprintjs/core';
import { IItemListRendererProps, renderFilteredItems } from '@blueprintjs/select';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';

import { InfiniteScroll } from 'common/components/infinite-scroll';
import { DataLoader } from 'common/helpers/data-loader';

const clsListContainer = css`
  max-height: 300px;
`;

export interface LoadInfiniteListParams {
  limit: number;
  query: string;
}

export interface InfiniteListStoreType {
  loadInitialData: (params: LoadInfiniteListParams) => void;
  loadMoreData: (params: LoadInfiniteListParams) => void;
  hasMoreData: boolean;
  loader: DataLoader;
}

interface Props<T> {
  store: InfiniteListStoreType;
  itemListRendererProps: IItemListRendererProps<T>;
  limit?: number;
  queryRequired?: boolean;
}

export const InfiniteList = observer(
  <T,>({ store, limit = 20, itemListRendererProps, queryRequired = false }: Props<T>) => {
    const { query, filteredItems, itemsParentRef } = itemListRendererProps;
    const disabled = useMemo(() => queryRequired && query.trim().length === 0, [queryRequired, query]);
    const params: LoadInfiniteListParams = useMemo(() => ({ limit, query }), [limit, query]);

    useEffect(() => {
      if (!disabled) {
        void store.loadInitialData(params);
      }
    }, [store, params, disabled]);

    const handleLoadMore = useCallback(() => {
      void store.loadMoreData(params);
    }, [store, params]);

    if (disabled) {
      return null;
    }

    return (
      <InfiniteScroll
        loadMore={handleLoadMore}
        hasMore={store.hasMoreData}
        isEmpty={filteredItems.length === 0}
        emptyElement={<MenuItem text="No results." disabled />}
        isLoading={store.loader.isPending}
        className={cx(Classes.MENU, clsListContainer)}
        ref={itemsParentRef}
      >
        {renderFilteredItems(itemListRendererProps)}
      </InfiniteScroll>
    );
  }
);
