import { action, makeObservable } from 'mobx';

import { User, UserRoleType } from 'b2b/users/api';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateEmail, validateRequired } from 'common/helpers/form/validators';
import { BaseStore } from 'common/stores';

export class UserFormStore extends BaseStore {
  readonly form = new FormModel({
    email: new FieldModel('').validators(validateRequired, validateEmail),
    role: new FieldModel<UserRoleType>(UserRoleType.USER_ROLE_BASIC).validators(validateRequired),
  });

  @action fill = (user: User): void => {
    this.form.reset({
      email: user.email,
      role: (user.roles[0] ?? UserRoleType.USER_ROLE_BASIC) as UserRoleType,
    });
  };

  @action storeReset = () => {
    this.form.reset();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
