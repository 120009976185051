import {
  whisk_backoffice_business_v1_BusinessAPI_GetBusinessAccounts,
  whisk_backoffice_business_v1_ShortAccountInfo,
} from '@whisklabs/b2b-backoffice-protobuf';

import { b2bGrpc } from 'b2b/grpc';

export type BusinessAccountPreview = whisk_backoffice_business_v1_ShortAccountInfo;

const api = b2bGrpc();

export const getBusinesses = () => api(whisk_backoffice_business_v1_BusinessAPI_GetBusinessAccounts);
