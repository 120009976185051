import { observer } from 'mobx-react-lite';

import { PageHeader } from 'common/components/page-header';

import { ClaimedDomainsTable } from './form';

export const ClaimedDomains = observer(() => (
  <>
    <PageHeader title="Claimed Domains" />
    <ClaimedDomainsTable />
  </>
));
