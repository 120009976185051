import { Radio, RadioGroup } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { RuleType } from 'b2c/indexing-tool/types';
import { Card, CardTitle } from 'common/components/card';
import { clsFormMaxWidth } from 'common/components/form/styles';
import { clsFlex } from 'common/styles/layout';
import { clsMb20 } from 'common/styles/margin-padding';

import { IndexingToolContext } from '../context';

import { ActionsSelect } from './actions';

const clsWrapperGap = css`
  gap: 100px;
`;

export const Settings = observer(() => {
  const {
    controlStore: { ruleType, setRuleType, recordType, setRecordType },
  } = useContext(IndexingToolContext);

  return (
    <Card header={<CardTitle>Settings</CardTitle>}>
      <div className={cx(clsFormMaxWidth, clsFlex, clsWrapperGap)}>
        <RadioGroup
          className={clsMb20}
          label="Rule type"
          selectedValue={ruleType}
          onChange={(event) => setRuleType(Number(event.currentTarget.value) as RuleType)}
        >
          <Radio
            key={RuleType.DIRECTORY_RULE_TYPE_NO_INDEX}
            label="Deindex"
            value={RuleType.DIRECTORY_RULE_TYPE_NO_INDEX}
          />
          <Radio
            key={RuleType.DIRECTORY_RULE_TYPE_ALWAYS_INDEX}
            label="Index"
            value={RuleType.DIRECTORY_RULE_TYPE_ALWAYS_INDEX}
          />
        </RadioGroup>
        <ActionsSelect value={recordType} onClick={setRecordType} />
      </div>
    </Card>
  );
});
