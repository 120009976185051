import { isFunction } from '@whisklabs/typeguards';

import { typedMemo } from 'common/helpers/react';

import { TableRow } from '../table-row';
import { ColumnId, TableHeadProps } from '../types';

import { TableHeadCell } from './head-cell';
import { clsTableHead } from './styles';

export const TableHead = typedMemo(<T, U extends ColumnId>({ data, columns, ...columnProps }: TableHeadProps<T, U>) => (
  <div className={clsTableHead}>
    <TableRow>
      {columns.map((column) => (
        <TableHeadCell key={column.id} column={column} {...columnProps}>
          {isFunction(column.title) ? column.title(data) : column.title}
        </TableHeadCell>
      ))}
    </TableRow>
  </div>
));
