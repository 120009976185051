import {
  whisk_backoffice_b2c_v1_Community,
  whisk_backoffice_b2c_v1_CommunityAPI_GetCommunity,
} from '@whisklabs/b2c-backoffice-protobuf';
import { Default, ServiceRequest } from '@whisklabs/grpc';

import { b2cGrpc } from 'b2c/grpc';

export type Community = whisk_backoffice_b2c_v1_Community;

export const stubCommunity = (): Community => Default(whisk_backoffice_b2c_v1_Community);

const api = b2cGrpc();

export type GetCommunityRequest = ServiceRequest<whisk_backoffice_b2c_v1_CommunityAPI_GetCommunity>;

export const getCommunity = (payload: GetCommunityRequest) =>
  api(whisk_backoffice_b2c_v1_CommunityAPI_GetCommunity, payload);
