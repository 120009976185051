import { Intent } from '@blueprintjs/core';
import { isText, isUndefined } from '@whisklabs/typeguards';
import { isEqual } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { nanoid } from 'nanoid/non-secure';
import { ChangeEventHandler } from 'react';

export type FieldValue<T extends FieldModel> = T['value'];
export interface FieldValidator<T = unknown> {
  (val: T): true | string;
  IS_REQUIRED_VALIDATOR?: true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class FieldModel<T = any> {
  readonly id = nanoid();

  @observable.ref private fieldValidators: FieldValidator<T>[] = [];
  @observable.ref private fieldReadonly = false;
  @observable.ref private fieldDisabled = false;
  @observable.ref initialValue: T;
  @observable.ref value: T;
  @observable private errorText?: string;
  @observable isTouched = false;

  constructor(value: T) {
    makeObservable(this);
    this.value = value;
    this.initialValue = value;
    this.validate();
  }

  get isReadonly() {
    return this.fieldReadonly;
  }

  get isDisabled() {
    return this.fieldDisabled;
  }

  @computed get isRequired() {
    return this.fieldValidators.some((validator) => validator.IS_REQUIRED_VALIDATOR);
  }

  @computed get isValid() {
    return this.isReadonly || isUndefined(this.errorText);
  }

  @computed get isDirty() {
    return !isEqual(this.value, this.initialValue);
  }

  @computed private get validState() {
    return this.isTouched ? this.isValid : undefined;
  }

  @computed get intent() {
    return this.validState === false ? Intent.DANGER : Intent.NONE;
  }

  @computed get error() {
    return this.validState === false ? this.errorText : undefined;
  }

  getValidators = (): readonly FieldValidator<T>[] => {
    return this.fieldValidators;
  };

  @action validators = (...validators: FieldValidator<T>[]) => {
    this.fieldValidators = Array.from(validators);
    this.validate();

    return this;
  };

  @action readonly = (isReadonly: boolean) => {
    this.fieldReadonly = isReadonly;
    if (isReadonly) {
      this.fieldDisabled = false;
    }

    return this;
  };

  @action disabled = (isDisabled: boolean) => {
    this.fieldDisabled = isDisabled;
    if (isDisabled) {
      this.fieldReadonly = false;
    }

    return this;
  };

  @action setValue = (value: T) => {
    this.write(value);
  };

  @action handleInput: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event): void => {
    this.write(event.target.value as unknown as T);
  };

  @action handleChecked: ChangeEventHandler<HTMLInputElement> = (event): void => {
    this.write(event.target.checked as unknown as T);
    this.markTouched();
  };

  @action handleValueChange = (value: T) => {
    this.write(value);
    this.markTouched();
  };

  @action handleBlur = () => {
    this.markTouched();
  };

  @action private write(value: T) {
    this.value = value;
    this.errorText = undefined;
    this.validate();
  }

  @action validate = () => {
    let validationResult: string | true = true;

    for (const validator of this.fieldValidators) {
      const maybeErrorText = validator(this.value);
      // set error from the first failed validator
      if (isText(maybeErrorText)) {
        validationResult = maybeErrorText;
        break;
      }
    }

    this.errorText = isText(validationResult) ? validationResult : undefined;

    return this;
  };

  markTouched = () => {
    this.isTouched = true;

    return this;
  };

  @action reset = (value: T) => {
    this.value = value;
    this.initialValue = value;
    this.isTouched = false;
    this.errorText = undefined;
    this.validate();

    return this;
  };
}
