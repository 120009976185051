import { H3, H4 } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { observer } from 'mobx-react-lite';

import { FieldInput } from 'common/components/fields/field-input';
import { FieldTextArea } from 'common/components/fields/field-textarea';
import { clsFlex, clsFlexJustifyBetween } from 'common/styles/layout';
import { clsMb20, clsMb30 } from 'common/styles/margin-padding';

import { BusinessAccountFormFieldCountry } from './field-country';
import { BusinessAccountFormFieldIndustry } from './field-industry';
import { BusinessAccountFormStore } from './store';

const clsWidthHalf = css`
  width: 47.5%;
`;

interface Props {
  store: BusinessAccountFormStore;
}

export const BusinessAccountFormFields = observer(({ store }: Props) => {
  const { form } = store;

  return (
    <div className={cx(clsFlex, clsFlexJustifyBetween, clsMb30)}>
      <div className={clsWidthHalf}>
        <div>
          <H3 className={clsMb20}>Company</H3>
          <FieldInput field={form.fields.name} label="Business Name" placeholder="ACME Inc" maxLength={255} />
          <FieldInput
            field={form.fields.website}
            label="Business Website"
            placeholder="www.CompanyName.com"
            maxLength={255}
          />
          <BusinessAccountFormFieldIndustry field={form.fields.industry} label="Business Type" />
          <FieldInput
            field={form.fields.socialMediaUrl}
            label="Business Social Media"
            placeholder="www.facebook.com/CompanyName/"
            maxLength={255}
          />
          <FieldTextArea
            field={form.fields.useCaseDescription}
            label="Use Case"
            placeholder="What are you looking to build with our tools?"
            maxLength={1000}
          />

          <div>
            <H4>Billing Contact</H4>
            <div className={cx(clsFlex, clsFlexJustifyBetween)}>
              <FieldInput
                field={form.fields.billingContactName}
                label="Name"
                maxLength={255}
                className={clsWidthHalf}
              />
              <FieldInput
                field={form.fields.billingContactEmail}
                type="email"
                label="Email"
                maxLength={255}
                className={clsWidthHalf}
              />
            </div>
          </div>

          <div>
            <H4>Technical Contact</H4>
            <div className={cx(clsFlex, clsFlexJustifyBetween)}>
              <FieldInput field={form.fields.techContactName} label="Name" maxLength={255} className={clsWidthHalf} />
              <FieldInput
                field={form.fields.techContactEmail}
                type="email"
                label="Email"
                maxLength={255}
                className={clsWidthHalf}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={clsWidthHalf}>
        <H3 className={clsMb20}>Address</H3>
        <FieldInput field={form.fields.streetAddress} label="Street Address" maxLength={255} />
        <div className={cx(clsFlex, clsFlexJustifyBetween)}>
          <FieldInput field={form.fields.city} label="City" maxLength={255} className={clsWidthHalf} />
          <FieldInput
            field={form.fields.stateOrProvince}
            label="State / Province"
            maxLength={255}
            className={clsWidthHalf}
          />
        </div>
        <div className={cx(clsFlex, clsFlexJustifyBetween)}>
          <BusinessAccountFormFieldCountry field={form.fields.country} label="Country" className={clsWidthHalf} />
          <FieldInput field={form.fields.zipCode} label="Zip Code" maxLength={255} className={clsWidthHalf} />
        </div>
        <div className={cx(clsFlex, clsFlexJustifyBetween)}>
          <FieldInput field={form.fields.phoneNumber} label="Phone Number" maxLength={255} className={clsWidthHalf} />
        </div>
      </div>
    </div>
  );
});
