import {
  whisk_backoffice_b2c_v1_RecipeAPI_BanRecipe as BanRecipe,
  whisk_backoffice_b2c_v1_BanRecipeRequest as BanRecipeRequest,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { BanRecipeRequest };

const api = b2cGrpc();

export const banRecipe = (payload: BanRecipeRequest) => api(BanRecipe, payload);
