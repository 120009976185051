import { whisk_backoffice_shared_v1_Cursors as Cursors } from '@whisklabs/b2c-backoffice-protobuf';
import { isDefined, isText } from '@whisklabs/typeguards';
import { IReactionDisposer, action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';

import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { deleteRules, getRules } from '../../api';
import { Rule } from '../../types';
import { ControlStore } from '../settings/store';

export class RulesListStore extends BaseStore {
  @observable private cursors: Cursors | undefined;
  @observable.ref private rules: Rule[] = [];
  private readonly disposer?: IReactionDisposer;

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  constructor(private readonly controlStore: ControlStore) {
    super();
    this.disposer = reaction(
      () => ({ recordType: this.controlStore.recordType, ruleType: this.controlStore.ruleType }),
      () => {
        this.rules = [];
        void this.loadData();
      },
      { fireImmediately: true }
    );
    makeObservable(this);
  }

  @action
  storeReset = () => {
    this.rules = [];
    this.loader.reset();
    this.disposer?.();
  };

  @computed
  get rulesList(): Rule[] {
    return this.rules;
  }

  @action
  private readonly removeRecord = (id: string) => {
    this.rules = this.rules.filter(({ recordId }) => recordId !== id);
  };

  @action
  loadData = async () => {
    const { cancelled, data, error } = await this.loader.load(() =>
      getRules({
        limit: 500,
        recordType: this.controlStore.recordType,
        ruleType: this.controlStore.ruleType,
        cursors: {
          after: this.cursors?.after ?? '',
          before: '',
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      const cursors = data.paging?.cursors;
      runInAction(() => {
        this.rules = this.rules.concat(data.rule);
      });

      if (isText(cursors?.after) && cursors?.after !== this.cursors?.after) {
        runInAction(() => {
          this.cursors = cursors;
        });
        void this.loadData();
      } else {
        this.loader.ok();
      }
    } else {
      this.loader.fail(error);
    }
  };

  @action
  removeRule = async ({ recordId }: Rule) => {
    const { cancelled, success, error } = await this.loader.load(() =>
      deleteRules({
        recordIds: [recordId],
        recordType: this.controlStore.recordType,
        ruleType: this.controlStore.ruleType,
      })
    );

    if (cancelled) {
      return;
    }

    if (success) {
      getStore('toast').success('Record removed');
      this.removeRecord(recordId);
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
