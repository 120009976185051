import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { DeletePopover } from 'common/components/delete-popover';
import { useStore } from 'common/hooks/use-store';

import { AppDeleteStore } from './store';
import { DeletedApp } from './types';

interface Props {
  app: DeletedApp;
  onDeleted: (app: DeletedApp) => void;
  className?: string;
  large?: boolean;
}

export const AppDelete = observer(({ app, onDeleted, className, large = false }: Props) => {
  const { deleteApp, deleteLoaders } = useStore(() => new AppDeleteStore());
  const deleteLoader = deleteLoaders.get(app.appId);

  const handleDeleteConfirm = useCallback(async () => {
    await deleteApp(app);
    if (deleteLoader.isOk) {
      onDeleted(app);
    }
  }, [deleteApp, deleteLoader, app, onDeleted]);

  return (
    <DeletePopover
      entityType="app"
      entityName={app.name}
      onConfirmDelete={handleDeleteConfirm}
      isDeleting={deleteLoader.isPending}
      large={large}
      className={className}
    />
  );
});
