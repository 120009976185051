import { isDefined } from '@whisklabs/typeguards';
import { observer } from 'mobx-react-lite';
import { Fragment, ReactNode } from 'react';

import { BusinessUserRoleType } from 'b2b/businesses/details/common/user-role';
import { BusinessUser } from 'b2b/businesses/details/users/api';
import { ChangeUserRoleDialog, ChangeUserRoleStore } from 'b2b/businesses/details/users/table/change-user-role';
import { DotsMenu, DotsMenuItem } from 'common/components/dots-menu';
import { useStore } from 'common/hooks/use-store';

import { RemoveUserDialog, RemoveUserStore } from './remove-user';
import { BusinessUsersTableStore } from './store';

interface DialogOption {
  dialog: ReactNode;
  menuItem: ReactNode;
}

interface Props {
  store: BusinessUsersTableStore;
  user: BusinessUser;
}

export const UsersTableDotsMenu = observer(({ store, user }: Props) => {
  const changeUserRoleStore = useStore(() => new ChangeUserRoleStore(store));
  const removeUserStore = useStore(() => new RemoveUserStore(store));

  // FIXME: https://samsungnext.atlassian.net/browse/WBI-36
  // FIXME: https://samsungnext.atlassian.net/browse/WBI-37
  const isRemoveUserAllowed = user.userRole?.role !== BusinessUserRoleType.ROLE_OWNER;
  const isChangeRoleAllowed = user.userRole?.role !== BusinessUserRoleType.ROLE_OWNER;

  if (!isChangeRoleAllowed && !isRemoveUserAllowed) {
    return null;
  }

  const dialogOptions: DialogOption[] = [
    isChangeRoleAllowed
      ? {
          dialog: <ChangeUserRoleDialog store={changeUserRoleStore} user={user} />,
          menuItem: <DotsMenuItem onClick={changeUserRoleStore.dialog.open}>Edit role</DotsMenuItem>,
        }
      : undefined,
    // {
    //   dialog: <ResetPasswordDialog user={user} store={resetPasswordStore} />,
    //   menuItem: <DotsMenuItem onClick={resetPasswordStore.dialog.open}>Reset password</DotsMenuItem>,
    // },
    isRemoveUserAllowed
      ? {
          dialog: <RemoveUserDialog user={user} store={removeUserStore} />,
          menuItem: <DotsMenuItem onClick={removeUserStore.dialog.open}>Remove user</DotsMenuItem>,
        }
      : undefined,
  ].filter(isDefined);

  return (
    <>
      {dialogOptions.map(({ dialog }, index) => (
        <Fragment key={index}>{dialog}</Fragment>
      ))}
      <DotsMenu
        content={
          <>
            {dialogOptions.map(({ menuItem }, index) => (
              <Fragment key={index}>{menuItem}</Fragment>
            ))}
          </>
        }
      />
    </>
  );
});
