import { Button, Classes, Colors, Icon, Intent, Popover, Position } from '@blueprintjs/core';
import { css, cx } from 'linaria';
import { memo } from 'react';

import { ButtonDelete } from 'common/components/buttons';
import { sharedPopoverProps } from 'common/popover';
import { clsFlex } from 'common/styles/layout';

const clsPopover = css`
  & .${Classes.POPOVER_CONTENT} {
    padding: 20px;
    max-width: 400px;
    background-color: ${Colors.LIGHT_GRAY4};
  }

  & .bp3-popover-arrow-fill {
    fill: ${Colors.LIGHT_GRAY4};
  }
`;

const clsBody = css`
  display: flex;
  align-items: flex-start;
`;

const clsIcon = css`
  margin-right: 20px;
`;

const clsContent = css`
  font-size: 16px;
  overflow: hidden;
  margin-bottom: 20px;
`;

const clsName = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const clsFooter = css`
  display: flex;
  margin-top: 10px;
`;

const clsActions = css`
  margin-left: auto;
`;

const clsAction = css`
  margin-left: 10px;
  &:first-child {
    margin-left: 0;
  }
`;

interface Props {
  entityType: string;
  entityName: string;
  onConfirmDelete: () => void;
  isDeleting: boolean;
  className?: string;
  large?: boolean;
}

export const DeletePopover = memo(
  ({ entityType, entityName, onConfirmDelete, isDeleting, className, large = false }: Props) => {
    return (
      <Popover
        {...sharedPopoverProps}
        minimal={false}
        position={Position.LEFT}
        className={className}
        popoverClassName={clsPopover}
        content={
          <>
            <div className={clsBody}>
              <Icon icon="trash" iconSize={40} intent={Intent.DANGER} className={clsIcon} />
              <div className={clsContent}>
                Are you sure you want to delete {entityType}
                <br />
                <div className={clsFlex}>
                  <strong className={clsName} title={entityName}>
                    {entityName}
                  </strong>{' '}
                  <span>?</span>
                </div>
              </div>
            </div>
            <div className={clsFooter}>
              <div className={clsActions}>
                <Button className={cx(clsAction, Classes.POPOVER_DISMISS)} large>
                  Cancel
                </Button>
                <Button
                  onClick={onConfirmDelete}
                  intent={Intent.DANGER}
                  className={clsAction}
                  loading={isDeleting}
                  large
                >
                  Delete
                </Button>
              </div>
            </div>
          </>
        }
        target={<ButtonDelete large={large} />}
      />
    );
  }
);
