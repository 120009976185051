import { isDefined, isText } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { InfiniteListStoreType, LoadInfiniteListParams } from 'common/components/infinite-list';
import { textOrFallback } from 'common/helpers/data';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { Brand, SearchBrandsRequest, searchBrands } from './api';

export class BrandsStore extends BaseStore implements InfiniteListStoreType {
  private readonly brandById = observable.map<string, Brand>([], { deep: false });

  @observable private cursorAfter = '';

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.brandById.clear();
    this.loader.reset();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get hasMoreData(): boolean {
    return isText(this.cursorAfter);
  }

  @computed get brands(): Brand[] {
    return Array.from(this.brandById.values(), (brand) => ({
      ...brand,
      canonicalName: textOrFallback(brand.canonicalName, 'Brand ""'),
    }));
  }

  @action loadInitialData = async ({ query, limit }: LoadInfiniteListParams) => {
    await this.loadData({
      query,
      limit,
    });
  };

  @action loadMoreData = async ({ query, limit }: LoadInfiniteListParams) => {
    if (this.hasMoreData) {
      await this.loadData({
        query,
        limit,
        cursors: { before: '', after: this.cursorAfter },
      });
    }
  };

  @action private readonly loadData = async (payload: SearchBrandsRequest) => {
    const { cancelled, data, error } = await this.loader.load(() => searchBrands(payload));

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      if (isText(payload.cursors?.after)) {
        data.brands.forEach((brand) => this.brandById.set(brand.id, brand));
      } else {
        this.brandById.replace(data.brands.map((brand) => [brand.id, brand]));
      }
      this.setCursor(data.paging?.cursors?.after);
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action private setCursor(after = '') {
    this.cursorAfter = after;
  }
}
