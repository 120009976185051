import { FocusEventHandler, MouseEventHandler, useCallback, useRef } from 'react';

type InputElement = HTMLInputElement | HTMLTextAreaElement;

export interface SelectAllHandlers {
  onFocus: FocusEventHandler<InputElement>;
  onMouseMove: MouseEventHandler<InputElement>;
  onMouseUp: MouseEventHandler<InputElement>;
}

export function useSelectAllInput(): SelectAllHandlers {
  const isFocusedRef = useRef(false);

  const onFocusSelectAll: FocusEventHandler<InputElement> = useCallback((event) => {
    isFocusedRef.current = true;
    event.target.select();
  }, []);

  const onMouseMovePreventDeselect: MouseEventHandler<InputElement> = useCallback((event) => {
    if (isFocusedRef.current) {
      event.preventDefault();
    }
  }, []);

  const onMouseUp: MouseEventHandler<InputElement> = useCallback(() => {
    isFocusedRef.current = false;
  }, []);

  return {
    onFocus: onFocusSelectAll,
    onMouseMove: onMouseMovePreventDeselect,
    onMouseUp,
  };
}
