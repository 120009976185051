import {
  whisk_backoffice_users_v1_UsersAPI_DeleteUser as DeleteUser,
  whisk_backoffice_users_v1_DeleteUserRequest as DeleteUserRequest,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { DeleteUserRequest };

const api = b2cGrpc();

export const deleteUser = (payload: DeleteUserRequest) => api(DeleteUser, payload);
