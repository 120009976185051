import { isDefined, isPresent, isText } from '@whisklabs/typeguards';
import { History } from 'history';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { ProtectedRoute, ProtectedRouteProps, RouteGuardProp } from 'common/components/routes/protected-route';
import { join } from 'common/helpers/router';
import { NotFoundPage } from 'common/pages/not-found';
import { AppLocationState } from 'common/stores/router';

const isRootPath = (path: string) => path === '/' || path === '';

interface Props {
  defaultGuard?: RouteGuardProp;
  defaultPath?: History.LocationDescriptor<AppLocationState> | null;
  routes: (ProtectedRouteProps | null | undefined)[];
}

export const RouteSwitch = ({ routes: routesProp, defaultPath: defaultPathProp, defaultGuard }: Props) => {
  const { path } = useRouteMatch();
  const routes = routesProp.filter(isPresent);
  const defaultPath = isDefined(defaultPathProp) ? defaultPathProp : routes[0]?.path;

  return (
    <Switch>
      {isText(defaultPath) && !isRootPath(defaultPath) ? (
        <Redirect exact from={path} to={join(path, defaultPath)} />
      ) : null}

      {routes.map((item) => (
        <ProtectedRoute {...item} key={item.path} path={join(path, item.path)} guard={item.guard ?? defaultGuard} />
      ))}

      <Route path={join(path, '*')} component={NotFoundPage} />
    </Switch>
  );
};
