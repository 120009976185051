import {
  whisk_backoffice_retailers_v1_RetailersAPI_GetRetailers as GetRetailers,
  whisk_backoffice_retailers_v1_Retailer as Retailer,
} from '@whisklabs/b2b-backoffice-protobuf';

import { b2bGrpc } from 'b2b/grpc';

export { Retailer };

const api = b2bGrpc();

export const getRetailers = () => api(GetRetailers);
