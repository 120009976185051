import { CookieAttributes } from 'js-cookie';

import { OAUTH_INITIAL_CALLBACK_ORIGIN } from 'common/config';

export const OAUTH_INITIAL_CALLBACK_URL = `${OAUTH_INITIAL_CALLBACK_ORIGIN}/oauth/google.html`;

export const getOauthCookieConfig = (): CookieAttributes => ({
  domain: window.location.hostname === 'localhost' ? undefined : `.${window.location.hostname}`,
  path: '/',
  secure: window.location.protocol === 'https:',
  sameSite: 'Lax',
  expires: new Date(Date.now() + 60 * 1000), // in 1 minute
});
