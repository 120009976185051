import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { css } from 'linaria';

import { Sort } from 'common/types/types';

const SortSelect = Select.ofType<Sort>();

const clsPop = css`
  .bp3-select-popover .bp3-menu {
    max-height: 500px;
  }
`;

interface Props<T extends Sort> {
  active: T;
  items: T[];
  onSelect: (sort?: T) => void;
}

export const SortSel = <T extends Sort>({ items, active, onSelect }: Props<T>) => (
  <SortSelect
    items={items}
    itemRenderer={(i, { handleClick, modifiers }) => (
      <MenuItem
        active={modifiers.active}
        key={i.sortKey + i.sortOrder}
        text={i.sortTitle}
        icon={i.sortOrder === 'desc' ? 'sort-numerical-desc' : 'sort-numerical'}
        onClick={handleClick}
      />
    )}
    filterable={false}
    onItemSelect={onSelect as (sort?: Sort) => void}
    activeItem={active}
    popoverProps={{
      portalClassName: clsPop,
    }}
  >
    <Button
      text={`Sort: ${active.sortTitle}`}
      icon={active.sortOrder === 'desc' ? 'sort-numerical-desc' : 'sort-numerical'}
    />
  </SortSelect>
);
