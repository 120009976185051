import { Button, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { useBusinessDetailsStoreContext } from 'b2b/businesses/details/context';
import { useStore } from 'common/hooks/use-store';

import { BusinessActivationStore } from './store';

export const BusinessActivation = observer(() => {
  const businessDetailsStore = useBusinessDetailsStoreContext();
  const { activateLoader, activate } = useStore(() => new BusinessActivationStore(businessDetailsStore));

  return (
    <Button
      onClick={activate}
      intent={Intent.SUCCESS}
      text="Activate Business"
      loading={activateLoader.isPending}
      large
    />
  );
});
