import { observer } from 'mobx-react-lite';

import { FieldInput } from 'common/components/fields/field-input';
import { clsMb30 } from 'common/styles/margin-padding';

import { UserFieldRole } from './field-role';
import { UserFormStore } from './store';

interface Props {
  store: UserFormStore;
  withEmailField?: boolean;
}

export const UserFormFields = observer(({ store, withEmailField }: Props) => (
  <div className={clsMb30}>
    {withEmailField ? <FieldInput field={store.form.fields.email} label="Email" /> : null}
    <UserFieldRole field={store.form.fields.role} label="Role" />
  </div>
));
