import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useStore } from 'common/hooks/use-store';

import { FlaggedImageQueueStore } from './store';
import { FlaggedImagesTable } from './table';

export const FlaggedImageTable = observer(() => {
  const store = useStore(() => new FlaggedImageQueueStore());

  useEffect(() => {
    void store.loadFlaggedImages();
  }, [store]);

  return <FlaggedImagesTable store={store} />;
});
