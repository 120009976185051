import { isPresent } from '@whisklabs/typeguards';

import { UserRoleType } from 'b2b/users/api';
import { SortComparator, orderCompareAsc } from 'common/helpers/sort';

export const userRoleLabels: Record<UserRoleType, string> = {
  [UserRoleType.USER_ROLE_BASIC]: 'Basic',
  [UserRoleType.USER_ROLE_EDITOR]: 'Editor',
  [UserRoleType.USER_ROLE_ADMIN]: 'Admin',
};

export const userRolesCompareAsc: SortComparator<UserRoleType | undefined> = (
  a = UserRoleType.USER_ROLE_BASIC,
  b = UserRoleType.USER_ROLE_BASIC
) => {
  return orderCompareAsc([UserRoleType.USER_ROLE_ADMIN, UserRoleType.USER_ROLE_EDITOR, UserRoleType.USER_ROLE_BASIC])(
    a,
    b
  );
};

export const userRoleSearchKey = (role: UserRoleType | undefined) => (isPresent(role) ? userRoleLabels[role] : '');

interface Props {
  role: UserRoleType | undefined;
}

export const UserRole = ({ role }: Props) => {
  if (!isPresent(role)) {
    return null;
  }

  const label = userRoleLabels[role];

  return <span>{label}</span>;
};
