import {
  whisk_backoffice_business_v1_Account as Business,
  whisk_backoffice_business_v1_Address as BusinessAddress,
  whisk_backoffice_business_v1_Details as BusinessDetails,
  whisk_backoffice_business_v1_BusinessAPI_GetBusinessAccount as GetBusiness,
  whisk_backoffice_business_v1_GetBusinessAccountRequest as GetBusinessRequest,
  whisk_backoffice_business_v1_BusinessAPI_UpdateBusinessAccount as UpdateBusiness,
  whisk_backoffice_business_v1_UpdateBusinessAccountRequest as UpdateBusinessRequest,
} from '@whisklabs/b2b-backoffice-protobuf';
import { Default } from '@whisklabs/grpc';

import { b2bGrpc } from 'b2b/grpc';

export { Business, BusinessAddress, BusinessDetails, GetBusinessRequest, UpdateBusinessRequest };

export const stubBusiness = (): Business => Default(Business);

const api = b2bGrpc();

export const getBusiness = (payload: GetBusinessRequest) => api(GetBusiness, payload);

export const updateBusiness = (payload: UpdateBusinessRequest) => api(UpdateBusiness, payload);
