import {
  whisk_backoffice_products_v1_Product as Product,
  whisk_backoffice_products_v1_ProductsAPI_SearchProducts as SearchProducts,
  whisk_backoffice_products_v1_SearchProductsRequest as SearchProductsRequest,
} from '@whisklabs/b2b-backoffice-protobuf';

import { b2bGrpc } from 'b2b/grpc';

export { Product, SearchProductsRequest };

const api = b2bGrpc();

export const searchProducts = (payload: SearchProductsRequest) => api(SearchProducts, payload);
