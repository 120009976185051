import { isBoolean, isNumber, isObject, isPresent, isString, isText } from '@whisklabs/typeguards';
import { isArrayLike } from 'lodash';

import { FieldValidator } from 'common/helpers/form/field';

export function hasValue<T>(val: T) {
  if (!isPresent(val)) {
    return false;
  }

  return (
    (isBoolean(val) && val === true) ||
    (isNumber(val) && val !== 0) ||
    (isString(val) && isText(val.trim())) ||
    (isArrayLike(val) && val.length > 0) ||
    (isObject(val) && Object.keys(val).length > 0)
  );
}

export const validateIfHasValue =
  <T>(validator: FieldValidator<T>) =>
  (value: T) =>
    !hasValue(value) ? true : validator(value);

export const createRequiredValidator = <T>(
  fn: (value: T) => boolean,
  errorMessage = 'This field is required'
): FieldValidator<T> => {
  const validator: FieldValidator<T> = (value: T) => fn(value) || errorMessage;
  validator.IS_REQUIRED_VALIDATOR = true;
  return validator;
};

export const validateRequired: FieldValidator = createRequiredValidator(hasValue);

export const validateUrlProtocol: FieldValidator<string> = validateIfHasValue(
  (value) => /^[a-zA-Z][a-zA-Z0-9+-.]+:\//.test(value) || 'Invalid URL'
);

export const validateEmail: FieldValidator<string> = validateIfHasValue(
  (value) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    ) || 'Invalid email'
);

export const validateCurrencyAmount: FieldValidator<string> = validateIfHasValue(
  (value) => /^[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]+)?$/.test(value) || 'Invalid format'
);
