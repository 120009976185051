import {
  whisk_backoffice_general_v1_UsersAPI_CreateUser as CreateUser,
  whisk_backoffice_general_v1_CreateUserRequest as CreateUserRequest,
  whisk_backoffice_general_v1_UsersAPI_DeleteUser as DeleteUser,
  whisk_backoffice_general_v1_DeleteUserRequest as DeleteUserRequest,
  whisk_backoffice_general_v1_UsersAPI_GetAllUsers as GetAllUsers,
  whisk_backoffice_general_v1_GetAllUsersRequest as GetAllUsersRequest,
  whisk_backoffice_general_v1_UsersAPI_UpdateUserRole as UpdateUserRole,
  whisk_backoffice_general_v1_UpdateUserRoleRequest as UpdateUserRoleRequest,
  whisk_backoffice_general_v1_User as User,
} from '@whisklabs/b2b-backoffice-protobuf';
import { Default } from '@whisklabs/grpc';

import { b2bGrpc } from 'b2b/grpc';

export { User };

export type UserPayload = {
  email: string;
  role: string;
};

export const enum UserRoleType {
  USER_ROLE_BASIC = 'basic',
  USER_ROLE_EDITOR = 'editor',
  USER_ROLE_ADMIN = 'admin',
}

export const stubUser = (): User => Default(User);

const api = b2bGrpc();

export const createUser = (payload: CreateUserRequest) => api(CreateUser, payload);

export const deleteUser = (payload: DeleteUserRequest) => api(DeleteUser, payload);

export const getAllUsers = (payload?: GetAllUsersRequest) => api(GetAllUsers, payload ?? {});

export const updateUserRole = (payload: UpdateUserRoleRequest) => api(UpdateUserRole, payload);
