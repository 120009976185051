import { observer } from 'mobx-react-lite';

import { Card } from 'common/components/card';
import { FieldInput } from 'common/components/fields/field-input';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';
import { clsMb30 } from 'common/styles/margin-padding';

import { CommunityIdFormStore } from './store';

export const CommunityIdForm = observer(() => {
  const { form, openCommunity } = useStore(() => new CommunityIdFormStore());

  return (
    <Card>
      <Form form={form} onSubmit={openCommunity}>
        <div className={clsMb30}>
          <FieldInput
            field={form.fields.communityId}
            label="Community ID"
            placeholder="e.g. 6cabec8c4b9245d993d641b58a0bb0b9"
          />
        </div>
        <ButtonSubmit text="Load Community" large />
      </Form>
    </Card>
  );
});
