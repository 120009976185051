import { memo } from 'react';

import { PageHeader } from 'common/components/page-header';

import { CommunityIdForm } from './form';

export const CommunityIdPage = memo(() => (
  <div>
    <PageHeader title="Load Community by Id" />
    <CommunityIdForm />
  </div>
));
