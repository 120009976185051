import { observer } from 'mobx-react-lite';

import { SortSel } from 'b2c/components';
import { ModeratedEntityType, Reported } from 'b2c/helpers/reported/api';
import { ReportedStore } from 'b2c/helpers/reported/store';
import { Box } from 'common/components/box';
import { LoadingWrapper } from 'common/components/loading-wrapper';
import { Search } from 'common/components/search';
import { LoadMore } from 'common/components/table-view';
import { useStore } from 'common/hooks/use-store';

import { RowPost } from './post';
import { PostReply } from './post-reply';
import { RowReply } from './reply';
import { ReportedPost } from './reported-post';
import { RowReview } from './review';
import { RowReviewReply } from './review-reply';

const rowMap = {
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_RECIPE_REVIEW]: RowReview,
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_COMMUNITY_CONVERSATION]: RowPost,
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_COMMUNITY_CONVERSATION_REPLY]: RowReply,
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_RECIPE_REVIEW_REPLY]: RowReviewReply,
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_POST]: ReportedPost,
  [ModeratedEntityType.MODERATED_ENTITY_TYPE_POST_REPLY]: PostReply,
};

interface Props<T extends ModeratedEntityType> {
  entity: T;
}

export const Page = observer(<T extends ModeratedEntityType>({ entity }: Props<T>) => {
  const store = useStore(() => new ReportedStore(entity));

  const Row = rowMap[entity] as React.FC<{
    report: Reported;
    onApprove: (reportId: string) => void;
    onReject: (reportId: string) => void;
  }>;

  return (
    <>
      <Box alignItems="flex-start" mb={20}>
        <Box flexGrow={1}>
          <Search search={store.searchText} placeholder="Filter by any field" onChange={store.search} />
        </Box>
        <Box>
          <SortSel active={store.sort} items={store.sortItems} onSelect={store.setSort} />
        </Box>
      </Box>

      <LoadingWrapper loader={store.canLoadMore ? undefined : store.loader}>
        <Box display="block">
          {store.reported.map((report) => (
            <Row key={report.reportId} report={report} onApprove={store.approve} onReject={store.reject} />
          ))}
        </Box>
      </LoadingWrapper>

      {store.canLoadMore ? <LoadMore onClick={store.loadMoreReported} loading={store.loader.isPending} /> : null}
    </>
  );
});
