import { Classes, FormGroup, IPopoverProps, Intent, Spinner } from '@blueprintjs/core';
import { Suggest as BlueprintSuggest, ISuggestProps, ItemListPredicate } from '@blueprintjs/select';
import { isDefined, isText } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { ComponentProps, KeyboardEventHandler, useCallback, useMemo, useState } from 'react';

import { useConstantItemsRef } from 'common/components/form/hooks/use-constant-items-ref';
import { clsField } from 'common/components/form/styles';
import { useRandomId } from 'common/hooks/use-random-id';
import { sharedPopoverProps } from 'common/popover';
import { clsNoMargin } from 'common/styles/margin-padding';

type FormGroupProps = ComponentProps<typeof FormGroup>;

interface Props<T> extends Omit<ISuggestProps<T>, 'items'> {
  items: T[];
  label?: FormGroupProps['label'];
  labelInfo?: FormGroupProps['labelInfo'];
  helperText?: FormGroupProps['helperText'];
  inline?: FormGroupProps['inline'];
  id?: string;
  intent?: Intent;
  onBlur?: () => void;
  loading?: boolean;
  className?: string;
  placeholder?: string;
  contentClassName?: string;
  noMargin?: boolean;
  large?: boolean;
  filterFunction?: (items: T[], query: string) => T[];
}

export const Suggest = observer(
  <T,>({
    id,
    label,
    loading,
    disabled,
    className,
    contentClassName,
    intent,
    helperText,
    labelInfo,
    large = true,
    inline = false,
    noMargin = false,
    placeholder,
    onBlur,
    inputProps: inputPropsProp,
    popoverProps: popoverPropsProp,
    selectedItem,
    inputValueRenderer,
    filterFunction,
    items = [],
    ...suggestProps
  }: Props<T>) => {
    const BlueprintSuggestComponent = useMemo(() => BlueprintSuggest.ofType<T>(), []);

    const randomId = useRandomId();
    const inputId = id ?? inputPropsProp?.id ?? randomId;

    const popoverProps: IPopoverProps = useMemo(
      () => ({
        ...sharedPopoverProps,
        ...popoverPropsProp,
        onClosed: (...args) => {
          onBlur?.();
          popoverPropsProp?.onClosed?.(...args);
        },
      }),
      [popoverPropsProp, onBlur]
    );

    const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
        inputPropsProp?.onKeyDown?.(event);
      },
      [inputPropsProp]
    );

    const inputProps = useMemo(
      () => ({
        ...inputPropsProp,
        rightElement: loading ? <Spinner size={Spinner.SIZE_SMALL} /> : undefined,
        onKeyDown: handleInputKeyDown,
        large,
        intent,
        placeholder,
        autoComplete: 'off',
        autoCapitalize: 'off',
        autoCorrect: 'off',
        spellCheck: false,
        id: inputId,
      }),
      [inputId, large, intent, placeholder, inputPropsProp, loading, handleInputKeyDown]
    );

    const [searchQuery, setSearchQuery] = useState('');

    const filterSortItems: ItemListPredicate<T> = useCallback(
      (query, allItems) => {
        const normalizedQuery = query.trim();
        if (!isDefined(filterFunction) || !isText(normalizedQuery)) {
          return allItems;
        }
        return filterFunction(allItems, query);
      },
      [filterFunction]
    );

    const itemsRef = useConstantItemsRef(items);

    return (
      <FormGroup
        labelFor={inputId}
        label={label}
        labelInfo={labelInfo}
        helperText={helperText}
        intent={intent}
        className={cx(clsField, large ? Classes.LARGE : undefined, noMargin ? clsNoMargin : undefined, className)}
        contentClassName={contentClassName}
        disabled={disabled}
        inline={inline}
      >
        <BlueprintSuggestComponent
          disabled={disabled}
          inputProps={inputProps}
          popoverProps={popoverProps}
          query={searchQuery}
          onQueryChange={setSearchQuery}
          selectedItem={selectedItem ?? null}
          itemListPredicate={filterSortItems}
          inputValueRenderer={inputValueRenderer}
          items={itemsRef}
          resetOnSelect
          fill
          {...suggestProps}
        />
      </FormGroup>
    );
  }
);
