import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingWrapper } from 'common/components/loading-wrapper';
import { PageHeader, PageTitle } from 'common/components/page-header';
import { useStore } from 'common/hooks/use-store';

import { UserDelete } from '../delete';

import { UserDetailsForm } from './form';
import { UserDetailsStore } from './store';

interface RouteParams {
  userId: string;
}

export const UserDetailsPage = observer(() => {
  const store = useStore(() => new UserDetailsStore());
  const { user, loader, handleUserDeleted } = store;
  const { userId } = useParams<RouteParams>();

  useEffect(() => {
    void store.loadUser(userId);
  }, [store, userId]);

  return (
    <LoadingWrapper loader={loader}>
      <PageHeader
        title={<PageTitle title={`User "${user.email}"`} />}
        rightElement={<UserDelete user={user} onDeleted={handleUserDeleted} large />}
      />
      <UserDetailsForm store={store} />
    </LoadingWrapper>
  );
});
