export interface GoogleTimestamp {
  seconds: number;
  nanos: number;
}

export const fromTimestampToMillis = ({ seconds, nanos }: GoogleTimestamp = { seconds: 0, nanos: 0 }): number =>
  seconds * 1000 + Math.floor(nanos / 1e6);

export interface GoogleMoney {
  currencyCode: string;
  units: number;
  nanos: number;
}

export const fromMoney = ({ currencyCode, units, nanos }: GoogleMoney) => ({
  currencyCode,
  amount: units + nanos * 1e-9,
});

export const toMoney = (currencyCode: string, amount: number): GoogleMoney => {
  const absAmount = Math.abs(amount);

  return {
    currencyCode,
    units: Math.trunc(amount),
    nanos: Math.sign(amount) * Math.floor(Number((absAmount - Math.floor(absAmount)).toFixed(2)) * 1e9),
  };
};
