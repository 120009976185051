import { memo } from 'react';

import { PageHeader } from 'common/components/page-header';
import { useStore } from 'common/hooks/use-store';

import { UsersTableStore } from './store';
import { UsersTable } from './table';

export const UsersTablePage = memo(() => {
  const store = useStore(() => new UsersTableStore());

  return (
    <div>
      <PageHeader title="Users" />
      <UsersTable store={store} />
    </div>
  );
});
