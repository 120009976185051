import { isText } from '@whisklabs/typeguards';
import { memo } from 'react';

interface Props {
  value?: string;
}

export const NonEmptyString = memo(({ value = '' }: Props) => {
  const trimmedValue = value.trim();

  return <>{isText(trimmedValue) ? trimmedValue : `"${value}"`}</>;
});
