import { isPresent } from '@whisklabs/typeguards';
import { action, makeObservable } from 'mobx';

import { DataLoader } from 'common/helpers/data-loader';
import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { BaseStore, getStore } from 'common/stores';

import { BanRecipeRequest, banRecipe } from './api';
import { ActionId } from './types';

export class BanRecipeFormStore extends BaseStore {
  readonly banLoader = new DataLoader();

  readonly form = new FormModel({
    recipeId: new FieldModel('').validators(validateRequired),
    actionId: new FieldModel<ActionId>(ActionId.RemoveFromAllCommunities),
    communityId: new FieldModel(''),
  });

  @action storeReset = () => {
    this.banLoader.reset();
    this.form.reset();
  };

  @action chooseAction = (actionId: ActionId) => {
    this.form.fields.communityId.validators(actionId === ActionId.RemoveFromCommunity ? validateRequired : () => true);
    this.form.fields.actionId.handleValueChange(actionId);
  };

  @action sumbitForm = ({
    recipeId,
    communityId,
    actionId,
  }: {
    recipeId: string;
    actionId: ActionId;
    communityId?: string;
  }) => {
    void this.banRecipe({
      recipeId,
      scope: {
        value:
          actionId === ActionId.RemoveFromCommunity && isPresent(communityId)
            ? { oneof: 'community', value: { communityId } }
            : actionId === ActionId.RemoveFromAllCommunities
            ? { oneof: 'allCommunities', value: { dummyFieldFoo: '' } }
            : { oneof: 'global', value: { dummyFieldBar: '' } },
      },
    });
  };

  @action banRecipe = async (payload: BanRecipeRequest) => {
    const { success, error } = await this.banLoader.load(() => banRecipe(payload));

    if (success) {
      getStore('toast').success('Banned recipe');
      this.form.reset();
      this.banLoader.ok();
    } else {
      this.banLoader.fail(error);
    }
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
