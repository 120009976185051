import { action, makeObservable } from 'mobx';

import { FieldModel, FormModel } from 'common/helpers/form';
import { validateRequired } from 'common/helpers/form/validators';
import { getStore } from 'common/stores';
import { BaseStore } from 'common/stores/base';

export class CommunityIdFormStore extends BaseStore {
  readonly form = new FormModel({
    communityId: new FieldModel('').validators(validateRequired),
  });

  @action storeReset = () => {
    this.form.reset();
  };

  @action openCommunity = ({ communityId }: { communityId: string }) => {
    getStore('router').push(`/b2c/update-communities/${communityId}`);
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
