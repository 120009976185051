import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable } from 'mobx';

import { CreateAppRequest, createApp } from 'b2b/apps/api';
import { DataLoader } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

export class AppCreateStore extends BaseStore {
  readonly createLoader = new DataLoader();

  @action storeReset = () => {
    this.createLoader.reset();
  };

  @action create = async (payload: CreateAppRequest) => {
    const { cancelled, data, error } = await this.createLoader.load(() => createApp(payload));

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.app)) {
      getStore('router').push(`/b2b/api-keys/${data.app.appId}`);
      getStore('toast').success('API Key created. Now you can set it up.');
      this.createLoader.ok();
    } else {
      this.createLoader.fail(error);
    }
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
