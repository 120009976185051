import { useEffect, useState } from 'react';

// blueprintjs has a bug that it resets active item if items reference changes,
// which makes scroll jump to the top when infinite scroll is used
// we use the same reference as a workaround
export const useConstantItemsRef = <T>(items: T[]): T[] => {
  const [itemsAsRef] = useState(() => Array.from(items));
  const [prevItems, setPrevItems] = useState(items);
  if (prevItems !== items) {
    itemsAsRef.splice(0, itemsAsRef.length, ...items);
  }

  useEffect(() => {
    setPrevItems(items);
  }, [items]);

  return itemsAsRef;
};
