import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { isDefined } from '@whisklabs/typeguards';
import { css } from 'linaria';
import { observer } from 'mobx-react-lite';

import { ButtonAdd, ButtonSave } from 'common/components/buttons';
import { DataLoader } from 'common/helpers/data-loader';
import { FormModel } from 'common/helpers/form';

import { RowKind } from '../types';

const clsContainer = css`
  width: 100%;
`;

interface Props {
  rowKind?: RowKind;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: FormModel<any>;
  addLoader?: DataLoader;
  saveLoader?: DataLoader;
  onRemove?: () => void;
  removeLoader?: DataLoader;
  removeDisabled?: boolean;
}

export const RowActions = observer((props: Props) => {
  switch (props.rowKind) {
    case 'new': {
      const { form, addLoader } = props;

      return (
        <ButtonGroup className={clsContainer}>
          <ButtonAdd onClick={form.submit} loading={addLoader?.isPending} disabled={!form.isValid} large />
        </ButtonGroup>
      );
    }
    default: {
      const { form, saveLoader, onRemove, removeLoader, removeDisabled } = props;
      return (
        <ButtonGroup className={clsContainer}>
          <ButtonSave
            onClick={form.submit}
            loading={saveLoader?.isPending}
            disabled={!form.isValidAndDirty || removeLoader?.isPending}
            large
          />
          {isDefined(onRemove) ? (
            <Button
              onClick={onRemove}
              loading={removeLoader?.isPending}
              disabled={removeDisabled || saveLoader?.isPending}
              icon="trash"
              title="Remove"
              intent={Intent.NONE}
              large
            />
          ) : null}
        </ButtonGroup>
      );
    }
  }
});
