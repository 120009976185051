import {
  whisk_backoffice_campaigns_v1_Campaign as Campaign,
  whisk_backoffice_campaigns_v1_CampaignBrand as CampaignBrand,
  whisk_backoffice_campaigns_v1_CampaignPayload as CampaignPayload,
  whisk_backoffice_campaigns_v1_CampaignProduct as CampaignProduct,
  whisk_backoffice_campaigns_v1_CampaignPublisher as CampaignPublisher,
  whisk_backoffice_campaigns_v1_CampaignsAPI_CreateCampaign as CreateCampaign,
  whisk_backoffice_campaigns_v1_CreateCampaignRequest as CreateCampaignRequest,
  whisk_backoffice_campaigns_v1_CampaignsAPI_DeleteCampaign as DeleteCampaign,
  whisk_backoffice_campaigns_v1_DeleteCampaignRequest as DeleteCampaignRequest,
  whisk_backoffice_campaigns_v1_CampaignsAPI_GetCampaign as GetCampaign,
  whisk_backoffice_campaigns_v1_GetCampaignRequest as GetCampaignRequest,
  whisk_backoffice_campaigns_v1_CampaignsAPI_GetCampaigns as GetCampaigns,
  whisk_backoffice_campaigns_v1_GetCampaignsRequest as GetCampaignsRequest,
  whisk_backoffice_campaigns_v1_CampaignsAPI_UpdateCampaign as UpdateCampaign,
  whisk_backoffice_campaigns_v1_UpdateCampaignRequest as UpdateCampaignRequest,
} from '@whisklabs/b2b-backoffice-protobuf';
import { Default } from '@whisklabs/grpc';

import { b2bGrpc } from 'b2b/grpc';

export {
  Campaign,
  CampaignPayload,
  CampaignBrand,
  CampaignProduct,
  CampaignPublisher,
  GetCampaignsRequest,
  GetCampaignRequest,
  UpdateCampaignRequest,
  DeleteCampaignRequest,
};

export const stubCampaign = (): Campaign => Default(Campaign);

const api = b2bGrpc();

export const getCampaigns = (payload: GetCampaignsRequest) => api(GetCampaigns, payload);

export const getCampaign = (payload: GetCampaignRequest) => api(GetCampaign, payload);

export const createCampaign = (payload: CreateCampaignRequest) => api(CreateCampaign, payload);

export const updateCampaign = (payload: UpdateCampaignRequest) => api(UpdateCampaign, payload);

export const deleteCampaign = (payload: DeleteCampaignRequest) => api(DeleteCampaign, payload);
