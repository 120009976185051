import {
  whisk_backoffice_b2c_v1_RecipeAPI_ApproveRecipe as ApproveRecipe,
  whisk_backoffice_b2c_v1_ApproveRecipeRequest as ApproveRecipeRequest,
} from '@whisklabs/b2c-backoffice-protobuf';

import { b2cGrpc } from 'b2c/grpc';

export { ApproveRecipeRequest };

const api = b2cGrpc();

export const approveRecipe = (payload: ApproveRecipeRequest) => api(ApproveRecipe, payload);
