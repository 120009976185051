import { Button, Intent } from '@blueprintjs/core';
import { cx } from 'linaria';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useEffect, useMemo } from 'react';

import { CountriesMultiselect, getSortedCountryNames } from 'b2b/countries';
import { Retailer, RetailersStore } from 'b2b/retailers';
import { Table } from 'common/components/client-pagination-table';
import { FieldCheckbox } from 'common/components/fields/field-checkbox';
import { NonEmptyString } from 'common/components/non-empty-string';
import { Column, RowKeyFunction, Sort } from 'common/components/table-view';
import { noop } from 'common/helpers/noop';
import { createSearchFunction, searchRankings } from 'common/helpers/search';
import { boolCompareAsc, stringCompareAsc } from 'common/helpers/sort';
import {
  clsFlex,
  clsFlexAlignItemsCenter,
  clsFlexJustifyBetween,
  clsFlexJustifyEnd,
  clsFlexJustifySelfRight,
} from 'common/styles/layout';
import { clsMr20, clsMt30, clsNoMargin } from 'common/styles/margin-padding';

import { AppRetailersStore } from './store';

interface RetailerDatum {
  retailer: Retailer;
  isSelected: boolean;
}

type ColumnId = 'selected' | 'name' | 'group' | 'countries';

const createColumns = (store: AppRetailersStore): Column<RetailerDatum, ColumnId>[] => [
  {
    id: 'selected',
    width: '1%',
    title: (data) => (
      <FieldCheckbox
        checked={data.length > 0 && data.every(({ isSelected }) => isSelected)}
        indeterminate={data.some(({ isSelected }) => isSelected) && data.some(({ isSelected }) => !isSelected)}
        onChange={action((event: ChangeEvent<HTMLInputElement>) => {
          const isSelected = event.currentTarget.checked;
          const dataRetailerIdsSet = new Set(data.map(({ retailer }) => retailer.id));
          store.retailers = store.retailers.map((datum) =>
            dataRetailerIdsSet.has(datum.retailer.id) ? { ...datum, isSelected } : datum
          );
        })}
        className={clsNoMargin}
        large
        inline
      />
    ),
    titleHasControl: true,
    sorter: (a, b) => boolCompareAsc(a.isSelected, b.isSelected),
    render: (datum) => (
      <FieldCheckbox
        checked={datum.isSelected}
        className={clsNoMargin}
        onChange={action((event: ChangeEvent<HTMLInputElement>) => {
          const isSelected = event.currentTarget.checked;
          const index = store.retailers.findIndex(({ retailer }) => retailer.id === datum.retailer.id);
          if (index > -1) {
            store.retailers = [
              ...store.retailers.slice(0, index),
              { ...datum, isSelected },
              ...store.retailers.slice(index + 1),
            ];
          }
        })}
      />
    ),
  },
  {
    id: 'name',
    title: 'Name',
    sorter: (a, b) => stringCompareAsc(a.retailer.displayName, b.retailer.displayName),
    render: ({ retailer }) => <NonEmptyString value={retailer.displayName} />,
  },
  {
    id: 'group',
    title: 'Group',
    sorter: (a, b) => stringCompareAsc(a.retailer.retailerGroup ?? '', b.retailer.retailerGroup ?? ''),
    render: ({ retailer }) => retailer.retailerGroup,
  },
  {
    id: 'countries',
    maxWidth: '300px',
    vAlign: 'top',
    title: 'Countries',
    sorter: (a, b) =>
      stringCompareAsc(
        getSortedCountryNames(a.retailer.availableCountries).join(','),
        getSortedCountryNames(b.retailer.availableCountries).join(',')
      ),
    render: ({ retailer }) => (
      <CountriesMultiselect
        countries={retailer.availableCountries}
        value={retailer.availableCountries}
        tagColumns={2}
        onChange={noop}
        disabled
      />
    ),
  },
];

const searchFunction = createSearchFunction<RetailerDatum>([
  ({ retailer }) => retailer.displayName,
  ({ retailer }) => retailer.retailerGroup ?? '',
  ({ retailer }) => retailer.availableCountries.map((country) => country.name),
  ({ retailer }) => retailer.availableCountries.map((country) => country.code),
  { key: ({ retailer }) => retailer.id, threshold: searchRankings.EQUAL },
]);

const DEFAULT_SORT: Sort<ColumnId> = { sortKey: 'name', sortOrder: 'asc' };

const getRowKey: RowKeyFunction<RetailerDatum> = ({ retailer }) => retailer.id;

interface Props {
  store: AppRetailersStore;
  retailersStore: RetailersStore;
}

export const AppRetailersTable = observer(({ store, retailersStore }: Props) => {
  const columns = useMemo(() => createColumns(store), [store]);

  useEffect(() => {
    void retailersStore.loadRetailers();
  }, [retailersStore]);

  const saveAction = retailersStore.loader.isOk ? (
    <div className={cx(clsFlex, clsFlexAlignItemsCenter, clsFlexJustifyBetween)}>
      <div className={clsMr20}>
        Selected: {store.selectedIdsSet.size} of {store.retailers.length}
      </div>

      <Button
        icon="floppy-disk"
        text="Save"
        intent={Intent.PRIMARY}
        loading={store.saveLoader.isPending}
        disabled={!store.selectionChanged}
        onClick={store.save}
        className={clsFlexJustifySelfRight}
        large
      />
    </div>
  ) : null;

  return (
    <>
      <Table
        data={store.retailers}
        columns={columns}
        loader={retailersStore.loader}
        rowKey={getRowKey}
        searchFunction={searchFunction}
        defaultSort={DEFAULT_SORT}
        cellPaddingVertical={5}
        defaultPagination={false}
        headerAction={saveAction}
      />
      <div className={cx(clsMt30, clsFlex, clsFlexJustifyEnd)}>{saveAction}</div>
    </>
  );
});
