import { memo } from 'react';

import { PageHeader } from 'common/components/page-header';

import { DeleteUserForm } from './form';

export const DeleteUserPage = memo(() => (
  <div>
    <PageHeader title="Delete user" />
    <DeleteUserForm />
  </div>
));
