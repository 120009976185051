import { useCallback, useEffect } from 'react';

import { CampaignFormFields, CampaignFormStore } from 'b2b/campaigns/common/campaign-form';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';

import { CampaignDetailsStore } from './store';

interface Props {
  store: CampaignDetailsStore;
}

export const CampaignDetailsForm = ({ store }: Props) => {
  const { campaign, saveCampaign, saveLoader } = store;
  const formStore = useStore(() => new CampaignFormStore());

  useEffect(() => {
    formStore.fill(campaign);
  }, [formStore, campaign]);

  const handleSubmit = useCallback(() => {
    void saveCampaign(formStore.getData());
  }, [saveCampaign, formStore]);

  return (
    <Form form={formStore.form} loader={saveLoader} onSubmit={handleSubmit} errorTitle="Failed to update campaign">
      <CampaignFormFields store={formStore} />
      <ButtonSubmit kind="save" text="Save" large />
    </Form>
  );
};
