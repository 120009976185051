import { Classes } from '@blueprintjs/core';
import { css } from 'linaria';

export const clsForm = css``;

export const clsFormMaxWidth = css`
  max-width: 500px;
`;

export const clsField = css`
  &.${Classes.INLINE}.${Classes.LARGE} {
    & .${Classes.LABEL} {
      line-height: 40px;
    }
  }
`;
