import { memo } from 'react';

import { RouteSwitch } from 'common/components/routes';

import { AppCreatePage } from './create';
import { AppDetailsPage } from './details';
import { AppsTablePage } from './table';

export const AppsPage = memo(() => (
  <RouteSwitch
    routes={[
      { path: '/', exact: true, component: AppsTablePage },
      { path: '/new', component: AppCreatePage },
      { path: '/:apiKeyId', component: AppDetailsPage },
    ]}
  />
));
