import { Button, Callout, Intent } from '@blueprintjs/core';
import { isObject, isString, isText, isUndefined } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { memo, useCallback, useMemo, useState } from 'react';

import { tryFormatError } from 'common/helpers/error';
import { clsMb30 } from 'common/styles/margin-padding';
import { clsTextAlignLeft } from 'common/styles/text';

export interface ErrorMessageProps {
  title?: string;
  error?: unknown;
  className?: string;
  noMargin?: boolean;
}

export const ErrorMessage = memo(({ title, error, className, noMargin }: ErrorMessageProps) => {
  const [stackVisible, setStackVisible] = useState(false);

  const handleToggleDetails = useCallback(() => {
    setStackVisible((cur) => !cur);
  }, []);

  const stack = useMemo(() => (isObject(error) && isString(error.stack) ? error.stack.trim() : undefined), [error]);
  const message: string | undefined = useMemo(() => tryFormatError(error), [error]);

  if (isUndefined(error)) {
    return null;
  }

  return (
    <Callout
      intent={Intent.DANGER}
      title={title ?? 'Error'}
      className={cx(noMargin ? undefined : clsMb30, clsTextAlignLeft, className)}
    >
      {isText(message) ? <pre>{message}</pre> : null}
      {isText(stack) ? (
        stackVisible ? (
          <pre>{stack}</pre>
        ) : (
          <Button text="Show details" onClick={handleToggleDetails} />
        )
      ) : null}
    </Callout>
  );
});
