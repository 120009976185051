import { isPresent } from '@whisklabs/typeguards';
import { cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { FormEventHandler, ReactNode, useCallback, useEffect, useMemo } from 'react';

import { ErrorMessage, ErrorMessageProps } from 'common/components/error-message';
import { DataLoader } from 'common/helpers/data-loader';
import { FormFields, FormModel, FormOnSubmit } from 'common/helpers/form';
import { clsVisuallyHidden } from 'common/styles/layout';

import { WarningMessage, WarningMessageProps } from '../warning-message';

import { FormContextProvider, FormContextType } from './context';
import { clsForm, clsFormMaxWidth } from './styles';

export interface FormProps<T extends FormFields> {
  form: FormModel<T>;
  onSubmit: FormOnSubmit<T>;
  loader?: DataLoader;
  errorTitle?: ErrorMessageProps['title'];
  error?: ErrorMessageProps['error'];
  warningTitle?: WarningMessageProps['title'];
  warning?: WarningMessageProps['warning'];
  children?: ReactNode;
  id?: string;
  className?: string;
  fill?: boolean;
  visuallyHidden?: boolean;
}

export const Form = observer(
  <T extends FormFields>({
    form,
    loader,
    errorTitle,
    error = loader?.error,
    warningTitle,
    warning = loader?.warning,
    id = form.id,
    onSubmit,
    children,
    className,
    fill = false,
    visuallyHidden = false,
  }: FormProps<T>) => {
    useEffect(() => {
      form.setOnSubmit(onSubmit);
    }, [form, onSubmit]);

    const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
      (event) => {
        event.preventDefault();
        form.submit();
      },
      [form]
    );

    const contextValue: FormContextType<T> = useMemo(() => ({ loader, form }), [loader, form]);

    return (
      <form
        noValidate
        onSubmit={handleSubmit}
        id={id}
        className={cx(clsForm, visuallyHidden ? clsVisuallyHidden : undefined, className)}
      >
        <FormContextProvider value={contextValue}>
          {isPresent(errorTitle) && isPresent(error) ? <ErrorMessage title={errorTitle} error={error} /> : null}
          {isPresent(warning) ? <WarningMessage title={warningTitle} warning={warning} /> : null}
          <div className={!fill ? clsFormMaxWidth : undefined}>{children}</div>
        </FormContextProvider>
      </form>
    );
  }
);
