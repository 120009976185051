import { isText } from '@whisklabs/typeguards';

import { APP_URL_B2C } from 'common/config';

const generatePath = (path: string) => (id?: string) => isText(id) ? `${APP_URL_B2C}/${path}/${id}` : undefined;

export const recipePath = generatePath('recipes');

export const communityPath = generatePath('community');

export const conversationPath = generatePath('conversation');

export const recipeReviewPath = generatePath('recipe-review-replies');

export const postPath = generatePath('post');

export const profilePath = generatePath('profile');

export const dishPath = generatePath('recipes/categories');

export const ingredientPath = generatePath('ingredients');
