import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable, observable } from 'mobx';

import { BusinessDetailsStore } from 'b2b/businesses/details/store';
import {
  GetWidgetRequest,
  Widget,
  WidgetFormPayload,
  getWidget,
  stubWidget,
  updateWidget,
} from 'b2b/businesses/details/widgets/api';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

export class WidgetDetailsStore extends BaseStore {
  @observable.ref widget: Widget = stubWidget();

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  readonly saveLoader = new DataLoader();

  constructor(private readonly businessDetailsStore: BusinessDetailsStore) {
    super();
    makeObservable(this);
  }

  @action storeReset = () => {
    this.loader.reset();
    this.saveLoader.reset();
    this.widget = stubWidget();
  };

  @action loadWidget = async (payload: GetWidgetRequest) => {
    const { cancelled, data, error } = await this.loader.load(() => getWidget(payload));

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.widget)) {
      this.widget = data.widget;
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action saveWidget = async (widgetPayload: WidgetFormPayload) => {
    const { cancelled, data, error } = await this.saveLoader.load(() =>
      updateWidget({
        widgetId: this.widget.id,
        widget: {
          ...widgetPayload,
          businessId: this.businessDetailsStore.business.id,
        },
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.widget)) {
      getStore('toast').success('Widget updated');
      this.widget = data.widget;
      this.saveLoader.ok();
    } else {
      this.saveLoader.fail(error);
    }
  };

  @action handleWidgetDeleted = () => {
    getStore('router').replace(`/b2b/businesses/${this.businessDetailsStore.business.id}/widgets`);
  };
}
