import {
  whisk_backoffice_widgets_v1_WidgetsAPI_CreateTrackingId as CreateTrackingId,
  whisk_backoffice_widgets_v1_CreateTrackingIdRequest as CreateTrackingIdRequest,
  whisk_backoffice_widgets_v1_WidgetsAPI_CreateWidget as CreateWidget,
  whisk_backoffice_widgets_v1_CreateWidgetRequest as CreateWidgetRequest,
  whisk_backoffice_widgets_v1_WidgetsAPI_DeleteWidget as DeleteWidget,
  whisk_backoffice_widgets_v1_DeleteWidgetRequest as DeleteWidgetRequest,
  whisk_backoffice_widgets_v1_WidgetsAPI_GetWidget as GetWidget,
  whisk_backoffice_widgets_v1_GetWidgetRequest as GetWidgetRequest,
  whisk_backoffice_widgets_v1_WidgetsAPI_GetWidgets as GetWidgets,
  whisk_backoffice_widgets_v1_GetWidgetsRequest as GetWidgetsRequest,
  whisk_backoffice_widgets_v1_WidgetsAPI_UpdateWidget as UpdateWidget,
  whisk_backoffice_widgets_v1_UpdateWidgetRequest as UpdateWidgetRequest,
  whisk_backoffice_widgets_v1_Widget as Widget,
  whisk_backoffice_widgets_v1_WidgetConfig as WidgetConfig,
  whisk_backoffice_widgets_v1_WidgetPayload as WidgetPayload,
  whisk_backoffice_widgets_v1_WidgetType as WidgetTypeGrpc,
} from '@whisklabs/b2b-backoffice-protobuf';
import { Default } from '@whisklabs/grpc';

import { b2bGrpc } from 'b2b/grpc';

export { WidgetTypeGrpc };

export {
  Widget,
  WidgetConfig,
  WidgetPayload,
  GetWidgetRequest,
  GetWidgetsRequest,
  UpdateWidgetRequest,
  DeleteWidgetRequest,
};

export enum WidgetType {
  B2C_COMMUNITY = 'B2C_COMMUNITY',
  B2B_SHOPPING_LIST = 'B2B_SHOPPING_LIST',
}

export const WidgetTypeToGrpcEnum: {
  [key in WidgetType]: WidgetTypeGrpc;
} = {
  [WidgetType.B2C_COMMUNITY]: WidgetTypeGrpc.WIDGET_TYPE_B2C_COMMUNITY,
  [WidgetType.B2B_SHOPPING_LIST]: WidgetTypeGrpc.WIDGET_TYPE_B2B_SHOPPING_LIST,
};

export const GrpcToWidgetTypeEnum: {
  [key in WidgetTypeGrpc]: WidgetType;
} = {
  [WidgetTypeGrpc.WIDGET_TYPE_B2C_COMMUNITY]: WidgetType.B2C_COMMUNITY,
  [WidgetTypeGrpc.WIDGET_TYPE_B2B_SHOPPING_LIST]: WidgetType.B2B_SHOPPING_LIST,
};

export type WidgetFormPayload = Omit<WidgetPayload, 'businessId' | 'userId'>;

export const stubWidget = (): Widget => Default(Widget);

const api = b2bGrpc();

export const getWidgets = (payload: GetWidgetsRequest) => api(GetWidgets, payload);

export const getWidget = (payload: GetWidgetRequest) => api(GetWidget, payload);

export const createTrackingId = (payload: CreateTrackingIdRequest) => api(CreateTrackingId, payload);

export const createWidget = (payload: CreateWidgetRequest) => api(CreateWidget, payload);

export const updateWidget = (payload: UpdateWidgetRequest) => api(UpdateWidget, payload);

export const deleteWidget = (payload: DeleteWidgetRequest) => api(DeleteWidget, payload);
