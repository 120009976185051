import { H4 } from '@blueprintjs/core';
import { cx } from 'linaria';
import { ReactNode } from 'react';

import { clsNoMargin } from 'common/styles/margin-padding';

interface Props {
  children: ReactNode;
  className?: string;
}

export const CardTitle = ({ children, className }: Props) => <H4 className={cx(clsNoMargin, className)}>{children}</H4>;
