import { cx } from 'linaria';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useBusinessDetailsStoreContext } from 'b2b/businesses/details/context';
import { LoadingWrapper } from 'common/components/loading-wrapper';
import { PageHeader, PageTitle } from 'common/components/page-header';
import { useStore } from 'common/hooks/use-store';
import { clsFlex, clsFlexAlignItemsCenter } from 'common/styles/layout';

import { WidgetDelete } from '../delete';

import { WidgetDetailsForm } from './form';
import { WidgetDetailsStore } from './store';

interface RouteParams {
  widgetId: string;
}

export const WidgetDetailsPage = observer(() => {
  const businessDetailsStore = useBusinessDetailsStoreContext();
  const store = useStore(() => new WidgetDetailsStore(businessDetailsStore));
  const { widget, loader, handleWidgetDeleted } = store;
  const { widgetId } = useParams<RouteParams>();

  useEffect(() => {
    void store.loadWidget({ widgetId });
  }, [store, widgetId]);

  return (
    <LoadingWrapper loader={loader}>
      <PageHeader
        title={
          <div className={cx(clsFlex, clsFlexAlignItemsCenter)}>
            <PageTitle title={`Widget "${widget.name}"`} />
          </div>
        }
        rightElement={<WidgetDelete widget={widget} onDeleted={handleWidgetDeleted} large />}
      />
      <WidgetDetailsForm store={store} />
    </LoadingWrapper>
  );
});
