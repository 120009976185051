import { memo } from 'react';

import { PageHeader } from 'common/components/page-header';
import { useStore } from 'common/hooks/use-store';

import { BusinessesTableStore } from './store';
import { BusinessesTable } from './table';

export const BusinessesTablePage = memo(() => {
  const store = useStore(() => new BusinessesTableStore());

  return (
    <div>
      <PageHeader title="Businesses" />
      <BusinessesTable store={store} />
    </div>
  );
});
