import { identity } from 'lodash';
import { observer } from 'mobx-react-lite';

import { useAppDetailsStoreContext } from 'b2b/apps/details/context';
import { Card, CardTitle } from 'common/components/card';
import { Multiselect } from 'common/components/form/multi-select';
import { useStore } from 'common/hooks/use-store';
import { Values } from 'common/types/types';

import { OAuthScope } from './api';
import { AppOAuthScopesStore } from './store';

const ICONS = {
  read: 'eye-open',
  write: 'edit',
} as const;

interface SelectItem {
  value: OAuthScope;
  label: string;
  comment: string;
  icon: Values<typeof ICONS>;
}

const selectItems: SelectItem[] = [
  {
    value: OAuthScope.O_AUTH_SCOPE_COOKBOOK_READ,
    label: 'Cookbook read',
    comment: "Access to user's recipe list",
    icon: ICONS.read,
  },
  {
    value: OAuthScope.O_AUTH_SCOPE_COOKBOOK_WRITE,
    label: 'Cookbook write',
    comment: "Access to user's recipe list",
    icon: ICONS.write,
  },
  {
    value: OAuthScope.O_AUTH_SCOPE_SHOPPING_LIST_READ,
    label: 'Shopping list read',
    comment: "Access to user's shopping list",
    icon: ICONS.read,
  },
  {
    value: OAuthScope.O_AUTH_SCOPE_SHOPPING_LIST_WRITE,
    label: 'Shopping list write',
    comment: "Access to user's shopping list",
    icon: ICONS.write,
  },
  {
    value: OAuthScope.O_AUTH_SCOPE_USER_PROFILE_READ,
    label: 'User profile read',
    comment: "Access to user's account",
    icon: ICONS.read,
  },
  {
    value: OAuthScope.O_AUTH_SCOPE_USER_PROFILE_WRITE,
    label: 'User profile write',
    comment: "Access to user's account",
    icon: ICONS.write,
  },
  {
    value: OAuthScope.O_AUTH_SCOPE_USER_PREFERENCE_READ,
    label: 'User preference read',
    comment: "Access to user's preferences",
    icon: ICONS.read,
  },
  {
    value: OAuthScope.O_AUTH_SCOPE_USER_PREFERENCE_WRITE,
    label: 'User preference write',
    comment: "Access to user's preferences",
    icon: ICONS.write,
  },
  {
    value: OAuthScope.O_AUTH_SCOPE_ALL_WRITE,
    label: 'All write',
    comment: "Access to all of the user's data\n(only available to users and whisk app)",
    icon: ICONS.write,
  },
];

export const AppOAuthScopes = observer(() => {
  const appDetailsStore = useAppDetailsStoreContext();
  const { appOAuthScopes, save } = useStore(() => new AppOAuthScopesStore(appDetailsStore));

  return (
    <Card header={<CardTitle>OAuth scopes</CardTitle>}>
      <Multiselect items={selectItems} value={appOAuthScopes} getValueKey={identity} onChange={save} minimal large />
    </Card>
  );
});
