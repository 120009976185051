import { observer } from 'mobx-react-lite';

import { useStore } from 'common/hooks/use-store';

import { Content } from './content';
import { ClaimedDomaisStore } from './store';

export const ClaimedDomainsTable = observer(() => {
  const store = useStore(() => new ClaimedDomaisStore());

  return <Content store={store} />;
});
