import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { AppInfoFormFields, AppInfoFormFieldsStore } from 'b2b/apps/common/info-form';
import { useAppDetailsStoreContext } from 'b2b/apps/details/context';
import { ButtonSubmit } from 'common/components/form/button-submit';
import { Form } from 'common/components/form/form';
import { useStore } from 'common/hooks/use-store';

import { AppInfoStore } from './store';

export const AppInfoPage = observer(() => {
  const appDetailsStore = useAppDetailsStoreContext();
  const { save, saveLoader } = useStore(() => new AppInfoStore(appDetailsStore));
  const formStore = useStore(() => new AppInfoFormFieldsStore());

  useEffect(() => {
    formStore.fill(appDetailsStore.app);
  }, [appDetailsStore.app, formStore]);

  return (
    <Form form={formStore.form} loader={saveLoader} onSubmit={save} errorTitle="Failed to update app information">
      <AppInfoFormFields store={formStore} />
      <ButtonSubmit kind="save" text="Save" large />
    </Form>
  );
});
