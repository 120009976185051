import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';

import { InviteUser, InviteUserStore } from 'b2b/businesses/details/common/invite-user';
import {
  BusinessUserRole,
  businessUserRoleSearchKey,
  businessUserRolesCompareAsc,
} from 'b2b/businesses/details/common/user-role';
import { Table } from 'common/components/client-pagination-table';
import { Column } from 'common/components/table-view';
import { createSearchFunction, searchRankings } from 'common/helpers/search';
import { stringCompareAsc } from 'common/helpers/sort';
import { useStore } from 'common/hooks/use-store';

import { BusinessUser } from '../api';

import { UsersTableDotsMenu } from './dots-menu';
import { BusinessUsersTableStore } from './store';

type ColumnId = 'name' | 'email' | 'role' | 'action';

const createColumns = (store: BusinessUsersTableStore): Column<BusinessUser, ColumnId>[] => [
  {
    id: 'name',
    title: 'Name',
    width: '35%',
    sorter: (a, b) => stringCompareAsc(a.name, b.name),
    render: ({ name }) => name,
  },
  {
    id: 'email',
    title: 'Email',
    width: '45%',
    sorter: (a, b) => stringCompareAsc(a.email, b.email),
    render: ({ email }) => email,
  },
  {
    id: 'role',
    title: 'Role',
    width: '20%',
    sorter: (a, b) => businessUserRolesCompareAsc(a.userRole?.role, b.userRole?.role),
    render: ({ userRole }) => <BusinessUserRole role={userRole?.role} />,
  },
  {
    id: 'action',
    title: 'Action',
    align: 'center',
    render: (user) => <UsersTableDotsMenu user={user} store={store} />,
  },
];

const searchFunction = createSearchFunction<BusinessUser>([
  (user) => user.name,
  (user) => user.email,
  (user) => businessUserRoleSearchKey(user.userRole?.role),
  { key: (user) => user.id, threshold: searchRankings.EQUAL },
]);

interface Props {
  store: BusinessUsersTableStore;
}

export const BusinessUsersTable = observer(({ store }: Props) => {
  const columns = useMemo(() => createColumns(store), [store]);
  const inviteUserStore = useStore(() => new InviteUserStore(store));

  useEffect(() => {
    void store.loadUsers();
  }, [store]);

  return (
    <Table
      data={store.users}
      columns={columns}
      rowKey="id"
      rowHeight={60}
      searchFunction={searchFunction}
      loader={store.loader}
      headerAction={<InviteUser store={inviteUserStore} />}
    />
  );
});
