import { Button, Intent } from '@blueprintjs/core';
import { css } from 'linaria';
import React, { useCallback } from 'react';

import { RecipeImage } from '../types';

interface Props {
  image: RecipeImage;
  onApprove: (image: RecipeImage) => void;
  onReject: (image: RecipeImage) => void;
}

const clsButton = css`
  text-align: center;

  & + & {
    margin-left: 30px;
  }
`;

export const Actions: React.FC<Props> = ({ image, onApprove, onReject }) => {
  const handleApprove = useCallback(() => {
    onApprove(image);
  }, [image, onApprove]);

  const handleReject = useCallback(() => {
    onReject(image);
  }, [image, onReject]);

  return (
    <div>
      <Button className={clsButton} rightIcon="tick" onClick={handleApprove} large intent={Intent.SUCCESS} />
      <Button className={clsButton} rightIcon="cross" onClick={handleReject} large intent={Intent.DANGER} />
    </div>
  );
};
