import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { AppPreview, GetAppsRequest, getApps } from 'b2b/apps/api';
import { DeletedApp } from 'b2b/apps/delete';
import { textOrFallback } from 'common/helpers/data';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

export class AppsTableStore extends BaseStore {
  private readonly appById = observable.map<string, AppPreview>([], { deep: false });

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.loader.reset();
    this.appById.clear();
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get apps(): AppPreview[] {
    return Array.from(this.appById.values(), (app) => ({
      ...app,
      name: textOrFallback(app.name, 'App ""'),
    }));
  }

  @action handleAppDeleted = (app: DeletedApp) => {
    this.appById.delete(app.appId);
  };

  @action loadApps = async (payload?: GetAppsRequest) => {
    const { cancelled, data, error } = await this.loader.load(() => getApps(payload));

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      this.appById.replace(data.apps.map((app) => [app.appId, app]));
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
