import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable } from 'mobx';

import { Business } from 'b2b/businesses/api';
import { BusinessDetailsStore } from 'b2b/businesses/details/store';
import { DataLoader, LoadStrategy } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { activateBusiness } from './api';

export class BusinessActivationStore extends BaseStore {
  readonly activateLoader = new DataLoader({
    strategy: LoadStrategy.takeFirst,
  });

  constructor(private readonly businessDetailsStore: BusinessDetailsStore) {
    super();
    makeObservable(this);
  }

  @action storeReset = () => {
    this.activateLoader.reset();
  };

  get business(): Business {
    return this.businessDetailsStore.business;
  }

  @action activate = async () => {
    const { cancelled, data, error } = await this.activateLoader.load(() => activateBusiness({ id: this.business.id }));

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.value)) {
      this.businessDetailsStore.setBusiness(data.value);
      getStore('toast').success('Activated business');
      this.activateLoader.ok();
    } else {
      getStore('toast').error('Failed to activate business', error);
      this.activateLoader.fail(error);
    }
  };
}
