import { isDefined, isPresent } from '@whisklabs/typeguards';
import uniq from 'lodash/uniq';
import { action, computed, makeObservable, observable } from 'mobx';

import {
  ApproveRecipeRequest,
  BanRecipeRequest,
  approveRecipe,
  banRecipe,
  getFlaggedRecipes,
} from 'b2c/flagged-recipe-queue/api';
import { createSort } from 'b2c/helpers/create-sort';
import { hasSearch } from 'b2c/helpers/has-search';
import { pageSlice } from 'b2c/helpers/page-slice';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';
import { Sort } from 'common/types/types';

import { ActionId, FlaggedRecipe } from '../types';

interface SortFn extends Sort {
  fn?: (i: FlaggedRecipe) => string | number | undefined;
}

const DEFAULT_SORT: SortFn = {
  sortTitle: 'Date',
  sortKey: 'date',
  sortOrder: 'asc',
  fn: (r) => r.index,
};

export class FlaggedRecipeQueueStore extends BaseStore {
  constructor() {
    super();

    makeObservable(this);

    void this.loadFlaggedRecipes();
  }
  private readonly flaggedRecipes = observable.map<string, FlaggedRecipe>([]);

  @observable sort: SortFn = DEFAULT_SORT;
  @observable sortItems: SortFn[] = [
    DEFAULT_SORT,
    {
      sortTitle: 'Date',
      sortKey: 'date',
      sortOrder: 'desc',
      fn: (r) => r.index,
    },
    {
      sortTitle: 'Name',
      sortKey: 'name',
      sortOrder: 'asc',
      fn: (r) => r.name.toLocaleLowerCase(),
    },
    {
      sortTitle: 'Name',
      sortKey: 'name',
      sortOrder: 'desc',
      fn: (r) => r.name.toLocaleLowerCase(),
    },
    {
      sortTitle: 'Community',
      sortKey: 'community',
      sortOrder: 'asc',
      fn: (r) => r.community?.name.toLocaleLowerCase(),
    },
    {
      sortTitle: 'Community',
      sortKey: 'community',
      sortOrder: 'desc',
      fn: (r) => r.community?.name.toLocaleLowerCase(),
    },
    {
      sortTitle: 'Reason',
      sortKey: 'flagReason',
      sortOrder: 'asc',
      fn: (r) => r.flagReason,
    },
    {
      sortTitle: 'Reason',
      sortKey: 'flagReason',
      sortOrder: 'desc',
      fn: (r) => r.flagReason,
    },
    {
      sortTitle: 'Comment',
      sortKey: 'comment',
      sortOrder: 'asc',
      fn: (r) => r.comment?.toLocaleLowerCase(),
    },
    {
      sortTitle: 'Comment',
      sortKey: 'comment',
      sortOrder: 'desc',
      fn: (r) => r.comment?.toLocaleLowerCase(),
    },
    {
      sortTitle: 'Email',
      sortKey: 'email',
      sortOrder: 'asc',
      fn: (r) => r.email?.toLocaleLowerCase(),
    },
    {
      sortTitle: 'Email',
      sortKey: 'email',
      sortOrder: 'desc',
      fn: (r) => r.email?.toLocaleLowerCase(),
    },
  ];
  @observable searchText = '';
  @observable page = 1;
  @observable pageSize = 10;

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.flaggedRecipes.clear();
    this.loader.reset();
  };

  @computed get recipesArray(): FlaggedRecipe[] {
    return Array.from(this.flaggedRecipes.values());
  }

  @computed get recipesFiltered(): FlaggedRecipe[] {
    return this.recipesArray.filter((item) => hasSearch(this.searchText, item));
  }

  @computed get recipes(): FlaggedRecipe[] {
    const newList = this.recipesFiltered.slice();
    if (this.sort.fn) {
      newList.sort(createSort(this.sort.fn, this.sort.sortOrder));
    }
    return pageSlice(newList, this.page, this.pageSize);
  }

  @computed get total(): number {
    return this.recipesFiltered.length;
  }

  @action search = (query: string) => {
    this.searchText = query;
  };

  @action setSort = (sort?: Sort): void => {
    this.sort = sort ?? DEFAULT_SORT;
  };

  @action pageChange = (page: number): void => {
    this.page = page;
  };

  @action loadFlaggedRecipes = async () => {
    const { cancelled, data, error } = await this.loader.load(() => getFlaggedRecipes());

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      const uniqData = uniq(data.recipes).map((recipe, index) => {
        const entityId = `${recipe.id}-${recipe.flagReason}-${recipe.community?.id ?? ''}`;
        return { ...recipe, index, entityId, loading: false };
      });
      this.flaggedRecipes.replace(uniqData.map((recipe) => [recipe.entityId, recipe]));
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action removeRecipeFromList = (id: string) => {
    this.flaggedRecipes.delete(id);

    if (this.flaggedRecipes.size === 0) {
      void this.loadFlaggedRecipes();
    }
  };

  @action applyAction = async ({ actionId, recipe }: { actionId: ActionId; recipe: FlaggedRecipe }) => {
    recipe.loading = true;

    let remove = false;

    if (actionId === ActionId.Approve) {
      remove = await this.approveRecipe({ recipeId: recipe.id, communityId: recipe.community?.id });
    } else {
      const communityId = recipe.community?.id;

      remove = await this.banRecipe({
        recipeId: recipe.id,
        scope: {
          value:
            actionId === ActionId.FlagInCommunity && isPresent(communityId)
              ? { oneof: 'community', value: { communityId } }
              : actionId === ActionId.FlagAllCommunities
              ? { oneof: 'allCommunities', value: { dummyFieldFoo: '' } }
              : { oneof: 'global', value: { dummyFieldBar: '' } },
        },
      });
    }

    recipe.loading = false;

    if (remove) {
      this.removeRecipeFromList(recipe.entityId);
    }
  };

  @action private readonly approveRecipe = async (payload: ApproveRecipeRequest) => {
    const { success, error } = await approveRecipe(payload);
    if (success) {
      getStore('toast').success('Recipe approved');
    } else {
      getStore('toast').error(error.message ?? `Can't approve Recipe`);
    }
    return success;
  };

  @action private readonly banRecipe = async (payload: BanRecipeRequest) => {
    const { success, error } = await banRecipe(payload);
    if (success) {
      getStore('toast').success('Recipe banned');
    } else {
      getStore('toast').error(error.message ?? `Can't ban Recipe`);
    }
    return success;
  };
}
