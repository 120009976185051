import { observer } from 'mobx-react-lite';

import { AppCreateForm } from 'b2b/apps/create/form';
import { PageHeader } from 'common/components/page-header';
import { useStore } from 'common/hooks/use-store';

import { AppCreateStore } from './store';

export const AppCreatePage = observer(() => {
  const store = useStore(() => new AppCreateStore());

  return (
    <div>
      <PageHeader title="New API Key" />
      <AppCreateForm store={store} />
    </div>
  );
});
