import { isDefined } from '@whisklabs/typeguards';
import { action, makeObservable, observable } from 'mobx';

import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore, getStore } from 'common/stores';

import { Campaign, CampaignPayload, GetCampaignRequest, getCampaign, stubCampaign, updateCampaign } from '../api';

export class CampaignDetailsStore extends BaseStore {
  @observable.ref campaign: Campaign = stubCampaign();

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  readonly saveLoader = new DataLoader();

  @action storeReset = () => {
    this.loader.reset();
    this.saveLoader.reset();
    this.campaign = stubCampaign();
  };

  @action loadCampaign = async (payload: GetCampaignRequest) => {
    const { cancelled, data, error } = await this.loader.load(() => getCampaign(payload));

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.campaign)) {
      this.campaign = data.campaign;
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };

  @action saveCampaign = async (campaignPayload: CampaignPayload) => {
    const { cancelled, data, error } = await this.saveLoader.load(() =>
      updateCampaign({
        campaignId: this.campaign.id,
        campaign: campaignPayload,
      })
    );

    if (cancelled) {
      return;
    }

    if (isDefined(data) && isDefined(data.campaign)) {
      getStore('toast').success('Campaign updated');
      this.campaign = data.campaign;
      this.saveLoader.ok();
    } else {
      this.saveLoader.fail(error);
    }
  };

  @action handleCampaignDeleted = () => {
    getStore('router').replace('/b2b/campaigns');
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }
}
