import { isDefined } from '@whisklabs/typeguards';
import { action, computed, makeObservable, observable } from 'mobx';

import { InfiniteListStoreType, LoadInfiniteListParams } from 'common/components/infinite-list';
import { textOrFallback } from 'common/helpers/data';
import { DataLoader, LoadStatus } from 'common/helpers/data-loader';
import { BaseStore } from 'common/stores';

import { GetPublishersRequest, Publisher, getPublishers } from './api';

export class PublishersStore extends BaseStore implements InfiniteListStoreType {
  private readonly publisherById = observable.map<string, Publisher>([], { deep: false });
  @observable hasMoreData = true;

  readonly loader = new DataLoader({
    initialStatus: LoadStatus.pending,
  });

  @action storeReset = () => {
    this.publisherById.clear();
    this.loader.reset();
    this.hasMoreData = true;
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  @computed get publishers(): Publisher[] {
    return Array.from(this.publisherById.values(), (publisher) => ({
      ...publisher,
      name: textOrFallback(publisher.name, 'Publisher ""'),
    }));
  }

  @action loadInitialData = async (params: LoadInfiniteListParams) => {
    await this.loadData({
      searchQuery: params.query,
      limit: params.limit,
      offset: 0,
    });
  };

  @action loadMoreData = async (params: LoadInfiniteListParams) => {
    if (this.hasMoreData) {
      await this.loadData({
        searchQuery: params.query,
        limit: params.limit,
        offset: this.publisherById.size,
      });
    }
  };

  @action private readonly loadData = async (payload: GetPublishersRequest) => {
    const { cancelled, data, error } = await this.loader.load(() => getPublishers(payload));

    if (cancelled) {
      return;
    }

    if (isDefined(data)) {
      if ((payload.offset ?? 0) > 0) {
        data.publishers.forEach((publisher) => this.publisherById.set(publisher.id, publisher));
      } else {
        this.publisherById.replace(data.publishers.map((publisher) => [publisher.id, publisher]));
      }

      this.hasMoreData = data.publishers.length >= (payload.limit ?? 0);
      this.loader.ok();
    } else {
      this.loader.fail(error);
    }
  };
}
