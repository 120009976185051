import { isPresent, isText } from '@whisklabs/typeguards';

import { ENVs, EnvMap, ParsedUrl } from './types';
export const APP_ENV_QUERY = 'app-env';

const rDomain = /(?:([^.]+.)\.)?(backstage)(?:.([^.]+.))?.whisk(?:-(dev))?.com/;

export function parseUrl(path: string): ParsedUrl {
  const result = rDomain.exec(path);

  if (!isPresent(result)) {
    return { env: 'local', envOriginal: 'local', dev: true, domain: 'localhost' };
  }

  const [, feature, domain = 'backstage', envRaw, devRaw] = result;

  const dev = devRaw === 'dev';
  const env = dev ? envRaw ?? 'base' : 'production';
  return {
    feature,
    env,
    envOriginal: env,
    dev,
    domain,
  };
}

export function initEnv(): ParsedUrl {
  const parsed = parseUrl(window.location.hostname);
  parsed.env = getQuery(APP_ENV_QUERY) ?? parsed.env;
  return parsed;
}

export const envCreate =
  (app: ParsedUrl) =>
  <T>(obj: EnvMap<T>, q: { query?: string } = {}): T => {
    const env = isText(q.query) ? getQuery(q.query) ?? app.env : app.env;
    const key = getMatch(env, obj);
    return obj[key] as T;
  };

export const getQuery = (q: string) => new URLSearchParams(window.location.search).get(q);

function getMatch<T>(env: string, obj: EnvMap<T>): ENVs {
  for (const key in obj) {
    const reg = new RegExp(`^(${key})$`);

    if (reg.test(env)) {
      return key as ENVs;
    }
  }

  return 'base';
}
